import { Autocomplete } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import { useState } from 'react';
import { RtxTextInput } from 'RtUi/components/rtx/inputs/Text/RtxTextInput';
import { RtxChip } from 'RtUi/components/rtx/ui/Chip/RtxChip';

type RtxTagListIInputProps = {
	value: string[];
	className?: string;
	disabled?: boolean;
	label?: string;
	customValidation?: (value: string) => string;
	onChange: (value: string[]) => void;
};

export const RtxTagListIInput = ({
	value = [],
	label,
	className,
	disabled,
	customValidation = (value: string) => value,
	onChange
}: RtxTagListIInputProps) => {
	const [inputValue, setInputValue] = useState<string>('');

	return (
		<Autocomplete
			disableClearable
			options={[]}
			className={className}
			freeSolo
			disabled={disabled}
			value={value}
			multiple
			inputValue={inputValue}
			onInputChange={(event, newInputValue) => {
				setInputValue(customValidation(newInputValue));
			}}
			renderTags={(value, props) => {
				return (
					<>
						{value.map((option, index) => (
							<RtxChip
								label={option}
								{...props({ index })}
								key={index}
								deleteIcon={<ClearIcon />}
							/>
						))}
					</>
				);
			}}
			onChange={(event: any, newValue: string[]) => {
				onChange(newValue);
			}}
			renderInput={(params) => (
				<RtxTextInput
					{...params}
					label={label}
					sx={{
						'minSize': 10,
						'.MuiInputBase-root': {
							'padding': '5px 6px',
							'gap': '3px',
							'.MuiInputBase-input': {
								height: '22px',
								boxSizing: 'border-box',
								padding: 0
							}
						}
					}}
				/>
			)}
		/>
	);
};
