import { noop } from 'lodash-es';
import { useState } from 'react';
import { isValidFqdn } from 'RtUi/app/AccountManagement/Switches/lib/utils';
import {
	RtxTextInput,
	RtxTextInputProps
} from 'RtUi/components/rtx/inputs/Text/RtxTextInput';

type RtxFqdnInputProps = Omit<RtxTextInputProps, 'error' | 'helperText'>;

export const RtxFqdnInput = ({
	onChange = noop,
	...props
}: RtxFqdnInputProps) => {
	const [error, setError] = useState<string>();

	const onChangeHandler = (currentValue: string) => {
		setError('');

		if (!isValidFqdn(currentValue)) {
			setError('invalid value');
		}

		onChange(currentValue);
	};

	return (
		<RtxTextInput
			helperText={error}
			error={Boolean(error)}
			onChange={onChangeHandler}
			{...props}
		/>
	);
};
