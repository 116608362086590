import { useMemo } from 'react';
import { ServiceIndexResponse } from 'RtModels';
import { useGetServices } from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import { ServiceTypeLookup } from 'RtUi/app/rtSip/Services/lib/resources/ServiceResource';

export const useGetServicesOfConnectionType = (
	serviceType?: ServiceTypeLookup
) => {
	const { data: services, isFetching: isLoading } = useGetServices(
		{},
		false,
		serviceType !== undefined
	);

	const filteredServices = useMemo(() => {
		if (!serviceType || !services) {
			return [];
		}

		const filteredRecords: ServiceIndexResponse[] = [];

		for (const record of services) {
			const hasEgress = record.hasEgressConnections === 1;
			const hasIngress = record.hasIngressConnections === 1;
			const hasEither = hasEgress || hasIngress;
			const hasNone = !hasEither;

			if (serviceType === ServiceTypeLookup.Egress && hasEgress) {
				filteredRecords.push(record);
				continue;
			}

			if (serviceType === ServiceTypeLookup.Ingress && hasIngress) {
				filteredRecords.push(record);
				continue;
			}

			if (serviceType === ServiceTypeLookup.IngressOrEgress && hasEither) {
				filteredRecords.push(record);
				continue;
			}

			if (serviceType === ServiceTypeLookup.None && hasNone) {
				filteredRecords.push(record);
				continue;
			}
		}

		return filteredRecords;
	}, [services, serviceType]);

	return { services: filteredServices, isLoading };
};
