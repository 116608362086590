import { noop } from 'lodash-es';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
	BlockTypeIndexResponse,
	MarginPlanIndexResponse,
	RateCenterIndexResponse,
	SubscriptionIndexResponse,
	SubscriptionQosMatch,
	SubscriptionQosProfileResponse,
	SubscriptionQosUpdateRequest
} from 'RtModels';
import {
	customerServiceTypes,
	vendorServiceTypes
} from 'RtUi/app/AccountManagement/Subscriptions/lib/constants';
import { SubscriptionQosResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionQosResource';
import { useGetSubscriptionQos } from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import { RtxForm } from 'RtUi/components/rtx/form';
import { RtxBooleanInput } from 'RtUi/components/rtx/inputs/Boolean/RtxBooleanInput';
import { RtxRadioInput } from 'RtUi/components/rtx/inputs/Radio/RtxRadioInput';
import { RtxBlockTypeSelect } from 'RtUi/components/rtx/inputs/Select/instances/BlockType/RtxBlockTypeSelect';
import { RtxMarginPlanSelect } from 'RtUi/components/rtx/inputs/Select/instances/MarginPlan/RtxMarginPlanSelect';
import { RtxRateCenterSelect } from 'RtUi/components/rtx/inputs/Select/instances/RateCenter/RtxRateCenterSelect';
import { RtxSubscriptionSelect } from 'RtUi/components/rtx/inputs/Select/instances/Subscription/RtxSubscriptionSelect';
import { RtxSliderInput } from 'RtUi/components/rtx/inputs/Slider/RtxSliderInput';
import { Loading } from 'RtUi/components/ui/Loading';
import { RtError } from 'RtUi/utils/errors/RtError';

interface ISubscriptionQosEditFormProps {
	rateCenterId: number;
	subscriptionId: number;
	onSuccess?: (profile: SubscriptionQosProfileResponse) => void;
	onCancel?: () => void;
}

export const SubscriptionQosEditForm = ({
	rateCenterId,
	subscriptionId,
	onSuccess = noop,
	onCancel
}: ISubscriptionQosEditFormProps) => {
	const [subscription, setSubscription] = useState<SubscriptionIndexResponse>();
	const [marginPlan, setMarginPlan] = useState<MarginPlanIndexResponse>();
	const [blockType, setBlockType] = useState<BlockTypeIndexResponse>();
	const [rateCenter, setRateCenter] = useState<RateCenterIndexResponse>();
	const [error, setError] = useState<RtError>();
	const { data, isFetching: isLoading } = useGetSubscriptionQos({
		rateCenterId,
		subscriptionId
	});

	if (isLoading) {
		return <Loading />;
	}

	if (!data) {
		return <p>Not found!</p>;
	}

	const shouldShowVendorFields = () => {
		return subscription && vendorServiceTypes.includes(subscription.serviceId);
	};

	const shouldShowCustomerFields = () => {
		return (
			subscription && customerServiceTypes.includes(subscription.serviceId)
		);
	};

	const onSubmit = async (data: SubscriptionQosUpdateRequest) => {
		const rs = new SubscriptionQosResource(subscriptionId);
		setError(undefined);
		data.marginPlanId = marginPlan?.marginPlanId || 1;

		try {
			const profile = await rs.updateProfile(
				subscriptionId,
				rateCenterId,
				data
			);
			onSuccess(profile);
		} catch (e: any) {
			setError(new RtError(e));
		}
	};

	return (
		<RtxForm<SubscriptionQosUpdateRequest>
			defaultValues={{
				filter: SubscriptionQosMatch.AllRateCentersInCountry,
				...data
			}}
			onSubmit={onSubmit}
			error={
				error && {
					name: 'root',
					error: {
						message: error.message
					}
				}
			}
			onCancel={onCancel}
		>
			{({ control }) => (
				<>
					<h5 className="mb-3">Rate Center</h5>
					<RtxSubscriptionSelect
						required
						displayMode
						onChange={(subscription) => {
							setSubscription(subscription);
						}}
						value={subscription}
						className="mb-3"
						initialOptionId={subscriptionId}
						appendDropdownToBody={false}
					/>
					<Controller
						control={control}
						name="rateCenterId"
						render={({ field: { value, onChange } }) => (
							<RtxRateCenterSelect
								required
								label="Rate Center"
								initialOptionId={value}
								className="mb-3"
								onChange={(rateCenter) => {
									setRateCenter(rateCenter);
									onChange(rateCenter.rateCenterId);
								}}
								value={rateCenter}
								appendDropdownToBody={false}
							/>
						)}
					/>
					<Controller
						control={control}
						name="filter"
						render={({ field: { value, onChange } }) => (
							<RtxRadioInput<SubscriptionQosMatch>
								label="Apply Changes To"
								className="mb-3"
								value={value}
								onChange={(value) => onChange(Number(value))}
								required
								options={[
									{
										value: SubscriptionQosMatch.CurrentRateCenterOnly,
										label: 'This rate center only'
									},
									{
										value:
											SubscriptionQosMatch.AllRateCentersOfSameTypeInCountry,
										label: 'All rate centers of the same type in this country'
									},
									{
										value: SubscriptionQosMatch.AllRateCentersInCountry,
										label: 'All rate centers in this country'
									},
									{
										value: SubscriptionQosMatch.AllRateCentersInAllCountries,
										label: 'All rate centers in all countries'
									}
								]}
							/>
						)}
					/>
					{shouldShowCustomerFields() && (
						<>
							<Controller
								control={control}
								name="marginPlanId"
								render={({ field: { value, onChange } }) => (
									<RtxMarginPlanSelect
										className="mb-3"
										onChange={(marginPlan) => {
											onChange(marginPlan.marginPlanId);
											setMarginPlan(marginPlan);
										}}
										initialOptionId={value || 1}
										value={marginPlan}
										appendDropdownToBody={false}
									/>
								)}
							/>
							<Controller
								control={control}
								name="blockTypeId"
								render={({ field: { value, onChange } }) => (
									<RtxBlockTypeSelect
										isClearable={true}
										className="mb-3"
										onChange={(blockType) => {
											onChange(blockType?.blockTypeId);
											setBlockType(blockType);
										}}
										initialOptionId={value || undefined}
										value={blockType}
										appendDropdownToBody={false}
									/>
								)}
							/>
						</>
					)}
					{shouldShowVendorFields() && (
						<>
							<Controller
								control={control}
								name="isRoutable"
								render={({ field: { value, onChange } }) => (
									<RtxBooleanInput
										className="mb-3"
										label="Is Routable"
										onChange={onChange}
										value={value}
									/>
								)}
							/>

							<Controller
								control={control}
								name="isLcrEnabled"
								render={({ field: { value, onChange } }) => (
									<RtxBooleanInput
										className="mb-3"
										label="Is In Default LCR"
										onChange={onChange}
										value={value}
									/>
								)}
							/>
							<Controller
								control={control}
								name="isRouteDepth"
								render={({ field: { value, onChange } }) => (
									<RtxBooleanInput
										className="mb-3"
										label="Include in Route Depth"
										onChange={onChange}
										value={value}
									/>
								)}
							/>
						</>
					)}
					<h5 className="mb-3">QoS Matching</h5>
					<Controller
						control={control}
						name="sq1"
						render={({ field: { value, onChange } }) => (
							<RtxSliderInput
								className="mb-3"
								label="SQ1"
								onChange={(sq1) => onChange(sq1 / 100)}
								value={Math.round(value * 100)}
							/>
						)}
					/>
					<Controller
						control={control}
						name="sq2"
						render={({ field: { value, onChange } }) => (
							<RtxSliderInput
								className="mb-3"
								label="SQ2"
								onChange={(sq2) => onChange(sq2 / 100)}
								value={Math.round(value * 100)}
							/>
						)}
					/>
					<Controller
						control={control}
						name="sq3"
						render={({ field: { value, onChange } }) => (
							<RtxSliderInput
								className="mb-3"
								label="SQ3"
								onChange={(sq3) => onChange(sq3 / 100)}
								value={Math.round(value * 100)}
							/>
						)}
					/>
					<Controller
						control={control}
						name="sq4"
						render={({ field: { value, onChange } }) => (
							<RtxSliderInput
								className="mb-3"
								label="SQ4"
								onChange={(sq4) => onChange(sq4 / 100)}
								value={Math.round(value * 100)}
							/>
						)}
					/>
					<Controller
						control={control}
						name="sq5"
						render={({ field: { value, onChange } }) => (
							<RtxSliderInput
								className="mb-3"
								label="SQ5"
								onChange={(sq5) => onChange(sq5 / 100)}
								value={Math.round(value * 100)}
							/>
						)}
					/>
					<Controller
						control={control}
						name="sq6"
						render={({ field: { value, onChange } }) => (
							<RtxSliderInput
								className="mb-3"
								label="SQ6"
								onChange={(sq6) => onChange(sq6 / 100)}
								value={Math.round(value * 100)}
							/>
						)}
					/>
					<Controller
						control={control}
						name="sq7"
						render={({ field: { value, onChange } }) => (
							<RtxSliderInput
								className="mb-3"
								label="SQ7"
								onChange={(sq7) => onChange(sq7 / 100)}
								value={Math.round(value * 100)}
							/>
						)}
					/>
					<Controller
						control={control}
						name="sq8"
						render={({ field: { value, onChange } }) => (
							<RtxSliderInput
								className="mb-3"
								label="SQ8"
								onChange={(sq8) => onChange(sq8 / 100)}
								value={Math.round(value * 100)}
							/>
						)}
					/>
					<Controller
						control={control}
						name="sq9"
						render={({ field: { value, onChange } }) => (
							<RtxSliderInput
								className="mb-3"
								label="SQ9"
								onChange={(sq9) => onChange(sq9 / 100)}
								value={Math.round(value * 100)}
							/>
						)}
					/>
					<h5 className="mb-3">Feature Matching</h5>
					<Controller
						control={control}
						name="cli"
						render={({ field: { value, onChange } }) => (
							<RtxBooleanInput
								className="mb-3"
								label="CLI"
								onChange={onChange}
								value={value}
							/>
						)}
					/>
					<Controller
						control={control}
						name="t38"
						render={({ field: { value, onChange } }) => (
							<RtxBooleanInput
								className="mb-3"
								label="T38"
								onChange={onChange}
								value={value}
							/>
						)}
					/>
					<Controller
						control={control}
						name="test"
						render={({ field: { value, onChange } }) => (
							<RtxBooleanInput
								className="mb-3"
								label="Test"
								onChange={onChange}
								value={value}
							/>
						)}
					/>
				</>
			)}
		</RtxForm>
	);
};
