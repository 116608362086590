/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum DestinationTypeId {
    Ftp = 1,
    Sftp = 2,
    Aws = 3,
    Email = 4,
}