import * as React from 'react';
import * as pluralize from 'pluralize';
import {
	IMetricsIndexSummary,
	DidMetricsHttp
} from 'RtUi/app/rtDid/Dashboard/lib/http/DidMetricHttp';
import { MetricSummariesVennChart } from 'RtUi/app/rtDid/Dashboard/lib/charts/MetricSummariesVennChart';
import { Row, Col } from 'react-bootstrap';
import { MetricSummaryItem } from 'RtUi/app/rtDid/Dashboard/lib/components/MetricSummaryItem';
import ContentLoader from 'react-content-loader';
import { ResourceType } from 'RtModels';
import { SelectedSummariesContext } from 'RtUi/app/rtDid/Dashboard/lib/components/context';

interface IMetricSummaryProps {
	className?: string;
	onDrilldownClick: (selected: ResourceType[]) => void;
}

interface IMetricSummaryState {
	summaries: IMetricsIndexSummary[];
	selected: ResourceType[];
	isLoading: boolean;
}

export class MetricSummary extends React.Component<
	IMetricSummaryProps,
	IMetricSummaryState
> {
	public didMetricsHttp = new DidMetricsHttp();
	public state: IMetricSummaryState = {
		summaries: [],
		selected: [],
		isLoading: true
	};

	public async componentDidMount() {
		const summaries = await this.didMetricsHttp.getMetricSummary();

		this.setState({ summaries, isLoading: false });
	}

	public getNumberPhrase(qty: number) {
		const pluralizedWord = pluralize('number', qty);
		const pairWord = qty > 1 ? 'are' : 'is';

		return `${qty.toLocaleString()} ${pluralizedWord} ${pairWord}`;
	}

	public updateSelected(selected: ResourceType[]) {
		this.setState({ selected });
	}

	public onClick(selected: ResourceType[]) {
		this.props.onDrilldownClick(selected);
	}

	public render() {
		const { summaries } = this.state;

		const billingSummary = summaries.find((ms) => ms.source === 'billing');
		const vendorSummary = summaries.find((ms) => ms.source === 'vendor');
		const switchSummary = summaries.find((ms) => ms.source === 'switch');

		const billingAndSwitch = billingSummary?.inSwitch ?? 0;
		const billingAndVendor = billingSummary?.inVendor ?? 0;
		const switchAndVendor = switchSummary?.inVendor ?? 0;
		const vendorOnly = vendorSummary?.isUnique ?? 0;
		const billingOnly = billingSummary?.isUnique ?? 0;
		const switchOnly = switchSummary?.isUnique ?? 0;
		const intersectionOfAll = switchSummary?.inBoth ?? 0;
		const totalNumbers =
			billingAndSwitch +
			billingAndVendor +
			switchAndVendor +
			vendorOnly +
			billingOnly +
			switchOnly +
			intersectionOfAll;

		return (
			<SelectedSummariesContext.Provider
				value={{
					selected: this.state.selected,
					toggleSelected: (selected) => this.updateSelected(selected),
					onClick: (selected) => this.onClick(selected)
				}}
			>
				<section className={this.props.className}>
					<Row>
						<Col xl={5} lg={6} className="d-none d-lg-flex">
							<MetricSummariesVennChart
								selected={this.state.selected}
								toggleSelected={(selected: any) =>
									this.updateSelected(selected)
								}
								onClick={(selected: any) => this.onClick(selected)}
								isLoading={this.state.isLoading}
								summaries={this.state.summaries}
							/>
						</Col>
						<Col
							xl={7}
							lg={6}
							className="d-flex justify-content-start align-items-center"
						>
							<article className="w-100 pe-3">
								<header>
									{this.state.isLoading && (
										<ContentLoader
											preserveAspectRatio="none"
											height={29}
											width={187}
										>
											<rect x={0} y={0} rx={5} ry={5} width={125} height={29} />
											<rect
												x={130}
												y={0}
												rx={5}
												ry={5}
												width={57}
												height={29}
											/>
										</ContentLoader>
									)}
									{!this.state.isLoading && (
										<h5 className="text-muted">
											Total Numbers: {totalNumbers.toLocaleString()}
										</h5>
									)}
									<hr />
								</header>
								<MetricSummaryItem
									className="mb-4"
									isLoading={this.state.isLoading}
									resourceTypes={[ResourceType.Billing, ResourceType.Switch]}
									color={billingAndSwitch > 0 ? 'warning' : 'success'}
								>
									{billingAndSwitch > 0 && (
										<>
											{this.getNumberPhrase(billingAndSwitch)} missing from your
											inventory.
										</>
									)}
									{billingAndSwitch <= 0 && <>Your inventory is up-to-date.</>}
								</MetricSummaryItem>
								<MetricSummaryItem
									className="mb-4"
									isLoading={this.state.isLoading}
									resourceTypes={[ResourceType.Billing, ResourceType.DidVendor]}
									color={billingAndVendor > 0 ? 'danger' : 'success'}
								>
									{billingAndVendor > 0 && (
										<>
											{this.getNumberPhrase(billingAndVendor)} not in your
											switch.
										</>
									)}
									{billingAndVendor <= 0 && <>Your switch is up-to-date.</>}
								</MetricSummaryItem>
								<MetricSummaryItem
									className="mb-4"
									isLoading={this.state.isLoading}
									resourceTypes={[ResourceType.Switch, ResourceType.DidVendor]}
									color={switchAndVendor > 0 ? 'danger' : 'success'}
								>
									{switchAndVendor > 0 && (
										<>
											{this.getNumberPhrase(switchAndVendor)} not being billed
											for.
										</>
									)}
									{switchAndVendor <= 0 && <>Your billing is up-to-date.</>}
								</MetricSummaryItem>
								<MetricSummaryItem
									className="mb-4"
									isLoading={this.state.isLoading}
									resourceTypes={[ResourceType.DidVendor]}
									color={vendorOnly > 0 ? 'danger' : 'success'}
								>
									{vendorOnly > 0 && (
										<>
											{this.getNumberPhrase(vendorOnly)} unused in your
											inventory.
										</>
									)}
									{vendorOnly <= 0 && <>Your inventory is up-to-date.</>}
								</MetricSummaryItem>
							</article>
						</Col>
					</Row>
				</section>
			</SelectedSummariesContext.Provider>
		);
	}
}
