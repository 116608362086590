import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { CreditLimitProfileResponse } from 'RtModels';
import { AccountCreditLimitResource } from 'RtUi/app/AccountManagement/Accounts/lib/resources/AccountCreditLimitResource';
import { useGetCreditLimit } from 'RtUi/app/AccountManagement/Accounts/lib/services';
import { RtxForm } from 'RtUi/components/rtx/form';
import { RtxBooleanInput } from 'RtUi/components/rtx/inputs/Boolean/RtxBooleanInput';
import { RtxPrecisionInput } from 'RtUi/components/rtx/inputs/Precision/RtxPrecisionInput';
import { Loading } from 'RtUi/components/ui/Loading';
import { RtError } from 'RtUi/utils/errors/RtError';

interface ICreditLimitEditFormProps {
	accountId: number;
}

export const CreditLimitEditForm = ({
	accountId
}: ICreditLimitEditFormProps) => {
	const [displayMode, setDisplayMode] = useState<boolean>(true);
	const [error, setError] = useState<RtError>();
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetCreditLimit({ accountId });
	const [isCustomerLimit, setIsCustomerLimit] = useState<boolean>(false);
	const [isVendorLimit, setIsVendorLimit] = useState<boolean>(false);
	const [isCombinedLimit, setIsCombinedLimit] = useState<boolean>(false);

	useEffect(() => {
		if (data) {
			setIsCustomerLimit(Boolean(data.customerLimit));
			setIsVendorLimit(Boolean(data.vendorLimit));
			setIsCombinedLimit(Boolean(data.combinedLimit));
		}
	}, [data]);

	if (isLoading) {
		return <Loading />;
	}

	const showBoolean = (value: Boolean) =>
		!displayMode || (displayMode && !value);

	const update = async (data: CreditLimitProfileResponse) => {
		const creditLimitResource = new AccountCreditLimitResource(accountId);

		setError(undefined);

		try {
			await creditLimitResource.update({
				customerLimit: Boolean(isCustomerLimit)
					? data.customerLimit
					: undefined,
				vendorLimit: Boolean(isVendorLimit) ? data.vendorLimit : undefined,
				combinedLimit: isCombinedLimit ? data.combinedLimit : undefined
			});

			setDisplayMode(true);
			refetch();
		} catch (error: any) {
			setError(new RtError(error));
		}
	};

	return (
		<RtxForm<CreditLimitProfileResponse>
			defaultValues={{ ...data }}
			displayMode={displayMode}
			error={
				error && {
					name: 'root',
					error: {
						message: error.message
					}
				}
			}
			onEdit={() => setDisplayMode(false)}
			onCancel={() => setDisplayMode(true)}
			onSubmit={update}
		>
			{({ control }) => (
				<>
					<Form.Group className="mb-3">
						{showBoolean(isCustomerLimit) && (
							<RtxBooleanInput
								displayMode={displayMode}
								label="Set Customer / Credit Limit"
								onChange={(isCustomerLimit) =>
									setIsCustomerLimit(Boolean(isCustomerLimit))
								}
								value={Number(isCustomerLimit)}
							/>
						)}
						{isCustomerLimit && (
							<Controller
								control={control}
								name="customerLimit"
								render={({ field: { value, onChange } }) => (
									<RtxPrecisionInput
										onChange={onChange}
										className="mt-3"
										precision={2}
										placeholder="Type Credit Limit"
										required={isCustomerLimit}
										label="Customer / Credit Limit"
										displayMode={displayMode}
										value={value}
									/>
								)}
							/>
						)}
					</Form.Group>
					<Form.Group className="mb-3">
						{showBoolean(isVendorLimit) && (
							<RtxBooleanInput
								displayMode={displayMode}
								label="Set Vendor / Credit Limit"
								onChange={(isVendorLimit) =>
									setIsVendorLimit(Boolean(isVendorLimit))
								}
								value={Number(isVendorLimit)}
							/>
						)}
						{isVendorLimit && (
							<Controller
								control={control}
								name="vendorLimit"
								render={({ field: { value, onChange } }) => (
									<RtxPrecisionInput
										onChange={onChange}
										className="mt-3"
										precision={2}
										label="Vendor / Credit Limit"
										required={isVendorLimit}
										displayMode={displayMode}
										value={value}
										placeholder="Type Credit Limit"
									/>
								)}
							/>
						)}
					</Form.Group>
					<Form.Group className="mb-3">
						{showBoolean(isCombinedLimit) && (
							<RtxBooleanInput
								displayMode={displayMode}
								label="Set Combined Limit"
								onChange={(isCombinedLimit) =>
									setIsCombinedLimit(Boolean(isCombinedLimit))
								}
								value={Number(isCombinedLimit)}
							/>
						)}
						{isCombinedLimit && (
							<Controller
								control={control}
								name="combinedLimit"
								render={({ field: { value, onChange } }) => (
									<RtxPrecisionInput
										onChange={onChange}
										precision={2}
										className="mt-3"
										label="Combined Credit Limit"
										displayMode={displayMode}
										value={value}
										required={isCombinedLimit}
										placeholder="Type Credit Limit"
									/>
								)}
							/>
						)}
					</Form.Group>
				</>
			)}
		</RtxForm>
	);
};
