import { useMemo } from 'react';
import { CicIndexResponse } from 'RtModels';

import { useGetCicLookups } from 'RtUi/app/Administration/CicLookup/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCicLookupColumns } from 'RtUi/components/data/DataGrid/configurations/administration/cicLookups';

interface ICicLookupGridProps {}

export const CicLookupGrid = ({}: ICicLookupGridProps) => {
	const { data, isLoading } = useGetCicLookups(true);

	const columns = useMemo(() => getCicLookupColumns(), []);

	return (
		<DataGrid<CicIndexResponse>
			data={data?.data}
			columns={columns}
			pageName={'administration-cicLookups'}
			loading={isLoading}
			totalRows={data?.totalCount}
		/>
	);
};
