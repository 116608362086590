import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	AccountIndexRequest,
	AccountIndexResponse,
	AccountProfileRequest,
	AccountSubscriptionIndexRequest,
	CreditLimitProfileRequest,
	CreditLimitProfileResponse,
	PortLimitIndexRequest,
	PortLimitIndexResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchAccounts = async <IncludeFullResponse extends boolean = false>(
	queryParams?: AccountIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<AccountIndexResponse[], IncludeFullResponse>> => {
	return handleGetRequest(RtxSipApiRoutes.Accounts.Index, {
		includeFullResponse,
		queryParams
	});
};

export const useGetAccounts = <IncludeFullResponse extends boolean = false>(
	queryParams?: AccountIndexRequest,
	includeFullResponse?: IncludeFullResponse
) => {
	return useQuery<
		RequestResponse<AccountIndexResponse[], IncludeFullResponse>,
		Error
	>(
		[`useGetAccounts`, queryParams, includeFullResponse],
		() => fetchAccounts(queryParams, includeFullResponse),
		{ enabled: true }
	);
};

const fetchCreditLimit = async (
	urlParams: CreditLimitProfileRequest
): Promise<CreditLimitProfileResponse> => {
	return handleGetRequest(RtxSipApiRoutes.CreditLimits.Profile, {
		urlParams
	});
};

export const useGetCreditLimit = (urlParams: CreditLimitProfileRequest) => {
	return useQuery<CreditLimitProfileResponse, Error>(
		[`useGetCreditLimit`, urlParams],
		() => fetchCreditLimit(urlParams),
		{ enabled: true }
	);
};

const fetchAccountSubscriptions = async (
	urlParams: AccountProfileRequest,
	queryParams?: AccountSubscriptionIndexRequest
): Promise<FullResponse<SubscriptionIndexResponse[]>> => {
	return handleGetRequest<SubscriptionIndexResponse[], true>(
		RtxSipApiRoutes.AccountSubscriptions.Index,
		{
			includeFullResponse: true,
			queryParams,
			urlParams
		}
	);
};

export const useGetAccountSubscriptions = (
	urlParams: AccountProfileRequest,
	queryParams?: AccountSubscriptionIndexRequest
) => {
	return useQuery<FullResponse<SubscriptionIndexResponse[]>, Error>(
		[`useGetAccountSubscriptions`, urlParams, queryParams],
		() => fetchAccountSubscriptions(urlParams, queryParams),
		{ enabled: true }
	);
};

const fetchAccountPortLimits = async (
	urlParams?: PortLimitIndexRequest
): Promise<FullResponse<PortLimitIndexResponse[]>> => {
	return handleGetRequest<PortLimitIndexResponse[], true>(
		RtxSipApiRoutes.AccountPortLimits.Index,
		{
			includeFullResponse: true,
			urlParams
		}
	);
};

export const useGetAccountPortLimits = (urlParams?: PortLimitIndexRequest) => {
	return useQuery<FullResponse<PortLimitIndexResponse[]>, Error>(
		[`useGetAccountPortLimits`],
		() => fetchAccountPortLimits(urlParams),
		{ enabled: true }
	);
};
