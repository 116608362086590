import { useMemo } from 'react';
import { RoutePlanIndexResponse } from 'RtModels';
import { useGetRoutePlans } from 'RtUi/app/rtSip/RoutePlans/lib/service';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxRoutePlanSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		RoutePlanIndexResponse,
		'routePlanId',
		IsMulti,
		IsClearable
	> {
	allowedRoutePlanIds?: number[];
}

export const RtxRoutePlanSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	allowedRoutePlanIds,
	label = 'Route Plan',
	...props
}: IRtxRoutePlanSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetRoutePlans();
	const options = useMemo(() => {
		if (!allowedRoutePlanIds) {
			return data;
		}

		return data.filter((routePlan) =>
			allowedRoutePlanIds.includes(routePlan.routePlanId)
		);
	}, [data, allowedRoutePlanIds]);

	return (
		<RtxSelectInput<RoutePlanIndexResponse, 'routePlanId', IsMulti, IsClearable>
			label={label}
			valueKey="routePlanId"
			labelKey="label"
			options={options}
			isLoading={isLoading}
			{...props}
		/>
	);
};
