import { noop } from 'lodash-es';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import {
	$AccountProfileResponse,
	AccountCreateRequest,
	AccountProfileResponse,
	AccountUpdateRequest,
	BillingEntityIndexResponse,
	RateAmendmentType,
	TimezoneIndexResponse
} from 'RtModels';
import { AccountResource } from 'RtUi/app/AccountManagement/Accounts/lib/resources/AccountResource';
import { RtxForm } from 'RtUi/components/rtx/form';
import { RtxIsActiveInput } from 'RtUi/components/rtx/inputs/IsActive/RtxIsActiveInput';
import { RtxRadioInput } from 'RtUi/components/rtx/inputs/Radio/RtxRadioInput';
import { RtxBillingEntitySelect } from 'RtUi/components/rtx/inputs/Select/instances/BillingEntity/RtxBillingEntitySelect';
import { RtxTimezoneSelect } from 'RtUi/components/rtx/inputs/Select/instances/Timezone/RtxTimezoneSelect';
import { RtxTextInput } from 'RtUi/components/rtx/inputs/Text/RtxTextInput';
import { RtError } from 'RtUi/utils/errors/RtError';

interface IAccountEditFormProps {
	profile?: AccountProfileResponse;
	onSuccess?: (profile: AccountProfileResponse) => void;
}

type AccountEditFormValue = AccountUpdateRequest | AccountCreateRequest;

export const AccountEditForm = ({
	profile,
	onSuccess = noop
}: IAccountEditFormProps) => {
	const [displayMode, setDisplayMode] = useState<boolean>(
		profile !== undefined
	);
	const [timezone, setTimezone] = useState<TimezoneIndexResponse>();
	const [billingEntity, setBillingEntity] =
		useState<BillingEntityIndexResponse>();
	const [error, setError] = useState<RtError>();

	const onSubmit = async (data: AccountEditFormValue) => {
		const accountResource = new AccountResource();
		setError(undefined);

		let newProfile: AccountProfileResponse;
		try {
			if (profile) {
				newProfile = await accountResource.update(
					profile.accountId,
					data as AccountUpdateRequest
				);
			} else {
				newProfile = await accountResource.create(data as AccountCreateRequest);
			}
			setDisplayMode(true);
			onSuccess(newProfile);
		} catch (error: any) {
			setError(new RtError(error));
		}
	};

	return (
		<Row>
			<Col md={8}>
				<RtxForm<AccountEditFormValue>
					displayMode={displayMode}
					error={
						error && {
							name: 'root',
							error: {
								message: error.message
							}
						}
					}
					onSubmit={onSubmit}
					createMode={profile === undefined}
					onEdit={() => setDisplayMode(false)}
					onCancel={() => setDisplayMode(true)}
					defaultValues={{
						timezoneId: 436,
						isActive: 1,
						rateAmendmentSubjectAtoZ:
							'%carrier Rate Amendment %id [%subscription]',
						rateAmendmentSubjectCountry:
							'%carrier Rate Amendment %id [%subscription]',
						rateAmendmentTypeId: RateAmendmentType.AtoZ,
						...profile
					}}
				>
					{({ control }) => (
						<Row>
							<Col md={6}>
								<h5 className="mb-3">Identification</h5>
								<Controller
									control={control}
									name="companyName"
									render={({ field: { value, onChange } }) => (
										<RtxTextInput
											required={
												$AccountProfileResponse.properties.companyName
													.isRequired
											}
											className="mb-3"
											label="Company name"
											displayMode={displayMode}
											value={value}
											onChange={onChange}
										/>
									)}
								/>
								<Controller
									control={control}
									name="label"
									render={({ field: { value, onChange } }) => (
										<RtxTextInput
											required={
												$AccountProfileResponse.properties.label.isRequired
											}
											label="Label"
											className="mb-3"
											displayMode={displayMode}
											value={value}
											onChange={onChange}
										/>
									)}
								/>
								{profile && (
									<Controller
										control={control}
										name="isActive"
										render={({ field: { value, onChange } }) => (
											<RtxIsActiveInput
												label="Active"
												className="mb-3"
												required
												hideBothOption
												displayMode={displayMode}
												onChange={onChange}
												value={value}
											/>
										)}
									/>
								)}
								<Controller
									control={control}
									name="billingEntityId"
									render={({ field: { value, onChange } }) => (
										<RtxBillingEntitySelect
											className="mb-3"
											required
											displayMode={displayMode}
											value={billingEntity}
											initialOptionId={value}
											onChange={(billingEntity) => {
												setBillingEntity(billingEntity);
												onChange(billingEntity.billingEntityId);
											}}
										/>
									)}
								/>
								<Controller
									control={control}
									name="timezoneId"
									render={({ field: { value, onChange } }) => (
										<RtxTimezoneSelect
											className="mb-3"
											required={
												$AccountProfileResponse.properties.timezoneId.isRequired
											}
											label="Billing Time Zone"
											displayMode={displayMode}
											onChange={(timeZone) => {
												setTimezone(timeZone);
												onChange(timeZone.timezoneId);
											}}
											value={timezone}
											initialOptionId={value}
										/>
									)}
								/>
							</Col>
							<Col md={6}>
								<h5 className="mb-3">Communications</h5>
								<Controller
									control={control}
									name="rateAmendmentTypeId"
									render={({ field: { value, onChange } }) => (
										<RtxRadioInput<RateAmendmentType>
											label="Rate Amendment Email Type"
											displayMode={displayMode}
											onChange={(value) => {
												onChange(Number(value));
											}}
											value={value}
											options={[
												{
													value: RateAmendmentType.AtoZ,
													label: 'A to Z'
												},
												{ value: RateAmendmentType.Country, label: 'Country' }
											]}
										/>
									)}
								/>
							</Col>
						</Row>
					)}
				</RtxForm>
			</Col>
		</Row>
	);
};
