import {
	CallTagMode,
	SubscriptionIndexResponse,
	SubscriptionTagProfileResponse,
	TagIndexResponse
} from 'RtModels';
import { SubscriptionTagResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionTagResource';
import { RtxForm } from 'RtUi/components/rtx/form';
import { RtxNumberInput } from 'RtUi/components/rtx/inputs/Number/RtxNumber';
import { RtxPercentageInput } from 'RtUi/components/rtx/inputs/Percentage/RtxPercentageInput';
import { IDefaultSelectOption } from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';
import { RtxSubscriptionSelect } from 'RtUi/components/rtx/inputs/Select/instances/Subscription/RtxSubscriptionSelect';
import { RtxTagModeSelect } from 'RtUi/components/rtx/inputs/Select/instances/TagMode/RtxTagModeSelect';
import { RtxTagTypeSelect } from 'RtUi/components/rtx/inputs/Select/instances/TagType/RtxTagTypeSelect';
import { RtxTagListIInput } from 'RtUi/components/rtx/inputs/TagListInput/RtxTagListInput';
import { RtError } from 'RtUi/utils/errors/RtError';
import { noop } from 'lodash-es';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

interface ISubscriptionTagFormEditorProps {
	profile?: SubscriptionTagProfileResponse;
	subscriptionId?: number;
	onSuccess?: (profile: SubscriptionTagProfileResponse) => void;
	isMatching?: boolean;
	isAssignment?: boolean;
}

export const SubscriptionTagFormEditor = ({
	profile,
	subscriptionId,
	isMatching,
	isAssignment,
	onSuccess = noop
}: ISubscriptionTagFormEditorProps) => {
	const [subscription, setSubscription] = useState<SubscriptionIndexResponse>();
	const [tagType, setTagType] = useState<TagIndexResponse>();
	const [tagMode, setTagMode] = useState<IDefaultSelectOption<CallTagMode>>();
	const [error, setError] = useState<RtError>();
	const [displayMode, setDisplayMode] = useState<boolean>(false);
	const initialSubscriptionId = subscriptionId ?? profile?.subscriptionId;

	const getServiceIds = () => {
		if (isMatching) {
			return [220];
		}

		if (isAssignment) {
			return [110, 120];
		}

		return undefined;
	};

	const onSubmit = async (data: SubscriptionTagProfileResponse) => {
		const subscriptionTagResource = new SubscriptionTagResource();
		let newProfile: SubscriptionTagProfileResponse;
		try {
			if (profile) {
				newProfile = await subscriptionTagResource.update(
					profile.subscriptionTagId,
					data
				);
			} else {
				newProfile = await subscriptionTagResource.create(data);
			}
			setDisplayMode(true);
			onSuccess(newProfile);
		} catch (error: any) {
			setError(new RtError(error));
		}
	};

	return (
		<RtxForm<SubscriptionTagProfileResponse>
			createMode
			defaultValues={{
				subscriptionId: initialSubscriptionId,
				values: [],
				tag: isAssignment ? 'CUSTOM' : undefined,
				tagModeId: CallTagMode.MatchAny,
				percentage: 100,
				priority: 50,
				weight: 10,
				...profile
			}}
			onSubmit={onSubmit}
			displayMode={displayMode}
			error={
				error && {
					name: 'root',
					error: {
						message: error.message
					}
				}
			}
		>
			{({ control, watch, setValue }) => {
				const tagModeId = watch('tagModeId');
				return (
					<>
						<Controller
							control={control}
							name="subscriptionId"
							render={({ field: { value, onChange } }) => (
								<RtxSubscriptionSelect
									value={subscription}
									className="mb-3"
									onChange={(subscription) => {
										setSubscription(subscription);
										onChange(subscription.subscriptionId);
									}}
									initialOptionId={value}
									appendDropdownToBody={false}
									serviceIds={getServiceIds()}
									displayMode={initialSubscriptionId !== undefined}
								/>
							)}
						/>
						<Controller
							control={control}
							name="tag"
							render={({ field: { value, onChange } }) => (
								<RtxTagTypeSelect
									required
									label="Tag"
									className="mb-3"
									serviceId={subscription?.serviceId}
									displayMode={displayMode || isAssignment || Boolean(profile)}
									valueKey="tag"
									initialOptionId={value}
									appendDropdownToBody={false}
									onChange={(tagSelect) => {
										setTagType(tagSelect);
										onChange(tagSelect.tag);
									}}
									value={tagType}
								/>
							)}
						/>
						<Controller
							control={control}
							name="values"
							render={({ field: { value, onChange } }) => (
								<RtxTagListIInput
									label="Values"
									className="mb-3"
									customValidation={(value) =>
										value.replace(/[^a-zA-Z0-9]/g, '')
									}
									disabled={displayMode}
									onChange={(val) => onChange(val.map((v) => v.toUpperCase()))}
									value={value?.sort((a, b) => a.localeCompare(b))}
								/>
							)}
						/>
						{!isAssignment && (
							<>
								<Controller
									control={control}
									name="tagModeId"
									render={({ field: { value, onChange } }) => (
										<RtxTagModeSelect
											className="mb-3"
											required
											label="Tag Mode"
											displayMode={displayMode}
											appendDropdownToBody={false}
											value={tagMode}
											initialOptionId={value}
											onChange={(tagModeSelect) => {
												if (
													[CallTagMode.AllowAny, CallTagMode.BlockAny].includes(
														tagModeSelect.value
													)
												) {
													setValue('priority', 0);
													setValue('weight', 0);
												}

												onChange(tagModeSelect.value);
												setTagMode(tagModeSelect);
											}}
										/>
									)}
								/>
								<Controller
									control={control}
									name="percentage"
									render={({ field: { value, onChange } }) => (
										<RtxPercentageInput
											className="mb-3"
											required
											label="Active Percent"
											displayMode={
												displayMode ||
												[CallTagMode.AllowAny, CallTagMode.BlockAny].includes(
													tagModeId
												)
											}
											value={value}
											onChange={onChange}
										/>
									)}
								/>
								<Controller
									control={control}
									name="priority"
									render={({ field: { value, onChange } }) => (
										<RtxPercentageInput
											required
											className="mb-3"
											label="Match Priority"
											displayMode={
												displayMode ||
												[CallTagMode.AllowAny, CallTagMode.BlockAny].includes(
													tagModeId
												)
											}
											value={value}
											onChange={onChange}
										/>
									)}
								/>
								<Controller
									control={control}
									name="weight"
									render={({ field: { value, onChange } }) => (
										<RtxNumberInput
											required
											className="mb-3"
											label="Match Weight"
											displayMode={
												displayMode ||
												[CallTagMode.AllowAny, CallTagMode.BlockAny].includes(
													tagModeId
												)
											}
											value={value}
											onChange={onChange}
										/>
									)}
								/>
							</>
						)}
					</>
				);
			}}
		</RtxForm>
	);
};
