import {
	ConnectionIndexResponse,
	RatePlanRateIndexResponse,
	SubscriptionIndexResponse,
	SubscriptionQosIndexResponse,
	SubscriptionScheduleIndexResponse
} from 'RtModels';
import { TagResponse } from 'RtUi/app/AccountManagement/Subscriptions/lib/grids/TagDataGrid';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { BlockTypeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BlockTypeDataGridColumn';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DeleteDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DeleteDataGridColumn';
import { DirectionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DirectionDataGridColumn';
import { FileStreamDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FileStreamDataColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { MarginPlanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/MarginPlanDataGridColumn';
import { SQDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SQDataGridColumn';
import { RateCenterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateCenterDataGridColumn';
import { RateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateDataGridColumn';
import { RatePlanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RatePlanDataGridColumn';
import { RoutePlanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RoutePlanDataGridColumn';
import { ScenarioDataGridColumn } from 'RtUi/components/data/DataGrid/columns/ScenarioDataGridColumn';
import { ServiceDataGridColumn } from 'RtUi/components/data/DataGrid/columns/ServiceDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { SubscriptionTagsDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionTagsDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { TimezoneDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimezoneDataGridColumn';
import { UserNameDataGridColumn } from 'RtUi/components/data/DataGrid/columns/UserNameDataGridColumn';
import { timeToReadable } from 'RtUi/utils/maps';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getSubscriptionsColumns = (): Array<
	DataGridColumn<SubscriptionIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'subscriptionId'
	}),
	AccountDataGridColumn({
		accessorKey: 'accountId',
		header: 'Account'
	}),
	LabelDataGridColumn({
		accessorKey: 'label',
		header: 'Subscription'
	}),
	ServiceDataGridColumn({
		accessorKey: 'serviceId',
		header: 'Service'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	RatePlanDataGridColumn({
		accessorKey: 'ratePlanId',
		header: 'Rate Plan'
	}),
	RoutePlanDataGridColumn({
		accessorKey: 'routePlanId',
		header: 'Route Plan',
		isHidden: true
	}),
	ScenarioDataGridColumn({
		accessorKey: 'defaultScenarioId',
		header: 'Scenario'
	}),
	TimeStampDataGridColumn({
		header: 'Last Rate Load',
		accessorKey: 'lastRateLoadTs',
		isHidden: true
	}),
	BlockTypeDataGridColumn({
		accessorKey: 'blockTypeId',
		header: 'Block Type',
		isHidden: true
	}),
	TimezoneDataGridColumn({
		accessorKey: 'timezoneId',
		header: 'Time-Zone',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'externalAccountId',
		header: 'Ext. Account'
	}),
	DefaultDataGridColumn({
		accessorKey: 'externalSubAccountId',
		header: 'Ext. Sub Account'
	})
];

export const getSubscriptionQosColumns = (
	isVendorSubscription: boolean,
	isCustomerSubscription: boolean
): Array<DataGridColumn<SubscriptionQosIndexResponse>> => [
	RateCenterDataGridColumn({
		accessorKey: 'rateCenterId',
		header: 'Rate Center'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isRoutable',
		header: 'Route',
		isInvisible: !isVendorSubscription
	}),
	BooleanDataGridColumn({
		accessorKey: 'isLcrEnabled',
		header: 'LCR',
		isInvisible: !isVendorSubscription
	}),
	BooleanDataGridColumn({
		accessorKey: 'isRouteDepth',
		header: 'Calc',
		isInvisible: !isVendorSubscription
	}),
	MarginPlanDataGridColumn({
		accessorKey: 'marginPlanId',
		header: 'Margin Plan',
		isInvisible: !isCustomerSubscription
	}),
	BlockTypeDataGridColumn({
		accessorKey: 'blockTypeId',
		header: 'Block Type'
	}),
	SQDataGridColumn({
		accessorKey: 'sq1',
		header: 'SQ1'
	}),
	SQDataGridColumn({
		accessorKey: 'sq2',
		header: 'SQ2'
	}),
	SQDataGridColumn({
		accessorKey: 'sq3',
		header: 'SQ3'
	}),
	SQDataGridColumn({
		accessorKey: 'sq4',
		header: 'SQ4'
	}),
	SQDataGridColumn({
		accessorKey: 'sq5',
		header: 'SQ5'
	}),
	SQDataGridColumn({
		accessorKey: 'sq6',
		header: 'SQ6'
	}),
	SQDataGridColumn({
		accessorKey: 'sq7',
		header: 'SQ7'
	}),
	SQDataGridColumn({
		accessorKey: 'sq8',
		header: 'SQ8'
	}),
	SQDataGridColumn({
		accessorKey: 'sq9',
		header: 'SQ9'
	}),
	BooleanDataGridColumn({
		accessorKey: 'cli',
		header: 'CLI'
	}),
	BooleanDataGridColumn({
		accessorKey: 't38',
		header: 'T38'
	}),
	BooleanDataGridColumn({
		accessorKey: 'test',
		header: 'Test'
	})
];

export const getSubscriptionRatePlansColumns = (): Array<
	DataGridColumn<RatePlanRateIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'rateKey',
		header: 'Rate Key'
	}),
	DefaultDataGridColumn({
		accessorKey: 'rateSheetId',
		header: 'Rate Sheet Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'rateSummary',
		header: 'Rate Summary'
	}),
	DefaultDataGridColumn({
		accessorKey: 'rateCenter',
		header: 'Rate Center'
	}),
	DefaultDataGridColumn({
		accessorKey: 'iso3',
		header: 'iso3'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'startTs',
		header: 'Start Date'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'endTs',
		header: 'End Date'
	}),
	RateDataGridColumn({
		accessorKey: 'interRate',
		header: 'Inter. Rate'
	}),
	RateDataGridColumn({
		accessorKey: 'intraRate',
		header: 'Intra. Rate'
	}),
	RateDataGridColumn({
		accessorKey: 'indetRate',
		header: 'Indet. Rate'
	}),
	IntegerDataGridColumn({
		accessorKey: 'durMin',
		header: 'Dur Min'
	}),
	IntegerDataGridColumn({
		accessorKey: 'durInc',
		header: 'Dur Inc'
	}),
	RateDataGridColumn({
		accessorKey: 'conFee',
		header: 'Con Fee'
	})
];

export const getSubscriptionScheduleColumns = (
	deleteSchedule: (row: SubscriptionScheduleIndexResponse) => void
): Array<DataGridColumn<SubscriptionScheduleIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: deleteSchedule
	}),
	TimezoneDataGridColumn({
		accessorKey: 'timezoneId',
		header: 'Time-Zone'
	}),
	DateDataGridColumn({
		accessorKey: 'startDate',
		header: 'Start Date'
	}),
	DateDataGridColumn({
		accessorKey: 'endDate',
		header: 'End Date'
	}),
	DefaultDataGridColumn({
		accessorKey: 'startSecond',
		header: 'Start Time',
		getValue: ({ cell }) => timeToReadable(cell.getValue<number>())
	}),
	DefaultDataGridColumn({
		accessorKey: 'endSecond',
		header: 'End Time',
		getValue: ({ cell }) => timeToReadable(cell.getValue<number>())
	}),
	BooleanDataGridColumn({
		accessorKey: 'isSunday',
		header: 'Sun'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isMonday',
		header: 'Mon'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isTuesday',
		header: 'Tue'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isWednesday',
		header: 'Wed'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isThursday',
		header: 'Thr'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isFriday',
		header: 'Fri'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isSaturday',
		header: 'Sat'
	})
];

export const getVueConnectionColumns = (): Array<
	DataGridColumn<ConnectionIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'connectionId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'switchName',
		header: 'Switch Name'
	}),
	FileStreamDataGridColumn({
		header: 'File Stream',
		accessorKey: 'fileStreamId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'trunkGroupId',
		header: 'Trunk Group Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'trunkGroupLabel',
		header: 'Trunk Group Label'
	}),
	DefaultDataGridColumn({
		accessorKey: 'gatewayAddress',
		header: 'Gateway Address'
	}),
	DefaultDataGridColumn({
		accessorKey: 'peerAddress',
		header: 'Peer Address'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	DefaultDataGridColumn({
		accessorKey: 'externalId',
		header: 'External Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary',
		isHidden: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created',
		isHidden: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updatedTs',
		header: 'Updated',
		isHidden: true
	}),
	UserNameDataGridColumn({
		accessorKey: 'updatedBy',
		header: 'Updated By',
		isHidden: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'subscriptionId',
		header: 'Subscription',
		isHidden: true
	}),
	DirectionDataGridColumn({
		accessorKey: 'directionId',
		header: 'Direction',
		isHidden: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'startTs',
		header: 'Start',
		isHidden: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'endTs',
		header: 'End',
		isHidden: true
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isRatePlanActive',
		header: 'Rate Plan Active',
		isHidden: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'ratePlanCreatedTs',
		header: 'Rate Plan Created',
		isHidden: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'ratePlanUpdatedTs',
		header: 'Rate Plan Updated',
		isHidden: true
	}),
	UserNameDataGridColumn({
		accessorKey: 'ratePlanUpdatedBy',
		header: 'Rate Plan Updated By',
		isHidden: true
	})
];

export const getSubscriptionTagsColumns = <T extends TagResponse>(
	onDeleteHandler: (row: T) => void,
	hideSubscriptionId: boolean,
	isAssignment?: boolean
): Array<DataGridColumn<T>> => [
	DeleteDataGridColumn({
		onClick: onDeleteHandler
	}),
	IntegerDataGridColumn({
		accessorKey: 'subscriptionTagId',
		header: 'Subscription Tag Id',
		isHidden: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'subscriptionId',
		header: 'Subscription',
		isInvisible: hideSubscriptionId
	}),
	DefaultDataGridColumn({
		accessorKey: 'tag',
		header: 'Tag'
	}),
	DefaultDataGridColumn({
		accessorKey: 'tagMode',
		header: 'Tag Mode',
		isInvisible: isAssignment
	}),
	IntegerDataGridColumn({
		accessorKey: 'priority',
		header: 'Match Priority',
		isInvisible: isAssignment
	}),
	IntegerDataGridColumn({
		accessorKey: 'weight',
		header: 'Match Weight',
		isInvisible: isAssignment
	}),
	IntegerDataGridColumn({
		accessorKey: 'percentage',
		header: 'Active Percent',
		isInvisible: isAssignment
	}),
	SubscriptionTagsDataGridColumn({
		accessorKey: 'values',
		header: 'Values'
	})
];
