import { PromptProfileResponse } from 'RtModels';
import { PromptSegmentRouter } from 'RtUi/app/rtSip/PromptSegments/PromptSegment.router';
import { BulkPromptUploader } from 'RtUi/app/rtSip/PromptSegments/lib/forms/BulkPromptUploader';
import { PromptEditor } from 'RtUi/app/rtSip/PromptSegments/lib/forms/PromptEditor';
import { PromptSegmentDataGridColumn } from 'RtUi/app/rtSip/PromptSegments/lib/grids/PromptSegmentDataGrid';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';

interface IInitialResourceParams {
	search: string;
	isActive: number | null;
}

interface IPromptSegmentsIndexContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {}

@PromptSegmentRouter.getBusinessOpsIndexRtUiController()
export class PromptSegmentIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	IPromptSegmentsIndexContainerState
> {
	public initParams: IInitialResourceParams = {
		search: '',
		isActive: 1
	};

	public state: IPromptSegmentsIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {}
	};

	constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { search, isActive } = this.state;
		const resourceParams: any = {};

		if (search) {
			resourceParams.search = search;
		}

		if (isActive !== null) {
			resourceParams.isActive = isActive;
		}

		return resourceParams;
	}

	public goToPromptSegment(promptSegment: PromptProfileResponse) {
		const profilePath = PromptSegmentRouter.getProfileRoute(promptSegment);

		this.goToPath(profilePath);
	}

	public render() {
		return (
			<TabbedLayout
				router={PromptSegmentRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<InputFormControl
							label="Search"
							onChange={(search) => this.setState({ search })}
							value={this.state.search}
						/>
						<IsActiveRadioFormControl
							label="Status"
							onChange={(isActive) => this.setState({ isActive })}
							value={this.state.isActive}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<PromptSegmentDataGridColumn
						resourceParams={this.state.resourceParams}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab header="Create Prompt Segment" isAction>
					<PromptEditor
						createMode
						narrow
						onCreate={(prompt) => this.goToPromptSegment(prompt)}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab header="Bulk Upload Prompt Segments" isAction>
					<BulkPromptUploader
						onCreate={() => this.setActiveTab(this.tabs.Results)}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
