import { RoboscoreIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { CallDateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { noop } from 'lodash-es';
import { CallDurationDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDurationDataGridColumn';

export const getRoboscoreColumns = (
	callDateFormat?: string,
	onDrilldown: (
		row: RoboscoreIndexResponse,
		key: keyof RoboscoreIndexResponse
	) => void = noop
): Array<DataGridColumn<RoboscoreIndexResponse>> => [
	CallDateDataGridColumn({
		accessorKey: 'callDate',
		header: 'Call Date',
		hiddenIfEmpty: true,
		format: callDateFormat
	}),
	AccountDataGridColumn({
		accessorKey: 'customerAccountId',
		header: 'Customer Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'customerSubscriptionId',
		header: 'Customer Subscription',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'customerExternalAccountId',
		header: 'Customer External Account Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'customerExternalSubAccountId',
		header: 'Customer External Sub Account Id',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'vendorAccountId',
		header: 'Vendor Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'vendorSubscriptionId',
		header: 'Vendor Subscription',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'vendorExternalAccountId',
		header: 'External Vendor Account Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'vendorExternalSubAccountId',
		header: 'External Vendor Sub Account Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressTrunkGroup',
		header: 'Egress Trunk Group',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressTrunkGroupLabel',
		header: 'Egress Trunk Group Label',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressTrunkGroup',
		header: 'Ingress Trunk Group',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressTrunkGroupLabel',
		header: 'Ingress Trunk Group Label',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'honeypotCampaign',
		header: 'Honeypot Campaign',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ani',
		header: 'Ani',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressState',
		header: 'Ingress State',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressOcn',
		header: 'Ingress OCN',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressOcnType',
		header: 'Ingress OCN Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'commonName',
		header: 'Common Name',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'attempts',
		header: 'Attempts',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'connects',
		header: 'Connects',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	CallDurationDataGridColumn({
		accessorKey: 'duration',
		header: 'Duration',
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'countAttestationA',
		header: 'Attestation A',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'countAttestationB',
		header: 'Attestation B',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'countAttestationC',
		header: 'Attestation C',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'countAttestationF',
		header: 'Attestation F',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'countNoAttestation',
		header: 'No Attestation',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		//cspell:ignore hastoken
		accessorKey: 'hastoken',
		header: 'Has Token',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'countUniqueAnis',
		header: 'Unique Anis',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'countUniqueDialedNumbers',
		header: 'Unique DNS',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'countInvalidDn',
		header: 'Invalid Dn',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'isDestDetailSame',
		header: 'Familiar Dialing',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'countDnc',
		header: 'Dnc',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'countAniNotValid',
		header: 'Ani Not Valid',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'countAniDno',
		header: 'Ani Dno',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'count404',
		header: '404',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'mediaFraudIps',
		header: 'Media Fraud Ips',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'stirShakenSigners',
		header: 'Stir Shaken Signers',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'totalReputationScore',
		header: 'Total Reputation Score',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'countHoneyPot',
		header: 'Honey Pot',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'totalRoboScore',
		header: 'Total Robo Score',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'totalFraudScore',
		header: 'Total Fraud Score',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'totalAnomalyScore',
		header: 'Total Anomaly Score',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'roboscore',
		header: 'Avg Robo Score',
		hiddenIfEmpty: true,
		onDrillDown: (e, row) => {
			e.preventDefault();
			onDrilldown(row, 'roboscore');
		}
	}),
	IntegerDataGridColumn({
		//cSpell:disable-next-line
		accessorKey: 'reputationscore',
		header: 'Reputation Score',
		hiddenIfEmpty: true
	})
];
