import { noop } from 'lodash-es';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
	CountryIndexResponse,
	PortLimitCreateRequest,
	PortLimitProfileResponse
} from 'RtModels';
import { PortLimitResource } from 'RtUi/app/rtSip/PortLimits/lib/resources/PortLimitResource';
import { RtxForm } from 'RtUi/components/rtx/form';
import { RtxBooleanInput } from 'RtUi/components/rtx/inputs/Boolean/RtxBooleanInput';
import { RtxNumberInput } from 'RtUi/components/rtx/inputs/Number/RtxNumber';
import { RtxCountrySelect } from 'RtUi/components/rtx/inputs/Select/instances/Country/RtxCountrySelect';
import { RtError } from 'RtUi/utils/errors/RtError';

interface IPortLimitFormEditorProps {
	profile?: PortLimitProfileResponse;
	accountId?: number;
	onSuccess?: (profile: PortLimitProfileResponse) => void;
	onCancel?: () => void;
}

export const PortLimitFormEditor = ({
	profile,
	accountId,
	onSuccess = noop,
	onCancel
}: IPortLimitFormEditorProps) => {
	const [error, setError] = useState<RtError>();
	const [country, setCountry] = useState<CountryIndexResponse>();
	const [ingressUnlimited, setIngressUnlimited] = useState<number>(
		Number(profile?.ingressPortLimit === null)
	);
	const [egressUnlimited, setEgressUnlimited] = useState<number>(
		Number(profile?.egressPortLimit === null)
	);

	const onSubmit = async (data: PortLimitCreateRequest) => {
		const portLimitResource = new PortLimitResource();
		let newProfile: PortLimitProfileResponse;

		setError(undefined);

		try {
			if (profile) {
				newProfile = await portLimitResource.update(
					profile.portLimitId,
					ingressUnlimited ? null : (data.ingressPortLimit as number),
					egressUnlimited ? null : (data.egressPortLimit as number)
				);
			} else {
				newProfile = await portLimitResource.create({
					...data,
					accountId: accountId!,
					ingressPortLimit: ingressUnlimited ? null : data.ingressPortLimit,
					egressPortLimit: egressUnlimited ? null : data.egressPortLimit
				});
			}
			onSuccess(newProfile);
		} catch (error: any) {
			setError(new RtError(error));
		}
	};

	return (
		<RtxForm<PortLimitCreateRequest>
			createMode={!profile}
			defaultValues={profile}
			error={
				error && {
					name: 'root',
					error: {
						message: error.message
					}
				}
			}
			onSubmit={onSubmit}
			onCancel={onCancel}
		>
			{({ control }) => (
				<>
					{!profile && (
						<Controller
							control={control}
							name="countryAbbr"
							render={({ field: { value, onChange } }) => (
								<RtxCountrySelect
									placeholder="Leave blank for ALL"
									label="Country"
									appendDropdownToBody={false}
									className="mb-3"
									onChange={(val) => {
										setCountry(val);
										onChange(val.iso3166Alpha3);
									}}
									value={country}
									initialOptionId={value}
								/>
							)}
						/>
					)}
					<RtxBooleanInput
						label="Unlimited Ingress Port Limit"
						value={ingressUnlimited}
						className="mb-3"
						onChange={setIngressUnlimited}
					/>
					{!ingressUnlimited && (
						<Controller
							control={control}
							name="ingressPortLimit"
							render={({ field: { value, onChange } }) => (
								<RtxNumberInput
									label="Ingress Port Limit"
									className="mb-3"
									onChange={onChange}
									value={value}
								/>
							)}
						/>
					)}
					<RtxBooleanInput
						label="Unlimited Egress Port Limit"
						className="mb-3"
						value={egressUnlimited}
						onChange={setEgressUnlimited}
					/>
					{!egressUnlimited && (
						<Controller
							control={control}
							name="egressPortLimit"
							render={({ field: { value, onChange } }) => (
								<RtxNumberInput
									label="Egress Port Limit"
									className="mb-3"
									onChange={onChange}
									value={value}
								/>
							)}
						/>
					)}
				</>
			)}
		</RtxForm>
	);
};
