import { FormErrors } from 'RtUi/components/form/FormErrors';
import { useUrlSearchParams } from 'RtUi/components/hooks/useUrlSearchParams';
import { SubmitButton } from 'RtUi/components/rtx/form/components/SubmitButton';
import { RtxFormContext } from 'RtUi/components/rtx/form/context/FormContext';
import { isEmpty, isNil, isString, noop, omitBy } from 'lodash-es';
import {
	ReactNode,
	cloneElement,
	createContext,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState
} from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import {
	DefaultValues,
	ErrorOption,
	FieldPath,
	FieldValues,
	UseFormReturn,
	useForm
} from 'react-hook-form';
import { useMount } from 'react-use';

interface IRtxFormContext {
	error?: string[];
	setError: (newError: string[]) => void;
}

export const RtUiFormContext = createContext<IRtxFormContext>({
	setError: noop
});

export type FormReturnProps<T extends FieldValues> = Omit<
	UseFormReturn<T>,
	'handleSubmit' | 'formState'
>;

interface BaseUrlParamsProps<T extends FieldValues> {
	initializer: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	onLoadUrlParams: (urlParams: Record<keyof T, any>) => void;
}

interface IRequiredUrlParamsProps<
	T extends FieldValues,
	UrlParams extends boolean = false
> extends BaseUrlParamsProps<T> {
	useUrlParams: UrlParams;
}

interface IOptionalUrlParamsProps<T extends FieldValues>
	extends Partial<BaseUrlParamsProps<T>> {
	useUrlParams?: false;
}

interface ExternalError<T extends FieldValues> {
	name: FieldPath<T> | 'root';
	error: ErrorOption;
}

type UrlParamsProps<
	T extends FieldValues,
	UrlParams extends boolean = false
> = UrlParams extends false
	? IOptionalUrlParamsProps<T>
	: IRequiredUrlParamsProps<T, UrlParams>;

interface IRtxFormBaseProps<T extends FieldValues> {
	defaultValues?: DefaultValues<T>;
	children?: ReactNode | ((formProps: FormReturnProps<T>) => ReactNode);
	submitButton?: JSX.Element;
	cancelButton?: JSX.Element;
	editButton?: JSX.Element;
	footerActions?: () => JSX.Element;
	error?: ExternalError<T>;
	onSubmit?: (data: T) => void;
	onEdit?: () => void;
	onCancel?: () => void;
	createMode?: boolean;
	hideSubmit?: boolean;
	hideButtons?: boolean;
	displayMode?: boolean;
}

export type IRtxFormProps<
	T extends FieldValues,
	UrlParams extends boolean = false
> = IRtxFormBaseProps<T> & UrlParamsProps<T, UrlParams>;

const RtxFormComponent = <
	T extends FieldValues,
	UrlParams extends boolean = false
>(
	{
		defaultValues,
		children,
		initializer = [false, noop],
		useUrlParams = false,
		submitButton,
		cancelButton,
		editButton,
		footerActions = () => <></>,
		onLoadUrlParams = noop,
		onSubmit = noop,
		onCancel = noop,
		onEdit = noop,
		error: externalError,
		displayMode = false,
		createMode = false,
		hideSubmit = false,
		hideButtons = false
	}: IRtxFormProps<T, UrlParams>,
	ref: React.ForwardedRef<FormReturnProps<T>>
) => {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const { urlSearchParams, setUrlSearchParams } =
		useUrlSearchParams<DefaultValues<T>>();
	const [initialized, setInitialized] = initializer;
	const initialValues = useMemo(() => {
		if (useUrlParams && urlSearchParams) {
			return { ...defaultValues, ...urlSearchParams };
		}

		return defaultValues;
	}, [useUrlParams, defaultValues, urlSearchParams]);

	useMount(() => {
		if (useUrlParams && !initialized && !isEmpty(urlSearchParams)) {
			onLoadUrlParams(urlSearchParams as T);
			setInitialized(true);
		}
	});

	const {
		handleSubmit,
		setError,
		clearErrors,
		formState: { isValid, errors },
		...formProps
	} = useForm<T>({
		defaultValues: initialValues
	});

	useEffect(() => {
		if (!externalError) {
			return;
		}

		const { name, error } = externalError;
		setError(name, error);
	}, [externalError, setError]);

	useImperativeHandle(ref, () => ({
		setError,
		clearErrors,
		...formProps
	}));

	const clearFormErrors = (name?: string) => {
		const path: `root.${string}` | undefined = name
			? `root.${name}`
			: undefined;
		clearErrors(path);
	};

	const setFormError = (name: string, message: string) => {
		setError(`root.${name}`, {
			message
		});
	};

	const formErrors = Object.keys(errors).reduce<string[]>((dest, key) => {
		const error = errors[key]?.message;

		if (error !== undefined) {
			dest.push(error as string);
		}

		return dest;
	}, []);

	const SubmitButtonInternal: ReactNode = submitButton || (
		<SubmitButton
			isSubmitting={isSubmitting}
			disabled={isSubmitting || !isValid}
		/>
	);

	const EditButtonInternal: ReactNode = editButton || (
		<Button type="button" variant="light">
			<i className="far fa-fw fa-edit" />
			<span>&nbsp;Edit</span>
		</Button>
	);

	const CancelButtonInternal: JSX.Element = cancelButton || (
		<Button
			type="button"
			variant="white"
			className="me-3"
			disabled={isSubmitting}
		>
			<span>&nbsp;Cancel</span>
		</Button>
	);

	const onSubmitHandler = async (data: T) => {
		setIsSubmitting(true);
		const values = omitBy(
			data,
			(value) =>
				(isString(value) && !value.length) ||
				(Array.isArray(value) && !value.length) ||
				isNil(value)
		);
		if (useUrlParams) {
			setUrlSearchParams(values);
			setInitialized(true);
		}
		await onSubmit(values as T);
		setIsSubmitting(false);
	};

	return (
		<RtxFormContext.Provider
			value={{ errors, setError: setFormError, clearFormErrors }}
		>
			<Card>
				{displayMode && (
					<Card.Header className="d-flex justify-content-end">
						{cloneElement(EditButtonInternal, {
							onClick: () => onEdit()
						})}
					</Card.Header>
				)}
				<Card.Body>
					<Form onSubmit={handleSubmit(onSubmitHandler)}>
						{children instanceof Function
							? children({ setError, clearErrors, ...formProps })
							: children}
						{Boolean(formErrors.length) && (
							<FormErrors className="mt-3" error={formErrors} />
						)}
						{!displayMode && !hideButtons && (
							<footer className="d-flex justify-content-end align-items-center">
								{footerActions()}
								{!createMode &&
									cloneElement(CancelButtonInternal, {
										onClick: () => {
											onCancel();
											formProps.reset();
										}
									})}
								{!hideSubmit && SubmitButtonInternal}
							</footer>
						)}
					</Form>
				</Card.Body>
			</Card>
		</RtxFormContext.Provider>
	);
};

export const RtxForm = forwardRef(RtxFormComponent) as <
	T extends FieldValues,
	UrlParams extends boolean = false
>(
	props: IRtxFormProps<T, UrlParams> & {
		ref?: React.ForwardedRef<FormReturnProps<T>>;
	}
) => ReturnType<typeof RtxFormComponent>;
