import { Rt800ApiRoutes } from 'RtExports/routes';
import { TemplateIndexRequest, TemplateIndexResponse } from 'RtModels';
import {
	fetchTemplates,
	useGetTemplates
} from 'RtUi/app/rt800/Templates/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getTemplateColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/templates';
import { UserActions } from 'RtUi/state/actions/user';
import { useMemo, useState } from 'react';
import { Item } from 'react-contexify';
import { TemplateRouter } from '../../Template.router';
import { TemplateQuickViewAside } from '../components/TemplateQuickViewAside';
import { useGetFullResults } from 'RtUi/components/hooks/useGetFullResults';

interface ITemplateGridProps {
	resourceParams?: TemplateIndexRequest;
	autoFocusFilter?: boolean;
}

export const TemplateGrid = ({ resourceParams }: ITemplateGridProps) => {
	if (!resourceParams) {
		resourceParams = {
			pageSize: 50_000
		};
	}
	if (!resourceParams.pageSize) {
		resourceParams.pageSize = 50_000;
	}
	const { data, isFetching: isLoading } = useGetTemplates(resourceParams);
	const fullResultsReq = useGetFullResults(
		['exportTasks', resourceParams],
		resourceParams ?? {},
		(params) => fetchTemplates(params)
	);
	const columns = useMemo(() => getTemplateColumns(), []);
	const [selectedTemplate, setSelectedTemplate] =
		useState<TemplateIndexResponse>();

	return (
		<>
			<DataGrid<TemplateIndexResponse>
				router={TemplateRouter}
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				enableExternalExport
				getExternalExportData={fullResultsReq}
				pageName={'rt800_Templates'}
				menuLinks={(row) => {
					const canViewCpr = UserActions.has(
						...Rt800ApiRoutes.TemplateCprs.Index.permissions
					);

					if (!canViewCpr) {
						return null;
					}

					return (
						<Item onClick={() => setSelectedTemplate(row)}>
							<span className="d-flex justify-content-start align-items-center">
								<i className="fas fa-fw fa-bolt" />
								<span>
									<span>Quick Template View</span>
								</span>
							</span>
						</Item>
					);
				}}
			/>
			{selectedTemplate && (
				<TemplateQuickViewAside
					key={selectedTemplate.templateName}
					isOpen={Boolean(selectedTemplate)}
					toggle={() => setSelectedTemplate(undefined)}
					template={selectedTemplate}
				/>
			)}
		</>
	);
};
