import { FC, useState } from 'react';
import Table from 'react-bootstrap/esm/Table';
import { FileProcessorType } from 'RtModels';
import { ConvertRateSheetResource } from 'RtUi/app/rtVue/ConvertRateSheet/lib/resources/ConvertRateSheetResource';
import { CollapsibleCard } from 'RtUi/components/ui/CollapsibleCard';
import { DragAndDropFileUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFileUploader';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { FileUtils } from 'RtUi/utils/file/FileUtils';

interface IConvertRateSheetUploadProps {
	onCancel: () => void;
	onSuccess: () => void;
}

export const ConvertRateSheetUpload: FC<IConvertRateSheetUploadProps> = ({
	onCancel,
	onSuccess
}) => {
	const [file, setFile] = useState<File | undefined>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [error, setError] = useState<any>(undefined);

	const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		if (!file) {
			return;
		}

		setError(undefined);
		setIsSubmitting(true);

		try {
			const resource = new ConvertRateSheetResource();
			await resource.create(
				{
					fileProcessTypeId: FileProcessorType.LataOcnConversion,
					summary: ''
				},
				file
			);

			onSuccess();
		} catch (e) {
			setError(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<RtUiNarrowForm
			onCancel={onCancel}
			displayMode={false}
			onSubmit={onSubmit}
			submitButtonText="Upload"
			error={error}
			isSubmitting={isSubmitting}
		>
			<CollapsibleCard
				header="Example of lata/ocn/state rate sheet"
				className="ps-0 col-lg-6 col-xl-6"
				closedByDefault
			>
				<Table size="lg" bordered className="bg-white mb-0" responsive>
					<thead>
						<tr>
							<th>ocn</th>
							<th>state</th>
							<th>lata</th>
							<th>inter</th>
							<th>intra</th>
						</tr>
					</thead>
					<tbody>
						{[
							['003', 'ME', '120', '0.03075', '0.01938'],
							['0019', 'ME', '120', '0.01268', '0.00381'],
							['001F', 'OH', '320', '0.00333', '0.00333'],
							['0021', 'KY', '922', '0.00668', '0.00566']
						].map((values, index) => (
							<tr key={index}>
								{values.map((value, innerIndex) => (
									<td className="text-monospace" key={innerIndex}>
										{value}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</Table>
			</CollapsibleCard>
			<DragAndDropFileUploader
				required
				accept={FileUtils.AcceptTypes.Spreadsheet}
				value={file}
				onDrop={setFile}
			/>
		</RtUiNarrowForm>
	);
};

ConvertRateSheetUpload.displayName = 'ConvertRateSheetUpload';
