/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ResourceUpdateRequest = {
    properties: {
        commonParams: {
            type: 'IntegrationConfigCommon',
            isRequired: true,
        },
        extraParams: {
            type: 'IntegrationConfigAni | IntegrationConfigAvoxi | IntegrationConfigBandwidth | IntegrationConfigBillingSystem | IntegrationConfigBlacklistAlliance | IntegrationConfigBlitz | IntegrationConfigCall48 | IntegrationConfigDidWw | IntegrationConfigEndstream | IntegrationConfigFlowroute | IntegrationConfigFractel | IntegrationConfigInteliquent | IntegrationConfigLayered | IntegrationConfigLingo | IntegrationConfigLumen | IntegrationConfigLumenApi | IntegrationConfigManual | IntegrationConfigMyHealthAngel | IntegrationConfigNetsapiensSwitch | IntegrationConfigNuso | IntegrationConfigPeerless | IntegrationConfigPlivo | IntegrationConfigRoc | IntegrationConfigRouteTrustTagging | IntegrationConfigRouteTrustWorker | IntegrationConfigSkyetel | IntegrationConfigSomosApi | IntegrationConfigSomosMgi | IntegrationConfigTeleApi | IntegrationConfigTelnyx | IntegrationConfigTenXPeople | IntegrationConfigThinq | IntegrationConfigThreeEightTwo | IntegrationConfigThreeThree | IntegrationConfigTurningPoint | IntegrationConfigTwilio | IntegrationConfigV1Voip | IntegrationConfigVeraz | IntegrationConfigVerizon | IntegrationConfigVitcom | IntegrationConfigVitelity | IntegrationConfigVoipInnovations | IntegrationConfigVoipMs | IntegrationConfigVoxbone | IntegrationConfigWest',
            isRequired: true,
        },
    },
};