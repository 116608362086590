import { RatePlanIndexResponse } from 'RtModels';
import { useGetRatePlans } from 'RtUi/app/AccountManagement/RatePlan/lib/service';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxRatePlanSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		RatePlanIndexResponse,
		'ratePlanId',
		IsMulti,
		IsClearable
	> {
	isShared?: boolean;
	assignableToSubscriptionId?: number;
}

export const RtxRatePlanSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	isShared,
	assignableToSubscriptionId,
	label = 'Rate Plan',
	...props
}: IRtxRatePlanSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetRatePlans({
		...(isShared !== undefined && { isShared: Number(isShared) }),
		isActive: 1,
		assignableToSubscriptionId
	});

	return (
		<RtxSelectInput<RatePlanIndexResponse, 'ratePlanId', IsMulti, IsClearable>
			label={label}
			valueKey="ratePlanId"
			labelKey="summary"
			options={data}
			isLoading={isLoading}
			{...props}
		/>
	);
};
