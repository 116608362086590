import { FormControl, FormLabel } from '@mui/material';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useRef } from 'react';
import Select, { Props, PropsValue, createFilter } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import { CustomOption } from 'RtUi/components/rtx/inputs/Select/components/CustomOption';
import { DropdownIndicator } from 'RtUi/components/rtx/inputs/Select/components/DropdownIndicator';
import { MenuList } from 'RtUi/components/rtx/inputs/Select/components/MenuList';

export type ReactSelectProps<
	OptionType,
	isMulti extends boolean = false
> = Props<OptionType, isMulti> & {
	maxOptions?: number;
	router?: RtUiRouter;
	linkTo?: string;
};

export interface IDefaultSelectOption<T> {
	value: T;
	label: string;
}

type OptionValue<T, IsMulti extends boolean = false> = IsMulti extends true
	? T[]
	: T;

type OnChangeValue<
	T,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> = IsClearable extends true
	? OptionValue<T, IsMulti> | undefined
	: OptionValue<T, IsMulti>;

type InitialOptionValue<
	T,
	K extends keyof T,
	IsMulti extends boolean = false
> = IsMulti extends true ? Array<T[K]> : T[K];

export type AsyncOptions<T> = {
	options: T[];
};

type AsyncProps<T> =
	| {
			isAsync?: false;
			promiseOptions?: (inputValue: string) => Promise<AsyncOptions<T>>;
	  }
	| {
			isAsync: true;
			promiseOptions: (inputValue: string) => Promise<AsyncOptions<T>>;
	  };

interface IRtxSelectInputBaseProps<
	T,
	K extends keyof T,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends Omit<
		ReactSelectProps<T, IsMulti>,
		'onChange' | 'getOptionValue' | 'getOptionLabel'
	> {
	label: string;
	options: T[];
	labelKey: keyof T;
	value: PropsValue<T> | undefined;
	valueKey: K;
	onChange: (newValue: OnChangeValue<T, IsMulti, IsClearable>) => void;
	isClearable?: IsClearable;
	initialOptionId?: InitialOptionValue<T, K, IsMulti>;
	displayMode?: boolean;
	appendDropdownToBody?: boolean;
	controlGroupClassName?: string;
	hideLabel?: boolean;
}

type RtxSelectInputProps<
	T,
	K extends keyof T,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> = IRtxSelectInputBaseProps<T, K, IsMulti, IsClearable> & AsyncProps<T>;

export interface IRtxSelectInputInstanceProps<
	T,
	K extends keyof T,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends Omit<
		IRtxSelectInputBaseProps<T, K, IsMulti, IsClearable>,
		'labelKey' | 'valueKey' | 'options' | 'label'
	> {
	label?: string;
}

export const RtxSelectInput = <
	T,
	K extends keyof T,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	labelKey,
	valueKey,
	value,
	options,
	onChange = () => {},
	filterOption,
	initialOptionId,
	label,
	displayMode,
	isDisabled,
	formatOptionLabel,
	isMulti,
	appendDropdownToBody = true,
	className,
	hideLabel = false,
	placeholder = '',
	maxOptions = 500,
	isAsync = false,
	promiseOptions,
	...props
}: RtxSelectInputProps<T, K, IsMulti, IsClearable>) => {
	const initialized = useRef<boolean>(false);

	const onChangeHandler = useCallback(
		(value: T | T[] | undefined) => {
			initialized.current = true;
			onChange(value as OnChangeValue<T, IsMulti>);
		},
		[onChange]
	);

	useEffect(() => {
		if (initialized.current || !options.length) {
			return;
		}

		const initialValue = isMulti
			? options.filter((opt) =>
					(initialOptionId as Array<T[K]>)?.includes(opt[valueKey])
				)
			: options.find((opt) => opt[valueKey] === initialOptionId);

		if (initialValue && !isEqual(initialValue, value)) {
			onChangeHandler(initialValue as OnChangeValue<T, IsMulti>);
		}
	}, [
		value,
		initialized,
		options,
		initialOptionId,
		valueKey,
		isMulti,
		onChangeHandler
	]);

	const getOptionValue = (newValue: T) => {
		return String(newValue[valueKey]);
	};

	const getOptionLabel = (currentValue: T): string => {
		return `${currentValue[labelKey]}`;
	};

	const selectProps: ReactSelectProps<T, IsMulti> = {
		...props,
		menuPortalTarget: appendDropdownToBody ? document.body : undefined,
		isSearchable: true,
		formatOptionLabel,
		getOptionValue,
		getOptionLabel
	};

	const filterOpt = (option: FilterOptionOption<T>, rawInput: string) => {
		const customFilter = filterOption && filterOption(option, rawInput);

		const defaultFilter = createFilter({ ignoreAccents: false });

		return customFilter || defaultFilter(option, rawInput);
	};

	return (
		<>
			<FormControl
				variant="outlined"
				sx={{ width: '100%' }}
				className={className}
				disabled={displayMode || isDisabled}
			>
				{!hideLabel && label && (
					<FormLabel
						sx={{
							fontSize: '12px',
							position: 'absolute',
							background:
								'linear-gradient(to bottom, transparent 45%, #FFF 45%)',
							padding: '0 6px',
							zIndex: 1,
							left: '8px',
							top: '-9px'
						}}
						required={props.required}
					>
						{label}
					</FormLabel>
				)}
				{isAsync && promiseOptions ? (
					<AsyncPaginate
						loadOptions={promiseOptions}
						components={{
							Option: CustomOption as any,
							MenuList: MenuList,
							DropdownIndicator
						}}
						defaultOptions
						value={value ?? null}
						onChange={(newValue) => {
							onChangeHandler(
								(newValue ?? undefined) as OnChangeValue<T, IsMulti>
							);
						}}
						isDisabled={isDisabled || displayMode}
						isMulti={isMulti}
						filterOption={filterOpt}
						placeholder={placeholder}
						maxOptions={maxOptions}
						debounceTimeout={500}
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								background: '#fff',
								minWidth: '180px',
								fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
								fontSize: '16px',
								...(state.isDisabled && {
									fontWeight: 550,
									borderColor: 'hsl(0deg 0% 77.25%)'
								})
							}),
							menu: (base) => ({
								...base,
								fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
								fontSize: '16px',
								zIndex: 999
							})
						}}
						{...selectProps}
					/>
				) : (
					<Select<T, IsMulti>
						components={{
							Option: CustomOption as any,
							MenuList: MenuList,
							DropdownIndicator
						}}
						value={value ?? null}
						options={options}
						onChange={(newValue) => {
							onChangeHandler(
								(newValue ?? undefined) as OnChangeValue<T, IsMulti>
							);
						}}
						isDisabled={isDisabled || displayMode}
						isMulti={isMulti}
						filterOption={filterOpt}
						placeholder={placeholder}
						maxOptions={maxOptions}
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								background: '#fff',
								fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
								fontSize: '16px',
								minWidth: '180px',
								...(state.isDisabled && {
									fontWeight: 550,
									borderColor: 'hsl(0deg 0% 77.25%)'
								})
							}),
							menu: (base) => ({
								...base,
								fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
								fontSize: '16px',
								zIndex: 999
							})
						}}
						{...selectProps}
					/>
				)}
				{!(displayMode || isDisabled) && options.length > maxOptions && (
					<blockquote className="fst-italic m-0 mt-1">
						Displaying {maxOptions} of {options.length}. Type to Search.
					</blockquote>
				)}
			</FormControl>
		</>
	);
};
