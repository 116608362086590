import { FieldValues } from 'react-hook-form';
import { IRtxFormProps, RtxForm } from 'RtUi/components/rtx/form';
import { SubmitButton } from 'RtUi/components/rtx/form/components/SubmitButton';

type IRtxSearchFormProps<T extends FieldValues> = Omit<
	IRtxFormProps<T, true>,
	'createMode' | 'submitButton' | 'useUrlParams'
>;

export const RtxSearchForm = <T extends FieldValues>(
	props: IRtxSearchFormProps<T>
) => {
	return (
		<RtxForm<T, true>
			{...props}
			createMode
			submitButton={<SubmitButton text="Search" icon="fa-search fa-sm" />}
			useUrlParams
		>
			{props.children}
		</RtxForm>
	);
};
