import { BillingEntityIndexResponse } from 'RtModels';
import { useGetPartitionBilling } from 'RtUi/app/rtAdmin/Partitions/lib/service';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxBillingEntitySelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		BillingEntityIndexResponse,
		'billingEntityId',
		IsMulti,
		IsClearable
	> {}

export const RtxBillingEntitySelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	displayMode,
	label = 'Billing Entity',
	...props
}: IRtxBillingEntitySelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetPartitionBilling();

	return (
		<RtxSelectInput<
			BillingEntityIndexResponse,
			'billingEntityId',
			IsMulti,
			IsClearable
		>
			label={label}
			valueKey="billingEntityId"
			labelKey="companyName"
			options={data}
			displayMode={displayMode}
			isLoading={isLoading}
			{...props}
		/>
	);
};
