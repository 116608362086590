import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { ManagedUpdateSql } from '../lib/components/ManagedUpdateSql';
import { ManagedRespOrgEditor } from './lib/forms/ManagedRespOrgEditor';
import { ManagedRespOrgResource } from './lib/resources/ManagedRespOrgResource';
import { ManagedRespOrgRouter } from './ManagedRespOrg.router';
import { ManagedRespOrgProfileResponse } from 'RtModels';

interface IManagedRespOrgContainerState {
	activeTab: string;
	displayMode: boolean;
	managedRespOrgProfile?: ManagedRespOrgProfileResponse;
}

@ManagedRespOrgRouter.getProfileRtUiController()
export class ManagedRespOrgProfileContainer extends ProfileApplicationContainer<
	{},
	IManagedRespOrgContainerState
> {
	public Tabs = ManagedRespOrgRouter.getProfileTabs();

	public managedRespOrgResource = new ManagedRespOrgResource();

	public state: IManagedRespOrgContainerState = {
		activeTab: this.Tabs.Profile.header,
		displayMode: true
	};

	public componentDidMount() {
		const respOrgId = this.getIdParam();

		this.managedRespOrgResource.get(respOrgId).then((managedRespOrgProfile) => {
			this.setState({ managedRespOrgProfile });
		});
	}

	public render() {
		const { managedRespOrgProfile } = this.state;

		if (!managedRespOrgProfile) {
			return <Loading />;
		}

		return (
			<TabbedLayout<ManagedRespOrgProfileResponse>
				router={ManagedRespOrgRouter}
				profile={managedRespOrgProfile}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<ManagedRespOrgEditor
						editMode={managedRespOrgProfile}
						onUpdate={(managedRespOrgProfile) =>
							this.setState({ managedRespOrgProfile })
						}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Credentials}>
					<article className="mb-5">
						<ManagedUpdateSql<ManagedRespOrgProfileResponse>
							id={managedRespOrgProfile.respOrgId}
							profile={managedRespOrgProfile}
							resourceClass={ManagedRespOrgResource}
							onUpdate={(managedRespOrgProfile) =>
								this.setState({ managedRespOrgProfile })
							}
						/>
					</article>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
