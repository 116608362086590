import { InvoiceCycleGroup } from 'RtModels';
import { useGetInvoiceCycleGroups } from 'RtUi/app/rtSip/Invoices/lib/service';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxInvoiceCycleGroupSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		InvoiceCycleGroup,
		'invoiceCycleGroupId',
		IsMulti,
		IsClearable
	> {}

export const RtxInvoiceCycleGroupSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	label = 'Invoice Cycle Groups',
	...props
}: IRtxInvoiceCycleGroupSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetInvoiceCycleGroups();

	return (
		<RtxSelectInput<
			InvoiceCycleGroup,
			'invoiceCycleGroupId',
			IsMulti,
			IsClearable
		>
			label={label}
			valueKey="invoiceCycleGroupId"
			labelKey="label"
			options={data}
			isLoading={isLoading}
			{...props}
		/>
	);
};
