import { FC, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianServiceNumberSubscriptionIndexRequest,
	SubscriptionIndexResponse,
	AccountIndexResponse
} from 'RtModels';
import { TenDigitTextArea } from 'RtUi/app/rt800/Numbers/lib/controls/TenDigitTextArea';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import {
	CallTypesSelect,
	ICallTypeSelectOption
} from 'RtUi/app/rtVue/Connections/lib/controls/CallTypesSelect';
import { GuardianServiceNumberSubscriptionRouter } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/GuardianServiceNumberSubscription.router';
import { GuardianServiceNumberSubscriptionBulkCreate } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/lib/components/GuardianServiceNumberSubscriptionBulkCreate';
import { GuardianServiceNumberSubscriptionBulkCancel } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/lib/components/GuardianServiceNumberSubscriptionBulkCancel';
import { GuardianServiceNumberSubscriptionGrid } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/lib/grids/GuardianServiceNumberSubscriptionGrid';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { ServiceNumberPropertyToNameMap } from 'RtUi/app/rtCommon/ServiceNumbers/lib/grids/Constants';
import { GuardianServiceNumberSubscriptionForm } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/lib/forms/GuardianServiceNumberSubscriptionForm';
import { useReplaceWindowUrl } from 'RtUi/utils/hooks/useReplaceWindowUrl';

export const GuardianServiceNumberSubscriptionIndex: FC<
	React.PropsWithChildren<unknown>
> = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search', isDefault: true },
		Results: { header: 'Results' },
		BulkUpload: {
			header: 'Bulk Upload',
			isAction: true,
			rtRoute: RtVueApiRoutes.GuardianServiceNumberSubscriptions.Create
		},
		BulkCancel: {
			header: 'Bulk Cancel',
			isAction: true,
			rtRoute: RtVueApiRoutes.GuardianServiceNumberSubscriptions.Update
		},
		AddService: {
			header: 'Add Service Number',
			isAction: true
		}
	});
	const [isActive, setIsActive] = useState<number | null>(1);
	const resourceParams: GuardianServiceNumberSubscriptionIndexRequest = {};
	const [subscription, setSubscription] = useState<SubscriptionIndexResponse>();
	const [account, setAccount] = useState<AccountIndexResponse>();
	const [callType, setCallType] = useState<ICallTypeSelectOption>();
	const [serviceNumbers, setServiceNumbers] = useState<string[]>();
	const [partialServiceNumber, setPartialServiceNumber] = useState<string>();
	const [custExtRef1, setCustExtRef1] = useState<string>();
	const [custExtRef2, setCustExtRef2] = useState<string>();
	const [custOrderNumber, setCustOrderNumber] = useState<string>();
	const [filename, setFilename] = useState<string>();

	if (isActive !== null) {
		resourceParams.isActive = isActive;
	}

	if (subscription) {
		resourceParams.subscriptionId = subscription.subscriptionId;
	}

	if (callType) {
		resourceParams.callType = callType.callTypeId;
	}

	if (partialServiceNumber) {
		resourceParams.serviceNumber = partialServiceNumber;
	}

	if (account) {
		resourceParams.accountId = account.accountId;
	}

	if (serviceNumbers?.length) {
		resourceParams.serviceNumbers = serviceNumbers;
	}

	if (custExtRef1) {
		resourceParams.custExtRef1 = custExtRef1;
	}

	if (custExtRef2) {
		resourceParams.custExtRef2 = custExtRef2;
	}

	if (custOrderNumber) {
		resourceParams.custOrderNumber = custOrderNumber;
	}

	if (filename) {
		resourceParams.filename = filename;
	}

	useReplaceWindowUrl(activeTab.header, resourceParams);

	return (
		<TabbedLayout
			router={GuardianServiceNumberSubscriptionRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm
					wide
					onSubmit={(evt) => {
						evt.preventDefault();

						setActiveTab(tabs.Results.header);
					}}
				>
					<Row>
						<Col lg={6} xl={4}>
							<SubscriptionSelect
								onChange={setSubscription}
								value={subscription}
							/>
						</Col>
						<Col lg={6} xl={4}>
							<AccountSelect onChange={setAccount} value={account} />
						</Col>
						<Col lg={6} xl={4}>
							<InputFormControl
								label="Service Number"
								subLabel="(partial search)"
								value={partialServiceNumber}
								onChange={setPartialServiceNumber}
							/>
						</Col>
						<Col lg={6} xl={4}>
							<CallTypesSelect onChange={setCallType} value={callType} />
						</Col>
						<Col lg={6} xl={4}>
							<InputFormControl
								label={ServiceNumberPropertyToNameMap.get('custExtRef1')!}
								onChange={setCustExtRef1}
								value={custExtRef1}
							/>
						</Col>
						<Col lg={6} xl={4}>
							<InputFormControl
								label={ServiceNumberPropertyToNameMap.get('custExtRef2')!}
								onChange={setCustExtRef2}
								value={custExtRef2}
							/>
						</Col>
						<Col lg={6} xl={4}>
							<InputFormControl
								label={ServiceNumberPropertyToNameMap.get('custOrderNumber')!}
								onChange={setCustOrderNumber}
								value={custOrderNumber}
							/>
						</Col>
						<Col lg={6} xl={4}>
							<InputFormControl
								label="filename"
								onChange={setFilename}
								value={filename}
							/>
						</Col>
						<Col lg={12}>
							<TenDigitTextArea
								allowForUpload
								allowInternational
								label="Service Numbers"
								subLabel="(multiple search)"
								value={serviceNumbers}
								onChange={setServiceNumbers}
							/>
						</Col>
						<Col lg={6}>
							<IsActiveRadioFormControl
								onChange={setIsActive}
								value={isActive}
							/>
						</Col>
					</Row>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<GuardianServiceNumberSubscriptionGrid
					resourceParams={resourceParams}
					showActions
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.BulkUpload}>
				<GuardianServiceNumberSubscriptionBulkCreate
					onUpload={() => setActiveTab(tabs.Results.header)}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.BulkCancel}>
				<GuardianServiceNumberSubscriptionBulkCancel
					onUpload={() => setActiveTab(tabs.Results.header)}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.AddService}>
				<GuardianServiceNumberSubscriptionForm
					createMode
					narrow
					onCancel={() => {
						setActiveTab(tabs.Search.header);
					}}
					onSuccess={() => {
						setActiveTab(tabs.Results.header);
					}}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

GuardianServiceNumberSubscriptionRouter.setIndexRtUiFunctionalComponent(
	GuardianServiceNumberSubscriptionIndex,
	{
		groupName: 'Configuration',
		orderPriority: 0,
		groupOrderPriority: 90
	}
);
