import { AsrLiteIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { AverageCallDurationDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AverageCallDurationDataGridColumn';
import { CallDateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDateDataGridColumn';
import {
	DataGridColumnAlignment,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { CallDurationDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDurationDataGridColumn';
import { sumBy } from 'lodash-es';
import { AnswerRateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AnswerRateDataGridColumn';

export const getAsrLiteColumns = (
	callDateFormat?: string
): Array<DataGridColumn<AsrLiteIndexResponse>> => [
	CallDateDataGridColumn({
		accessorKey: 'callDate',
		header: 'Call Date',
		hiddenIfEmpty: true,
		format: callDateFormat
	}),
	AccountDataGridColumn({
		accessorKey: 'customerAccountId',
		header: 'Customer Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'customerSubscriptionId',
		header: 'Customer Subscription',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'customerExternalAccountId',
		header: 'Customer External Account Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'customerExternalSubAccountId',
		header: 'Customer External Sub Account Id',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'vendorAccountId',
		header: 'Vendor Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'vendorSubscriptionId',
		header: 'Vendor Subscription',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'vendorExternalAccountId',
		header: 'External Vendor Account Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'vendorExternalSubAccountId',
		header: 'External Vendor Sub Account Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressTrunkGroup',
		header: 'Egress Trunk Group',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressTrunkGroupLabel',
		header: 'Egress Trunk Group Label',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressTrunkGroup',
		header: 'Ingress Trunk Group',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressTrunkGroupLabel',
		header: 'Ingress Trunk Group Label',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'ingressReleaseId',
		header: 'Ingress Release Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressCountry',
		header: 'Ingress Country',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressCountry',
		header: 'Egress Country',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'egressReleaseId',
		header: 'Egress Release Id',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'egressResultId',
		header: 'Egress Result Id',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'ingressResultId',
		header: 'Ingress Result Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'attempts',
		header: 'Attempts',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'connects',
		header: 'Connects',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	CallDurationDataGridColumn({
		accessorKey: 'duration',
		header: 'Duration',
		showFooter: true
	}),
	AnswerRateDataGridColumn({
		accessorKey: 'actualAsr',
		header: 'Actual Asr',
		hiddenIfEmpty: true
	}),
	AverageCallDurationDataGridColumn({
		accessorKey: 'aloc',
		header: 'ALOC',
		hiddenIfEmpty: true,
		showFooter: true,
		align: DataGridColumnAlignment.RIGHT,
		connections: (rows) => sumBy(rows, 'connects'),
		duration: (rows) => sumBy(rows, 'duration')
	}),
	IntegerDataGridColumn({
		accessorKey: 'sub6',
		header: 'Sub 6',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'roboscore',
		header: 'Avg Robo Score',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'fraudscore',
		header: 'Fraud Score',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'anomalyscore',
		header: 'Anomaly Score',
		hiddenIfEmpty: true
	}),
	AnswerRateDataGridColumn({
		accessorKey: 'asr',
		header: 'ASR',
		hiddenIfEmpty: true,
		showFooter: true,
		align: DataGridColumnAlignment.RIGHT,
		attempts: (rows) => sumBy(rows, 'attempts'),
		connections: (rows) => sumBy(rows, 'connects')
	}),
	IntegerDataGridColumn({
		accessorKey: 'routeAdvances',
		header: 'Route Advances',
		hiddenIfEmpty: true,
		showFooter: true
	})
];
