import { useQuery } from 'react-query';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { BlockTypeIndexRequest, BlockTypeIndexResponse } from 'RtModels';
import {
	RequestResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchBlockTypes = async <IncludeFullResponse extends boolean = false>(
	queryParams?: BlockTypeIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<BlockTypeIndexResponse[], IncludeFullResponse>> => {
	return handleGetRequest<BlockTypeIndexResponse[], IncludeFullResponse>(
		RtxSipApiRoutes.BlockTypes.Index,
		{
			queryParams,
			includeFullResponse
		}
	);
};

export const useGetBlockTypes = <IncludeFullResponse extends boolean = false>(
	params?: BlockTypeIndexRequest,
	includeFullResponse?: IncludeFullResponse
) => {
	return useQuery<
		RequestResponse<BlockTypeIndexResponse[], IncludeFullResponse>,
		Error
	>(['getBlockTypes', params, includeFullResponse], () =>
		fetchBlockTypes(params, includeFullResponse)
	);
};
