import { CdrExportSourceTypes } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AccountDataGridCache } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { SubscriptionDataGridCache } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { GenericTypeRender } from 'RtUi/components/ui/GenericTypeRenderer';

interface RecurringExportDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	cdrSourceTypeId: (row: T) => CdrExportSourceTypes;
}

export const RecurringExportDataGridColumn = <T = any,>({
	cdrSourceTypeId,
	...config
}: RecurringExportDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: any, data: T) => {
		const type = cdrSourceTypeId(data);

		switch (type) {
			case CdrExportSourceTypes.Subscription:
				if (Number(value === 0)) {
					return 'All';
				}
				return (
					<GenericTypeRender
						id={value}
						showIdInLabel={false}
						resourceCacheMap={SubscriptionDataGridCache}
					/>
				);
			case CdrExportSourceTypes.Account:
				return (
					<GenericTypeRender
						id={value}
						showIdInLabel={false}
						resourceCacheMap={AccountDataGridCache}
					/>
				);
			default:
				return value;
		}
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: any, data) => getValue(value, data),
		getValue: ({ cell, row }) =>
			getValue(cell.getValue<any>(), row.original as T)
	});
};
