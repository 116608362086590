import {
	CallTypeIndexResponse,
	CallTypes,
	ConnectionIndexResponse,
	GuardianConnectionSubscriptionCreateRequest,
	GuardianConnectionSubscriptionProfileResponse,
	GuardianConnectionSubscriptionUpdateRequest,
	GuardianConnectionUpdateOptions
} from 'RtModels';
import { ConnectionSubscriptionResource } from 'RtUi/app/rtVue/ConnectionSubscription/lib/resources/ConnectionSubscriptionResource';
import { ConnectionEditor } from 'RtUi/app/rtVue/Connections/lib/forms/ConnectionEditor';
import { RtxForm } from 'RtUi/components/rtx/form';
import { RtxDateInput } from 'RtUi/components/rtx/inputs/Date/RtxDateInput';
import { RtxDirectionInput } from 'RtUi/components/rtx/inputs/Direction/RtxDirectionInput';
import { RtxIsActiveInput } from 'RtUi/components/rtx/inputs/IsActive/RtxIsActiveInput';
import { RtxRadioInput } from 'RtUi/components/rtx/inputs/Radio/RtxRadioInput';
import { RtxCallTypeSelect } from 'RtUi/components/rtx/inputs/Select/instances/CallType/RtxCallTypeSelect';
import { RtxVueConnectionSelect } from 'RtUi/components/rtx/inputs/Select/instances/VueConnection/RtxVueConnectionSelect';
import { RtxTextInput } from 'RtUi/components/rtx/inputs/Text/RtxTextInput';
import { RtError } from 'RtUi/utils/errors/RtError';
import { endOfDay, startOfDay } from 'date-fns';
import { useState } from 'react';
import { Alert, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Controller } from 'react-hook-form';

interface IVueConnectionEditorFormProps {
	value?: GuardianConnectionSubscriptionProfileResponse;
	createMode?: boolean;
	onCancel?: () => void;
	onSuccess?: () => void;
	subscriptionId: number;
}

export const VueConnectionEditorForm = ({
	value,
	createMode = false,
	subscriptionId,
	onSuccess = () => {},
	onCancel = () => {}
}: IVueConnectionEditorFormProps): JSX.Element => {
	const [vueConnection, setVueConnection] = useState<ConnectionIndexResponse>();
	const [showCreateConnectionModal, setShowCreateConnectionModal] =
		useState<boolean>(false);
	const [updateOption, setUpdateOption] =
		useState<GuardianConnectionUpdateOptions>(
			GuardianConnectionUpdateOptions.ThisTrunkGroupOnly
		);
	const [callType, setCallType] = useState<CallTypeIndexResponse>();
	const [error, setError] = useState<RtError>();

	const submitHandler = async (
		data: GuardianConnectionSubscriptionProfileResponse
	) => {
		const resource = new ConnectionSubscriptionResource();

		try {
			setError(undefined);
			const payload:
				| GuardianConnectionSubscriptionCreateRequest
				| GuardianConnectionSubscriptionUpdateRequest = {
				...data,
				updateOption
			};

			if (createMode) {
				await resource.create(
					payload as GuardianConnectionSubscriptionCreateRequest
				);
			} else {
				await resource.update(
					data.guardianConnectionSubscriptionId,
					payload as GuardianConnectionSubscriptionUpdateRequest
				);
			}

			onSuccess();
		} catch (e: any) {
			setError(new RtError(e));
		}
	};

	return (
		<>
			<RtxForm<GuardianConnectionSubscriptionProfileResponse>
				defaultValues={{
					startTs: startOfDay(new Date()),
					endTs: endOfDay(new Date()),
					subscriptionId,
					...value
				}}
				onSubmit={submitHandler}
				error={
					error && {
						name: 'root',
						error: {
							message: error.message
						}
					}
				}
				onCancel={onCancel}
			>
				{({ control, setValue }) => (
					<>
						<RtxTextInput
							label="Subscription ID"
							required
							type="number"
							className="mb-3"
							displayMode
							value={String(subscriptionId)}
						/>
						<Controller
							control={control}
							name="guardianConnectionId"
							render={({ field: { onChange, value } }) => (
								<div className="d-flex gap-1 align-items-center mb-3">
									<div className="flex-grow-1">
										<RtxVueConnectionSelect
											value={vueConnection}
											required
											appendDropdownToBody={false}
											onChange={(val) => {
												setVueConnection(val);
												onChange(val.connectionId);
											}}
											initialOptionId={value}
										/>
									</div>
									<OverlayTrigger
										overlay={(props) => (
											<Tooltip id={`connection-tooltip`} {...props}>
												Create new connection
											</Tooltip>
										)}
									>
										{({ ref, ...triggerHandler }) => (
											<Button
												ref={ref}
												{...triggerHandler}
												onClick={() => setShowCreateConnectionModal(true)}
												variant="white"
												id="connection"
											>
												<i className="fas fa-fw fa-plus fa-lg" />
											</Button>
										)}
									</OverlayTrigger>
								</div>
							)}
						/>
						<Controller
							control={control}
							name="callType"
							render={({ field: { onChange, value } }) => (
								<RtxCallTypeSelect
									className="mb-3"
									required
									onChange={(val) => {
										setCallType(val);
										onChange(val?.callTypeId);
									}}
									appendDropdownToBody={false}
									isClearable
									value={callType}
									initialOptionId={value as CallTypes}
								/>
							)}
						/>
						<Controller
							control={control}
							name="directionId"
							render={({ field: { onChange, value } }) => (
								<RtxDirectionInput
									callType={callType?.callTypeId}
									onChange={onChange}
									className="mb-3"
									value={value}
									required
								/>
							)}
						/>
						<Controller
							control={control}
							name="startTs"
							render={({ field: { onChange, value } }) => (
								<RtxDateInput
									required
									label="Start Date"
									className="mb-3"
									onChange={(val) => onChange(val)}
									value={value}
								/>
							)}
						/>
						<Controller
							control={control}
							name="endTs"
							render={({ field: { onChange, value } }) => (
								<RtxDateInput
									label="End Date"
									className="mb-3"
									onChange={(val) => onChange(val)}
									value={value}
								/>
							)}
						/>
						{!createMode && (
							<Controller
								control={control}
								name="isActive"
								render={({ field: { onChange, value } }) => (
									<RtxIsActiveInput
										className="mb-3"
										hideBothOption={true}
										label="Active"
										onChange={onChange}
										value={value}
									/>
								)}
							/>
						)}
						<Alert variant="warning">
							<RtxRadioInput<GuardianConnectionUpdateOptions>
								label="Apply Changes To"
								value={updateOption}
								required
								onChange={(val) => setUpdateOption(Number(val))}
								options={[
									{
										value: GuardianConnectionUpdateOptions.ThisTrunkGroupOnly,
										label: 'This trunk group only'
									},
									{
										value:
											GuardianConnectionUpdateOptions.AllTrunkGroupsInThisSwitch,
										label:
											'All trunk groups in this switch with this trunk group number'
									},
									{
										value: GuardianConnectionUpdateOptions.AllTrunkGroups,
										label:
											'All trunk groups with this number across ALL switches (dangerous)'
									}
								]}
							/>
						</Alert>
						<Modal show={showCreateConnectionModal} size="lg">
							<Modal.Header>
								<Modal.Title>Create new connection</Modal.Title>
							</Modal.Header>

							<Modal.Body>
								<ConnectionEditor<true>
									col={12}
									onUpdate={([profile]) => {
										setVueConnection(profile as any);
										setValue('guardianConnectionId', profile?.connectionId);
										setShowCreateConnectionModal(false);
									}}
									onCancel={() => setShowCreateConnectionModal(false)}
								/>
							</Modal.Body>
						</Modal>
					</>
				)}
			</RtxForm>
		</>
	);
};
