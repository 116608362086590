import { TimeFilter, TollfreeEnhancedSummaryIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { CallDateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { FileStreamDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FileStreamDataColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';

export const getTollFreeEnhancedColumns = (
	timeRange: TimeFilter,
	onDrillDown: (row: TollfreeEnhancedSummaryIndexResponse) => void
): Array<DataGridColumn<TollfreeEnhancedSummaryIndexResponse>> => [
	CallDateDataGridColumn({
		format: timeRange?.detailLevel?.key === 'month' ? 'YYYY-MM' : 'YYYY-MM-DD',
		header: 'Call Date',
		accessorKey: 'callDate'
	}),
	DefaultDataGridColumn({
		header: 'Calculated Template',
		accessorKey: 'calculatedTemplate',
		onDrillDown: (e, row) => {
			e.preventDefault();
			onDrillDown(row);
		},
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'calculatedResporg',
		header: 'Calculated RespOrg',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'calculatedCic',
		header: 'Calculated Cic',
		hiddenIfEmpty: true
	}),
	FileStreamDataGridColumn({
		accessorKey: 'fileStreamId',
		header: 'File Stream',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressToStd',
		header: 'Ingress To Std',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressFrDialCode',
		header: 'Ingress Fr Dial Code',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressTrunkGroup',
		header: 'Ingress Trunk Group',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressTrunkGroup',
		header: 'Egress Trunk Group',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'connect',
		header: 'Connects',
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'originalRevenue',
		header: 'Original Revenue',
		showFooter: true
	}),
	IntegerDataGridColumn({
		header: 'Original Revenue Duration',
		accessorFn: (row) => {
			return (Number(row.originalRevenueDurationMs) || 0) / 60_000;
		},
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'originalCost',
		header: 'Original Cost',
		showFooter: true
	}),
	IntegerDataGridColumn({
		header: 'Original Cost Duration',
		accessorFn: (row) => {
			return (Number(row.originalCostDurationMs) || 0) / 60_000;
		},
		showFooter: true
	}),
	PrecisionDataGridColumn({
		header: 'Original CPM',
		decimals: 4,
		accessorFn: (row) => {
			if (row.originalCostDurationMs) {
				return (
					(Number(row.originalCost) || 0) /
					(Number(row.originalCostDurationMs) / 60_000)
				);
			}
		}
	}),
	IntegerDataGridColumn({
		header: 'Calculated Cost Duration',
		accessorFn: (row) => {
			return (Number(row.calculatedCostDurationMs) || 0) / 60_000;
		},
		showFooter: true
	}),
	PrecisionDataGridColumn({
		header: 'calculated CPM',
		decimals: 4,
		accessorFn: (row) => {
			if (row.calculatedCostDurationMs) {
				return (
					(Number(row.calculatedCost) || 0) /
					(Number(row.calculatedCostDurationMs) / 60_000)
				);
			}
		}
	}),
	PrecisionDataGridColumn({
		accessorKey: 'calculatedCost',
		header: 'Calculated Cost',
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'originalMargin',
		header: 'Original Margin',
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'calculatedMargin',
		header: 'Calculated Margin',
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'calculatedLossCount',
		header: 'Calculated Loss Count',
		onDrillDown: (e, row) => {
			e.preventDefault();
			onDrillDown(row);
		},
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'calculatedLossMargin',
		header: 'Calculated Loss Margin',
		showFooter: true
	})
];
