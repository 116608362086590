import {
	FinancialIndexResponseData,
	HavingFilter,
	HavingFilterOperator,
	ReportAggregates,
	ReportDataColumnPresets,
	Reports
} from 'RtModels';
import { FinancialViewRouter } from 'RtUi/app/rtVue/FinancialView/FinancialView.router';
import { FinancialTotalsLineChart } from 'RtUi/app/rtVue/FinancialView/lib/charts/FinancialTotalsLineChart';
import { FinancialDataGrid } from 'RtUi/app/rtVue/FinancialView/lib/grids/FinancialDataGrid';
import {
	IRtVueContainerState,
	RtVueReportContainer
} from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import { RadioButtons } from 'RtUi/components/ui/RadioButtons';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import * as React from 'react';
import { Form } from 'react-bootstrap';

interface IFinancialViewIndexContainerState
	extends IRtVueContainerState<FinancialIndexResponseData> {
	reportDataColumnPreset: ReportDataColumnPresets;
}

@FinancialViewRouter.getIndexRtUiController({
	groupName: 'Business Reports',
	groupId: 'VueBusinessReports',
	orderPriority: 2,
	groupOrderPriority: 20
})
export class FinancialViewIndexContainer extends RtVueReportContainer<
	FinancialIndexResponseData,
	IFinancialViewIndexContainerState
> {
	private static DRILL_DOWN_COLUMN = [
		'vendorSubscriptionId',
		'ingressTrunkGroup',
		'customerSubscriptionId',
		'egressTrunkGroup'
	];

	public state: IFinancialViewIndexContainerState = {
		...this.getBaseState(),
		dataSums: [ReportAggregates.Margin],
		reportDataColumnPreset: ReportDataColumnPresets.IngressDetail
	};

	protected reportId = Reports.Financial;
	protected router = FinancialViewRouter;

	public async getResource(
		allRows: boolean = false
	): Promise<HttpResource<FinancialIndexResponseData>> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns,
			havingFilter
		} = this.state;
		const vueHttp = new RtVueHttp();
		const pageSize = allRows ? 1_000_000 : undefined;
		const {
			data: { data },
			totalCount
		} = await vueHttp.getFinancialView(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			additionalColumns?.map((column) => column.value),
			havingFilter,
			pageSize
		);
		const financeResource = new ArrayResource<FinancialIndexResponseData>(
			'callDate',
			data
		);

		financeResource.setTotalCount(totalCount);

		return financeResource;
	}

	protected onDrillDown = () => {
		const timeRange = this.state.timeRange;
		const havingFilter: HavingFilter[] = [
			{
				column: 'negMargin',
				operator: HavingFilterOperator.Lesser,
				value: String('-0.01'), //ask @dshifley
				isSum: 1
			}
		];

		const additionalColumns = this.state.allColumns.filter((column) =>
			FinancialViewIndexContainer.DRILL_DOWN_COLUMN.includes(column.value)
		);

		this.setState(
			{
				havingFilter,
				timeRange,
				additionalColumns
			},
			() => {
				this.updateReports();
			}
		);
	};

	protected switchReportPreset(
		reportDataColumnPreset: ReportDataColumnPresets
	) {
		this.setState({ reportDataColumnPreset }, () => {
			this.updateReports();
		});
	}

	/**
	 * @override
	 */
	protected appendToFilters() {
		return (
			<Form.Group className="mb-3">
				<Form.Label className="label-90">Type:</Form.Label>
				<RadioButtons<ReportDataColumnPresets>
					onChange={(reportDataColumnPreset) =>
						this.switchReportPreset(reportDataColumnPreset)
					}
					value={this.state.reportDataColumnPreset}
					options={[
						{ label: 'Ingress', value: ReportDataColumnPresets.IngressDetail },
						{ label: 'Egress', value: ReportDataColumnPresets.EgressDetail }
					]}
				/>
			</Form.Group>
		);
	}

	protected renderReports(): React.ReactNode {
		return (
			<>
				<FinancialTotalsLineChart resource={this.state.resource} />
				<FinancialDataGrid
					isLoading={this.state.loadingReport}
					defaultSorting={this.state.defaultSorting}
					onSortingChange={this.onSortedChange}
					onDrillDown={this.onDrillDown}
					resource={this.state.resource}
					callDateFormat={this.getCallDateFormat()}
					container={this}
				/>
			</>
		);
	}
}
