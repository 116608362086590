import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DefaultDataGridColumn,
	DataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { dateToReadable } from 'RtUi/utils/maps';
import moment from 'moment';

interface DateDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	format?: string;
	timezone?: string | 'UTC';
	showTime?: boolean;
	isMilitaryTimeFormat?: boolean;
}

export const DateDataGridColumn = <T = any,>({
	format,
	showTime = false,
	timezone = 'UTC',
	isMilitaryTimeFormat = false,
	exportExcelDateFormat: exportFormat = 'yyyy-mm-dd',
	...config
}: DateDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const formattedValue = (value: string) => {
		if (!value) {
			return '';
		}

		const date = moment.utc(value);
		if (format && date.isValid()) {
			return date.format(format).toString();
		}

		return dateToReadable(value, showTime, timezone, isMilitaryTimeFormat);
	};

	return DefaultDataGridColumn({
		...config,
		exportExcelDateFormat: exportFormat,
		exportValue: (value, row, isExcel) => {
			if (!moment(value).isValid()) {
				return '';
			}

			if (isExcel) {
				return new Date(value);
			}

			return formattedValue(value);
		},
		filterFn: (row, id, filterValue) => {
			return formattedValue(row.getValue(id)).includes(filterValue);
		},
		getValue: ({ cell }) => formattedValue(cell.getValue<string>())
	});
};
