/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $JobIndexResponse = {
    properties: {
        jobId: {
            type: 'string',
            isRequired: true,
        },
        jobLabel: {
            type: 'string',
            isRequired: true,
        },
        jobSummary: {
            type: 'string',
            isRequired: true,
        },
        flowId: {
            type: 'number',
            isRequired: true,
        },
        userId: {
            type: 'number',
            isRequired: true,
        },
        userEmail: {
            type: 'string',
            isRequired: true,
        },
        jobStatusId: {
            type: 'number',
            isRequired: true,
        },
        jobStatus: {
            type: 'string',
            isRequired: true,
        },
        ref1: {
            type: 'string',
            isRequired: true,
        },
        ref2: {
            type: 'string',
            isRequired: true,
        },
        totalQty: {
            type: 'number',
            isRequired: true,
        },
        tfnErrorQty: {
            type: 'number',
            isRequired: true,
        },
        tfnWarningQty: {
            type: 'number',
            isRequired: true,
        },
        scheduledTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        priority: {
            type: 'number',
            isRequired: true,
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        completedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        resourceQty: {
            type: 'number',
            isRequired: true,
        },
        resourcesUsed: {
            type: 'string',
            isRequired: true,
        },
        resWithErrorQty: {
            type: 'number',
            isRequired: true,
        },
    },
};