import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { Permissions } from 'RtExports/routes';
import {
	AccountIndexResponse,
	CountryIndexResponse,
	FullSummaryColumns,
	FullSummaryIndexRequest,
	FullSummaryTimeDisplay,
	RateCenterIndexResponse,
	ServerProfileResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { useFormInitializer } from 'RtUi/components/rtx/form/hooks/useFormInitializer';
import { RtxSearchForm } from 'RtUi/components/rtx/form/RtxUiSearchForm';
import { RtxDateInput } from 'RtUi/components/rtx/inputs/Date/RtxDateInput';
import { RtxMultipleCheckboxInput } from 'RtUi/components/rtx/inputs/MultipleCheckbox/RtxMultipleCheckboxInput';
import { RtxNumberInput } from 'RtUi/components/rtx/inputs/Number/RtxNumber';
import { RtxRadioInput } from 'RtUi/components/rtx/inputs/Radio/RtxRadioInput';
import { RtxAccountSelect } from 'RtUi/components/rtx/inputs/Select/instances/Account/RtxAccountSelect';
import { RtxCountrySelect } from 'RtUi/components/rtx/inputs/Select/instances/Country/RtxCountrySelect';
import { RtxRateCenterSelect } from 'RtUi/components/rtx/inputs/Select/instances/RateCenter/RtxRateCenterSelect';
import { RtxServerSelect } from 'RtUi/components/rtx/inputs/Select/instances/Server/RtxServerSelect';
import { RtxCustomerOriginationSubscriptionSelect } from 'RtUi/components/rtx/inputs/Select/instances/Subscription/RtxCustomerOriginationSubscriptionSelect';
import { RtxCustomerTerminationSubscriptionSelect } from 'RtUi/components/rtx/inputs/Select/instances/Subscription/RtxCustomerTerminationSubscriptionSelect';
import { RtxVendorOriginationSubscriptionSelect } from 'RtUi/components/rtx/inputs/Select/instances/Subscription/RtxVendorOriginationSubscriptionSelect';
import { RtxVendorTerminationSubscriptionSelect } from 'RtUi/components/rtx/inputs/Select/instances/Subscription/RtxVendorTerminationSubscriptionSelect';
import { RtxTextInput } from 'RtUi/components/rtx/inputs/Text/RtxTextInput';
import { TwoFADisabledWarning } from 'RtUi/components/ui/WarningAlert/TwoFADisabledWarning';
import { UserActions } from 'RtUi/state/actions/user';

interface ISummaryReportSearchFormProps {
	resourceParams: FullSummaryIndexRequest;
	onSubmit: (newValues: FullSummaryIndexRequest) => void;
}

export const SummaryReportSearchForm = ({
	resourceParams,
	onSubmit
}: ISummaryReportSearchFormProps) => {
	const [servers, setServers] = useState<ServerProfileResponse[]>();
	const initializer = useFormInitializer();
	const [callingPartyCountry, setCallingPartyCountry] =
		useState<CountryIndexResponse[]>();
	const [callingPartyRateCenter, setCallingPartyRateCenter] =
		useState<RateCenterIndexResponse[]>();

	const [dialedNumberCountry, setDialedNumberCountry] =
		useState<CountryIndexResponse[]>();
	const [dialedNumberRateCenter, setDialedNumberRateCenter] =
		useState<RateCenterIndexResponse[]>();

	const [ingressRevenueAccounts, setIngressRevenueAccounts] =
		useState<AccountIndexResponse[]>();
	const [ingressRevenueSubscriptions, setIngressRevenueSubscriptions] =
		useState<SubscriptionIndexResponse[]>();

	const [egressRevenueAccounts, setEgressRevenueAccounts] =
		useState<AccountIndexResponse[]>();
	const [egressRevenueSubscriptions, setEgressRevenueSubscriptions] =
		useState<SubscriptionIndexResponse[]>();

	const [ingressCostAccounts, setIngressCostAccounts] =
		useState<AccountIndexResponse[]>();
	const [ingressCostSubscriptions, setIngressCostSubscriptions] =
		useState<SubscriptionIndexResponse[]>();

	const [egressCostAccounts, setEgressCostAccounts] =
		useState<AccountIndexResponse[]>();
	const [egressCostSubscriptions, setEgressCostSubscriptions] =
		useState<SubscriptionIndexResponse[]>();

	return (
		<>
			<TwoFADisabledWarning />
			<RtxSearchForm<FullSummaryIndexRequest>
				defaultValues={resourceParams}
				initializer={initializer}
				onLoadUrlParams={({ startTs, endTs, ...urlParams }) => {
					const initialValues: FullSummaryIndexRequest = {
						startTs: new Date(startTs),
						endTs: new Date(endTs),
						...urlParams
					};

					onSubmit(initialValues);
				}}
				onSubmit={onSubmit}
			>
				{({ control }) => (
					<>
						<Row className="mb-3">
							<Col md={6}>
								<h6>Date and Time</h6>
								<hr />
								<Controller
									control={control}
									name="startTs"
									render={({ field: { onChange, value } }) => (
										<RtxDateInput
											required
											className="mb-3"
											label="Start Date"
											value={value}
											onChange={onChange}
										/>
									)}
								/>
								<Controller
									control={control}
									name="endTs"
									render={({ field: { onChange, value } }) => (
										<RtxDateInput
											required
											label="End Date"
											className="mb-3"
											value={value}
											onChange={onChange}
										/>
									)}
								/>
								<Controller
									control={control}
									name="timeslot"
									render={({ field: { onChange, value } }) => (
										<RtxRadioInput<FullSummaryTimeDisplay>
											label="Time Display"
											onChange={(timeslot) => onChange(Number(timeslot))}
											value={value}
											options={[
												{
													value: FullSummaryTimeDisplay.None,
													label: 'None'
												},
												{
													value: FullSummaryTimeDisplay.TimeQuarterly,
													label: '15 Minutes'
												},
												{
													value: FullSummaryTimeDisplay.TimeHourly,
													label: 'Hourly'
												},
												{
													value: FullSummaryTimeDisplay.TimeUTCDaily,
													label: 'UTC Daily'
												},
												{
													value: FullSummaryTimeDisplay.TimeESTDaily,
													label: 'EST Daily'
												}
											]}
										/>
									)}
								/>
							</Col>
							<Col md={6}>
								<h6>Miscellaneous</h6>
								<hr />
								<Row>
									<Col md={6}>
										<Controller
											control={control}
											name="inMin"
											render={({ field: { onChange, value } }) => (
												<RtxNumberInput
													value={value}
													label="In. Min Dur"
													className="mb-3"
													onChange={onChange}
												/>
											)}
										/>
									</Col>
									<Col md={6}>
										<Controller
											control={control}
											name="inMax"
											render={({ field: { onChange, value } }) => (
												<RtxNumberInput
													value={value}
													label="In. Max Dur"
													className="mb-3"
													onChange={onChange}
												/>
											)}
										/>
									</Col>
								</Row>
								<Row>
									<Col md={6}>
										<Controller
											control={control}
											name="egMin"
											render={({ field: { onChange, value } }) => (
												<RtxNumberInput
													value={value}
													label="Eg. Min Dur"
													className="mb-3"
													onChange={onChange}
												/>
											)}
										/>
									</Col>
									<Col md={6}>
										<Controller
											control={control}
											name="egMax"
											render={({ field: { onChange, value } }) => (
												<RtxNumberInput
													value={value}
													className="mb-3"
													label="Eg. Max Dur"
													onChange={onChange}
												/>
											)}
										/>
									</Col>
								</Row>
								<Row>
									<Col md={6}>
										<Controller
											control={control}
											name="inCon"
											render={({ field: { onChange, value } }) => (
												<RtxNumberInput
													value={value}
													label="In. Min Cons"
													className="mb-3"
													onChange={onChange}
												/>
											)}
										/>
									</Col>
									<Col md={6}>
										<Controller
											control={control}
											name="egCon"
											render={({ field: { onChange, value } }) => (
												<RtxNumberInput
													value={value}
													label="Eg. Min Cons"
													className="mb-3"
													onChange={onChange}
												/>
											)}
										/>
									</Col>
								</Row>
								{UserActions.has(Permissions.RtxAdministrator) && (
									<Row>
										<Col>
											<Controller
												control={control}
												name="serverIds"
												render={({ field: { onChange, value } }) => (
													<RtxServerSelect<true>
														isMulti
														value={servers}
														onChange={(servers) => {
															setServers(servers);
															onChange(servers.map((s) => s.serverId));
														}}
														initialOptionId={value}
													/>
												)}
											/>
										</Col>
									</Row>
								)}
							</Col>
						</Row>
						<Row className="mb-3">
							<Col md={6}>
								<h6>Calling Party</h6>
								<hr />
								<Controller
									control={control}
									name="callingPartyStd"
									render={({ field: { onChange, value } }) => (
										<RtxTextInput
											label="Number (begins)"
											className="mb-3"
											value={value}
											onChange={onChange}
										/>
									)}
								/>
								<Controller
									control={control}
									name="callingPartyStdContains"
									render={({ field: { onChange, value } }) => (
										<RtxTextInput
											label="Number (contains)"
											className="mb-3"
											value={value}
											onChange={onChange}
										/>
									)}
								/>
								<Controller
									control={control}
									name="callingPartyCountryIsoIds"
									render={({ field: { onChange, value } }) => (
										<RtxCountrySelect<'iso3166Alpha3', true>
											isMulti
											label="Countries"
											className="mb-3"
											value={callingPartyCountry}
											onChange={(callingPartyCountry) => {
												setCallingPartyCountry(callingPartyCountry);
												onChange(
													callingPartyCountry.map(
														(country) => country.iso3166Alpha3
													)
												);
											}}
											initialOptionId={value}
										/>
									)}
								/>
								<Controller
									control={control}
									name="callingPartyRateCenterIds"
									render={({ field: { onChange, value } }) => (
										<RtxRateCenterSelect<true>
											isMulti
											className="mb-3"
											label="Rate Centers"
											value={callingPartyRateCenter}
											onChange={(callingPartyRateCenter) => {
												setCallingPartyRateCenter(callingPartyRateCenter);
												onChange(
													callingPartyRateCenter.map(
														(rateCenter) => rateCenter.rateCenterId
													)
												);
											}}
											initialOptionId={value}
										/>
									)}
								/>
								<Controller
									control={control}
									name="columns"
									render={({ field: { onChange, value } }) => (
										<RtxMultipleCheckboxInput<FullSummaryColumns>
											className="mb-3"
											label="Display Details"
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Country',
													value: FullSummaryColumns.CallingPartyCountryIsoId
												},
												{
													label: 'Rate Center',
													value: FullSummaryColumns.CallingPartyRateCenterId
												},
												{
													label: 'Rate Center Type',
													value: FullSummaryColumns.CallingPartyRateCenterTypeId
												},
												{
													label: 'Number (Std)',
													value: FullSummaryColumns.CallingPartyStd
												}
											]}
										/>
									)}
								/>
								<Controller
									control={control}
									name="columns"
									render={({ field: { onChange, value } }) => (
										<RtxMultipleCheckboxInput<FullSummaryColumns>
											className="mb-3"
											label="Attestation Detail"
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Attestation',
													value: FullSummaryColumns.CallingPartyAttestation
												},
												{
													label: 'Attestation URL',
													value: FullSummaryColumns.CallingPartyAttestationUrl
												}
											]}
										/>
									)}
								/>
							</Col>
							<Col md={6}>
								<h6>Dialed Number</h6>
								<hr />
								<Controller
									control={control}
									name="dialedNumberStd"
									render={({ field: { onChange, value } }) => (
										<RtxTextInput
											className="mb-3"
											label="Number (begins)"
											value={value}
											onChange={onChange}
										/>
									)}
								/>
								<Controller
									control={control}
									name="dialedNumberStdContains"
									render={({ field: { onChange, value } }) => (
										<RtxTextInput
											className="mb-3"
											label="Number (contains)"
											value={value}
											onChange={onChange}
										/>
									)}
								/>
								<Controller
									control={control}
									name="dialedNumberCountryIsoIds"
									render={({ field: { onChange, value } }) => (
										<RtxCountrySelect
											isMulti
											className="mb-3"
											label="Countries"
											value={dialedNumberCountry}
											onChange={(dialedNumberCountry) => {
												setDialedNumberCountry(dialedNumberCountry);
												onChange(
													dialedNumberCountry.map(
														(country) => country.iso3166Alpha3
													)
												);
											}}
											initialOptionId={value}
										/>
									)}
								/>
								<Controller
									control={control}
									name="dialedNumberRateCenterIds"
									render={({ field: { onChange, value } }) => (
										<RtxRateCenterSelect
											isMulti
											className="mb-3"
											label="Rate Centers"
											value={dialedNumberRateCenter}
											onChange={(dialedNumberRateCenter) => {
												setDialedNumberRateCenter(dialedNumberRateCenter);
												onChange(
													dialedNumberRateCenter.map(
														(rateCenter) => rateCenter.rateCenterId
													)
												);
											}}
											initialOptionId={value}
										/>
									)}
								/>
								<Controller
									control={control}
									name="columns"
									render={({ field: { onChange, value } }) => (
										<RtxMultipleCheckboxInput<FullSummaryColumns>
											className="mb-3"
											label="Display Details"
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Country',
													value: FullSummaryColumns.DialedNumberCountryIsoId
												},
												{
													label: 'Rate Center',
													value: FullSummaryColumns.DialedNumberRateCenterId
												},
												{
													label: 'Rate Center Type',
													value: FullSummaryColumns.DialedNumberRateCenterTypeId
												},
												{
													label: 'Number (Std)',
													value: FullSummaryColumns.DialedNumberStd
												}
											]}
										/>
									)}
								/>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col md={6}>
								<h6>Ingress Revenue</h6>
								<hr />
								<Controller
									control={control}
									name="ingressRevenueAccountIds"
									render={({ field: { onChange, value } }) => (
										<RtxAccountSelect<true>
											label="Accounts"
											className="mb-3"
											value={ingressRevenueAccounts}
											onChange={(ingressRevenueAccounts) => {
												setIngressRevenueAccounts(ingressRevenueAccounts);
												onChange(
													ingressRevenueAccounts.map(
														(account) => account.accountId
													)
												);
											}}
											initialOptionId={value}
											isMulti
										/>
									)}
								/>
								<Controller
									control={control}
									name="ingressRevenueSubscriptionIds"
									render={({ field: { onChange, value } }) => (
										<RtxCustomerOriginationSubscriptionSelect<true>
											label="Subscriptions"
											value={ingressRevenueSubscriptions}
											className="mb-3"
											onChange={(ingressRevenueSubscriptions) => {
												setIngressRevenueSubscriptions(
													ingressRevenueSubscriptions
												);
												onChange(
													ingressRevenueSubscriptions.map(
														(subscription) => subscription.subscriptionId
													)
												);
											}}
											initialOptionId={value}
											isMulti
										/>
									)}
								/>
								<Controller
									control={control}
									name="columns"
									render={({ field: { onChange, value } }) => (
										<RtxMultipleCheckboxInput<FullSummaryColumns>
											className="mb-3"
											label="Account Detail"
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Account',
													value: FullSummaryColumns.IngressRevenueAccountId
												},
												{
													label: 'Subscription',
													value: FullSummaryColumns.IngressRevenueSubscriptionId
												}
											]}
										/>
									)}
								/>
								<Controller
									control={control}
									name="columns"
									render={({ field: { onChange, value } }) => (
										<RtxMultipleCheckboxInput<FullSummaryColumns>
											className="mb-3"
											label="Rating Detail"
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Rate Key',
													value: FullSummaryColumns.IngressRevenueRateKey
												},
												{
													label: 'Rate',
													value: FullSummaryColumns.IngressRevenueRate
												},
												{
													label: 'Minutes',
													value: FullSummaryColumns.IngressRevenueMinutes
												},
												{
													label: 'Total',
													value: FullSummaryColumns.IngressRevenueTotal
												}
											]}
										/>
									)}
								/>
							</Col>
							<Col md={6}>
								<h6>Egress Revenue</h6>
								<hr />
								<Controller
									control={control}
									name="egressRevenueAccountIds"
									render={({ field: { onChange, value } }) => (
										<RtxAccountSelect<true>
											label="Accounts"
											className="mb-3"
											value={egressRevenueAccounts}
											onChange={(egressRevenueAccounts) => {
												setEgressRevenueAccounts(egressRevenueAccounts);
												onChange(
													egressRevenueAccounts.map(
														(account) => account.accountId
													)
												);
											}}
											initialOptionId={value}
											isMulti
										/>
									)}
								/>
								<Controller
									control={control}
									name="egressRevenueSubscriptionIds"
									render={({ field: { onChange, value } }) => (
										<RtxCustomerTerminationSubscriptionSelect<true>
											label="Subscriptions"
											className="mb-3"
											value={egressRevenueSubscriptions}
											onChange={(egressRevenueSubscriptions) => {
												setEgressRevenueSubscriptions(
													egressRevenueSubscriptions
												);
												onChange(
													egressRevenueSubscriptions.map(
														(subscription) => subscription.subscriptionId
													)
												);
											}}
											initialOptionId={value}
											isMulti
										/>
									)}
								/>
								<Controller
									control={control}
									name="columns"
									render={({ field: { onChange, value } }) => (
										<RtxMultipleCheckboxInput<FullSummaryColumns>
											className="mb-3"
											label="Account Detail"
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Account',
													value: FullSummaryColumns.EgressRevenueAccountId
												},
												{
													label: 'Subscription',
													value: FullSummaryColumns.EgressRevenueSubscriptionId
												}
											]}
										/>
									)}
								/>
								<Controller
									control={control}
									name="columns"
									render={({ field: { onChange, value } }) => (
										<RtxMultipleCheckboxInput<FullSummaryColumns>
											className="mb-3"
											label="Rating Detail"
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Rate Key',
													value: FullSummaryColumns.EgressRevenueRateKey
												},
												{
													label: 'Rate',
													value: FullSummaryColumns.EgressRevenueRate
												},
												{
													label: 'Minutes',
													value: FullSummaryColumns.EgressRevenueMinutes
												},
												{
													label: 'Total',
													value: FullSummaryColumns.EgressRevenueTotal
												}
											]}
										/>
									)}
								/>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col md={6}>
								<h6>Ingress Cost</h6>
								<hr />
								<Controller
									control={control}
									name="ingressCostAccountIds"
									render={({ field: { onChange, value } }) => (
										<RtxAccountSelect<true>
											label="Accounts"
											className="mb-3"
											value={ingressCostAccounts}
											onChange={(ingressCostAccounts) => {
												setIngressCostAccounts(ingressCostAccounts);
												onChange(
													ingressCostAccounts.map(
														(account) => account.accountId
													)
												);
											}}
											initialOptionId={value}
											isMulti
										/>
									)}
								/>
								<Controller
									control={control}
									name="ingressCostSubscriptionIds"
									render={({ field: { onChange, value } }) => (
										<RtxVendorOriginationSubscriptionSelect<true>
											label="Subscriptions"
											className="mb-3"
											value={ingressCostSubscriptions}
											onChange={(ingressCostSubscriptions) => {
												setIngressCostSubscriptions(ingressCostSubscriptions);
												onChange(
													ingressCostSubscriptions.map(
														(subscription) => subscription.subscriptionId
													)
												);
											}}
											initialOptionId={value}
											isMulti
										/>
									)}
								/>
								<Controller
									control={control}
									name="columns"
									render={({ field: { onChange, value } }) => (
										<RtxMultipleCheckboxInput<FullSummaryColumns>
											className="mb-3"
											label="Account Detail"
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Account',
													value: FullSummaryColumns.IngressCostAccountId
												},
												{
													label: 'Subscription',
													value: FullSummaryColumns.IngressCostSubscriptionId
												}
											]}
										/>
									)}
								/>
								<Controller
									control={control}
									name="columns"
									render={({ field: { onChange, value } }) => (
										<RtxMultipleCheckboxInput<FullSummaryColumns>
											className="mb-3"
											label="Rating Detail"
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Rate Key',
													value: FullSummaryColumns.IngressCostRateKey
												},
												{
													label: 'Rate',
													value: FullSummaryColumns.IngressCostRate
												},
												{
													label: 'Minutes',
													value: FullSummaryColumns.IngressCostMinutes
												},
												{
													label: 'Total',
													value: FullSummaryColumns.IngressCostTotal
												}
											]}
										/>
									)}
								/>
							</Col>
							<Col md={6}>
								<h6>Egress Cost</h6>
								<hr />
								<Controller
									control={control}
									name="egressCostAccountIds"
									render={({ field: { onChange, value } }) => (
										<RtxAccountSelect<true>
											label="Accounts"
											className="mb-3"
											value={egressCostAccounts}
											onChange={(egressCostAccounts) => {
												setEgressCostAccounts(egressCostAccounts);
												onChange(
													egressCostAccounts.map((account) => account.accountId)
												);
											}}
											initialOptionId={value}
											isMulti
										/>
									)}
								/>
								<Controller
									control={control}
									name="egressCostSubscriptionIds"
									render={({ field: { onChange, value } }) => (
										<RtxVendorTerminationSubscriptionSelect<true>
											label="Subscriptions"
											className="mb-3"
											value={egressCostSubscriptions}
											onChange={(egressCostSubscriptions) => {
												setEgressCostSubscriptions(egressCostSubscriptions);
												onChange(
													egressCostSubscriptions.map(
														(subscription) => subscription.subscriptionId
													)
												);
											}}
											initialOptionId={value}
											isMulti
										/>
									)}
								/>
								<Controller
									control={control}
									name="columns"
									render={({ field: { onChange, value } }) => (
										<RtxMultipleCheckboxInput<FullSummaryColumns>
											className="mb-3"
											label="Account Detail"
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Account',
													value: FullSummaryColumns.EgressCostAccountId
												},
												{
													label: 'Subscription',
													value: FullSummaryColumns.EgressCostSubscriptionId
												}
											]}
										/>
									)}
								/>
								<Controller
									control={control}
									name="columns"
									render={({ field: { onChange, value } }) => (
										<RtxMultipleCheckboxInput<FullSummaryColumns>
											className="mb-3"
											label="Rating Detail"
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Rate Key',
													value: FullSummaryColumns.EgressCostRateKey
												},
												{
													label: 'Rate',
													value: FullSummaryColumns.EgressCostRate
												},
												{
													label: 'Minutes',
													value: FullSummaryColumns.EgressCostMinutes
												},
												{
													label: 'Total',
													value: FullSummaryColumns.EgressCostTotal
												}
											]}
										/>
									)}
								/>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col md={6}>
								<h6>Ingress Connection</h6>
								<hr />
								<Controller
									control={control}
									name="columns"
									render={({ field: { onChange, value } }) => (
										<RtxMultipleCheckboxInput<FullSummaryColumns>
											className="mb-3"
											label="Signaling Detail"
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Remote IP',
													value: FullSummaryColumns.IngressConnectionRemoteIp
												},
												{
													label: 'Result',
													value: FullSummaryColumns.IngressConnectionResult
												}
											]}
										/>
									)}
								/>
							</Col>
							<Col md={6}>
								<h6>Egress Connection</h6>
								<hr />
								<Controller
									control={control}
									name="columns"
									render={({ field: { onChange, value } }) => (
										<RtxMultipleCheckboxInput<FullSummaryColumns>
											className="mb-3"
											label="Signaling Detail"
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Remote IP',
													value: FullSummaryColumns.EgressConnectionRemoteIp
												},
												{
													label: 'Result',
													value: FullSummaryColumns.EgressConnectionResult
												}
											]}
										/>
									)}
								/>
							</Col>
						</Row>
					</>
				)}
			</RtxSearchForm>
		</>
	);
};
