/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LumenDidServiceAddress = {
    properties: {
        streetNumber: {
            type: 'string',
            isRequired: true,
        },
        streetName: {
            type: 'string',
            isRequired: true,
        },
        streetSuffix: {
            type: 'string',
            isRequired: true,
        },
        city: {
            type: 'string',
            isRequired: true,
        },
        state: {
            type: 'string',
            isRequired: true,
        },
        zipCode: {
            type: 'string',
            isRequired: true,
        },
        country: {
            type: 'string',
            isRequired: true,
        },
    },
};