export enum RtxPartitionId {
	MyCountryMobileBls = 11,
	LDINetworksLdi = 16,
	DemoDmo = 22,
	CometMediaLum = 23,
	TollFreeManagementSms = 25,
	RTXSolutionsRtx = 29,
	PrimaryWaveMediaPwm = 30,
	PPLSPpl = 33,
	CallDeliverySystemsCds = 34,
	NationalSalesPartnersNsp = 35,
	LDXNetworksLdx = 36,
	TNAuthorityTna = 40,
	TelnetTnt = 41,
	WholesaleCarrierServicesWcs = 42,
	MyHealthAngelMha = 442,
	LingoLgo = 453,
	LifeProtectLpt = 467,
	USADigitalUsa = 470,
	IntradoInt = 474,
	TwilioTwi = 501,
	TouchtoneTtn = 504,
	T3CommunicationsT3c = 516,
	TeliaxTex = 517,
	CallSourceCal = 520,
	USSouthUss = 541,
	Magna5Mga = 555,
	Go2UnoUno = 562,
	VoIPInnovationsVoi = 563,
	VoiceIncVce = 566,
	FortySixLabsLab = 567,
	TelnyxTyx = 571,
	TelevergenceTvg = 602,
	SyringaSyr = 603,
	InvocaInv = 617,
	IntelemediaIma = 620,
	Call48C48 = 623,
	RichardsonElectronicsRic = 648,
	PhoneNumberPlusPnp = 651,
	PayPortPpt = 672,
	GraniteTelecommunicationsGra = 677,
	CloudAgeSolutionsClo = 680,
	NetrioNet = 683,
	ICaptiveCap = 685,
	AllAccessAll = 692,
	GainscoGco = 703,
	QuestblueBlu = 707,
	OneEightHundredDialWordDwd = 715,
	LiveVoxLvx = 718,
	VoyceTelecomVoy = 732,
	AllstreamAls = 744,
	ANINetworksAni = 756,
	TwentySixHundredHzSix = 759,
	Direct2MarketingD2m = 762,
	CallHavenPartnersChp = 764,
	KnotNetworksKno = 771,
	TelcoloudTcl = 799,
	BandwidthBan = 823,
	OomaOom = 876
}
