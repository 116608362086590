import {
	FormControl,
	FormControlLabel,
	FormControlProps,
	FormLabel,
	FormLabelOwnProps,
	Radio,
	RadioGroup,
	RadioGroupProps,
	Tooltip
} from '@mui/material';
import { noop } from 'lodash-es';
import { ReactNode } from 'react';

export interface RtxRadioInputOptions<T> {
	label: string | ReactNode;
	value: T;
	tooltip?: string;
}

export type RtxRadioInputProps<T> = Omit<
	FormControlProps,
	'onChange' | 'value'
> &
	Omit<RadioGroupProps, 'onChange' | 'value'> & {
		label: string;
		value?: T | null;
		displayMode?: boolean;
		vertical?: boolean;
		color?: FormLabelOwnProps['color'];
		options: Array<RtxRadioInputOptions<T>>;
		onChange?: (value: string) => void;
	};

export const RtxRadioInput = <T = any,>({
	label,
	options,
	value = null,
	defaultValue,
	disabled,
	displayMode,
	color = 'primary',
	vertical = false,
	onChange = noop,
	...props
}: RtxRadioInputProps<T>) => {
	const getRadioComponent = (option: RtxRadioInputOptions<T>) => {
		const radioComponent = (
			<Radio
				sx={{
					'& .MuiSvgIcon-root': {
						fontSize: 16
					}
				}}
			/>
		);

		if (!option.tooltip) {
			return radioComponent;
		}

		return <Tooltip title={option.tooltip}>{radioComponent}</Tooltip>;
	};

	return (
		<FormControl
			variant="outlined"
			sx={{ width: '100%' }}
			disabled={displayMode || disabled}
			{...props}
		>
			<FormLabel sx={{ fontSize: '12px' }} color={color}>
				{label}
			</FormLabel>
			<RadioGroup
				row={!vertical}
				defaultValue={defaultValue}
				value={value}
				onChange={(evt) => onChange(evt.target.value)}
			>
				{options.map((option, key) => (
					<FormControlLabel
						key={key}
						value={option.value}
						sx={{
							'.MuiTypography-root': {
								'fontSize': 16,
								'&.Mui-disabled': {
									fontWeight: 550,
									color: '#9c9c9c'
								}
							}
						}}
						control={getRadioComponent(option)}
						label={option.label}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};
