import { Star, StarBorder } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import { GuardianUserQueryIndexResponse, Reports } from 'RtModels';
import { UserActions } from 'RtUi/state/actions/user';
import { useMemo } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { Permissions } from 'RtExports/routes';

interface CannedReportProps {
	reports: GuardianUserQueryIndexResponse[];
	favoriteReports?: GuardianUserQueryIndexResponse[];
	handleUpdateReportFavoriteStatus: (
		report: GuardianUserQueryIndexResponse
	) => void;
	handleRedirectToReportPage: (report: GuardianUserQueryIndexResponse) => void;
	loading: boolean;
}

const CannedReportsAccordion = ({
	reports,
	handleUpdateReportFavoriteStatus,
	handleRedirectToReportPage,
	favoriteReports = [],
	loading
}: CannedReportProps) => {
	const networkReports = useMemo(
		() =>
			reports.filter((report) =>
				[Reports.Asr, Reports.Cps, Reports.Ports, Reports.AsrLite].includes(
					report.reportId
				)
			),
		[reports]
	);
	const financialReports = useMemo(
		() =>
			reports.filter((report) =>
				[
					Reports.Financial,
					Reports.Numbers,
					Reports.FourteenDay,
					Reports.BirdsEyeView
				].includes(report.reportId)
			),
		[reports]
	);
	const regulatoryReports = useMemo(
		() =>
			reports.filter((report) => [Reports.Roboscore].includes(report.reportId)),
		[reports]
	);
	const tollfreeEnhancedReports = useMemo(
		() =>
			reports.filter((report) =>
				[Reports.TollfreeEnhanced].includes(report.reportId)
			),
		[reports]
	);
	const ratingReports = useMemo(
		() =>
			reports.filter((report) =>
				[Reports.RejectSummary].includes(report.reportId)
			),
		[reports]
	);

	const label = { inputProps: { 'aria-label': 'Favorite' } };

	const getReportItems = (reports: GuardianUserQueryIndexResponse[]) => {
		return (
			<>
				{Boolean(reports.length) && (
					<Accordion.Body className="p-0 ps-2 pe-2">
						{reports.map((report) => (
							<div
								className="d-flex justify-content-between w-100 align-items-center"
								key={Math.random()}
							>
								<div className="d-flex gap-2 align-items-center">
									<Button
										variant="link"
										onClick={() => handleRedirectToReportPage(report)}
									>
										{report.label}
									</Button>
									{Boolean(report.queryDescription) &&
										report.queryDescription.toLowerCase() !== 'placeholder' && (
											<OverlayTrigger
												overlay={(props) => (
													<Tooltip id={report.reportId} {...props}>
														{report.queryDescription}
													</Tooltip>
												)}
											>
												<i className="fas fa-fw fa-info-circle" />
											</OverlayTrigger>
										)}
								</div>
								<Checkbox
									size="small"
									{...label}
									icon={<StarBorder />}
									checkedIcon={<Star />}
									checked={Boolean(report.isFavorite)}
									onChange={() => handleUpdateReportFavoriteStatus(report)}
									disabled={loading}
								/>
							</div>
						))}
					</Accordion.Body>
				)}
			</>
		);
	};

	return (
		<Accordion defaultActiveKey={['0', '1', '2', '3', '4', '5']} alwaysOpen>
			{favoriteReports.length > 0 && (
				<Accordion.Item eventKey="0">
					<Accordion.Header>
						Favorites ({favoriteReports.length})
					</Accordion.Header>
					<Accordion.Body className="p-0 ps-2 pe-2">
						{favoriteReports.map((report) => {
							return (
								<div
									key={Math.random()}
									className="d-flex justify-content-between w-100 align-items-center"
								>
									<Button
										variant="link"
										onClick={() => handleRedirectToReportPage(report)}
									>
										{report.label}
										<span className="fs-2">{report.queryDescription}</span>
									</Button>
									<Checkbox
										{...label}
										icon={<StarBorder />}
										checkedIcon={<Star />}
										checked={Boolean(report.isFavorite)}
										onChange={() => handleUpdateReportFavoriteStatus(report)}
										disabled={loading}
									/>
								</div>
							);
						})}
					</Accordion.Body>
				</Accordion.Item>
			)}

			<Accordion.Item eventKey="1">
				<Accordion.Header>Network ({networkReports.length})</Accordion.Header>
				{getReportItems(networkReports)}
			</Accordion.Item>
			<Accordion.Item eventKey="2">
				<Accordion.Header>
					Financial ({financialReports.length})
				</Accordion.Header>
				{getReportItems(financialReports)}
			</Accordion.Item>
			<Accordion.Item eventKey="3">
				<Accordion.Header>
					Regulatory ({regulatoryReports.length})
				</Accordion.Header>
				{getReportItems(regulatoryReports)}
			</Accordion.Item>
			{UserActions.has(
				Permissions.VueRatingOperator,
				Permissions.VueRatingManager,
				Permissions.VueRatingAdministrator
			) && (
				<Accordion.Item eventKey="4">
					<Accordion.Header>Rating ({ratingReports.length})</Accordion.Header>
					{getReportItems(ratingReports)}
				</Accordion.Item>
			)}
			{UserActions.has(
				Permissions.VueTfEnhancedOperator,
				Permissions.VueTfEnhancedManager,
				Permissions.VueTfEnhancedAdministrator
			) && (
				<Accordion.Item eventKey="5">
					<Accordion.Header>
						Tollfree Enhanced ({tollfreeEnhancedReports.length})
					</Accordion.Header>
					{getReportItems(tollfreeEnhancedReports)}
				</Accordion.Item>
			)}
		</Accordion>
	);
};

export default CannedReportsAccordion;
