import { SubscriptionQosEditForm } from 'RtUi/app/AccountManagement/Subscriptions/lib/forms/SubscriptionQosEditForm';
import { Aside } from 'RtUi/components/ui/Aside';

interface IEditQosSubscriptionAsideProps {
	subscriptionId?: number;
	rateCenterId?: number;
	isOpen: boolean;
	onCancel: () => void;
	onSave: () => void;
}

export const EditQosSubscriptionAside = ({
	onCancel,
	rateCenterId,
	subscriptionId,
	onSave,
	isOpen
}: IEditQosSubscriptionAsideProps) => {
	return (
		<Aside isOpen={isOpen} disabledBody>
			<Aside.Header
				warning
				header="Subscription QoS Settings"
				onClose={() => onCancel()}
			/>
			{subscriptionId && rateCenterId && (
				<SubscriptionQosEditForm
					subscriptionId={subscriptionId}
					rateCenterId={rateCenterId}
					onSuccess={onSave}
					onCancel={onCancel}
				/>
			)}
		</Aside>
	);
};
