import { JobIndexRequest, JobIndexResponse } from 'RtModels';
import { AdminUserRouter } from 'RtUi/app/Administration/Users/AdminUser.router';
import { JobRouter } from 'RtUi/app/rtCarrierConnect/Jobs/Job.router';
import { IJobTaskStatusSelectOption } from 'RtUi/app/rtCarrierConnect/Jobs/lib/controls/JobTaskStatusSelect';
import { useGetJobs } from 'RtUi/app/rtCarrierConnect/Jobs/lib/services';
import { RerateControlRouter } from 'RtUi/app/rtVue/RerateControl/RerateControl.router';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getJobsColumns } from 'RtUi/components/data/DataGrid/configurations/rtCarrierConnect/jobs';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { FC, useMemo, useState } from 'react';

interface IJobGridProps {
	onRowClick?: (row: JobIndexResponse) => void;
	resourceParams?: JobIndexRequest;
	autoFocusFilter?: boolean;
}

export const JobGrid: FC<React.PropsWithChildren<IJobGridProps>> = ({
	resourceParams
}) => {
	const { data, isFetching: isLoading } = useGetJobs(resourceParams);
	const columns = useMemo(() => getJobsColumns(), []);
	useState<IJobTaskStatusSelectOption>();

	return (
		<>
			<Confirmation />
			<DataGrid<JobIndexResponse>
				router={JobRouter}
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				disableExternalLinks={[AdminUserRouter, RerateControlRouter]}
				pageName={'rtCarrierConnect_jobs'}
			/>
		</>
	);
};
