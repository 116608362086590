import {
	Unstable_NumberInput as NumberInput,
	NumberInputProps,
	numberInputClasses
} from '@mui/base/Unstable_NumberInput';
import { noop } from 'lodash-es';
import { styled } from '@mui/system';
import { FormControl, FormLabel } from '@mui/material';

export type RtxNumberInputProps = Omit<
	NumberInputProps,
	'variant' | 'onChange'
> & {
	label?: string;
	displayMode?: boolean;
	onChange?: (value: number | null) => void;
};

export const RtxNumberInput = ({
	className,
	displayMode,
	disabled,
	value = null,
	label,
	onChange = noop,
	...props
}: RtxNumberInputProps) => {
	return (
		<FormControl
			className={className}
			variant="outlined"
			sx={{ width: '100%' }}
			disabled={displayMode || disabled}
		>
			<FormLabel
				sx={{
					fontSize: '12px',
					position: 'absolute',
					background: 'linear-gradient(to bottom, transparent 50%, #FFF 50%)',
					padding: '0 6px',
					left: '8px',
					top: '-9px'
				}}
				required={props.required}
			>
				{label}
			</FormLabel>
			<NumberInput
				{...props}
				value={value}
				slots={{
					root: StyledInputRoot,
					input: StyledInputElement,
					incrementButton: StyledButton,
					decrementButton: StyledButton
				}}
				slotProps={{
					incrementButton: {
						children: '▴',
						type: 'button'
					},
					decrementButton: {
						children: '▾',
						type: 'button'
					}
				}}
				onChange={(evt, val) => onChange(val)}
				disabled={disabled || displayMode}
			/>
		</FormControl>
	);
};

const grey = {
	50: '#F3F6F9',
	100: '#E5EAF2',
	200: '#DAE2ED',
	300: '#C7D0DD',
	400: '#B0B8C4',
	500: '#9DA8B7',
	600: '#6B7A90',
	700: '#434D5B',
	800: '#303740',
	900: '#1C2025'
};

const StyledInputRoot = styled('div')(
	({ theme }) => `
  font-family: 'Roboto', 'Helvetica', sans-serif;
  font-weight: 400;
  border-radius: 4px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  display: grid;
  grid-template-columns: 1fr 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  column-gap: 8px;
  padding: 0;

  &:hover:not(.${numberInputClasses.disabled}) {
    border-color: #252525;
  }
`
);

const StyledInputElement = styled('input')(
	({ disabled }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  grid-column: 1/2;
  grid-row: 1/3;
  color: ${disabled ? grey[300] : grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  outline: 0;
	padding: 10px 14px 5px;
`
);

const StyledButton = styled('button')(
	({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 19px;
  height: 18px;
  font-family: system-ui, sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  box-sizing: border-box;
  border: 0;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
	background: none;

	&.${numberInputClasses.disabled} {
    color: #dae2ed;
  }

  &.${numberInputClasses.incrementButton} {
    grid-column: 2/3;
    grid-row: 1/2;
    border: none;
    border-bottom: 0;
    &:hover {
      cursor: pointer;
    }
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 2/3;
    grid-row: 2/3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: none;
    &:hover {
      cursor: pointer;
    }
  }
  & .arrow {
    transform: translateY(-1px);
  }
`
);
