import {
	BrowserHistory,
	createPath,
	logoutUser
} from 'RtUi/app/@RtUi/lib/browser';
import { useAppState, useBrowserEffect } from 'RtUi/app/@RtUi/lib/hooks';
import { ManifestChecker } from 'RtUi/app/ManifestChecker';
import { useGetAppRoutes } from 'RtUi/app/ApplicationShell/lib/hooks';
import { PublicHttp } from 'RtUi/app/public/lib/http/PublicHttp';
import { RtDashboardOldRouter } from 'RtUi/app/rtCommon/DashboardOld/DashboardOld.router';
import {
	LoginPath,
	LogoutPath,
	RedirectSearchParamName
} from 'RtUi/app/user/lib/Constants';
import { ComponentController } from 'RtUi/components/ComponentController';
import { Loading } from 'RtUi/components/ui/Loading';
import { UserActions } from 'RtUi/state/actions/user';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { generateUUID } from 'RtUi/utils/http/resources/utils';
import { flatten } from 'lodash-es';
import {
	FC,
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';
import { matchPath } from 'react-router';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useMount } from 'react-use';
import { GetRoutersFromModule, findRouteMatch } from '../@RtUi/RtUiDecorators';
import { RtUiAppModule } from '../RtUiApp.module';
import { ApplicationNotifications } from './lib/components/ApplicationNotifications';
import { Navigation } from './lib/components/Navigation';
import { TopNavigation } from './lib/components/TopNavigation';

export interface IApplicationContainerProps {
	rtModule: new () => RtUiAppModule;
}

type FluidLayoutContextType = [boolean, (v: boolean) => void];

export const FluidLayoutContext = createContext<FluidLayoutContextType>([
	false,
	() => {}
]);

export const ApplicationShellContainer: FC<IApplicationContainerProps> = ({
	rtModule
}) => {
	const [currentVersion, setCurrentVersion] = useState(generateUUID());
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [resourceNotFound, setResourceNotFound] = useState(false);
	const appState = useAppState();
	const [isFluidLayout, setFluidLayout] = useState(false);
	const [hideNav, setHideNav] = useState(false);

	const location = BrowserHistory.location;
	const prevPathName = useRef<string>();

	const { isInitialized, loginInfo } = appState.get('user');
	const isLoggedIn = loginInfo !== null;
	const routes = useGetAppRoutes({ isLoggedIn });

	const routeElements = useMemo(() => {
		const elements: JSX.Element[] = [];

		for (const config of routes) {
			for (const route of config.routes) {
				const { unauthorizedAccessAllowed = false } = route;
				const requiresLogin = !unauthorizedAccessAllowed;

				if (requiresLogin && !isLoggedIn) {
					continue;
				}

				elements.push(
					<Route key={route.path} caseSensitive={false} {...route} />
				);
			}
		}

		return elements;
	}, [routes, isLoggedIn]);

	const trackPageView = () => {
		const { pathname } = window.location;
		const allRoutes = flatten(routes.map((routeConfig) => routeConfig.routes));

		for (const route of allRoutes) {
			const isMatch = matchPath(route, pathname);

			if (isMatch) {
				UserActions.trackEvent(`Page View: ${route.name}`);
				break;
			}
		}
	};

	useMount(async () => {
		ManifestChecker.Start();
		const publicHttp = new PublicHttp();

		try {
			const brand = await publicHttp.getBranding();

			document.title = brand.siteTitle;
		} catch (err) {
			console.error('Unable to load site title', err);
		}

		const componentController = ComponentController.getInstance();
		const routers = GetRoutersFromModule(rtModule);
		componentController.registerApplicationRouters(routers);

		const removeUnauthorizedListener = HttpRequest.onUnauthorizedFetch(() => {
			logoutUser();
		});

		//First Page
		trackPageView();

		const removeNotFoundListener = HttpRequest.onNotFoundFetch(() => {
			setResourceNotFound(true);
		});

		return () => {
			removeUnauthorizedListener();
			removeNotFoundListener();
		};
	});

	useBrowserEffect(({ location }) => {
		trackPageView();

		const routeMatch = findRouteMatch(location.pathname, routes);
		const hideNav = routeMatch?.hideNav ?? false;

		setHideNav(hideNav);
		setResourceNotFound(false);
	});

	useEffect(() => {
		const listener = BrowserHistory.listen(({ location }) => {
			if (prevPathName.current !== location.pathname) {
				setFluidLayout(false);
			}
			const matchedRoute = findRouteMatch(location.pathname, routes);
			setHideNav(Boolean(matchedRoute?.hideNav));

			prevPathName.current = location.pathname;
			window.scrollTo({
				top: 0
			});
		});

		return () => listener();
	});

	const loadNoMatch = useCallback(() => {
		const currentPathname = location.pathname;
		const isLogoutPath = currentPathname === LogoutPath;
		const redirectSearchParams = new URLSearchParams();

		if (!isLoggedIn && currentPathname && !isLogoutPath) {
			redirectSearchParams.append(
				RedirectSearchParamName,
				encodeURIComponent(currentPathname)
			);
		}

		const redirectRoute = isLoggedIn
			? RtDashboardOldRouter.getIndexRoute()
			: LoginPath;

		if (redirectRoute !== currentPathname) {
			const path = createPath(redirectRoute, redirectSearchParams);
			return <Navigate to={path} replace />;
		}

		return <Loading />;
	}, [isLoggedIn, location.pathname]);

	if (!isInitialized || !routes.length) {
		return null;
	}

	return (
		<>
			{isLoggedIn && <TopNavigation routesConfig={routes} />}
			<FluidLayoutContext.Provider value={[isFluidLayout, setFluidLayout]}>
				<section
					className="page-container"
					style={isFluidLayout ? { maxWidth: '100%' } : undefined}
				>
					{isLoggedIn && !hideNav && (
						<Navigation
							routesConfig={routes}
							onRouteClickedTwice={() => setCurrentVersion(generateUUID())}
						/>
					)}
					<main key={currentVersion}>
						<ApplicationNotifications />
						<Routes>
							{routeElements}
							<Route path="*" element={loadNoMatch()} />
						</Routes>
					</main>
				</section>
			</FluidLayoutContext.Provider>
		</>
	);
};
