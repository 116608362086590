import { JurisPrecedenceOptions } from 'RtModels';
import {
	IDefaultSelectOption,
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';
import { useMemo } from 'react';

export interface IRtxRatingPrecedenceSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		IDefaultSelectOption<JurisPrecedenceOptions>,
		'value',
		IsMulti,
		IsClearable
	> {}

export const RtxRatingPrecedenceSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	label = 'Rating Precedence',
	...props
}: IRtxRatingPrecedenceSelectProps<IsMulti, IsClearable>) => {
	const options: Array<IDefaultSelectOption<JurisPrecedenceOptions>> =
		useMemo(() => {
			return [
				{
					value: JurisPrecedenceOptions.Ani,
					label: 'Ani'
				},
				{
					value: JurisPrecedenceOptions.Indet,
					label: 'Indet'
				},
				{
					value: JurisPrecedenceOptions.Inter,
					label: 'Inter'
				},
				{
					value: JurisPrecedenceOptions.Intra,
					label: 'Intra'
				},
				{
					value: JurisPrecedenceOptions.Jip,
					label: 'Jip'
				},
				{
					value: JurisPrecedenceOptions.Lrn,
					label: 'Lrn'
				},
				{
					value: JurisPrecedenceOptions.Pai,
					label: 'Pai'
				},
				{
					value: JurisPrecedenceOptions.Poi,
					label: 'Poi'
				},
				{
					value: JurisPrecedenceOptions.Piu,
					label: 'Piu'
				}
			];
		}, []);

	return (
		<RtxSelectInput<
			IDefaultSelectOption<JurisPrecedenceOptions>,
			'value',
			IsMulti,
			IsClearable
		>
			label={label}
			valueKey="value"
			labelKey="label"
			options={options}
			{...props}
		/>
	);
};
