import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { SipConnectionResource } from './lib/resources/SipConnectionResource';
import { SipConnection } from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	IRouterBreadcrumb,
	SearchConfidenceLevel
} from 'RtUi/components/containers/lib/RtUiRouter';

interface IConnectionContainerTabs extends IProfileApplicationContainerTabs {}

export const ConnectionContainerTabs: IConnectionContainerTabs = {};

const SEARCH_REGEX = /^((\d{1,3})(\.|$)){4}$/g;
class ConnectionRouterClass extends SipApplicationRouter<
	SipConnection,
	SipConnection,
	IConnectionContainerTabs
> {
	constructor() {
		super(
			'SIP Connections',
			'connections',
			'sipConnectionId',
			ConnectionContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.Connections);
	}

	public globalSearch(search: string) {
		return this.defaultGlobalSearch(SipConnectionResource, search);
	}

	public globalSearchConfidenceCheck(search: string) {
		if (search.match(SEARCH_REGEX)) {
			return SearchConfidenceLevel.Certainty;
		}

		return SearchConfidenceLevel.Impossible;
	}

	public getProfileLabel(profile: SipConnection) {
		return String(profile.hostAddress);
	}
	public getSipConnectionIdFromCurrentPath() {
		const currentPath = location.pathname;
		const profilePath = this.getProfileMatch<{
			id: string;
			sipConnectionId: string;
		}>(currentPath);

		let sipConnectionId: number | undefined;

		if (profilePath) {
			const { id } = profilePath.params;
			sipConnectionId = Number(id);
		}

		return sipConnectionId;
	}
	public async getBreadcrumbs(
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		currentPath: string,
		profile?: SipConnection
	): Promise<IRouterBreadcrumb[]> {
		const sipConnectionId = this.getSipConnectionIdFromCurrentPath();
		const breadcrumbs = [
			this.getSectionBreadcrumb(),
			this.getIndexBreadcrumb()
		];
		let sipConnection;
		if (sipConnectionId) {
			const sipConnectionResource = new SipConnectionResource();
			sipConnection = await sipConnectionResource.get(sipConnectionId);
			breadcrumbs.push(
				this.getProfileBreadcrumb(
					String(sipConnectionId),
					sipConnection,
					false,
					'Profile'
				)
			);
		}

		return breadcrumbs;
	}
}

export const ConnectionRouter = new ConnectionRouterClass();
