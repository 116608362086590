import {
	RtxNumberInput,
	RtxNumberInputProps
} from 'RtUi/components/rtx/inputs/Number/RtxNumber';

type IRtxPortInputProps = Omit<RtxNumberInputProps, 'max' | 'min'>;

export const RtxPortInput = ({
	label = 'Port',
	...props
}: IRtxPortInputProps) => {
	return <RtxNumberInput max={65535} min={0} label={label} {...props} />;
};
