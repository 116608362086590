import { AsYouType } from 'libphonenumber-js';
import { NumberTypes } from 'RtUi/components/rtx/inputs/PhoneNumber/RtxPhoneNumberInput';
import {
	nanpRegions,
	PhoneNumberFormatter
} from 'RtUi/utils/phone/PhoneNumberFormatter';

export const isValidPhoneNumber = (
	value: string,
	allowedType?: NumberTypes
) => {
	const formatter = new PhoneNumberFormatter();
	const parsedNumber = formatter.toPhoneNumber(value);

	const isValid = formatter.isValid(value);

	if (allowedType === undefined || !isValid) {
		return isValid;
	}

	if (allowedType === NumberTypes.NANP) {
		return nanpRegions.includes(parsedNumber.regionCode ?? '');
	}

	return parsedNumber.type === 'fixed-line-or-mobile';
};

export const processValue = (value: string, allowedType?: NumberTypes) => {
	if (!value) {
		return '';
	}

	const isNanp = allowedType === NumberTypes.NANP;
	const parsedNumber = PhoneNumberFormatter.convertLettersToNumbers(
		value.replace(/\W/g, '')
	);

	if (isNanp) {
		return new AsYouType().input(parsedNumber.replace(/^\+?1?/g, '+1'));
	}

	return new AsYouType().input(`+${parsedNumber}`);
};
