import { format } from 'date-fns';
import { RtVueApiRoutes } from 'RtExports/routes';
import {
	RejectDetailsIndexResponse,
	RejectSummaryIndexResponse
} from 'RtModels';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IRejectSuspenseReportContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	RevenueReject: TabbedLayoutTabProps;
	CostReject: TabbedLayoutTabProps;
}

export const RejectSuspenseReportContainerTabs: IRejectSuspenseReportContainerTabs =
	{
		Profile: { header: 'Profile' },
		RevenueReject: {
			header: 'Revenue Reject'
		},
		CostReject: {
			header: 'Cost Reject'
		}
	};

class RejectSuspenseReportRouterClass extends RtVueReportRouter<
	RejectSummaryIndexResponse,
	RejectDetailsIndexResponse,
	IRejectSuspenseReportContainerTabs
> {
	constructor() {
		super(
			'Reject/Suspense Report',
			'rejects',
			'callDate',
			RejectSuspenseReportContainerTabs
		);

		this.setPermissionsFromApiRoute(RtVueApiRoutes.ViewRejectSummary);
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: RejectDetailsIndexResponse): string {
		return format(new Date(profile.callDate), 'yyyy-MM-dd');
	}
}

export const RejectSuspenseReportRouter = new RejectSuspenseReportRouterClass();
