import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	EntityIndexResponse,
	RespOrgIndexResponse,
	RoutingGroupIndexRequest
} from 'RtModels';
import { EntitySelect } from 'RtUi/app/rt800/Entities/lib/controls/EntitySelect';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtxSearchForm } from 'RtUi/components/rtx/form/RtxUiSearchForm';
import { useFormInitializer } from 'RtUi/components/rtx/form/hooks/useFormInitializer';
import {
	TabbedLayout,
	TabbedLayoutHelpContainer,
	TabbedLayoutTab
} from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { isEqual } from 'lodash-es';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { RoutingGroupRouter } from './RoutingGroup.router';
import { RoutingGroupEditor } from './lib/forms/RoutingGroupEditor';
import { RoutingGroupsGrid } from './lib/grids/RoutingGroupsGrid';

export const RoutingGroupIndexContainer = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDefault: true },
		Create: {
			header: 'Create Routing Group',
			isAction: true,
			rtRoute: Rt800ApiRoutes.RoutingGroups.Create
		}
	});
	const formInitializer = useFormInitializer();
	const [entity, setEntity] = useState<EntityIndexResponse>();
	const [respOrg, setRespOrg] = useState<RespOrgIndexResponse>();
	const [resourceParams, setResourceParams] =
		useState<RoutingGroupIndexRequest>({
			isActive: 1
		});

	const submitHandler = (data: RoutingGroupIndexRequest) => {
		setResourceParams(data);
		setActiveTab(tabs.Results.header);
	};

	return (
		<TabbedLayout
			router={RoutingGroupRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<Row>
					<Col md={4}>
						<RtxSearchForm<RoutingGroupIndexRequest>
							initializer={formInitializer}
							onSubmit={submitHandler}
							defaultValues={resourceParams}
							onLoadUrlParams={(urlParams) => {
								if (!isEqual(urlParams, resourceParams)) {
									submitHandler(urlParams);
								}
							}}
						>
							{({ control }) => (
								<>
									<Controller
										control={control}
										name="entityId"
										render={({ field: { onChange, value } }) => (
											<EntitySelect
												onChange={(entity) => {
													setEntity(entity);
													onChange(entity?.entityId);
												}}
												value={entity}
												initialOptionId={value}
											/>
										)}
									/>
									<Controller
										control={control}
										name="respOrgId"
										render={({ field: { onChange, value } }) => (
											<RespOrgSelect
												onChange={(respOrg) => {
													setRespOrg(respOrg);
													onChange(respOrg?.respOrgId);
												}}
												value={respOrg}
												initialOptionId={value}
											/>
										)}
									/>
									<Controller
										control={control}
										name="isActive"
										render={({ field: { onChange, value } }) => (
											<IsActiveRadioFormControl
												label="Status"
												onChange={onChange}
												value={value}
											/>
										)}
									/>
								</>
							)}
						</RtxSearchForm>
					</Col>
				</Row>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<RoutingGroupsGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Create}>
				<RoutingGroupEditor createMode />
			</TabbedLayoutTab>

			<TabbedLayoutHelpContainer tab={tabs.Results.header}>
				<p>
					Access to Routing Groups disaster recovery tool are based on your
					RespOrg id permissions.
				</p>
			</TabbedLayoutHelpContainer>
		</TabbedLayout>
	);
};
RoutingGroupRouter.setIndexRtUiFunctionalComponent(RoutingGroupIndexContainer, {
	description: 'Disaster recovery tool.'
});
