import { useCallback } from 'react';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { ConnectionIndexResponse } from 'RtModels';
import { ConnectionRouter } from 'RtUi/app/rtVue/Connections/Connection.router';
import { useGetConnections } from 'RtUi/app/rtVue/Connections/lib/service';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxVueConnectionSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		ConnectionIndexResponse,
		'connectionId',
		IsMulti,
		IsClearable
	> {}

export const RtxVueConnectionSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	label = 'Connections',
	...props
}: IRtxVueConnectionSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetConnections();

	const formatOptionLabel = useCallback(
		(connection: ConnectionIndexResponse) => {
			if (connection.trunkGroupLabel) {
				return <span>{connection.trunkGroupLabel}</span>;
			}

			if (connection.trunkGroupId) {
				return <span>{connection.trunkGroupId}</span>;
			}

			if (connection.peerAddress) {
				return <span>{connection.peerAddress}</span>;
			}

			return <span>{connection.connectionId}</span>;
		},
		[]
	);

	const filterOption = useCallback(
		(option: FilterOptionOption<ConnectionIndexResponse>, rawInput: string) => {
			const connection = option.data;
			const hasValue = rawInput.length > 0;

			if (hasValue && connection.trunkGroupLabel) {
				return connection.trunkGroupLabel
					.toLowerCase()
					.includes(rawInput.toLowerCase());
			}

			if (hasValue && connection.trunkGroupId) {
				return connection.trunkGroupId
					.toLowerCase()
					.includes(rawInput.toLowerCase());
			}

			if (hasValue && connection.peerAddress) {
				return connection.peerAddress
					.toLowerCase()
					.includes(rawInput.toLowerCase());
			}

			return false;
		},
		[]
	);

	return (
		<RtxSelectInput<
			ConnectionIndexResponse,
			'connectionId',
			IsMulti,
			IsClearable
		>
			label={label}
			valueKey="connectionId"
			formatOptionLabel={formatOptionLabel}
			filterOption={filterOption}
			router={ConnectionRouter}
			maxOptions={600}
			labelKey="fileStreamName"
			options={data}
			isLoading={isLoading}
			{...props}
		/>
	);
};
