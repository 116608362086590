import { noop } from 'lodash-es';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import {
	$ContactCreateRequest,
	$ContactUpdateRequest,
	Contact,
	ContactIndexResponse,
	CountryIndexResponse
} from 'RtModels';
import { ContactResource } from 'RtUi/app/rtSip/Contacts/lib/resources/ContactResource';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { RtxForm } from 'RtUi/components/rtx/form';
import { RtxBooleanInput } from 'RtUi/components/rtx/inputs/Boolean/RtxBooleanInput';
import { RtxRadioInputOptions } from 'RtUi/components/rtx/inputs/Radio/RtxRadioInput';
import { RtxCountrySelect } from 'RtUi/components/rtx/inputs/Select/instances/Country/RtxCountrySelect';
import { RtxTextInput } from 'RtUi/components/rtx/inputs/Text/RtxTextInput';
import { RtError } from 'RtUi/utils/errors/RtError';

interface IContactFormEditorProps {
	profile?: ContactIndexResponse;
	accountId?: number;
	onSuccess?: (profile: ContactIndexResponse) => void;
	onDelete?: () => void;
}

export const ContactFormEditor = ({
	profile,
	accountId,
	onSuccess = noop,
	onDelete = noop
}: IContactFormEditorProps) => {
	const [error, setError] = useState<RtError>();
	const [displayMode, setDisplayMode] = useState<boolean>(
		profile !== undefined
	);
	const [deleting, setDeleting] = useState<boolean>(false);
	const [selectedCountry, setSelectedCountry] =
		useState<CountryIndexResponse>();

	const primaryOption: RtxRadioInputOptions<number> = {
		value: 2,
		label: (
			<span>
				Primary&nbsp;
				<small style={{ opacity: 0.5 }}>
					<i className="fas fa-fw fa-star text-muted" />
				</small>
			</span>
		)
	};

	const $schema =
		profile !== undefined ? $ContactUpdateRequest : $ContactCreateRequest;

	const onSubmit = async (data: ContactIndexResponse) => {
		const contactResource = new ContactResource();
		let newProfile: Contact;

		try {
			if (profile) {
				newProfile = await contactResource.update(profile.contactId, data);
			} else {
				newProfile = await contactResource.create({
					...data
				});
			}
			setDisplayMode(true);
			onSuccess(newProfile);
		} catch (error: any) {
			setError(new RtError(error));
		}
	};

	const onDeleteInternal = async () => {
		if (!profile) {
			return;
		}

		const confirm = await Confirmation.createDelete(
			`the contact for ${profile.firstName} ${profile.lastName} `
		);

		if (!confirm) {
			return;
		}

		setError(undefined);

		try {
			const contactResource = new ContactResource();

			setDeleting(true);

			await contactResource.delete(profile.contactId);

			if (onDelete) {
				onDelete();
			}
		} catch (error: any) {
			setError(new RtError(error));
		} finally {
			setDeleting(false);
		}
	};

	return (
		<RtxForm<ContactIndexResponse>
			createMode={profile === undefined}
			defaultValues={{
				firstName: '',
				lastName: '',
				emailAddress: '',
				address1: '',
				address2: '',
				city: '',
				state: '',
				postalCode: '',
				countryId: 218,
				homePhone: '',
				mobilePhone: '',
				workPhone: '',
				accountId: accountId!,
				isBilling: 0,
				isMailing: 0,
				isRates: 0,
				isSales: 0,
				isNoc: 0,
				isProvisioning: 0,
				...profile
			}}
			onSubmit={onSubmit}
			displayMode={displayMode}
			error={
				error && {
					name: 'root',
					error: {
						message: error.message
					}
				}
			}
			onEdit={() => setDisplayMode(false)}
			onCancel={() => setDisplayMode(true)}
			footerActions={() => {
				if (profile === undefined) {
					return <></>;
				}

				return (
					<Button
						type="button"
						variant="white"
						onClick={onDeleteInternal}
						disabled={deleting}
					>
						{deleting && <i className="fas fa-fw fa-cog fa-spin" />}
						{!deleting && <i className="fas fa-fw fa-trash-alt" />}
						&nbsp;Delete Contact
					</Button>
				);
			}}
		>
			{({ control }) => (
				<>
					<section>
						<Row>
							<Col lg={4}>
								<Controller
									control={control}
									name="firstName"
									render={({ field: { value, onChange } }) => (
										<RtxTextInput
											required={$schema.properties.firstName.isRequired}
											label="First Name"
											className="mb-3"
											displayMode={displayMode}
											onChange={onChange}
											value={value}
										/>
									)}
								/>
							</Col>
							<Col lg={4}>
								<Controller
									control={control}
									name="lastName"
									render={({ field: { value, onChange } }) => (
										<RtxTextInput
											required={$schema.properties.lastName.isRequired}
											label="Last Name"
											className="mb-3"
											displayMode={displayMode}
											onChange={onChange}
											value={value}
										/>
									)}
								/>
							</Col>
							<Col lg={4}>
								<Controller
									control={control}
									name="emailAddress"
									render={({ field: { value, onChange } }) => (
										<RtxTextInput
											required={
												$ContactCreateRequest.properties.emailAddress.isRequired
											}
											type="email"
											className="mb-3"
											label="Email Address"
											displayMode={displayMode}
											onChange={onChange}
											value={value}
										/>
									)}
								/>
							</Col>
						</Row>
						<Row>
							<Col lg={4}>
								<Controller
									control={control}
									name="workPhone"
									render={({ field: { value, onChange } }) => (
										<RtxTextInput
											label="Work Phone"
											displayMode={displayMode}
											onChange={onChange}
											value={value}
										/>
									)}
								/>
							</Col>
							<Col lg={4}>
								<Controller
									control={control}
									name="mobilePhone"
									render={({ field: { value, onChange } }) => (
										<RtxTextInput
											label="Mobile Phone"
											displayMode={displayMode}
											onChange={onChange}
											value={value}
										/>
									)}
								/>
							</Col>
							<Col lg={4}>
								<Controller
									control={control}
									name="homePhone"
									render={({ field: { value, onChange } }) => (
										<RtxTextInput
											label="Home Phone"
											displayMode={displayMode}
											onChange={onChange}
											value={value}
										/>
									)}
								/>
							</Col>
						</Row>
						<section className="mt-3">
							<h5 className="mb-0">Address</h5>
							<hr />
							<Row>
								<Col lg={4}>
									<Controller
										control={control}
										name="address1"
										render={({ field: { value, onChange } }) => (
											<RtxTextInput
												label="Address Line 1"
												className="mb-3"
												displayMode={displayMode}
												onChange={onChange}
												value={value}
											/>
										)}
									/>
								</Col>
								<Col lg={4}>
									<Controller
										control={control}
										name="address2"
										render={({ field: { value, onChange } }) => (
											<RtxTextInput
												label="Address Line 2"
												className="mb-3"
												displayMode={displayMode}
												onChange={onChange}
												value={value}
											/>
										)}
									/>
								</Col>
								<Col lg={4}>
									<Controller
										control={control}
										name="city"
										render={({ field: { value, onChange } }) => (
											<RtxTextInput
												label="City"
												className="mb-3"
												displayMode={displayMode}
												onChange={onChange}
												value={value}
											/>
										)}
									/>
								</Col>
							</Row>
							<Row>
								<Col lg={4}>
									<Controller
										control={control}
										name="state"
										render={({ field: { value, onChange } }) => (
											<RtxTextInput
												label="State"
												displayMode={displayMode}
												onChange={onChange}
												value={value}
											/>
										)}
									/>
								</Col>
								<Col lg={4}>
									<Controller
										control={control}
										name="postalCode"
										render={({ field: { value, onChange } }) => (
											<RtxTextInput
												label="Zip/Postal Code"
												displayMode={displayMode}
												onChange={onChange}
												value={value}
											/>
										)}
									/>
								</Col>
								<Col lg={4}>
									<Controller
										control={control}
										name="countryId"
										render={({ field: { value, onChange } }) => (
											<RtxCountrySelect
												valueKey="id"
												required={$schema.properties.countryId.isRequired}
												displayMode={displayMode}
												placeholder="Select country"
												label="Country"
												onChange={(selectedCountry) => {
													onChange(selectedCountry.id);
													setSelectedCountry(selectedCountry);
												}}
												value={selectedCountry}
												initialOptionId={value}
											/>
										)}
									/>
								</Col>
							</Row>
						</section>
					</section>
					<section className="mt-3">
						<h5 className="mb-0">Contact For</h5>
						<hr />
						<Row>
							<Col lg={4}>
								<Controller
									control={control}
									name="isBilling"
									render={({ field: { value, onChange } }) => (
										<RtxBooleanInput
											label="Billing"
											displayMode={displayMode}
											onChange={onChange}
											value={value}
											customOption={{ ...primaryOption }}
										/>
									)}
								/>
							</Col>
							<Col lg={4}>
								<Controller
									control={control}
									name="isMailing"
									render={({ field: { value, onChange } }) => (
										<RtxBooleanInput
											label="Mailing"
											displayMode={displayMode}
											onChange={onChange}
											value={value}
											customOption={{ ...primaryOption }}
										/>
									)}
								/>
							</Col>
							<Col lg={4}>
								<Controller
									control={control}
									name="isRates"
									render={({ field: { value, onChange } }) => (
										<RtxBooleanInput
											label="Rates"
											displayMode={displayMode}
											onChange={onChange}
											value={value}
											customOption={{ ...primaryOption }}
										/>
									)}
								/>
							</Col>
						</Row>
						<Row>
							<Col lg={4}>
								<Controller
									control={control}
									name="isRates"
									render={({ field: { value, onChange } }) => (
										<RtxBooleanInput
											label="Rates"
											displayMode={displayMode}
											onChange={onChange}
											value={value}
											customOption={{ ...primaryOption }}
										/>
									)}
								/>
								<Controller
									control={control}
									name="isSales"
									render={({ field: { value, onChange } }) => (
										<RtxBooleanInput
											label="Sales"
											displayMode={displayMode}
											onChange={onChange}
											value={value}
											customOption={{ ...primaryOption }}
										/>
									)}
								/>
							</Col>
							<Col lg={4}>
								<Controller
									control={control}
									name="isNoc"
									render={({ field: { value, onChange } }) => (
										<RtxBooleanInput
											label="Network Operations"
											displayMode={displayMode}
											onChange={onChange}
											value={value}
											customOption={{ ...primaryOption }}
										/>
									)}
								/>
							</Col>
							<Col lg={4}>
								<Controller
									control={control}
									name="isProvisioning"
									render={({ field: { value, onChange } }) => (
										<RtxBooleanInput
											label="Provisioning"
											displayMode={displayMode}
											onChange={onChange}
											value={value}
											customOption={{ ...primaryOption }}
										/>
									)}
								/>
							</Col>
						</Row>
					</section>
				</>
			)}
		</RtxForm>
	);
};
