import { useMemo } from 'react';
import { ServiceIndexResponse } from 'RtModels';
import { useGetServices } from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxServiceSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		ServiceIndexResponse,
		'serviceId',
		IsMulti,
		IsClearable
	> {
	showAllOption?: boolean;
}

export const RtxServiceSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	showAllOption = false,
	label = 'Service',
	...props
}: IRtxServiceSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetServices();
	const options = useMemo(() => {
		if (!showAllOption) {
			return data;
		}

		return [
			{
				serviceId: 0,
				label: 'All',
				hasIngressConnections: 0,
				hasEgressConnections: 0,
				isCustomerService: 0,
				isVendorService: 0
			},
			...data
		];
	}, [data, showAllOption]);

	return (
		<RtxSelectInput<ServiceIndexResponse, 'serviceId', IsMulti, IsClearable>
			label={label}
			valueKey="serviceId"
			labelKey="label"
			options={options}
			isLoading={isLoading}
			{...props}
		/>
	);
};
