import {
	JobProfileResponse,
	JobStepExtended,
	JobStepResourceExtended
} from 'RtModels';
import { JobStatusHelper } from 'RtUi/app/rtCarrierConnect/Jobs/bin/JobStatusHelper';
import { JobTaskGrid } from 'RtUi/app/rtCarrierConnect/Jobs/lib/grids/JobTaskGrid';
import { JobResource } from 'RtUi/app/rtCarrierConnect/Jobs/lib/resources/JobResource';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { CollapsibleCard } from 'RtUi/components/ui/CollapsibleCard';
import { Loading } from 'RtUi/components/ui/Loading';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import * as moment from 'moment-timezone';
import {
	Badge,
	Button,
	Card,
	Col,
	Collapse,
	ListGroup,
	OverlayTrigger,
	Row,
	Tooltip
} from 'react-bootstrap';
import { JobRouter } from './Job.router';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { RtxFlowApiRoutes, Permissions } from 'RtExports/routes';
import { IconButton } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { UserActions } from 'RtUi/state/actions/user';

interface IJobProfileContainerProps {}

interface IJobProfileContainerState {
	job?: JobProfileResponse;
	openedJobStepResourceId?: string;
}

@JobRouter.getProfileRtUiController()
export class JobProfileContainer extends ProfileApplicationContainer<
	IJobProfileContainerProps,
	IJobProfileContainerState
> {
	public Tabs = JobRouter.getProfileTabs();

	public state: IJobProfileContainerState = {
		//activeTab: this.Tabs.Profile.header,
	};

	protected jobStatusHelper = new JobStatusHelper();

	public async componentDidMount() {
		this.init();
	}

	public async init() {
		const jobId = this.getIdParam();
		const jobResource = new JobResource();
		const job = await jobResource.get(jobId);

		this.setState({ job });
	}

	/**
	 * Toggles which jobStepResource is shown
	 * @param openedJobStepResourceId
	 */
	public onToggle(openedJobStepResourceId: string) {
		if (this.state.openedJobStepResourceId === openedJobStepResourceId) {
			this.setState({ openedJobStepResourceId: undefined });
		} else {
			this.setState({ openedJobStepResourceId });
		}
	}

	/**
	 * Renders a JobStepResource status in a Badge
	 * @param jobStepResource
	 */
	public renderJobStepResourceStatusBadges(
		job: JobProfileResponse,
		jobStepResource: JobStepResourceExtended
	) {
		const { totalQty, pendingQty, warningQty, errorQty } = jobStepResource;
		const completedQty = totalQty - pendingQty - warningQty - errorQty;

		return (
			<section className="rounded" style={{ overflow: 'hidden' }}>
				{this.renderRequeueButton(job, jobStepResource)}
				<Badge
					style={{ minWidth: 60 }}
					text="dark"
					bg="light"
					className="rounded-0"
				>
					Pending: {pendingQty}
				</Badge>
				<OverlayTrigger
					overlay={(props) => (
						<Tooltip id={`completed-tooltip`} {...props}>
							Success
						</Tooltip>
					)}
				>
					{({ ref, ...triggerHandler }) => (
						<Badge
							ref={ref}
							{...triggerHandler}
							style={{ minWidth: 60 }}
							text={completedQty > 0 ? 'white' : 'dark'}
							bg={completedQty > 0 ? 'success' : 'light'}
							className="rounded-0"
						>
							{completedQty}
						</Badge>
					)}
				</OverlayTrigger>
				<OverlayTrigger
					overlay={(props) => (
						<Tooltip id={`completed-tooltip`} {...props}>
							Warning
						</Tooltip>
					)}
				>
					{({ ref, ...triggerHandler }) => (
						<Badge
							ref={ref}
							{...triggerHandler}
							style={{ minWidth: 60 }}
							text={warningQty > 0 ? 'white' : 'dark'}
							bg={warningQty > 0 ? 'warning' : 'light'}
							className="rounded-0"
						>
							{warningQty}
						</Badge>
					)}
				</OverlayTrigger>
				<OverlayTrigger
					overlay={(props) => (
						<Tooltip id={`completed-tooltip`} {...props}>
							Error
						</Tooltip>
					)}
				>
					{({ ref, ...triggerHandler }) => (
						<Badge
							ref={ref}
							{...triggerHandler}
							style={{ minWidth: 60 }}
							text={errorQty > 0 ? 'white' : 'dark'}
							bg={errorQty > 0 ? 'danger' : 'light'}
							className="rounded-0"
						>
							{errorQty}
						</Badge>
					)}
				</OverlayTrigger>
			</section>
		);
	}

	/**
	 * Renders a JobStepResource,
	 * if it is within the ONLY step and is the ONLY resource, then it will just show the JobTaskGrid component
	 * @param jobStep
	 * @param jobStepResource
	 * @param stepsLength
	 * @param stepResourcesLength
	 */
	public renderStepResource(
		job: JobProfileResponse,
		jobStepResource: JobStepResourceExtended,
		jobStep: JobStepExtended,
		jobStepIndex: number,
		stepsLength: number,
		stepResourcesLength: number
	) {
		const isStepResourceOpen =
			jobStepResource.jobStepResourceId === this.state.openedJobStepResourceId;
		const isOnlyStepAndResource =
			stepsLength === 1 && stepResourcesLength === 1;
		const jobTaskGridComponent = (
			<JobTaskGrid
				stepNumber={jobStepIndex + 1}
				onUpdate={() => this.init()}
				disableCollapse
				removeCardHeaderXPadding
				jobStepId={jobStep.jobStepId}
				isManageable={jobStepResource.isManageable}
				resourceId={jobStepResource.resourceId}
			/>
		);

		if (isOnlyStepAndResource) {
			return jobTaskGridComponent;
		}

		let fontIcon = 'fa-angle-down';

		if (
			this.state.openedJobStepResourceId === jobStepResource.jobStepResourceId
		) {
			fontIcon = 'fa-angle-up';
		}

		return (
			<>
				<header
					className="d-flex justify-content-between align-items-center mb-1"
					style={{ cursor: 'pointer' }}
				>
					<section className="d-flex justify-content-start align-items-end">
						<i className={`fas fa-fw ${fontIcon} me-1`} />
						<a
							onClick={() => this.onToggle(jobStepResource.jobStepResourceId)}
							className="h6 mb-0"
						>
							<b>
								{jobStepResource.configurationLabel}
								&nbsp;
							</b>
						</a>
					</section>
					<section>
						{this.renderJobStepResourceStatusBadges(job, jobStepResource)}
					</section>
				</header>
				<Collapse in={isStepResourceOpen}>
					<div>
						{isStepResourceOpen && jobTaskGridComponent}
						{!isStepResourceOpen && (
							<section
								style={
									{ height: 475 } //placeholder for collapsing animation
								}
							></section>
						)}
					</div>
				</Collapse>
			</>
		);
	}

	/**
	 * Renders a jobStep. If stepsLength === 1 (is only step),
	 * then the step name will not be rendered.
	 *
	 * @param jobStep
	 * @param jobStepIndex
	 * @param stepsLength
	 */
	public renderStep(
		job: JobProfileResponse,
		jobStep: JobStepExtended,
		jobStepIndex: number,
		stepsLength: number
	) {
		const jobStepResources = jobStep.stepResources;

		if (jobStepIndex === 0 && stepsLength > 1) {
			return '';
		}

		return (
			<Card className="mb-4" key={jobStep.jobStepId}>
				<ListGroup key={jobStep.jobStepId} variant="flush">
					<CollapsibleCard header={`Step ${jobStepIndex}. ${jobStep.label}`}>
						{jobStepResources.map((jobStepResource) => (
							<ListGroup.Item key={jobStepResource.jobStepResourceId}>
								{this.renderStepResource(
									job,
									jobStepResource,
									jobStep,
									jobStepIndex,
									stepsLength,
									jobStepResources.length
								)}
							</ListGroup.Item>
						))}
					</CollapsibleCard>
				</ListGroup>
			</Card>
		);
	}

	/**
	 * Render steps in job
	 * @param job
	 */
	public renderSteps(job: JobProfileResponse) {
		const jobSteps = job.steps;

		return jobSteps.map((jobStep, jobStepIndex) =>
			this.renderStep(job, jobStep, jobStepIndex, jobSteps.length)
		);
	}

	public jobIsCompleted(job: JobProfileResponse) {
		return job.jobStatusId >= 40 && job.jobStatusId <= 49;
	}

	public jobHasErrors(job: JobProfileResponse) {
		for (const jobStep of job.steps) {
			for (const jobStepResource of jobStep.stepResources) {
				if (jobStepResource.errorQty > 0) {
					return true;
				}
			}
		}
	}

	public renderRequeueButton(
		job: JobProfileResponse,
		jobStepResource: JobStepResourceExtended
	) {
		const requeue = async (
			job: JobProfileResponse,
			jobStepResource: JobStepResourceExtended
		) => {
			const confirm = await Confirmation.create(
				<span>
					Are you sure you want to requeue these {jobStepResource.errorQty}{' '}
					items for reprocessing?
				</span>
			);

			if (!confirm) {
				return;
			}

			const jobProfile =
				await HttpRequest.fetchWithRoute<JobProfileResponse | null>(
					RtxFlowApiRoutes.TaskRequeue.Create,
					{
						urlParams: {
							jobStepResourceId: jobStepResource.jobStepResourceId
						}
					}
				);

			if (!jobProfile) {
				// show popup with error
				return;
			}
			const jobProfilePath = JobRouter.getProfileRoute(jobProfile.jobId);
			this.goToPath(jobProfilePath);
		};

		let render = true;

		if (!this.jobIsCompleted(job)) {
			render = false;
		}

		if (job.steps.length !== 2) {
			render = false;
		}

		if (!this.jobHasErrors(job)) {
			render = false;
		}

		if (jobStepResource.errorQty === 0) {
			render = false;
		}

		render = false;

		if (!render) {
			if (jobStepResource.requeueId) {
				return (
					<>
						<NavLink
							className="text-white"
							style={{
								textDecoration: 'underline'
							}}
							target="_blank"
							rel="noreferrer"
							to={JobRouter.getProfileRoute(jobStepResource.requeueId)}
						>
							<IconButton
								size="small"
								color="success"
								title="Open requeued job in new window"
							>
								<i className="fa-solid fa-up-right-from-square" />
							</IconButton>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</NavLink>
					</>
				);
			}

			return '';
		}

		return (
			<>
				<IconButton
					size="small"
					color="warning"
					title="Requeue all failed numbers as new job"
					onClick={() => requeue(job, jobStepResource)}
				>
					<i className="fa-solid fa-circle-plus" />
				</IconButton>
				&nbsp;
			</>
		);
	}

	public renderActionButton(job: JobProfileResponse) {
		const cancelJob = async () => {
			const confirm = await Confirmation.create(
				<span>
					Are you sure you want to close this job?
					<br />
					<br />
					<ul>
						<li>
							This will complete all pending tasks within RouteTrust and mark
							them as completed with error.
						</li>
						<li>
							Any task that has been submitted to the vendor will not be
							affected.
						</li>
						<li>
							This means that the task can still complete out with the vendor,
							RouteTrust will not be tracking it.
						</li>
					</ul>
					This is irreversible.
				</span>
			);

			if (!confirm) {
				return;
			}

			await HttpRequest.fetchWithRoute<JobProfileResponse>(
				RtxFlowApiRoutes.Jobs.Delete,
				{
					urlParams: {
						jobId: job.jobId
					}
				}
			);

			// refresh current data
			this.init();
		};

		let render = true;

		const maxDaysOld = 5;
		const threshold = moment().subtract(maxDaysOld, 'days').toDate().getTime();
		const createdTs = moment(job.createdTs).toDate();

		const hasPermissions = UserActions.has(Permissions.RtxAdministrator);

		if (createdTs.getTime() > threshold) {
			render = false;
		}

		if (this.jobIsCompleted(job)) {
			render = false;
		} else if (hasPermissions) {
			render = true;
		}

		if (!render) {
			return '';
		}

		return (
			<Button variant="danger" onClick={cancelJob}>
				<i className="fas fa-fw fa-ban" /> Close Job
			</Button>
		);
	}

	public render() {
		const { job } = this.state;

		if (!job) {
			return <Loading />;
		}

		return (
			<StandardLayout<JobProfileResponse> profile={job} router={JobRouter}>
				<CollapsibleCard header="Job Status">
					<Row>
						<Col className="d-flex gap-2 w-100">
							<InputFormControl
								label="Status"
								controlGroupClassName="flex-grow-1"
								displayMode
								value={this.jobStatusHelper.getReadableJobStatus(
									job.jobStatusId
								)}
							/>
							<DatetimeFormControl
								label="Created"
								displayMode
								className="flex-grow-1"
								value={job.createdTs ? moment(job.createdTs) : undefined}
							/>
							<DatetimeFormControl
								label="Updated"
								displayMode
								className="flex-grow-1"
								value={job.updatedTs ? moment(job.updatedTs) : undefined}
							/>
							<DatetimeFormControl
								label="Completed"
								displayMode
								className="flex-grow-1"
								value={job.completedTs ? moment(job.completedTs) : undefined}
							/>
						</Col>
					</Row>
					<Row>
						<Col className="text-end">{this.renderActionButton(job)}</Col>
					</Row>
				</CollapsibleCard>
				<Row>
					<Col>{this.renderSteps(job)}</Col>
				</Row>
			</StandardLayout>
		);
	}
}
