import { CicIndexResponse } from 'RtModels';
import { useGetCicLookups } from 'RtUi/app/Administration/CicLookup/lib/service';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxCarrierSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		CicIndexResponse,
		'cic',
		IsMulti,
		IsClearable
	> {}

export const RtxCarrierSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	displayMode,
	label = 'Carrier',
	...props
}: IRtxCarrierSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetCicLookups();

	return (
		<RtxSelectInput<CicIndexResponse, 'cic', IsMulti, IsClearable>
			label={label}
			valueKey="cic"
			labelKey="label"
			options={data}
			displayMode={displayMode}
			isLoading={isLoading}
			{...props}
		/>
	);
};
