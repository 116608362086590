import { SubscriptionIndexRequest, SubscriptionIndexResponse } from 'RtModels';
import { SubscriptionRouter } from 'RtUi/app/AccountManagement/Subscriptions/Subscription.router';
import {
	useGetServices,
	useGetSubscriptions
} from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getSubscriptionsColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/subscriptions';
import { useMemo } from 'react';

interface ISubscriptionGridProps {
	onRowClick?: (row: SubscriptionIndexResponse) => void;
	resourceParams?: SubscriptionIndexRequest;
	autoFocusFilter?: boolean;
}

export const SubscriptionDataGrid = ({
	resourceParams
}: ISubscriptionGridProps) => {
	const { data, isFetching: isLoading } = useGetSubscriptions(resourceParams);
	const { data: servicesResponse = [] } = useGetServices();
	const columns = useMemo(() => getSubscriptionsColumns(), []);

	return (
		<DataGrid<SubscriptionIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			onRowClick={(row) => {
				const serviceLabel = servicesResponse.find(
					(s) => s.serviceId === row.serviceId
				);

				if (serviceLabel) {
					localStorage.setItem('serviceType', serviceLabel.label);
				}
			}}
			loading={isLoading}
			pageName={'accountManagement_subscriptions'}
			router={SubscriptionRouter}
		/>
	);
};
