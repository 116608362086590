import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	TrackingGroupIndexRequest,
	TrackingGroupIndexResponse
} from 'RtModels';
import {
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchTrackingGroups = async <IncludeFullResponse extends boolean = false>(
	queryParams?: TrackingGroupIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<
	RequestResponse<TrackingGroupIndexResponse[], IncludeFullResponse>
> => {
	return handleGetRequest(RtxSipApiRoutes.TrackingGroups.Index, {
		queryParams,
		includeFullResponse
	});
};

export const useGetTrackingGroups = <
	IncludeFullResponse extends boolean = false
>(
	params?: TrackingGroupIndexRequest,
	includeFullResponse?: IncludeFullResponse
) => {
	return useQuery<
		RequestResponse<TrackingGroupIndexResponse[], IncludeFullResponse>,
		Error
	>(['getTrackingGroup', params, includeFullResponse], () =>
		fetchTrackingGroups(params, includeFullResponse)
	);
};
