import { noop } from 'lodash-es';
import {
	RtxRadioInput,
	RtxRadioInputOptions,
	RtxRadioInputProps
} from 'RtUi/components/rtx/inputs/Radio/RtxRadioInput';

type RtxBooleanInputProps = Omit<
	RtxRadioInputProps<number>,
	'options' | 'label' | 'onChange'
> & {
	label?: string;
	customOption?: RtxRadioInputOptions<number>;
	onChange?: (value: number) => void;
};

export const RtxBooleanInput = ({
	label = 'Boolean',
	customOption,
	onChange = noop,
	...props
}: RtxBooleanInputProps) => {
	return (
		<RtxRadioInput
			label={label}
			options={[
				{
					label: 'Yes',
					value: 1
				},
				{
					label: 'No',
					value: 0
				},
				...(customOption !== undefined ? [customOption] : [])
			]}
			{...props}
			onChange={(value) => onChange(Number(value))}
		/>
	);
};
