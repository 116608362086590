import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { CallflowService, TagIndexResponse } from 'RtModels';
import { useGetTagTypes } from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxTagTypeSelectProps<
	K extends keyof TagIndexResponse,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		TagIndexResponse,
		K,
		IsMulti,
		IsClearable
	> {
	serviceId?: number;
	valueKey?: K;
}

export const RtxTagTypeSelect = <
	K extends keyof TagIndexResponse,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	displayMode,
	valueKey,
	serviceId,
	label = 'Tag Type',
	...props
}: IRtxTagTypeSelectProps<K, IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetTagTypes();

	const filterOption = (option: FilterOptionOption<TagIndexResponse>) => {
		if (serviceId === CallflowService.InboundCustomerIdentification) {
			return option.value === 'CUSTOM';
		}

		return false;
	};

	return (
		<RtxSelectInput<TagIndexResponse, K, IsMulti, IsClearable>
			label={label}
			valueKey={valueKey ?? ('tagTypeId' as K)}
			labelKey="label"
			filterOption={filterOption}
			options={data}
			displayMode={displayMode}
			isLoading={isLoading}
			{...props}
		/>
	);
};
