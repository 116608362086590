import { Attestation } from 'RtModels';
import {
	IDefaultSelectOption,
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';
import { useMemo } from 'react';

export interface IRtxAttestationSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		IDefaultSelectOption<Attestation>,
		'value',
		IsMulti,
		IsClearable
	> {}

export const RtxAttestationSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	label = 'Attestation',
	...props
}: IRtxAttestationSelectProps<IsMulti, IsClearable>) => {
	const options: Array<IDefaultSelectOption<Attestation>> = useMemo(() => {
		return [
			{
				label: 'A',
				value: Attestation.A
			},
			{
				label: 'B',
				value: Attestation.B
			},
			{
				label: 'C',
				value: Attestation.C
			}
		];
	}, []);

	return (
		<RtxSelectInput<
			IDefaultSelectOption<Attestation>,
			'value',
			IsMulti,
			IsClearable
		>
			label={label}
			valueKey="value"
			labelKey="label"
			options={options}
			{...props}
		/>
	);
};
