import { Reports } from 'RtModels';
import { FourteenDayReportRouter } from 'RtUi/app/rtVue/14DayReport/14DayReport.router';
import { AsrLiteViewRouter } from 'RtUi/app/rtVue/AsrLiteView/AsrLiteView.router';
import { AsrViewRouter } from 'RtUi/app/rtVue/AsrView/AsrView.router';
import { BirdEyeReportRouter } from 'RtUi/app/rtVue/BirdsEyeViewReport/BirdEyeReport.router';
import { CdrSearchViewRouter } from 'RtUi/app/rtVue/CdrSearchView/CdrSearchView.router';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';
import { CpsViewRouter } from 'RtUi/app/rtVue/CpsView/CpsView.router';
import { FinancialViewRouter } from 'RtUi/app/rtVue/FinancialView/FinancialView.router';
import { LcoSummaryRouter } from 'RtUi/app/rtVue/LcoSummary/LcoSummary.router';
import { MessagesDetailRouter } from 'RtUi/app/rtVue/MessagesDetail/MessagesDetail.router';
import { MessagesSummaryRouter } from 'RtUi/app/rtVue/MessagesSummary/MessagesSummary.router';
import { MonthlyTrendingRouter } from 'RtUi/app/rtVue/MonthlyTrending/MonthlyTrending.router';
import { ViewNumbersLiteRouter } from 'RtUi/app/rtVue/NumbersLite/ViewNumbersLite.router';
import { VueNumberViewRouter } from 'RtUi/app/rtVue/NumbersView/VueNumberView.router';
import { PortViewRouter } from 'RtUi/app/rtVue/PortsView/PortView.router';
import { ReconciliationRouter } from 'RtUi/app/rtVue/Reconciliation/Reconciliation.router';
import { RejectDetailsCostReportRouter } from 'RtUi/app/rtVue/RejectDetailsCostReport/RejectDetailsCostReport.router';
import { RejectDetailsRevenueReportRouter } from 'RtUi/app/rtVue/RejectDetailsRevenueReport/RejectDetailsRevenueReport.router';
import { RejectSuspenseReportRouter } from 'RtUi/app/rtVue/RejectSuspenseReport/RejectSuspenseReport.router';
import { RoboscoreRouter } from 'RtUi/app/rtVue/Roboscore/Roboscore.router';
import { TfEnhancedDetailsReportRouter } from 'RtUi/app/rtVue/TfEnhancedDetailsReport/TfEnhancedDetailsReport.router';
import { TfEnhancedReportRouter } from 'RtUi/app/rtVue/TfEnhancedReport/TfEnhancedReport.router';
import { UsageAuditByDayRouter } from 'RtUi/app/rtVue/UsageAuditByDayReport/UsageAuditByDay.router';

export type ExistingReports = Exclude<
	Reports,
	| Reports.FullSummary
	| Reports.NetworkFinancialSummary
	| Reports.NetworkSummary
	| Reports.ReconciliationSummary
	| Reports.CustomMargin
>;

export type IRtVueReportRouters = {
	[key in ExistingReports]: RtVueReportRouter<any, any, {}>;
};

export const ReportBaseUrls: IRtVueReportRouters = {
	[Reports.Asr]: AsrViewRouter,
	[Reports.Cps]: CpsViewRouter,
	[Reports.Ports]: PortViewRouter,
	[Reports.Numbers]: VueNumberViewRouter,
	[Reports.Financial]: FinancialViewRouter,
	[Reports.Cdrs]: CdrSearchViewRouter,
	[Reports.LcoSummary]: LcoSummaryRouter,
	[Reports.RejectSummary]: RejectSuspenseReportRouter,
	[Reports.RejectRevenue]: RejectDetailsRevenueReportRouter,
	[Reports.RejectCost]: RejectDetailsCostReportRouter,
	[Reports.TollfreeEnhanced]: TfEnhancedReportRouter,
	[Reports.TollfreeEnhancedDetail]: TfEnhancedDetailsReportRouter,
	[Reports.Reconciliation]: ReconciliationRouter,
	[Reports.UsageAuditByDay]: UsageAuditByDayRouter,
	[Reports.FourteenDay]: FourteenDayReportRouter,
	[Reports.BirdsEyeView]: BirdEyeReportRouter,
	[Reports.AsrLite]: AsrLiteViewRouter,
	[Reports.NumbersLite]: ViewNumbersLiteRouter,
	[Reports.Roboscore]: RoboscoreRouter,
	[Reports.MonthlyTrending]: MonthlyTrendingRouter,
	[Reports.MessagesSummary]: MessagesSummaryRouter,
	[Reports.MessagesDetail]: MessagesDetailRouter
};
