import {
	$SubscriptionScheduleProfileResponse,
	SubscriptionScheduleCreateRequest,
	SubscriptionScheduleProfileResponse,
	TimezoneIndexResponse
} from 'RtModels';
import { SubscriptionScheduleHttp } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionScheduleHttp';
import { RtxForm } from 'RtUi/components/rtx/form';
import { RtxCheckboxInput } from 'RtUi/components/rtx/inputs/Checkbox/RtxCheckboxInput';
import { RtxDateInput } from 'RtUi/components/rtx/inputs/Date/RtxDateInput';
import { RtxTimezoneSelect } from 'RtUi/components/rtx/inputs/Select/instances/Timezone/RtxTimezoneSelect';
import { RtxTimeInput } from 'RtUi/components/rtx/inputs/Time/RtxTimeInput';
import { RtError } from 'RtUi/utils/errors/RtError';
import { endOfDay, set, setYear, startOfDay } from 'date-fns';
import { noop } from 'lodash-es';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

interface ISubscriptionScheduleFormEditorProps {
	narrow?: boolean;
	profile?: SubscriptionScheduleProfileResponse;
	subscriptionId?: number;
	onSuccess?: (profile: SubscriptionScheduleProfileResponse) => void;
}

export const SubscriptionScheduleFormEditor = ({
	subscriptionId,
	profile,
	onSuccess = noop
}: ISubscriptionScheduleFormEditorProps) => {
	const [displayMode, setDisplayMode] = useState<boolean>(false);
	const [timezone, setTimezone] = useState<TimezoneIndexResponse>();
	const [error, setError] = useState<RtError>();

	const getTime = (seconds: number) => {
		const hour = Math.floor(seconds / 3600);
		const minute = Math.floor((seconds % 3600) / 60);
		const second = seconds % 60;

		return set(new Date(), {
			hours: hour,
			minutes: minute,
			seconds: second
		});
	};

	const setTime = (date: Date) => {
		return date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
	};

	const onSubmit = async (data: SubscriptionScheduleCreateRequest) => {
		const httpResource = new SubscriptionScheduleHttp();
		let newProfile: SubscriptionScheduleProfileResponse;

		setError(undefined);

		try {
			if (profile) {
				newProfile = await httpResource.update(
					profile.subscriptionScheduleId,
					data
				);
			} else {
				newProfile = await httpResource.create(data);
			}
			setDisplayMode(true);
			onSuccess(newProfile);
		} catch (error: any) {
			setError(new RtError(error));
		}
	};

	return (
		<RtxForm<SubscriptionScheduleCreateRequest>
			onSubmit={onSubmit}
			createMode
			displayMode={displayMode}
			error={
				error && {
					name: 'root',
					error: {
						message: error.message
					}
				}
			}
			defaultValues={{
				subscriptionId,
				isSunday: 0,
				isMonday: 0,
				isTuesday: 0,
				isWednesday: 0,
				isThursday: 0,
				isFriday: 0,
				isSaturday: 0,
				startDate: startOfDay(new Date()),
				endDate: null,
				startSecond: setTime(startOfDay(new Date())),
				endSecond: setTime(endOfDay(setYear(new Date(), 2099))),
				timezoneId: 142,
				...profile
			}}
		>
			{({ control, watch }) => {
				const [
					startDate,
					isSunday,
					isMonday,
					isTuesday,
					isWednesday,
					isThursday,
					isFriday,
					isSaturday
				] = watch([
					'startDate',
					'isSunday',
					'isMonday',
					'isTuesday',
					'isWednesday',
					'isThursday',
					'isFriday',
					'isSaturday'
				]);

				const shouldRequireDay = [
					isSunday,
					isMonday,
					isTuesday,
					isWednesday,
					isThursday,
					isFriday,
					isSaturday
				].every((d) => d === 0);

				return (
					<div className="d-flex flex-column">
						<Controller
							control={control}
							name="timezoneId"
							render={({ field: { value, onChange } }) => (
								<RtxTimezoneSelect
									required={
										$SubscriptionScheduleProfileResponse.properties.timezoneId
											.isRequired
									}
									label="Time Zone"
									displayMode={displayMode}
									onChange={(timeZone: TimezoneIndexResponse) => {
										onChange(timeZone.timezoneId);
										setTimezone(timeZone);
									}}
									appendDropdownToBody={false}
									className="mb-3"
									value={timezone}
									initialOptionId={value}
								/>
							)}
						/>
						<Controller
							control={control}
							name="startDate"
							render={({ field: { value, onChange } }) => (
								<RtxDateInput
									className="mb-3"
									label="Start Date"
									required
									displayMode={displayMode}
									onChange={onChange}
									value={new Date(value)}
								/>
							)}
						/>
						<Controller
							control={control}
							name="endDate"
							render={({ field: { value, onChange } }) => (
								<RtxDateInput
									className="mb-3"
									label="End Date"
									minDate={new Date(startDate)}
									displayMode={displayMode}
									onChange={onChange}
									value={value && new Date(value)}
								/>
							)}
						/>
						<Controller
							control={control}
							name="startSecond"
							render={({ field: { value, onChange } }) => (
								<RtxTimeInput
									className="mb-3"
									label="Start Time"
									required
									disabled={displayMode}
									onChange={(time) => onChange(setTime(time as Date))}
									value={getTime(value)}
								/>
							)}
						/>
						<Controller
							control={control}
							name="endSecond"
							render={({ field: { value, onChange } }) => (
								<RtxTimeInput
									className="mb-3"
									label="End Time"
									required
									disabled={displayMode}
									onChange={(time) => onChange(setTime(time as Date))}
									value={getTime(value)}
								/>
							)}
						/>
						<Controller
							control={control}
							name="isSunday"
							render={({ field: { value, onChange } }) => (
								<RtxCheckboxInput
									disabled={displayMode}
									label="Sunday"
									required={shouldRequireDay}
									onChange={(evt, value) => onChange(Number(value))}
									value={Boolean(value)}
								/>
							)}
						/>
						<Controller
							control={control}
							name="isMonday"
							render={({ field: { value, onChange } }) => (
								<RtxCheckboxInput
									disabled={displayMode}
									label="Monday"
									required={shouldRequireDay}
									onChange={(evt, value) => onChange(Number(value))}
									value={Boolean(value)}
								/>
							)}
						/>
						<Controller
							control={control}
							name="isTuesday"
							render={({ field: { value, onChange } }) => (
								<RtxCheckboxInput
									disabled={displayMode}
									label="Tuesday"
									required={shouldRequireDay}
									onChange={(evt, value) => onChange(Number(value))}
									value={Boolean(value)}
								/>
							)}
						/>
						<Controller
							control={control}
							name="isWednesday"
							render={({ field: { value, onChange } }) => (
								<RtxCheckboxInput
									disabled={displayMode}
									label="Wednesday"
									required={shouldRequireDay}
									onChange={(evt, value) => onChange(Number(value))}
									value={Boolean(value)}
								/>
							)}
						/>
						<Controller
							control={control}
							name="isThursday"
							render={({ field: { value, onChange } }) => (
								<RtxCheckboxInput
									disabled={displayMode}
									label="Thursday"
									required={shouldRequireDay}
									onChange={(evt, value) => onChange(Number(value))}
									value={Boolean(value)}
								/>
							)}
						/>
						<Controller
							control={control}
							name="isFriday"
							render={({ field: { value, onChange } }) => (
								<RtxCheckboxInput
									disabled={displayMode}
									label="Friday"
									required={shouldRequireDay}
									onChange={(evt, value) => onChange(Number(value))}
									value={Boolean(value)}
								/>
							)}
						/>
						<Controller
							control={control}
							name="isSaturday"
							render={({ field: { value, onChange } }) => (
								<RtxCheckboxInput
									disabled={displayMode}
									label="Saturday"
									required={shouldRequireDay}
									onChange={(evt, value) => onChange(Number(value))}
									value={Boolean(value)}
								/>
							)}
						/>
					</div>
				);
			}}
		</RtxForm>
	);
};
