import { useMemo } from 'react';
import { AccountIndexResponse } from 'RtModels';
import { AccountRouter } from 'RtUi/app/AccountManagement/Accounts/Account.router';
import { useGetAccounts } from 'RtUi/app/AccountManagement/Accounts/lib/services';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxAccountSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		AccountIndexResponse,
		'accountId',
		IsMulti,
		IsClearable
	> {
	showAllOption?: boolean;
	isActive?: number;
}

export const RtxAccountSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	isActive = 1,
	showAllOption = false,
	displayMode,
	label = 'Account',
	...props
}: IRtxAccountSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetAccounts({ isActive });
	const options = useMemo(() => {
		if (!showAllOption) {
			return data;
		}

		return [
			{
				label: 'All',
				//filler
				accountId: -1,
				isActive: -1,
				customerBalance: -1,
				vendorBalance: -1,
				combinedBalance: -1,
				customerLimit: -1,
				vendorLimit: -1,
				combinedLimit: -1,
				companyName: ''
			},
			...data
		];
	}, [data, showAllOption]);

	return (
		<RtxSelectInput<AccountIndexResponse, 'accountId', IsMulti, IsClearable>
			label={label}
			valueKey="accountId"
			router={AccountRouter}
			labelKey="label"
			options={options}
			displayMode={displayMode}
			isLoading={isLoading}
			{...props}
		/>
	);
};
