import { FormControl, FormLabel, Slider, SliderProps } from '@mui/material';
import { noop } from 'lodash-es';

type RtxSliderInputValue = number | number[];
type RtxSliderInputEmittedValue<T extends RtxSliderInputValue> =
	T extends number ? number : number[];

type RtxSliderInputProps<T extends RtxSliderInputValue> = Omit<
	SliderProps,
	'onChange' | 'value' | 'size'
> & {
	label?: string;
	value?: T;
	required?: boolean;
	onChange?: (value: RtxSliderInputEmittedValue<T>) => void;
};

export const RtxSliderInput = <T extends RtxSliderInputValue>({
	label,
	disabled,
	className,
	required,
	onChange = noop,
	...props
}: RtxSliderInputProps<T>) => {
	const onChangeHandler = (event: Event, newValue: RtxSliderInputValue) => {
		onChange(newValue as RtxSliderInputEmittedValue<T>);
	};

	return (
		<FormControl
			variant="outlined"
			sx={{ width: '100%', marginTop: '15px' }}
			className={className}
			disabled={disabled}
		>
			{label && (
				<FormLabel
					sx={{
						fontSize: '12px',
						position: 'absolute',
						padding: '0 6px',
						left: '8px',
						top: '-15px'
					}}
					required={required}
				>
					{label}
				</FormLabel>
			)}
			<Slider
				onChange={onChangeHandler}
				disabled={disabled}
				valueLabelDisplay="auto"
				{...props}
				size="small"
			/>
		</FormControl>
	);
};
