import { isNil, noop } from 'lodash-es';
import { useMemo } from 'react';
import { IFormControlProps } from '../FormControl';
import { RadioFormControl } from '../RadioFormControl';

interface IIsActiveRadioFormControlProps<HideBothOption extends boolean = false>
	extends IFormControlProps<IsActiveValue<HideBothOption>> {
	activeTooltip?: string;
	hideBothOption?: HideBothOption;
}

type IsActiveValue<HideBothOption extends boolean = false> =
	HideBothOption extends false ? number | null : number;

export const IsActiveRadioFormControl = <
	HideBothOption extends boolean = false
>({
	displayMode,
	label = 'Active',
	hideBothOption,
	disabled,
	activeTooltip,
	onChange = noop,
	value
}: IIsActiveRadioFormControlProps<HideBothOption>) => {
	const internalValue = useMemo(() => {
		if (isNil(value)) {
			return -1;
		}

		return value;
	}, [value]);

	const options = useMemo(() => {
		const initialOptions = [
			{
				value: 1,
				label: 'Active',
				tooltip: activeTooltip,
				disabled: Boolean(disabled)
			},
			{ value: 0, label: 'Inactive', disabled: Boolean(disabled) }
		];

		if (hideBothOption) {
			return initialOptions;
		}

		return [
			...initialOptions,
			{
				value: -1,
				label: 'Both',
				disabled: Boolean(disabled)
			}
		];
	}, [activeTooltip, disabled, hideBothOption]);

	return (
		<RadioFormControl<number>
			label={label}
			displayMode={displayMode}
			onChange={(isActive) => {
				const newValue = isActive >= 0 ? isActive : null;
				onChange(newValue as IsActiveValue<HideBothOption>);
			}}
			value={internalValue as number}
			options={options}
		/>
	);
};
