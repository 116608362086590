import {
	RtxNumberInput,
	RtxNumberInputProps
} from 'RtUi/components/rtx/inputs/Number/RtxNumber';

type RtxPercentageInputProps = Omit<RtxNumberInputProps, 'min' | 'max'>;

export const RtxPercentageInput = ({ ...props }: RtxPercentageInputProps) => {
	return <RtxNumberInput min={0} max={100} {...props} />;
};
