import {
	SubscriptionProfileResponse,
	SubscriptionQosIndexResponse
} from 'RtModels';
import { EditQosSubscriptionAside } from 'RtUi/app/AccountManagement/Subscriptions/lib/components/EditQosSubscriptionAside';
import {
	customerServiceTypes,
	vendorServiceTypes
} from 'RtUi/app/AccountManagement/Subscriptions/lib/constants';
import { useGetSubscriptionQosList } from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getSubscriptionQosColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/subscriptions';
import { useMemo, useState } from 'react';
import { Item } from 'react-contexify';

interface ISubscriptionQosGridProps {
	subscription: SubscriptionProfileResponse;
	autoFocusFilter?: boolean;
}

export const SubscriptionQosDataGrid = ({
	subscription
}: ISubscriptionQosGridProps) => {
	const [selectedQos, setSelectedQos] =
		useState<SubscriptionQosIndexResponse>();
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetSubscriptionQosList({
		subscriptionId: subscription.subscriptionId
	});
	const isVendorSubscription = useMemo(
		() => vendorServiceTypes.includes(subscription.serviceId),
		[subscription]
	);

	const isCustomerSubscription = useMemo(
		() => customerServiceTypes.includes(subscription.serviceId),
		[subscription]
	);

	const columns = useMemo(
		() =>
			getSubscriptionQosColumns(isVendorSubscription, isCustomerSubscription),
		[isVendorSubscription, isCustomerSubscription]
	);

	return (
		<>
			<DataGrid<SubscriptionQosIndexResponse>
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'accountManagement_subscriptionQos'}
				menuLinks={(record) => {
					return (
						<Item onClick={() => setSelectedQos(record)}>
							<span className="d-flex justify-content-start align-items-center">
								<i className="fas fa-fw fa-edit" />
								<span>
									<span>Modify</span>
								</span>
							</span>
						</Item>
					);
				}}
			/>
			<EditQosSubscriptionAside
				rateCenterId={selectedQos?.rateCenterId}
				subscriptionId={subscription.subscriptionId}
				isOpen={selectedQos !== undefined}
				onCancel={() => setSelectedQos(undefined)}
				onSave={() => {
					setSelectedQos(undefined);
					refetch();
				}}
			/>
		</>
	);
};
