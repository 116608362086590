import { cloneDeep } from 'lodash-es';
import { RtVueApiRoutes } from 'RtExports/routes';
import {
	AccountFilterIndexResponse,
	AsrIndexResponse,
	AsrLiteIndexResponse,
	BirdsEyeIndexResponse,
	CallTypeFilterIndexResponse,
	CarrierCommonNameFilterIndexResponse,
	CdrSearchIndexResponse,
	CountryFilterIndexResponse,
	CpsIndexResponse,
	DataFilter,
	DataPreset,
	EntityFilterIndexResponse,
	FinancialIndexResponse,
	FourteenDayIndexResponse,
	FullSummaryIndexResponse,
	GuardianUserQueryCreateRequest,
	GuardianUserQueryDeleteRequest,
	GuardianUserQueryIndexRequest,
	GuardianUserQueryIndexResponse,
	GuardianUserQueryUpdateRequest,
	GuardianUserQueryUrlProfileRequest,
	HavingFilter,
	IsdnFilterIndexResponse,
	LcoSummaryIndexResponse,
	MessagesDetailIndexResponse,
	MessagesSummaryIndexResponse,
	NumbersIndexResponse,
	NumbersLiteIndexResponse,
	OcnFilterIndexResponse,
	OcnTypeFilterIndexResponse,
	PartitionIndexResponse,
	PortsIndexResponse,
	RateCenterFilterIndexResponse,
	ReconciliationSummaryIndexResponse,
	RejectDetailsIndexResponse,
	RejectSummaryIndexResponse,
	ReportAggregates,
	ReportMetadataProfileRequest,
	ReportMetadataProfileResponse,
	Reports,
	RespOrgFilterIndexResponse,
	RoboscoreIndexResponse,
	RtVueIndexRequest,
	RtVueLcoSummaryIndexRequest,
	SipFilterIndexResponse,
	SubscriptionFilterIndexResponse,
	TemplateFilterIndexResponse,
	TermSummaryIndexResponse,
	TimeFilter,
	TimeRange,
	TollfreeEnhancedDetailIndexResponse,
	TollfreeEnhancedSummaryIndexResponse,
	UsageAuditByDayIndexResponse,
	VendorReconciliationIndexResponseData
} from 'RtModels';
import { createDateAsUTC } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import { ISimpleSelectFormControlOption } from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import {
	FullResponse,
	handlePostRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';

export interface IReconciliationResponse {
	data: VendorReconciliationIndexResponseData[];
}

interface ICdrSearchConfigInterface {
	timeRange: TimeFilter;
	filters: DataFilter[];
	preset?: DataPreset;
	dataSums?: ReportAggregates[];
	partition?: PartitionIndexResponse;
	dataColumns?: string[];
	pageSize?: number;
}

/**
 * fetching helper
 */
export class RtVueHttp {
	public getUsageAuditFilter(
		timeRange: TimeFilter,
		filters: DataFilter[],
		dataSums?: ReportAggregates[],
		preset?: DataPreset,
		partition?: PartitionIndexResponse,
		dataColumns?: string[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns
		);
		const body = JSON.stringify(req);
		return HttpRequest.fetchWithRoute<UsageAuditByDayIndexResponse[]>(
			RtVueApiRoutes.ViewUsageAuditByDay.Index,
			{ body }
		);
	}

	public getISDNFilter() {
		return HttpRequest.fetchWithRoute<IsdnFilterIndexResponse>(
			RtVueApiRoutes.IsdnFilter.Index
		);
	}

	public getSIPFilter() {
		return HttpRequest.fetchWithRoute<SipFilterIndexResponse>(
			RtVueApiRoutes.SipFilter.Index
		);
	}

	public getRespOrgFilter() {
		return HttpRequest.fetchWithRoute<RespOrgFilterIndexResponse>(
			RtVueApiRoutes.RespOrgFilter.Index
		);
	}

	public getEntityFilter() {
		return HttpRequest.fetchWithRoute<EntityFilterIndexResponse>(
			RtVueApiRoutes.EntityFilter.Index
		);
	}

	public getTemplateFilter() {
		return HttpRequest.fetchWithRoute<TemplateFilterIndexResponse>(
			RtVueApiRoutes.TemplateFilter.Index
		);
	}

	public getSubscriptionFilter() {
		return HttpRequest.fetchWithRoute<SubscriptionFilterIndexResponse>(
			RtVueApiRoutes.SubscriptionFilter.Index
		);
	}

	public getRateCenterFilter() {
		return HttpRequest.fetchWithRoute<RateCenterFilterIndexResponse>(
			RtVueApiRoutes.RateCenterFilter.Index
		);
	}

	public getOcnFilter() {
		return HttpRequest.fetchWithRoute<OcnFilterIndexResponse>(
			RtVueApiRoutes.OcnFilter.Index
		);
	}

	public getOcnTypeFilter() {
		return HttpRequest.fetchWithRoute<OcnTypeFilterIndexResponse>(
			RtVueApiRoutes.OcnTypeFilter.Index
		);
	}

	public getCarrierCommonNameIndexResponse() {
		return HttpRequest.fetchWithRoute<CarrierCommonNameFilterIndexResponse>(
			RtVueApiRoutes.CarrierCommonNameFilter.Index
		);
	}

	public getAccountFilter() {
		return HttpRequest.fetchWithRoute<AccountFilterIndexResponse>(
			RtVueApiRoutes.AccountFilter.Index
		);
	}

	public getCountryFilter() {
		return HttpRequest.fetchWithRoute<CountryFilterIndexResponse>(
			RtVueApiRoutes.CountryFilter.Index
		);
	}

	public getCallTypeFilter() {
		return HttpRequest.fetchWithRoute<CallTypeFilterIndexResponse>(
			RtVueApiRoutes.CallTypeFilter.Index
		);
	}

	public getReportMetadata(reportId: Reports) {
		const urlParams: ReportMetadataProfileRequest = { reportId };

		return HttpRequest.fetchWithRoute<ReportMetadataProfileResponse>(
			RtVueApiRoutes.ReportMetadata.Profile,
			{ urlParams }
		);
	}

	/**
	 * Get a user's saved report queries
	 * @param reportId (optional) only return queries for that specific report
	 */
	public getGuardianUserQueryIndexes(reportId?: Reports) {
		const params: GuardianUserQueryIndexRequest = { reportId };

		return HttpRequest.fetchWithRoute<GuardianUserQueryIndexResponse[]>(
			RtVueApiRoutes.GuardianUserQueries.Index,
			{ params }
		);
	}

	/**
	 * Get a saved query Profile
	 * @param guardianUserQueryId the id for the user query profile
	 */
	public getGuardianUserQueryProfile(
		params: GuardianUserQueryUrlProfileRequest
	) {
		return HttpRequest.fetchWithRoute<GuardianUserQueryIndexResponse[]>(
			RtVueApiRoutes.GuardianUserQueries.Profile,
			{ params }
		);
	}

	/**
	 * Save a user query
	 * @param request create data for user query profile
	 */
	public createGuardianUserQueryProfile(
		request: GuardianUserQueryCreateRequest
	) {
		const timeObject = request.queryParameters.timeRange.timeObject;
		request.queryParameters.timeRange.timeObject.start = createDateAsUTC(
			timeObject.start
		) as Date;
		request.queryParameters.timeRange.timeObject.end = createDateAsUTC(
			timeObject.end
		) as Date;

		const body = JSON.stringify(request);

		return HttpRequest.fetchWithRoute<GuardianUserQueryIndexResponse>(
			RtVueApiRoutes.GuardianUserQueries.Create,
			{ body }
		);
	}

	/**
	 * Update a user query profile
	 * @param guardianUserQueryId the id for the user query profile
	 * @param request update data for user query profile
	 */
	public updateGuardianUserQueryProfile(
		guardianUserQueryId: number,
		request: GuardianUserQueryUpdateRequest
	) {
		const body = JSON.stringify(request);
		const urlParams: GuardianUserQueryUrlProfileRequest = {
			guardianUserQueryId
		};

		return HttpRequest.fetchWithRoute<GuardianUserQueryIndexResponse>(
			RtVueApiRoutes.GuardianUserQueries.Update,
			{ urlParams, body }
		);
	}

	/**
	 * Delete a user query profile
	 * @param guardianUserQueryId the id for the user query profile
	 */
	public deleteGuardianUserQueryProfile(
		guardianUserQueryId: number,
		bodyParms: GuardianUserQueryDeleteRequest = {}
	) {
		const body = JSON.stringify(bodyParms);
		const urlParams: GuardianUserQueryUrlProfileRequest = {
			guardianUserQueryId
		};

		return HttpRequest.fetchWithRoute<void>(
			RtVueApiRoutes.GuardianUserQueries.Delete,
			{ urlParams, body }
		);
	}

	/**
	 * Get ASR view
	 */
	public async getAsrView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		compareToDate?: Date,
		dataSums?: ReportAggregates[],
		preset?: DataPreset,
		partition?: PartitionIndexResponse,
		dataColumns?: string[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns,
			compareToDate
		);

		return handlePostRequest<AsrIndexResponse, true>(
			RtVueApiRoutes.ViewAsr.Index,
			req,
			{ includeFullResponse: true }
		);
	}

	public async getLiteAsrView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		compareToDate?: Date,
		dataSums?: ReportAggregates[],
		preset?: DataPreset,
		partition?: PartitionIndexResponse,
		dataColumns?: string[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns,
			compareToDate
		);

		return handlePostRequest<AsrLiteIndexResponse[], true>(
			RtVueApiRoutes.ViewAsrLite.Index,
			req,
			{ includeFullResponse: true }
		);
	}
	/**
	 * @param timeRange
	 * @param presets
	 * @param filters
	 */
	public getCpsView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		dataSums?: ReportAggregates[],
		preset?: DataPreset,
		partition?: PartitionIndexResponse,
		dataColumns?: string[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns
		);

		return handlePostRequest<CpsIndexResponse, true>(
			RtVueApiRoutes.ViewCps.Index,
			req,
			{ includeFullResponse: true }
		);
	}

	/**
	 * @param timeRange
	 * @param presets
	 * @param filters
	 * @param dataSums
	 */
	public getPortsView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		dataSums?: ReportAggregates[],
		preset?: DataPreset,
		partition?: PartitionIndexResponse,
		dataColumns?: string[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns
		);

		return handlePostRequest<PortsIndexResponse, true>(
			RtVueApiRoutes.ViewPorts.Index,
			req,
			{ includeFullResponse: true }
		);
	}

	/**
	 * @param timeRange
	 * @param presets
	 * @param filters
	 * @param dataSums
	 */
	public getTermSummaryView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		dataSums?: ReportAggregates[],
		preset?: DataPreset,
		partition?: PartitionIndexResponse,
		dataColumns?: string[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns
		);

		return handlePostRequest<{ data: TermSummaryIndexResponse }, true>(
			RtVueApiRoutes.ViewTermSummary.Index,
			req,
			{ includeFullResponse: true }
		);
	}

	/**
	 * @param timeRange
	 * @param presets
	 * @param filters
	 * @param dataSums
	 */
	public getReconciliationSummaryView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		dataSums?: ReportAggregates[],
		preset?: DataPreset,
		partition?: PartitionIndexResponse,
		dataColumns?: string[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns
		);
		const body = JSON.stringify(req);

		return HttpRequest.fetchWithRoute<ReconciliationSummaryIndexResponse[]>(
			RtVueApiRoutes.ViewReconciliationSummary.Index,
			{ body }
		);
	}

	/**
	 * @param timeRange
	 * @param presets
	 * @param filters
	 * @param dataSums
	 */
	public getLcoSummaryView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		dataSums?: ReportAggregates[],
		preset?: DataPreset,
		partition?: PartitionIndexResponse,
		dataColumns?: string[],
		createMinuteProfile?: boolean,
		respOrgId?: string,
		label?: string
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns
		);

		if (respOrgId) {
			req.respOrgId = respOrgId;
		}

		if (createMinuteProfile) {
			//@ts-ignore
			req.createMinuteProfile = true;
		}

		if (label) {
			(req as any).label = label;
		}

		return handlePostRequest<LcoSummaryIndexResponse[], true>(
			RtVueApiRoutes.ViewLcoSummary.Index,
			req,
			{ includeFullResponse: true }
		);
	}

	/**
	 * @param timeRange
	 * @param presets
	 * @param filters
	 */
	public getNumbersView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		dataSums?: ReportAggregates[],
		preset?: DataPreset,
		partition?: PartitionIndexResponse,
		dataColumns?: string[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns
		);
		const body = JSON.stringify(req);

		return HttpRequest.fetchWithRoute<NumbersIndexResponse>(
			RtVueApiRoutes.ViewNumbers.Index,
			{ body }
		);
	}

	public getMessagesDetail(
		timeRange: TimeFilter,
		filters: DataFilter[],
		dataSums?: ReportAggregates[],
		preset?: DataPreset,
		partition?: PartitionIndexResponse,
		dataColumns?: string[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns
		);

		return handlePostRequest<MessagesDetailIndexResponse[], true>(
			RtVueApiRoutes.ViewMessagesDetail.Index,
			req,
			{ includeFullResponse: true }
		);
	}

	public getMessagesSummaryView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		preset?: DataPreset,
		dataSums?: ReportAggregates[],
		partition?: PartitionIndexResponse,
		dataColumns?: string[],
		havingFilter?: HavingFilter[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns,
			undefined,
			havingFilter
		);

		return handlePostRequest<MessagesSummaryIndexResponse[], true>(
			RtVueApiRoutes.ViewMessagesSummary.Index,
			req,
			{
				includeFullResponse: true
			}
		);
	}

	public getCdrSearchView(cdrSearchViewConfig: ICdrSearchConfigInterface) {
		const {
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns,
			pageSize
		} = cdrSearchViewConfig;
		const mappedFilters = filters.map((filter) => {
			if (filter.key === 'ingress_raw_duration_ms') {
				const operand = { ...filter.operands[0] };
				operand.value = `${Number(operand.value) * 1000}`;

				return { ...filter, operands: [operand] };
			}
			return { ...filter };
		});

		const req = this.createRtVueIndexRequest(
			timeRange,
			mappedFilters,
			preset,
			dataSums,
			partition,
			dataColumns
		);
		req.pageSize = pageSize;

		return handlePostRequest<CdrSearchIndexResponse[], true>(
			RtVueApiRoutes.ViewCdrSearch.Index,
			req,
			{ includeFullResponse: true }
		);
	}

	public getFinancialView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		preset?: DataPreset,
		dataSums?: ReportAggregates[],
		partition?: PartitionIndexResponse,
		dataColumns?: string[],
		havingFilter?: HavingFilter[],
		pageSize?: number
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns,
			undefined,
			havingFilter,
			pageSize
		);

		return handlePostRequest<FinancialIndexResponse, true>(
			RtVueApiRoutes.ViewFinancial.Index,
			req,
			{ includeFullResponse: true }
		);
	}

	public getFourteenDayView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		preset?: DataPreset,
		dataSums?: ReportAggregates[],
		partition?: PartitionIndexResponse,
		dataColumns?: string[],
		havingFilter?: HavingFilter[]
	): Promise<FullResponse<FourteenDayIndexResponse[]>> {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns,
			undefined,
			havingFilter
		);

		return handlePostRequest<FourteenDayIndexResponse[], true>(
			RtVueApiRoutes.ViewFourteenDay.Index,
			req,
			{
				includeFullResponse: true
			}
		);
	}

	public getBirdEyeReport(
		timeRange: TimeFilter,
		filters: DataFilter[],
		preset?: DataPreset,
		dataSums?: ReportAggregates[],
		partition?: PartitionIndexResponse,
		dataColumns?: string[],
		havingFilter?: HavingFilter[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns,
			undefined,
			havingFilter
		);

		return handlePostRequest<BirdsEyeIndexResponse[], true>(
			RtVueApiRoutes.ViewBirdsEye.Index,
			req,
			{ includeFullResponse: true }
		);
	}

	public getRejectSummaryView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		preset?: DataPreset,
		dataSums?: ReportAggregates[],
		partition?: PartitionIndexResponse,
		dataColumns?: string[],
		havingFilter?: HavingFilter[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns,
			undefined,
			havingFilter
		);

		return handlePostRequest<RejectSummaryIndexResponse[], true>(
			RtVueApiRoutes.ViewRejectSummary.Index,
			req,
			{ includeFullResponse: true }
		);
	}

	public getRejectCostDetailsView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		preset?: DataPreset,
		dataSums?: ReportAggregates[],
		partition?: PartitionIndexResponse,
		dataColumns?: string[],
		havingFilter?: HavingFilter[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns,
			undefined,
			havingFilter
		);

		return handlePostRequest<RejectDetailsIndexResponse[], true>(
			RtVueApiRoutes.ViewRejectDetailsCost.Index,
			req,
			{ includeFullResponse: true }
		);
	}

	public getRejectRevenueDetailsView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		preset?: DataPreset,
		dataSums?: ReportAggregates[],
		partition?: PartitionIndexResponse,
		dataColumns?: string[],
		havingFilter?: HavingFilter[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns,
			undefined,
			havingFilter
		);

		return handlePostRequest<RejectDetailsIndexResponse[], true>(
			RtVueApiRoutes.ViewRejectDetailsRevenue.Index,
			req,
			{ includeFullResponse: true }
		);
	}

	public getNumbersDetails(
		timeRange: TimeFilter,
		filters: DataFilter[],
		partition?: PartitionIndexResponse | undefined,
		preset?: DataPreset,
		dataSums?: ReportAggregates[],
		additionalColumns?: Array<ISimpleSelectFormControlOption<string>>,
		compareToDate?: Date,
		havingFilter?: HavingFilter[],
		pageSize?: number
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			additionalColumns?.map((column) => column.value),
			compareToDate,
			havingFilter,
			pageSize
		);

		return handlePostRequest<NumbersIndexResponse, true>(
			RtVueApiRoutes.ViewNumbers.Index,
			req,
			{
				includeFullResponse: true
			}
		);
	}

	public getNumbersLite(
		timeRange: TimeFilter,
		filters: DataFilter[],
		partition?: PartitionIndexResponse | undefined,
		preset?: DataPreset,
		dataSums?: ReportAggregates[],
		additionalColumns?: Array<ISimpleSelectFormControlOption<string>>,
		compareToDate?: Date,
		havingFilter?: HavingFilter[],
		pageSize?: number
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			additionalColumns?.map((column) => column.value),
			compareToDate,
			havingFilter,
			pageSize
		);

		return handlePostRequest<NumbersLiteIndexResponse[], true>(
			RtVueApiRoutes.ViewNumbersLite.Index,
			req,
			{
				includeFullResponse: true
			}
		);
	}

	public getTfEnhancedSummaryView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		preset?: DataPreset,
		dataSums?: ReportAggregates[],
		partition?: PartitionIndexResponse,
		dataColumns?: string[],
		havingFilter?: HavingFilter[],
		pageSize?: number
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns,
			undefined,
			havingFilter,
			pageSize
		);

		return handlePostRequest<TollfreeEnhancedSummaryIndexResponse[], true>(
			RtVueApiRoutes.ViewTollfreeEnhancedSummary.Index,
			req,
			{
				includeFullResponse: true
			}
		);
	}

	public getTfEnhancedDetailsView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		preset?: DataPreset,
		dataSums?: ReportAggregates[],
		partition?: PartitionIndexResponse,
		dataColumns?: string[],
		havingFilter?: HavingFilter[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns,
			undefined,
			havingFilter
		);

		return handlePostRequest<TollfreeEnhancedDetailIndexResponse[], true>(
			RtVueApiRoutes.ViewTollfreeEnhancedDetail.Index,
			req,
			{
				includeFullResponse: true
			}
		);
	}

	public getFullSummaryView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		preset?: DataPreset,
		dataSums?: ReportAggregates[],
		partition?: PartitionIndexResponse,
		dataColumns?: string[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns
		);

		const body = JSON.stringify(req);

		return HttpRequest.fetchWithRoute<FullSummaryIndexResponse[]>(
			RtVueApiRoutes.ViewFullSummary.Index,
			{ body }
		);
	}

	public getReconciliationView(
		timeRange: TimeFilter,
		filters: DataFilter[],
		preset?: DataPreset,
		dataSums?: ReportAggregates[],
		partition?: PartitionIndexResponse,
		dataColumns?: string[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns
		);

		return handlePostRequest<IReconciliationResponse, true>(
			RtVueApiRoutes.ViewVendorReconciliation.Index,
			req,
			{ includeFullResponse: true }
		);
	}

	public async getRoboscore(
		timeRange: TimeFilter,
		filters: DataFilter[],
		compareToDate?: Date,
		dataSums?: ReportAggregates[],
		preset?: DataPreset,
		partition?: PartitionIndexResponse,
		dataColumns?: string[]
	) {
		const req = this.createRtVueIndexRequest(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns,
			compareToDate
		);

		return handlePostRequest<RoboscoreIndexResponse[], true>(
			RtVueApiRoutes.ViewRoboscore.Index,
			req,
			{ includeFullResponse: true }
		);
	}

	/**
	 * Create
	 */
	public createRtVueIndexRequest(
		timeFilter: TimeFilter,
		filters: DataFilter[],
		preset?: DataPreset,
		dataSums?: ReportAggregates[],
		partition?: PartitionIndexResponse,
		dataColumns?: string[],
		compareToDate?: Date,
		havingFilter?: HavingFilter[],
		pageSize: number = 50_000
	) {
		type ReqBody = RtVueLcoSummaryIndexRequest & RtVueIndexRequest;
		const timeRange = this.normalizeTimeObject(timeFilter);
		const req: ReqBody = {
			timeRange,
			filters,
			dataSums,
			dataColumns,
			compareToDate,
			havingFilter,
			pageSize
		};

		if (partition) {
			req.customerTestingOverride = partition.prefix;
		}

		if (preset) {
			req.presets = [preset.reportPreset];
		}

		return req;
	}

	/**
	 * Copies and normalizes a TimeObject on RtVueIndexRequest
	 */
	private normalizeTimeObject<T extends { timeObject: TimeRange }>(
		timeObjectWrapper: T
	) {
		timeObjectWrapper = cloneDeep(timeObjectWrapper);

		timeObjectWrapper.timeObject.start = this.toUtc(
			timeObjectWrapper.timeObject.start
		) as Date;

		timeObjectWrapper.timeObject.end = this.toUtc(
			timeObjectWrapper.timeObject.end
		) as Date;

		return timeObjectWrapper;
	}

	/**
	 * Return UTC date
	 */
	private toUtc(date: Date | string | undefined) {
		if (typeof date === 'undefined') {
			return undefined;
		}
		return createDateAsUTC(date);
	}
}
