import { CdrSearchIndexResponse } from 'RtModels';
import { AccountRouter } from 'RtUi/app/AccountManagement/Accounts/Account.router';
import { SubscriptionRouter } from 'RtUi/app/AccountManagement/Subscriptions/Subscription.router';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { CdrConnectionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CdrConnectionDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { FileStreamDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FileStreamDataColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';

export const getCdrSearchColumns = (): Array<
	DataGridColumn<CdrSearchIndexResponse>
> => [
	DateDataGridColumn({
		accessorKey: 'ingress_invite_ts',
		header: 'Start Time',
		hiddenIfEmpty: true,
		showTime: true,
		isMilitaryTimeFormat: true,
		timezone: 'UTC'
	}),
	DateDataGridColumn({
		accessorKey: 'ingress_answer_ts',
		header: 'Ingress Answer Ts',
		hiddenIfEmpty: true,
		showTime: true,
		isMilitaryTimeFormat: true,
		timezone: 'UTC'
	}),
	DateDataGridColumn({
		accessorKey: 'ingress_release_ts',
		header: 'Release Time',
		hiddenIfEmpty: true,
		showTime: true,
		isMilitaryTimeFormat: true,
		timezone: 'UTC'
	}),
	DefaultDataGridColumn({
		accessorKey: 'to_raw',
		header: 'Dialed Number',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'to_lrn',
		header: 'To LRN',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'to_lrn_dial_code',
		header: 'To Lrn Dial Code',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'fr_raw',
		header: 'Calling Party Number',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'fr_dial_code',
		header: 'Fr Dial Code',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'fr_lrn_dial_code',
		header: 'Fr Lrn Dial Code',
		hiddenIfEmpty: true
	}),
	CdrConnectionDataGridColumn({
		accessorKey: 'ingress_connection_id',
		header: 'Ingress Connection'
	}),
	CdrConnectionDataGridColumn({
		accessorKey: 'egress_connection_id',
		header: 'Egress Connection'
	}),
	DefaultDataGridColumn({
		accessorKey: 'calculated_call_type',
		header: 'Calculated Call Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'calculated_jurisdiction',
		header: 'Calculated Jurisdiction',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'ingress_raw_duration_ms',
		header: 'Calculated Raw Duration',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_call_id',
		header: 'Ingress Call',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_call_id',
		header: 'Egress Call',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_alternate_id',
		header: 'Ingress Alternate',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_trunk_group_id',
		header: 'Ingress TrunkGrp',
		hiddenIfEmpty: true,
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_to_ip',
		header: 'Ingress To Ip',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_fr_ip',
		header: 'Ingress Fr Ip',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_cic',
		header: 'Ingress Cic',
		hiddenIfEmpty: true
	}),
	DateDataGridColumn({
		accessorKey: 'ingress_progress_ts',
		header: 'Ingress Progress Ts',
		hiddenIfEmpty: true,
		showTime: true,
		isMilitaryTimeFormat: true,
		timezone: 'UTC'
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_result_id',
		header: 'Ingress Result',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'ingress_release_id',
		header: 'Ingress Release',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_connect',
		header: 'Ingress Connect',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_pdd_ms',
		header: 'Ingress Pdd Ms',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'cost_billing_ref1',
		header: 'Cost billing ref 1',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'cost_billing_ref2',
		header: 'Cost billing ref 2',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'cost_billing_ref3',
		header: 'Cost billing ref 3',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'cost_billing_ref4',
		header: 'Cost billing ref 4',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'cost_xref1',
		header: 'Cost xref 1',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'cost_xref2',
		header: 'Cost xref 2',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'cost_currency_type',
		header: 'Cost currency type',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'cost_conversationRate',
		header: 'Cost Conversation Rate',
		hiddenIfEmpty: true,
		decimals: 6
	}),
	DefaultDataGridColumn({
		accessorKey: 'revenue_billing_ref1',
		header: 'Revenue billing ref 1',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'revenue_billing_ref2',
		header: 'Revenue billing ref 2',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'revenue_billing_ref3',
		header: 'Revenue billing ref 3',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'revenue_billing_ref4',
		header: 'Revenue billing ref 4',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'revenue_xref1',
		header: 'Revenue xref 1',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'revenue_xref2',
		header: 'Revenue xref 2',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'revenue_currency_type',
		header: 'Revenue currency type',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'revenue_conversationRate',
		header: 'Revenue Conversation Rate',
		hiddenIfEmpty: true,
		decimals: 6
	}),
	DefaultDataGridColumn({
		accessorKey: 'attestation_has_token',
		header: 'Attestation Has Token',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'attestation_token_url',
		header: 'Attestation Token Url',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'ingress_latency',
		header: 'Ingress Latency',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_mos',
		header: 'Egress Mos',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'egress_jitter',
		header: 'Egress Jitter',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_packets_received',
		header: 'Egress Packets Received',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_packets_sent',
		header: 'Egress Packets Sent',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'egress_latency',
		header: 'Egress Latency',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_fas',
		header: 'Egress fas',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'is_final',
		header: 'Is Final',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_alternate_id',
		header: 'Egress Alternate',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_trunk_group_id',
		header: 'Egress TrunkGrp',
		hiddenIfEmpty: true,
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_to_ip',
		header: 'Egress To Ip',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_fr_ip',
		header: 'Egress Fr Ip',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_cic',
		header: 'Egress Cic',
		hiddenIfEmpty: true
	}),
	DateDataGridColumn({
		accessorKey: 'egress_invite_ts',
		header: 'Egress Invite Ts',
		hiddenIfEmpty: true,
		showTime: true,
		isMilitaryTimeFormat: true,
		timezone: 'UTC'
	}),
	DateDataGridColumn({
		accessorKey: 'egress_answer_ts',
		header: 'Egress Answer Ts',
		hiddenIfEmpty: true,
		showTime: true,
		isMilitaryTimeFormat: true,
		timezone: 'UTC'
	}),
	DateDataGridColumn({
		accessorKey: 'egress_progress_ts',
		header: 'Egress Progress Ts',
		hiddenIfEmpty: true,
		showTime: true,
		isMilitaryTimeFormat: true,
		timezone: 'UTC'
	}),
	DateDataGridColumn({
		accessorKey: 'egress_release_ts',
		header: 'Egress Release Ts',
		hiddenIfEmpty: true,
		showTime: true,
		isMilitaryTimeFormat: true,
		timezone: 'UTC'
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_result_id',
		header: 'Egress Result',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_release_id',
		header: 'Egress Release',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_connect',
		header: 'Egress Connect',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_raw_duration_ms',
		header: 'Egress Raw Duration',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_pdd_ms',
		header: 'Egress Pdd Ms',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_route_depth',
		header: 'Egress Route Depth',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_route_list',
		header: 'Egress Route List',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'to_std',
		header: 'To Std',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'to_iso',
		header: 'To Iso',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'to_dial_code',
		header: 'To Dial Code',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'to_rate_center_id',
		header: 'To Rate Center',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'fr_std',
		header: 'Fr Std',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'fr_lrn',
		header: 'From LRN',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'fr_jip',
		header: 'Fr Jip',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'fr_iso',
		header: 'Fr Iso',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'fr_rate_center_id',
		header: 'Fr Rate Center',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_mos',
		header: 'Ingress Mos',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_jitter',
		header: 'Ingress Jitter',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_packets_received',
		header: 'Ingress Packets Received',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_packets_sent',
		header: 'Ingress Packets Sent',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_to_is_valid',
		header: 'DN is Valid',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_to_is_dnc',
		header: 'Ingress To Is Dnc',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_fr_is_valid',
		header: 'ANI is Valid',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_fr_is_dno',
		header: 'ANI is DNO',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'robo_score',
		header: 'Robo Score',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'fraud_score',
		header: 'Fraud Score',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'anomaly_score',
		header: 'Anomaly Score',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'attestation',
		header: 'Attestation',
		hiddenIfEmpty: true
	}),
	BooleanDataGridColumn({
		accessorKey: 'is_dest_same',
		header: 'Is Dest Same',
		hiddenIfEmpty: true
	}),
	BooleanDataGridColumn({
		accessorKey: 'is_dest_detail_same',
		header: 'Is Dest Detail Same',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'cost_carrier_id',
		header: 'Cost Account',
		hiddenIfEmpty: true,
		onDrillDown: (e, row) => {
			e.preventDefault();
			ApplicationContainer.openNewTab(
				AccountRouter.getProfileRoute(row.cost_carrier_id)
			);
		}
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'cost_subscription_id',
		header: 'Cost Subscription',
		hiddenIfEmpty: true,
		onDrillDown: (e, row) => {
			e.preventDefault();
			ApplicationContainer.openNewTab(
				SubscriptionRouter.getProfileRoute(row.cost_subscription_id)
			);
		}
	}),
	IntegerDataGridColumn({
		accessorKey: 'cost_service_id',
		header: 'Cost Service',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'cost_billing_cycle_id',
		header: 'Cost Billing Cycle',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'cost_jurisdiction_id',
		header: 'Cost Jurisdiction',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'cost_billing_number_used',
		header: 'Cost Billing Number Used',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'cost_juris_precedence',
		header: 'Cost Juris Precedence',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'cost_rate_sheet_id',
		header: 'Cost Rate Sheet',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'cost_rate_key',
		header: 'Cost Rate Key',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'cost_rate',
		header: 'Cost Rate',
		hiddenIfEmpty: true,
		decimals: 6
	}),
	IntegerDataGridColumn({
		accessorKey: 'cost_reject_code',
		header: 'Cost Reject Code',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'cost_duration_ms',
		header: 'Cost Duration',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'cost_rate_charge',
		header: 'Cost Rate Charge',
		hiddenIfEmpty: true,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'cost_connection_charge',
		header: 'Cost Connection Charge',
		hiddenIfEmpty: true,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'cost_other_charge',
		header: 'Cost Other Charge',
		hiddenIfEmpty: true,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'cost_tax_charge',
		header: 'Cost Tax Charge',
		hiddenIfEmpty: true,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'cost_total_charge',
		header: 'Cost Total Charge',
		hiddenIfEmpty: true,
		decimals: 6
	}),
	AccountDataGridColumn({
		accessorKey: 'revenue_carrier_id',
		header: 'Revenue Account',
		hiddenIfEmpty: true,
		onDrillDown: (e, row) => {
			e.preventDefault();
			ApplicationContainer.openNewTab(
				AccountRouter.getProfileRoute(row.revenue_carrier_id)
			);
		}
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'revenue_subscription_id',
		header: 'Revenue Subscription',
		hiddenIfEmpty: true,
		onDrillDown: (e, row) => {
			e.preventDefault();
			ApplicationContainer.openNewTab(
				SubscriptionRouter.getProfileRoute(row.revenue_subscription_id)
			);
		}
	}),
	DefaultDataGridColumn({
		accessorKey: 'revenue_service_id',
		header: 'Revenue Service',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'revenue_billing_cycle_id',
		header: 'Revenue Billing Cycle',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'revenue_jurisdiction_id',
		header: 'Revenue Jurisdiction',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'revenue_billing_number_used',
		header: 'Revenue Billing Number Used',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'revenue_juris_precedence',
		header: 'Revenue Juris Precedence',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'revenue_rate_sheet_id',
		header: 'Revenue Rate Sheet',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'revenue_rate_key',
		header: 'Revenue Rate Key',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'revenue_rate',
		header: 'Revenue Rate',
		hiddenIfEmpty: true,
		decimals: 6
	}),
	IntegerDataGridColumn({
		accessorKey: 'revenue_reject_code',
		header: 'Revenue Reject Code',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'revenue_duration_ms',
		header: 'Revenue Duration',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'revenue_rate_charge',
		header: 'Revenue Rate Charge',
		hiddenIfEmpty: true,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'revenue_connection_charge',
		header: 'Revenue Connection Charge',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'revenue_other_charge',
		header: 'Revenue Other Charge',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'revenue_tax_charge',
		header: 'Revenue Tax Charge',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'revenue_total_charge',
		header: 'Revenue Total Charge',
		hiddenIfEmpty: true,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'total_cost',
		header: 'Total Cost',
		hiddenIfEmpty: true,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'total_revenue',
		header: 'Total Revenue',
		hiddenIfEmpty: true,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'total_margin',
		header: 'Total Margin',
		hiddenIfEmpty: true,
		decimals: 6
	}),
	IntegerDataGridColumn({
		accessorKey: 'info_digits',
		header: 'Info Digits',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'custom_field1',
		header: 'Custom Field1',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'custom_field2',
		header: 'Custom Field2',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'custom_field3',
		header: 'Custom Field3',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'custom_field4',
		header: 'Custom Field4',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'custom_field5',
		header: 'Custom Field5',
		hiddenIfEmpty: true
	}),
	FileStreamDataGridColumn({
		accessorKey: 'file_stream_id',
		header: 'File Stream'
	}),
	DefaultDataGridColumn({
		accessorKey: 'row_id',
		header: 'Row Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'file_name',
		header: 'File Name',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'vuid',
		header: 'VUID',
		maxSize: 9000
	}),
	DefaultDataGridColumn({
		accessorKey: 'cdr_record',
		header: 'Cdr Record',
		hiddenIfEmpty: true
	})
];
