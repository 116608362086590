import { ScenarioIndexRequest, ScenarioIndexResponse } from 'RtModels';
import { ScenarioRouter } from 'RtUi/app/rtSip/Scenarios/Scenario.router';
import { useGetSipScenarios } from 'RtUi/app/rtSip/Scenarios/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getScenarioColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/scenario';
import { useMemo } from 'react';

interface IScenarioDataGridProps {
	resourceParams: ScenarioIndexRequest;
}

export const ScenarioDataGrid = ({
	resourceParams
}: IScenarioDataGridProps) => {
	const { data, isFetching: isLoading } = useGetSipScenarios(
		resourceParams,
		true
	);

	const columns = useMemo(() => getScenarioColumns(), []);

	return (
		<DataGrid<ScenarioIndexResponse>
			router={ScenarioRouter}
			data={data?.data}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rtSip_scenarios'}
			columns={columns}
		/>
	);
};
