import { FourteenDayIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { CallDateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDateDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import {
	DataGridColumnAlignment,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PercentDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PercentDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { AverageCallDurationDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AverageCallDurationDataGridColumn';
import { CallDurationDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDurationDataGridColumn';
import { sumBy } from 'lodash-es';
import { AnswerRateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AnswerRateDataGridColumn';

export const getFourteenDayColumns = (
	onClickRevenueColumn: (row: FourteenDayIndexResponse) => void,
	onClickCostColumn: (row: FourteenDayIndexResponse) => void,
	onClickMarginColumn: (row: FourteenDayIndexResponse) => void,
	onClickASRColumn: (row: FourteenDayIndexResponse) => void,
	callDateFormat?: string,
	onDrillDown?: (row: FourteenDayIndexResponse) => void
): Array<DataGridColumn<FourteenDayIndexResponse>> => [
	CallDateDataGridColumn({
		accessorKey: 'callDate',
		header: 'Call Date',
		format: callDateFormat
	}),
	AccountDataGridColumn({
		accessorKey: 'customerAccountId',
		header: 'Customer Account Id',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'customerSubscriptionId',
		header: 'Customer Subscription Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'customerExternalAccountId',
		header: 'Customer External Account Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'customerExternalSubAccountId',
		header: 'Customer External Sub Account Id',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'vendorAccountId',
		header: 'Vendor Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'vendorSubscriptionId',
		header: 'Vendor Subscription Id',
		hiddenIfEmpty: true,
		onDrillDown: (e, row) => {
			e.preventDefault();
			if (onDrillDown) {
				onDrillDown(row);
			}
		}
	}),
	DefaultDataGridColumn({
		accessorKey: 'vendorExternalAccountId',
		header: 'External Vendor Account Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'vendorExternalSubAccountId',
		header: 'External Vendor Sub Account Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressTrunkGroup',
		header: 'Ingress Trunk Group',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressTrunkGroupLabel',
		header: 'Ingress Trunk Group Label',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressTrunkGroup',
		header: 'Egress Trunk Group',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressTrunkGroupLabel',
		header: 'Egress Trunk Group Label',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'attempts',
		header: 'Attempts',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'connects',
		header: 'Connects',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'routeAdvances',
		header: 'Route Advances',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	CallDurationDataGridColumn({
		accessorKey: 'duration',
		header: 'Duration',
		showFooter: true
	}),
	PrecisionDataGridColumn({
		//cSpell:disable-next-line
		header: 'Total Revenue',
		accessorKey: 'totalRevenue',
		hiddenIfEmpty: true,
		showFooter: true,
		onDrillDown: (e, row) => {
			e.preventDefault();
			onClickRevenueColumn(row);
		}
	}),
	PrecisionDataGridColumn({
		//cSpell:disable-next-line
		header: 'Total Cost',
		accessorKey: 'totalCost',
		hiddenIfEmpty: true,
		showFooter: true,
		onDrillDown: (e, row) => {
			e.preventDefault();
			onClickCostColumn(row);
		}
	}),
	PrecisionDataGridColumn({
		//cSpell:disable-next-line
		header: 'Margin',
		accessorKey: 'margin',
		hiddenIfEmpty: true,
		showFooter: true,
		onDrillDown: (e, row) => {
			e.preventDefault();
			onClickMarginColumn(row);
		}
	}),
	PercentDataGridColumn({
		//cSpell:disable-next-line
		accessorKey: 'marginPercent',
		header: 'Margin Percent',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		//cSpell:disable-next-line
		accessorKey: 'negMargin',
		header: 'Negative Margin',
		hiddenIfEmpty: true
	}),
	AnswerRateDataGridColumn({
		//cSpell:disable-next-line
		accessorKey: 'asr',
		header: 'ASR',
		hiddenIfEmpty: true,
		showFooter: true,
		align: DataGridColumnAlignment.RIGHT,
		attempts: (rows) => sumBy(rows, 'attempts'),
		connections: (rows) => sumBy(rows, 'connects'),
		onDrillDown: (e, row) => {
			e.preventDefault();
			onClickASRColumn(row);
		}
	}),
	PercentDataGridColumn({
		accessorKey: 'actualAsr',
		header: 'Actual ASR',
		hiddenIfEmpty: true
	}),
	AverageCallDurationDataGridColumn({
		//cSpell:disable-next-line
		accessorKey: 'aloc',
		header: 'Aloc',
		hiddenIfEmpty: true,
		showFooter: true,
		align: DataGridColumnAlignment.RIGHT,
		connections: (rows) => sumBy(rows, 'connects'),
		duration: (rows) => sumBy(rows, 'duration')
	}),
	PrecisionDataGridColumn({
		//cSpell:disable-next-line
		header: 'Avg Roboscore',
		accessorFn: (val) =>
			val.connects ? (Number(val.roboscore) || 0) / Number(val.connects) : 0,
		hiddenIfEmpty: true
	})
];
