import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	CprRateSheetIndexRequest,
	CprRateSheetIndexResponse,
	CprRateSheetProfileRequest,
	CprRateSheetResultsProfileRequest,
	CprRateSheetResultsProfileResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchCprRateSheets = async (
	queryParams?: CprRateSheetIndexRequest
): Promise<FullResponse<CprRateSheetIndexResponse[]>> => {
	return handleGetRequest<CprRateSheetIndexResponse[], true>(
		Rt800ApiRoutes.CprRateSheets.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetCprRateSheets = (queryParams?: CprRateSheetIndexRequest) => {
	return useQuery<FullResponse<CprRateSheetIndexResponse[]>, Error>(
		['getCprRateSheets', queryParams],
		() => fetchCprRateSheets(queryParams)
	);
};

export const fetchCprRateResultsSheets = async <
	IncludeFullResponse extends boolean = false
>(
	urlParams: CprRateSheetProfileRequest,
	queryParams?: CprRateSheetResultsProfileRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<
	RequestResponse<CprRateSheetResultsProfileResponse[], IncludeFullResponse>
> => {
	return handleGetRequest(Rt800ApiRoutes.CprRateSheetResults.Profile, {
		includeFullResponse,
		urlParams,
		queryParams
	});
};

export const useGetCprRateResultsSheets = (
	urlParams: CprRateSheetProfileRequest,
	queryParams?: CprRateSheetResultsProfileRequest
) => {
	return useQuery<FullResponse<CprRateSheetResultsProfileResponse[]>, Error>(
		['getCprRateSheets', urlParams, queryParams],
		() => fetchCprRateResultsSheets(urlParams, queryParams, true)
	);
};
