import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { Controller, Control } from 'react-hook-form';
import CreatableMultiSelect from 'RtUi/components/form/CreatableMultiSelect';
import Select from 'react-select';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';

interface IInputFieldProps {
	control: Control;
	disabled: boolean;
	required: boolean;
	initialValue?: string;
}

export const SiteIdInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.siteId"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Site ID"
					required={required}
					disabled={disabled}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const PeerIdInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.peerId"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Peer ID"
					required={required}
					disabled={disabled}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const TrunkGroupInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.trunkGroup"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Trunk Group"
					required={required}
					disabled={disabled}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const AccountNumberInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.accountNumber"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Account Number"
					required={required}
					disabled={disabled}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const TrunkIdInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.trunkId"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Trunk Id"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const RouteLabelInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.routeLabel"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Route Label"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const AccountInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.account"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Account"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const RoutingLabelInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.routingLabel"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Routing Label"
					required={required}
					disabled={disabled}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const RespOrgIdInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.respOrgId"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Resp Org Id"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const AllowedRespOrgIdInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled } = props;
	return (
		<Controller
			control={control}
			name="configuration.allowedRespOrgIds"
			render={({ field: { onChange, value } }) => (
				<CreatableMultiSelect
					label="Allowed Resp Org Ids"
					disabled={disabled}
					onChange={onChange}
					value={value}
				/>
			)}
		/>
	);
};

export const CustomerIdInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.customerId"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Customer Id"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const RoutingInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.routing"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Routing"
					required={required}
					disabled={disabled}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const PopInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.pop"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Pop"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const ServiceTypeIdInputField = (props: IInputFieldProps) => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.serviceTypeId"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Service Type"
					required={required}
					disabled={disabled}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const IntraLataInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.intraLata"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Intra Lata"
					required={required}
					disabled={disabled}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const InterLataInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.interLata"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Inter Lata"
					required={required}
					disabled={disabled}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const SubscriptionTypeIdInputField = (props: IInputFieldProps) => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.subscriptionId"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Subscription"
					required={required}
					disabled={disabled}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const ServiceInstanceIdInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.serviceInstanceId"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Service Instance Id"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const SwitchIdInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.switchId"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Switch Id"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const TrunkGroupIdInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.trunkGroupId"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Trunk Group Id"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const DirectoryInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.directory"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Directory"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const RouteListIdInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.routeListId"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Route List Id"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const LocationCodeInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.locationCode"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Location Code"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const PiccFeeWaiverInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.piccFeeWaiver"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Picc Fee Waiver"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const IsAccountCodeInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.isAccountCode"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Is Account Code"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const RespOrgInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.respOrg"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Resp Org"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const OverFlowInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.overflow"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Overflow"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const NoPayPhoneInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.noPayPhone"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="NO Pay Phone"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const TrunkGroupNameInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.trunkGroupName"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Trunk Group Name"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const BanInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.ban"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Ban"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const CorpSrpInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.corpSrp"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Corp SRP"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const RelatedPartyIdInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.relatedPartyId"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Related Party Id"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const RelatedPartyNameInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.relatedPartyName"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Related Party Name"
					required={required}
					disabled={disabled}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const AreaOfServiceInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.areaOfService"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Area of Service"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const PrimaryContactNameInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.primaryContactName"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Primary Contact Name"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const PrimaryContactEmailInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.primaryContactEmail"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Primary Contact Email"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const PrimaryContactPhoneInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.primaryContactPhone"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Primary Contact Phone"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const SecondaryContactNameInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.secondaryContactName"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Secondary Contact Name"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const VerizonTollFreeTypeDropdown = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled } = props;
	const options = [
		{ value: 'TVL', label: 'Advanced' },
		{ value: 'BAS', label: 'Basic' }
	];
	return (
		<Controller
			control={control}
			name="configuration.tollFreeType"
			render={({ field: { onChange, value } }) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '1rem'
					}}
				>
					<label htmlFor="tollFreeType" className="form-label">
						TollFree Type
					</label>
					<Select
						isDisabled={disabled}
						name="tollFreeType"
						options={options}
						onChange={(val) => onChange(val?.value)}
						value={options.find((val) => val.value === value)}
					/>
				</div>
			)}
		/>
	);
};

export const VerizonFusfExemptionDropdown = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled } = props;
	const options = [
		{ value: 'E', label: 'Exempt' },
		{ value: 'N', label: 'NotExempt' }
	];
	return (
		<Controller
			control={control}
			name="configuration.fusfExemption"
			render={({ field: { onChange, value } }) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '1rem'
					}}
				>
					<label htmlFor="fusfExemption" className="form-label">
						Fusf Exemption
					</label>
					<Select
						isDisabled={disabled}
						name="fusfExemption"
						options={options}
						onChange={(val) => onChange(val?.value)}
						value={options.find((val) => val.value === value)}
					/>
				</div>
			)}
		/>
	);
};

export const SecondaryContactEmailInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.secondaryContactEmail"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Secondary Contact Email"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const SecondaryContactPhoneInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.secondaryContactPhone"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Secondary Contact Phone"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const TranslateBtnInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.translateBtn"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Translate BTN"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const RoutingNameInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.routingName"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Routing Name"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const PayPhoneBlockInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required } = props;
	return (
		<Controller
			control={control}
			name="configuration.payPhoneBlock"
			render={({ field: { onChange, value } }) => (
				<RadioFormControl<number>
					label="Pay Phone Block"
					disabled={disabled}
					required={required}
					displayMode={disabled}
					onChange={onChange}
					value={value}
					options={[
						{ value: 1, label: 'Yes' },
						{ value: 0, label: 'No' }
					]}
				/>
			)}
		/>
	);
};

export const BillingTelephoneNumberInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.billingTelephoneNumber"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Billing Telephone Number"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const RouteNameInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.routeName"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Route Name"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const EmailAddressInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.emailAddress"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Email Address"
					type="email"
					required={required}
					disabled={disabled}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const CustomerNumberInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.customerNumber"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Customer Number"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const DidCustomerNumberInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.didCustomerNumber"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="DID Customer Number"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const ParentCustomerNumberInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.parentCustomerNumber"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Parent Customer Number"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const BillingAccountNumberInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.billingAccountNumber"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Billing Account Number"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const AccountIdInputField = (props: IInputFieldProps) => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.accountId"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Account Id"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const SmsConfigIdInputField = (props: IInputFieldProps): JSX.Element => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.smsConfigId"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Sms Config Id"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const ResponseTypeInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required } = props;
	return (
		<Controller
			control={control}
			name="configuration.responseType"
			render={({ field: { onChange, value } }) => (
				<RadioFormControl<String>
					label="Response Type"
					disabled={disabled}
					required={required}
					displayMode={disabled}
					onChange={onChange}
					value={value}
					options={[
						{ value: 'json', label: 'json' },
						{ value: 'xml', label: 'xml' }
					]}
				/>
			)}
		/>
	);
};

export const VersionInputField = (props: IInputFieldProps) => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.version"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Version"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const LumenCsnInputField = (props: IInputFieldProps) => {
	const { control, disabled, required, initialValue } = props;
	return (
		<Controller
			control={control}
			name="configuration.didCustomerServiceName"
			render={({ field: { onChange, value } }) => (
				<InputFormControl
					label="Customer Service Name (CSN)"
					disabled={disabled}
					required={required}
					displayMode={false}
					onChange={onChange}
					value={value}
					initialValue={initialValue}
				/>
			)}
		/>
	);
};

export const LumenProductIdInputField = (
	props: IInputFieldProps
): JSX.Element => {
	const { control, disabled, required } = props;
	return (
		<Controller
			control={control}
			name="configuration.didProductId"
			render={({ field: { onChange, value } }) => (
				<RadioFormControl<String>
					label="DID Product ID"
					disabled={disabled}
					required={required}
					displayMode={disabled}
					onChange={onChange}
					value={value || '2036'}
					options={[
						{ value: '2036', label: 'LI' },
						{ value: '2042', label: 'LI-ELS' }
					]}
				/>
			)}
		/>
	);
};
