import { Rt800ApiRoutes } from 'RtExports/routes';
import { EntityIndexRequest, EntityIndexResponse } from 'RtModels';
import {
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchEntities = async <
	IncludeFullResponse extends boolean = false
>(
	queryParams?: EntityIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<EntityIndexResponse[], IncludeFullResponse>> => {
	return handleGetRequest(Rt800ApiRoutes.Entities.Index, {
		queryParams,
		includeFullResponse
	});
};

export const useGetEntities = <IncludeFullResponse extends boolean = false>(
	queryParams?: EntityIndexRequest,
	includeFullResponse?: IncludeFullResponse
) => {
	return useQuery<
		RequestResponse<EntityIndexResponse[], IncludeFullResponse>,
		Error
	>(
		[`useGetEntity`, queryParams, includeFullResponse],
		() => fetchEntities(queryParams, includeFullResponse),
		{
			enabled: true
		}
	);
};
