import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	RespOrgContactIndexResponse,
	RespOrgIndexRequest,
	RespOrgIndexResponse,
	RespOrgProfileRequest,
	RespOrgProfileResponse
} from 'RtModels';
import {
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchRespOrgs = async <
	IncludeFullResponse extends boolean = false
>(
	queryParams?: RespOrgIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<RespOrgIndexResponse[], IncludeFullResponse>> => {
	return handleGetRequest(Rt800ApiRoutes.RespOrgs.Index, {
		queryParams,
		includeFullResponse
	});
};

export const useGetRespOrgs = <IncludeFullResponse extends boolean = false>(
	queryParams?: RespOrgIndexRequest,
	includeFullResponse?: IncludeFullResponse
) => {
	return useQuery<
		RequestResponse<RespOrgIndexResponse[], IncludeFullResponse>,
		Error
	>([`useGetRespOrgs`], () => fetchRespOrgs(queryParams, includeFullResponse), {
		enabled: true
	});
};

export const fetchRespOrg = async (
	urlParams: RespOrgIndexRequest
): Promise<RespOrgProfileResponse> => {
	return handleGetRequest<RespOrgProfileResponse>(
		Rt800ApiRoutes.RespOrgs.Profile,
		{
			urlParams
		}
	);
};

export const useGetRespOrg = (urlParams: RespOrgProfileRequest) => {
	return useQuery<RespOrgProfileResponse, Error>(
		[`useGetRespOrgs`, urlParams],
		() => fetchRespOrg(urlParams),
		{ enabled: true }
	);
};

export const fetchRespOrgContacts = async (
	urlParams: RespOrgIndexRequest
): Promise<RespOrgContactIndexResponse[]> => {
	return handleGetRequest<RespOrgContactIndexResponse[]>(
		Rt800ApiRoutes.RespOrgContact.Index,
		{
			urlParams
		}
	);
};

export const useGetRespOrgContacts = (urlParams: RespOrgProfileRequest) => {
	return useQuery<RespOrgContactIndexResponse[], Error>(
		[`useGetRespOrgContacts`, urlParams],
		() => fetchRespOrgContacts(urlParams),
		{ enabled: true }
	);
};
