import { ManagedEntityIndexResponse } from 'RtModels';
import { EntityIdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/EntityIdDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';

export const getManagedEntitiesColumns = (): Array<
	DataGridColumn<ManagedEntityIndexResponse>
> => [
	EntityIdDataGridColumn({
		accessorKey: 'entityId'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isManaged',
		header: 'Managed'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isApi',
		header: 'API'
	}),
	DefaultDataGridColumn({
		accessorKey: 'apiResourceId',
		header: 'Resource'
	}),
	DefaultDataGridColumn({
		accessorKey: 'contactName',
		header: 'Contact Name'
	}),
	DefaultDataGridColumn({
		accessorKey: 'contactNumber',
		header: 'Contact Number'
	}),
	DefaultDataGridColumn({
		accessorKey: 'apiStatus',
		header: 'API Status'
	}),
	DefaultDataGridColumn({
		accessorKey: 'lastApiAttemptTs',
		header: 'Last API Attempt'
	}),
	DefaultDataGridColumn({
		accessorKey: 'lastApiSuccessTs',
		header: 'Last API Success'
	}),
	DefaultDataGridColumn({
		accessorKey: 'sqlStatus',
		header: 'SQL Status'
	}),
	DefaultDataGridColumn({
		accessorKey: 'lastSqlAttemptTs',
		header: 'Last SQL Attempt'
	}),
	DefaultDataGridColumn({
		accessorKey: 'lastSqlSuccessTs',
		header: 'Last SQL Success'
	})
];
