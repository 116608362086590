import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import moment from 'moment';

interface CallDateDataGridColumnConfiguration<T = any>
	extends DataGridColumnConfiguration<T> {
	format?: string;
}

export const CallDateDataGridColumn = <T = any,>({
	format = 'YYYY-MM-DD',
	...config
}: CallDateDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const parseValue = (momentValue: moment.Moment, row: any) => {
		const callDateMoment = momentValue.utc();

		if (typeof row.hour !== 'undefined') {
			callDateMoment.hours(Number(row.hour));
		}

		if (typeof row.minute !== 'undefined') {
			callDateMoment.minutes(Number(row.minute));
		}

		return callDateMoment;
	};

	const getFormat = (baseFormat: string, row: any) => {
		let momentFormatStr = String(baseFormat);

		if (typeof row.hour !== 'undefined' || typeof row.minute !== 'undefined') {
			momentFormatStr += ' HH:mm';
		}

		return momentFormatStr;
	};

	const getValue = (value: any, row: any) => {
		const momentValue = moment(value);

		if (!momentValue.isValid()) {
			return '';
		}

		const parsedValue = parseValue(momentValue, row);
		const momentFormatStr = getFormat(format, row);

		return parsedValue.format(momentFormatStr);
	};

	return DefaultDataGridColumn({
		...config,
		exportExcelDateAsUtc: true,
		exportExcelDateFormat: (data) =>
			data.length ? getFormat('yyyy-mm-dd', data[0]) : 'yyyy-mm-dd',
		exportValue: (value: any, row: any, isExcel: boolean) => {
			const momentValue = moment(value);
			if (!momentValue.isValid()) {
				return '';
			}

			if (isExcel) {
				return parseValue(momentValue, row).toDate();
			}

			return getValue(value, row);
		},
		sortingFn: (rowA, rowB, columnId) => {
			const currentDate = moment(
				getValue(rowA.getValue(columnId), rowA.original)
			);
			if (!currentDate.isValid()) {
				return -1;
			}

			const nextDate = moment(getValue(rowB.getValue(columnId), rowB.original));

			if (!nextDate.isValid()) {
				return -1;
			}

			if (currentDate.isBefore(nextDate)) {
				return -1;
			}

			return Number(currentDate.isAfter(nextDate));
		},
		getValue: ({ cell, row }) => getValue(cell.getValue<any>(), row.original)
	});
};
