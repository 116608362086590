import { BlockTypeIndexResponse } from 'RtModels';
import { useGetBlockTypes } from 'RtUi/app/rtSip/BlockTypes/lib/service';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxBlockTypeSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		BlockTypeIndexResponse,
		'blockTypeId',
		IsMulti,
		IsClearable
	> {}

export const RtxBlockTypeSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	label = 'Block Type',
	...props
}: IRtxBlockTypeSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetBlockTypes();

	return (
		<RtxSelectInput<BlockTypeIndexResponse, 'blockTypeId', IsMulti, IsClearable>
			label={label}
			valueKey="blockTypeId"
			labelKey="label"
			options={data}
			isLoading={isLoading}
			{...props}
		/>
	);
};
