import { format, parseISO } from 'date-fns';
import { FC, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
	AccountIndexResponse,
	ConnectionIndexResponse,
	FileStreamIndexResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { ConnectionRouter } from 'RtUi/app/rtVue/Connections/Connection.router';
import { FileStreamSelect } from 'RtUi/app/rtVue/FileStreams/lib/controls/FileStreamSelect';
import { TrunkGroupSelect } from 'RtUi/app/rtVue/Reconciliation/lib/components/TrunkGroupSelect';
import { rejectCodeToLabel } from 'RtUi/app/rtVue/RerateControl/lib/utils/Constants';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface IRatingSimulatorSummaryProps {
	vcdr: any;
}

export const RatingSimulatorSummary: FC<
	React.PropsWithChildren<IRatingSimulatorSummaryProps>
> = ({ vcdr }) => {
	const [fileStream, setFileStream] = useState<FileStreamIndexResponse>();
	const [trunkGroup1, setTrunkGroup1] = useState<ConnectionIndexResponse>();
	const [trunkGroup2, setTrunkGroup2] = useState<ConnectionIndexResponse>();
	const [account1, setAccount1] = useState<AccountIndexResponse>();
	const [account2, setAccount2] = useState<AccountIndexResponse>();
	const [subscription1, setSubscription1] =
		useState<SubscriptionIndexResponse>();
	const [subscription2, setSubscription2] =
		useState<SubscriptionIndexResponse>();

	return (
		<RtUiForm isInline hideButtons displayMode>
			<Row>
				<Col lg={6}>
					<fieldset className="m-1 card">
						<FileStreamSelect
							displayMode
							value={fileStream}
							onChange={(fs) => {
								setFileStream(fs);
							}}
							initialOptionId={String(vcdr.fileStreamId)}
						/>
						<InputFormControl
							label="Call Type"
							displayMode
							value={String(vcdr.callType)}
						/>
						<InputFormControl displayMode label="ANI/CPN" value={vcdr.fr.raw} />
						<InputFormControl
							displayMode
							label="Orin LRN"
							value={vcdr.fr.lrn}
						/>
						<TrunkGroupSelect
							displayMode
							label="Orig Trunk Group"
							autoHeight
							onChange={(origTrunkGroup) => {
								setTrunkGroup1(origTrunkGroup);
							}}
							linkTo={ConnectionRouter.getProfileRoute(vcdr.ibCon.connectionId)}
							value={trunkGroup1}
							initialOptionId={vcdr.ibCon.trunkGroupId}
						/>
						<InputFormControl
							displayMode
							value={format(parseISO(vcdr.ibCon.inviteTs), 'MM/dd/yyyy HH:mm')}
							label="Start Time"
						/>
					</fieldset>
				</Col>
				<Col lg={6}>
					<fieldset className="m-1 card">
						<InputFormControl displayMode label="" value={''} />
						<InputFormControl
							label="Calculated Jurisdiction"
							displayMode
							value={String(vcdr.calculatedJurisdiction)}
						/>
						<InputFormControl
							displayMode
							label="Dialed Number"
							value={vcdr.to.raw}
						/>
						<InputFormControl
							displayMode
							label="Term LRN"
							value={vcdr.to.lrn}
						/>
						<TrunkGroupSelect
							displayMode
							clearable={false}
							label="Term Trunk Group"
							autoHeight
							onChange={(termTrunkGroup) => {
								setTrunkGroup2(termTrunkGroup);
							}}
							linkTo={ConnectionRouter.getProfileRoute(vcdr.obCon.connectionId)}
							value={trunkGroup2}
							initialOptionId={vcdr.obCon.trunkGroupId}
						/>
						<InputFormControl
							min={1}
							label="Duration ms"
							type="number"
							required={true}
							displayMode
							value={String(vcdr.ibCon.rawDurationMs / 1000)}
						/>
					</fieldset>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<fieldset className="mt-3 m-1 card">
						<legend className="card-header">
							<h6>Revenue</h6>
						</legend>
						<AccountSelect
							displayMode
							value={account1}
							onChange={(account) => {
								setAccount1(account);
							}}
							initialOptionId={String(vcdr.ibRev.accountId)}
						/>
						<SubscriptionSelect
							displayMode
							value={subscription1}
							onChange={(subscription) => {
								setSubscription1(subscription);
							}}
							initialOptionId={Number(vcdr.ibRev.subscriptionId)}
						/>
						<InputFormControl
							label="Rate"
							displayMode
							value={String(vcdr.ibRev.rate.rate)}
						/>
						<InputFormControl
							label="Rate Key"
							displayMode
							value={String(vcdr.ibRev.rate.rateKey)}
						/>
						<InputFormControl
							label="Reject Code"
							displayMode
							value={rejectCodeToLabel(vcdr.ibRev.rate.rejectCode)}
						/>
						<InputFormControl
							label="Billing Number Used"
							displayMode
							value={String(vcdr.ibRev.rate.billingNumberUsed)}
						/>
					</fieldset>
				</Col>
				<Col lg={6}>
					<fieldset className="mt-3 m-1 card">
						<legend className="card-header">
							<h6>Cost</h6>
						</legend>
						<AccountSelect
							displayMode
							value={account2}
							onChange={(account) => {
								setAccount2(account);
							}}
							initialOptionId={String(vcdr.ibCost.accountId)}
						/>
						<SubscriptionSelect
							displayMode
							value={subscription2}
							onChange={(subscription) => {
								setSubscription2(subscription);
							}}
							initialOptionId={Number(vcdr.ibCost.subscriptionId)}
						/>
						<InputFormControl
							label="Rate"
							displayMode
							value={String(vcdr.ibCost.rate.rate)}
						/>
						<InputFormControl
							label="Rate Key"
							displayMode
							value={String(vcdr.ibCost.rate.rateKey)}
						/>
						<InputFormControl
							label="Reject Code"
							displayMode
							value={rejectCodeToLabel(vcdr.ibCost.rate.rejectCode)}
						/>
						<InputFormControl
							label="Billing Number Used"
							displayMode
							value={String(vcdr.ibCost.rate.billingNumberUsed)}
						/>
					</fieldset>
				</Col>
			</Row>
			<Row>
				<Col>
					<hr />
					<InputFormControl
						label="Total Cost"
						displayMode
						value={String(vcdr.totalCost)}
					/>
					<InputFormControl
						label="Total Revenue"
						displayMode
						value={String(vcdr.totalRevenue)}
					/>
					<InputFormControl
						label="Total Margin"
						displayMode
						value={String(vcdr.totalMargin)}
					/>
				</Col>
			</Row>
			<Row className="mt-4">
				<Col>
					<InputFormControl
						label="Total Fraud Score"
						displayMode
						value={String(Number(vcdr.fraud.fraudScore))}
					/>
					<InputFormControl
						label="Total Robo Score"
						displayMode
						value={String(Number(vcdr.fraud.roboScore))}
					/>
					<InputFormControl
						label="Attestation"
						displayMode
						value={vcdr.fraud.attestation}
					/>
				</Col>
			</Row>
		</RtUiForm>
	);
};

RatingSimulatorSummary.displayName = 'RatingSimulatorSummary';
