import { noop } from 'lodash-es';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import {
	$GuardianAlertUpdateRequest,
	AlertInterval,
	AlertTimeRange,
	GuardianAlertCreateRequest,
	GuardianAlertProfileResponse,
	GuardianAlertTypeIndexResponse,
	GuardianAlertUpdateRequest,
	HavingFilterOperator
} from 'RtModels';
import { ParametersFilteringControlList } from 'RtUi/app/rtGuardian/AlertRules/lib/components/ParametersFilteringControlList';
import { AlertTypeSelect } from 'RtUi/app/rtGuardian/AlertRules/lib/forms/AlertTypeSelect';
import { createOptionsFromList } from 'RtUi/app/rtGuardian/AlertRules/lib/forms/ParametersFilteringControl';
import { TriggerFilteringControl } from 'RtUi/app/rtGuardian/AlertRules/lib/forms/TriggerFilteringControl';
import { AlertRulesResource } from 'RtUi/app/rtGuardian/AlertRules/lib/resources/AlertRulesResource';
import { useGetGuardianAlertRuleType } from 'RtUi/app/rtGuardian/AlertRules/lib/services';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { RtxForm } from 'RtUi/components/rtx/form';
import {
	IDefaultSelectOption,
	SelectFormControl
} from 'RtUi/components/ui/SelectFormControl';
import { RtError } from 'RtUi/utils/errors/RtError';

interface IAlertRulesEditorProps {
	createMode?: boolean;
	editMode?: GuardianAlertProfileResponse;
	onUpdate?: (newProfile: GuardianAlertProfileResponse) => void;
	onCreate?: (newProfile: GuardianAlertProfileResponse) => void;
}

const operatorValues = [
	{
		value: HavingFilterOperator.Greater,
		label: 'Is Above'
	},
	{
		value: HavingFilterOperator.Lesser,
		label: 'Is Below'
	}
];

export const AlertRulesEditor = ({
	createMode,
	editMode,
	onCreate = noop,
	onUpdate = noop
}: IAlertRulesEditorProps) => {
	const [displayMode, setDisplayMode] = useState<boolean>(!createMode);
	const { data: alertRuleType } = useGetGuardianAlertRuleType(
		editMode?.alertTypeId ?? 0,
		editMode !== undefined
	);
	const [error, setError] = useState<RtError>();
	const [alertType, setAlertType] = useState<GuardianAlertTypeIndexResponse>();
	// aka Data Rollup
	const [allowedDataColumns, setAllowedDataColumns] = useState<string[]>([]);
	// aka Criteria
	const [allowedDataFilters, setAllowedDataFilters] = useState<string[]>([]);
	// aka Trigger on
	const [allowedHavingFields, setAllowedHavingFields] = useState<string[]>([]);
	const [selectedDataColumns, setSelectedDataColumns] =
		useState<IDefaultSelectOption<string>>();
	const [selectedAlertRuleOperator, setSelectedAlertRuleOperator] =
		useState<IDefaultSelectOption<HavingFilterOperator>>();

	const onAlertTypeChange = (
		selectedAlertType: GuardianAlertTypeIndexResponse
	) => {
		setAlertType(selectedAlertType);
		setAllowedDataColumns(selectedAlertType.allowedDataColumns ?? []);
		setAllowedDataFilters(selectedAlertType.allowedDataFilters ?? []);
		setAllowedHavingFields(selectedAlertType.allowedHavingFields ?? []);
		setSelectedDataColumns(undefined);
	};

	useEffect(() => {
		if (alertRuleType) {
			onAlertTypeChange(alertRuleType);
		}
	}, [alertRuleType]);

	const onSubmit = async (currentProfile: GuardianAlertProfileResponse) => {
		setError(undefined);
		const alertRulesResource = new AlertRulesResource();
		let newProfile: GuardianAlertProfileResponse | null = null;

		try {
			if (editMode) {
				const { guardianAlertId } = editMode;
				newProfile = await alertRulesResource.update(
					guardianAlertId,
					currentProfile as GuardianAlertUpdateRequest
				);
			} else if (createMode) {
				newProfile = await alertRulesResource.create(
					currentProfile as GuardianAlertCreateRequest
				);
			}

			setDisplayMode(true);
		} catch (error) {
			setError(undefined);
			console.error('Error while submitting the form', error);
		}

		if (newProfile) {
			const updateHandler = editMode ? onUpdate : onCreate;

			updateHandler(newProfile);
		}
	};

	return (
		<RtxForm<GuardianAlertProfileResponse>
			createMode={createMode}
			defaultValues={editMode}
			error={
				error && {
					name: 'root',
					error: {
						message: error.message
					}
				}
			}
			onSubmit={onSubmit}
			displayMode={displayMode}
			onCancel={() => setDisplayMode(true)}
			onEdit={() => setDisplayMode((curr) => !curr)}
		>
			{({ control, setValue }) => {
				return (
					<>
						<Row>
							<Col lg>
								<Controller
									control={control}
									name="summary"
									render={({ field: { onChange, value } }) => (
										<InputFormControl
											label="Summary"
											required={
												$GuardianAlertUpdateRequest.properties.summary
													.isRequired
											}
											displayMode={displayMode}
											value={value}
											onChange={onChange}
										/>
									)}
								/>
							</Col>
							<Col lg></Col>
						</Row>
						<Card body className="bg-light form-group">
							<Row className="row-sm">
								<Col lg={5}>
									<Controller
										control={control}
										name="alertTypeId"
										render={({ field: { onChange, value } }) => (
											<AlertTypeSelect<false>
												initialOptionId={String(value)}
												onChange={(alertType) => {
													onAlertTypeChange(alertType);
													onChange(alertType ? alertType.alertTypeId : 0);
												}}
												value={alertType}
												displayMode={displayMode}
												clearable={false}
											/>
										)}
									/>
								</Col>
								<Col lg={3}>
									<Controller
										control={control}
										name="rules"
										render={({ field: { onChange, value } }) => (
											<SelectFormControl<
												IDefaultSelectOption<HavingFilterOperator>,
												'value'
											>
												required
												label=""
												labelKey="label"
												valueKey="value"
												options={operatorValues}
												value={selectedAlertRuleOperator}
												onChange={(selectedAlertRuleOperator) => {
													const currentValue = value && value[0];
													setSelectedAlertRuleOperator(
														selectedAlertRuleOperator
													);
													onChange([
														{
															...currentValue,
															operator: selectedAlertRuleOperator.value
														}
													]);
												}}
												initialOptionId={value && value[0] && value[0].operator}
												displayMode={displayMode}
												isClearable={false}
											/>
										)}
									/>
								</Col>
								<Col lg={4}>
									<Controller
										control={control}
										name="rules"
										render={({ field: { onChange, value } }) => (
											<InputFormControl
												label=""
												value={value && value[0] && value[0].value}
												disabled={displayMode}
												onChange={(newValue) => {
													const currentValue = value && value[0];
													onChange([
														{
															...currentValue,
															value: newValue
														}
													]);
												}}
												displayMode={displayMode}
											/>
										)}
									/>
								</Col>
							</Row>
							<Controller
								control={control}
								name="filters"
								render={({ field: { onChange, value } }) => (
									<ParametersFilteringControlList
										alertRules={value}
										allowedDataFilters={allowedDataFilters}
										displayMode={!Boolean(alertType) || displayMode}
										onChange={onChange}
									/>
								)}
							/>
							<Controller
								control={control}
								name="having"
								render={({ field: { onChange, value } }) => {
									const selectedOperator =
										value &&
										value[0] &&
										operatorValues.find(
											(operator) => operator.value === value[0].operator
										);
									return (
										<TriggerFilteringControl
											dataFilters={allowedHavingFields}
											selectedHavingField={
												value &&
												value[0] && {
													label: value[0].column,
													value: value[0].column
												}
											}
											selectedOperator={selectedOperator}
											filterValue={value && value[0] && value[0].value}
											onChange={(fieldName, fieldValues) => {
												const currentValue = (value && value[0]) ?? {};
												const newValue = {
													...currentValue
												};

												newValue[fieldName] = fieldValues;
												onChange([newValue]);
											}}
											isDisabled={!Boolean(alertType) || displayMode}
										/>
									);
								}}
							/>
							<Controller
								control={control}
								name="dataColumns"
								render={({ field: { onChange, value } }) => (
									<SelectFormControl<
										IDefaultSelectOption<string>,
										'value',
										false,
										true
									>
										isClearable
										label="Data Rollup"
										labelKey="label"
										valueKey="value"
										options={createOptionsFromList(allowedDataColumns)}
										value={selectedDataColumns}
										onChange={(selectedValues) => {
											setSelectedDataColumns(selectedValues);
											onChange([selectedValues?.value]);
										}}
										initialOptionId={value ? value[0] : undefined}
										displayMode={displayMode}
										isDisabled={allowedDataColumns.length === 0}
										required
									/>
								)}
							/>
						</Card>
						<Card body className="bg-light form-group mt-2">
							<Row>
								<Col md={6}>
									<Controller
										control={control}
										name="timeRange"
										render={({ field: { onChange, value } }) => (
											<RadioFormControl<AlertTimeRange>
												required
												displayMode={displayMode}
												label="Time Range"
												onChange={(timeRange) => {
													let runIntervalQty = 15;
													let runIntervalType: AlertInterval =
														AlertInterval.Minutes;

													if (timeRange === AlertTimeRange.OneHour) {
														runIntervalQty = 1;
														runIntervalType = AlertInterval.Hours;
													} else if (
														timeRange === AlertTimeRange.TwentyFourHour
													) {
														runIntervalQty = 24;
														runIntervalType = AlertInterval.Hours;
													}

													setValue('runIntervalQty', runIntervalQty);
													setValue('runIntervalType', runIntervalType);
													onChange(timeRange);
												}}
												value={value}
												options={[
													{
														value: AlertTimeRange.FifteenMins,
														label: '15 min'
													},
													{ value: AlertTimeRange.OneHour, label: '1h' },
													{ value: AlertTimeRange.TwentyFourHour, label: '24h' }
												]}
											/>
										)}
									/>
								</Col>
								{displayMode && (
									<>
										<Col>
											<Controller
												control={control}
												name="runIntervalQty"
												render={({ field: { value } }) => (
													<InputFormControl
														label="Run Interval"
														displayMode={displayMode}
														value={String(value)}
													/>
												)}
											/>
										</Col>
										<Col>
											<Controller
												control={control}
												name="runIntervalType"
												render={({ field: { value } }) => (
													<RadioFormControl<AlertInterval>
														required
														displayMode
														label=""
														value={value ?? ''}
														options={[
															{
																value: AlertInterval.Minutes,
																label: 'Minutes'
															},
															{ value: AlertInterval.Hours, label: 'Hours' }
														]}
													/>
												)}
											/>
										</Col>
									</>
								)}
							</Row>
						</Card>
						<Row>
							<Col lg={6}>
								<Controller
									control={control}
									name="isActive"
									render={({ field: { onChange, value } }) => (
										<IsActiveRadioFormControl
											label="Active"
											hideBothOption
											displayMode={displayMode}
											onChange={onChange}
											value={value}
										/>
									)}
								/>
							</Col>
						</Row>
					</>
				);
			}}
		</RtxForm>
	);
};
