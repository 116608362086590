import { useMemo } from 'react';
import { ReleaseCodeIndexResponse } from 'RtModels';
import { useGetReleaseCodes } from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxReleaseCodeSelectProps<
	K extends keyof ReleaseCodeIndexResponse,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		ReleaseCodeIndexResponse,
		K,
		IsMulti,
		IsClearable
	> {
	valueKey?: K;
	allowedReleaseCodeIds?: number[];
}

export const RtxReleaseCodeSelect = <
	K extends keyof ReleaseCodeIndexResponse,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	allowedReleaseCodeIds,
	valueKey,
	label = 'Release Code',
	...props
}: IRtxReleaseCodeSelectProps<K, IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetReleaseCodes();
	const options = useMemo(() => {
		if (!allowedReleaseCodeIds) {
			return data;
		}

		return data.filter((releaseCode) =>
			allowedReleaseCodeIds.includes(releaseCode.releaseCodeId)
		);
	}, [data, allowedReleaseCodeIds]);

	return (
		<RtxSelectInput<ReleaseCodeIndexResponse, K, IsMulti, IsClearable>
			label={label}
			valueKey={valueKey ?? ('releaseCodeId' as K)}
			labelKey="label"
			options={options}
			isLoading={isLoading}
			{...props}
		/>
	);
};
