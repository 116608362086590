/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $JobStepResourceExtended = {
    properties: {
        jobStepResourceId: {
            type: 'string',
            isRequired: true,
        },
        jobStepId: {
            type: 'string',
            isRequired: true,
        },
        resourceId: {
            type: 'number',
            isRequired: true,
        },
        resourceConfigurationId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        resourceConfiguration: {
            properties: {
            },
            isRequired: true,
            isNullable: true,
        },
        parameters: {
            properties: {
            },
            isRequired: true,
            isNullable: true,
        },
        jobStatusId: {
            type: 'number',
            isRequired: true,
        },
        totalQty: {
            type: 'number',
            isRequired: true,
        },
        pendingQty: {
            type: 'number',
            isRequired: true,
        },
        warningQty: {
            type: 'number',
            isRequired: true,
        },
        errorQty: {
            type: 'number',
            isRequired: true,
        },
        resEstQty: {
            type: 'number',
            isRequired: true,
        },
        resReqQty: {
            type: 'number',
            isRequired: true,
        },
        resResQty: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        scheduledTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        startedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        completedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        notificationTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        requeueId: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        isManageable: {
            type: 'boolean',
            isRequired: true,
        },
        configurationLabel: {
            type: 'string',
            isRequired: true,
        },
    },
};