import { ConnectionIndexResponse, ConnectionIndexRequest } from 'RtModels';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { TrunkGroupResource } from 'RtUi/app/rtVue/Reconciliation/lib/resources/TrunkGroupResource';
import { isEqual } from 'lodash-es';

interface ITrunkGroupSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<ConnectionIndexResponse, isMulti> {
	params?: ConnectionIndexRequest;
}

export class TrunkGroupSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	ConnectionIndexResponse,
	isMulti,
	ITrunkGroupSelectProps<isMulti>
> {
	public resourceClass = TrunkGroupResource;
	public state: ISelectFormControlState<ConnectionIndexResponse> = {
		formLabel: 'Trunk Group',
		valueKey: 'trunkGroupId',
		labelKey: 'trunkGroupId'
	};

	public componentDidMount() {
		this.setGetAllParams(this.props.params);

		super.componentDidMount();
	}

	public async componentDidUpdate(prevProps: ITrunkGroupSelectProps<isMulti>) {
		if (!isEqual(prevProps.params, this.props.params)) {
			this.setGetAllParams(this.props.params);
			this.reloadOptionsOnFocus();
		}

		super.componentDidUpdate(prevProps);
	}

	public optionRenderer = (record: ConnectionIndexResponse) => {
		return (
			<section>
				<span className="d-inline-block me-2">
					{record.trunkGroupId ?? record.summary}
				</span>
			</section>
		);
	};
}
