/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RespOrgIndexResponse = {
    properties: {
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        entityId: {
            type: 'string',
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
        },
        isManaged: {
            type: 'number',
        },
        assigned: {
            type: 'number',
            isRequired: true,
        },
        disconnected: {
            type: 'number',
            isRequired: true,
        },
        pending: {
            type: 'number',
            isRequired: true,
        },
        reserved: {
            type: 'number',
            isRequired: true,
        },
        transitional: {
            type: 'number',
            isRequired: true,
        },
        unavailable: {
            type: 'number',
            isRequired: true,
        },
        working: {
            type: 'number',
            isRequired: true,
        },
        total: {
            type: 'number',
            isRequired: true,
        },
        primaryName: {
            type: 'string',
            isRequired: true,
        },
        primaryEmail: {
            type: 'string',
            isRequired: true,
        },
        primaryPhone: {
            type: 'string',
            isRequired: true,
        },
        primaryFax: {
            type: 'string',
            isRequired: true,
        },
        changeName: {
            type: 'string',
            isRequired: true,
        },
        changeEmail: {
            type: 'string',
            isRequired: true,
        },
        changePhone: {
            type: 'string',
            isRequired: true,
        },
        changeFax: {
            type: 'string',
            isRequired: true,
        },
    },
};