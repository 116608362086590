import { RespOrgIndexResponse } from 'RtModels';
import { useGetRespOrgs } from 'RtUi/app/rt800/RespOrgs/lib/services';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxRespOrgSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		RespOrgIndexResponse,
		'respOrgId',
		IsMulti,
		IsClearable
	> {
	allowAll?: boolean;
	isActive?: boolean;
	entityId?: string;
}

export const RtxRespOrgSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	displayMode,
	label = 'RespOrg',
	allowAll,
	isActive,
	entityId,
	...props
}: IRtxRespOrgSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetRespOrgs({
		...(isActive !== undefined && { isActive: Number(isActive) }),
		...(!allowAll && { isManaged: 1 }),
		entityId
	});

	const optionRenderer = (respOrg: RespOrgIndexResponse) => (
		<span>
			{respOrg.respOrgId} ({respOrg.summary})
		</span>
	);

	return (
		<RtxSelectInput<RespOrgIndexResponse, 'respOrgId', IsMulti, IsClearable>
			label={label}
			valueKey="respOrgId"
			labelKey="label"
			options={data}
			displayMode={displayMode}
			isLoading={isLoading}
			formatOptionLabel={optionRenderer}
			{...props}
		/>
	);
};
