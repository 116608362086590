import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	InvoiceCycleGroup,
	InvoiceCycleGroupIndexRequest,
	InvoiceIndexRequest,
	InvoiceIndexResponse,
	InvoiceSubscription,
	InvoiceSubscriptionIndexUrlRequest,
	InvoiceSubscriptionProfileResponse,
	InvoiceSubscriptionProfileUrlRequest
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchInvoices = async (
	queryParams?: InvoiceIndexRequest
): Promise<FullResponse<InvoiceIndexResponse[]>> => {
	return handleGetRequest(RtxSipApiRoutes.Invoices.Index, {
		queryParams,
		includeFullResponse: true
	});
};

export const useGetInvoices = (queryParams?: InvoiceIndexRequest) => {
	return useQuery<FullResponse<InvoiceIndexResponse[]>, Error>(
		[`getInvoices`, queryParams],
		() => fetchInvoices(queryParams),
		{ enabled: true }
	);
};

const fetchInvoiceSubscriptions = async (
	urlParams?: InvoiceSubscriptionIndexUrlRequest
): Promise<FullResponse<InvoiceSubscription[]>> => {
	return handleGetRequest(RtxSipApiRoutes.InvoiceSubscriptions.Index, {
		urlParams,
		includeFullResponse: true
	});
};

export const useGetInvoiceSubscriptions = (
	urlParams?: InvoiceSubscriptionIndexUrlRequest
) => {
	return useQuery<FullResponse<InvoiceSubscription[]>, Error>(
		[`getInvoiceSubscriptions`, urlParams],
		() => fetchInvoiceSubscriptions(urlParams),
		{ enabled: true }
	);
};

const fetchInvoiceSubscriptionDetails = async (
	urlParams?: InvoiceSubscriptionProfileUrlRequest
): Promise<FullResponse<InvoiceSubscriptionProfileResponse[]>> => {
	return handleGetRequest(RtxSipApiRoutes.InvoiceSubscriptions.Profile, {
		urlParams,
		includeFullResponse: true
	});
};

export const useGetInvoiceSubscriptionDetails = (
	urlParams?: InvoiceSubscriptionProfileUrlRequest
) => {
	return useQuery<FullResponse<InvoiceSubscriptionProfileResponse[]>, Error>(
		[`getInvoiceSubscriptionDetails`, urlParams],
		() => fetchInvoiceSubscriptionDetails(urlParams),
		{ enabled: true }
	);
};

const fetchInvoiceCycleGroups = async <
	IncludeFullResponse extends boolean = false
>(
	queryParams?: InvoiceCycleGroupIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<InvoiceCycleGroup[], IncludeFullResponse>> => {
	return handleGetRequest<InvoiceCycleGroup[], IncludeFullResponse>(
		RtxSipApiRoutes.InvoiceCycleGroups.Index,
		{
			queryParams,
			includeFullResponse
		}
	);
};

export const useGetInvoiceCycleGroups = <
	IncludeFullResponse extends boolean = false
>(
	params?: InvoiceCycleGroupIndexRequest,
	includeFullResponse?: IncludeFullResponse
) => {
	return useQuery<
		RequestResponse<InvoiceCycleGroup[], IncludeFullResponse>,
		Error
	>(['getInvoiceCycleGroups', params, includeFullResponse], () =>
		fetchInvoiceCycleGroups(params, includeFullResponse)
	);
};
