/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SubscriptionNanpUpdateRequest = {
    properties: {
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        useNpdi: {
            type: 'number',
        },
        npdiFormatMode: {
            type: 'number',
        },
        usaTfAniJurisdictionId: {
            type: 'number',
        },
        usaTfAniResultId: {
            type: 'number',
            isNullable: true,
        },
        extendedNanpAniJurisdictionId: {
            type: 'number',
        },
        extendedNanpAniResultId: {
            type: 'number',
            isNullable: true,
        },
        idddAniJurisdictionId: {
            type: 'number',
        },
        idddAniResultId: {
            type: 'number',
            isNullable: true,
        },
        unknownAniJurisdictionId: {
            type: 'number',
        },
        unknownAniResultId: {
            type: 'number',
            isNullable: true,
        },
        unknownAniUseMode: {
            type: 'number',
        },
        defaultAni: {
            type: 'string',
            isNullable: true,
        },
        defaultAniUseMode: {
            type: 'number',
        },
        callingPartyCheckNanp: {
            type: 'number',
        },
        callingPartyPreferNanp: {
            type: 'number',
        },
        dialedNumberCheckNanp: {
            type: 'number',
        },
        dialedNumberPreferNanp: {
            type: 'number',
        },
    },
};