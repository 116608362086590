import { differenceInMilliseconds } from 'date-fns';
import { FC, useState } from 'react';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useAsync, useToggle } from 'react-use';
import { Permissions } from 'RtExports/routes';
import {
	ServerProfileResponse,
	SipTraceProfileResponseMessages
} from 'RtModels';
import { useGetServers } from 'RtUi/app/rtAdmin/Servers/lib/service';
import { MessageModal } from 'RtUi/app/rtSip/SipTrace/lib/components/MessageModal';
import { SipTraceChart } from 'RtUi/app/rtSip/SipTrace/lib/components/SipTraceChart';
import { SipTraceResource } from 'RtUi/app/rtSip/SipTrace/lib/resource/SipTraceResource';
import { useUuid } from 'RtUi/components/hooks/useUuid';
import { Loading } from 'RtUi/components/ui/Loading';
import { UserActions } from 'RtUi/state/actions/user';

interface ISipTraceViewProps {
	resource: SipTraceResource;
	callId: string;
}

function formatData(
	messages: SipTraceProfileResponseMessages[] = [],
	servers?: ServerProfileResponse[]
) {
	// Create a mermaid diagram
	const data = `
		sequenceDiagram
		${messages
			.sort((a, b) => a.micro_ts - b.micro_ts)
			.map((set) => {
				const src = `${set.srcIp} (${set.srcPort})`;
				const dest = `${set.dstIp} (${set.dstPort})`;
				let serverParticipant = '';
				let destParticipant = '';

				const srcTime = new Date(messages[0].micro_ts);
				const tgtTime = new Date(set.micro_ts);

				const ms = `${differenceInMilliseconds(tgtTime, srcTime)} ms`;
				const method = set.method ? `${set.method} (${ms})` : 'HEP';

				if (servers) {
					const srcServer = servers.find((s) =>
						[s.privateIpAddress, s.publicIpAddress].includes(set.srcIp)
					);
					const destServer = servers.find((s) =>
						[s.privateIpAddress, s.publicIpAddress].includes(set.dstIp)
					);

					serverParticipant = srcServer
						? `participant ${src} as ${src}<br/>${srcServer.hostName}`
						: '';
					destParticipant = destServer
						? `participant ${dest} as ${dest}<br/>${destServer.hostName}`
						: '';
				}

				return `
					rect ${set.id}
					${serverParticipant}
					${destParticipant}
					${src}->>${dest}: ${method}
					end
				`;
			})
			.join(' \r\n')}
	`;

	return data;
}

export const SipTraceView: FC<React.PropsWithChildren<ISipTraceViewProps>> = ({
	resource,
	callId
}) => {
	const profile = useAsync(async () => {
		const prf = await resource.getProfile(callId);
		return prf;
	}, [callId]);

	const [uniqueId] = useUuid();
	const isRtxAdmin = UserActions.has(Permissions.RtxAdministrator);
	const { data: servers, isLoading } = useGetServers(false, isRtxAdmin);

	const [isDownloadingPcap, toggleIsDownloadingPcap] = useToggle(false);
	const [isDownloadingText, toggleIsDownloadingText] = useToggle(false);
	const [openedModals, setOpenedModals] = useState<string[]>([]);

	if (profile.loading || isLoading) {
		return <Loading />;
	}

	if (!profile.value?.messages || profile.value?.messages.length === 0) {
		return <h5>Unable to find SIP Trace data</h5>;
	}

	return (
		<div className="d-flex flex-column">
			<div className="d-flex justify-content-center">
				<ButtonGroup>
					<Button
						disabled={isDownloadingPcap}
						variant="outline-primary"
						onClick={async () => {
							toggleIsDownloadingPcap();
							await resource.downloadPcap(callId);
							toggleIsDownloadingPcap();
						}}
					>
						PCAP{' '}
						{isDownloadingPcap ? (
							<i className="fas fa-fw fa-cog fa-spin" />
						) : (
							<i className="fas fa-fw fa-download" />
						)}
					</Button>
					<Button
						disabled={isDownloadingText}
						variant="outline-primary"
						onClick={async () => {
							toggleIsDownloadingText();
							resource.downloadText(callId);
							toggleIsDownloadingText();
						}}
					>
						TXT{' '}
						{isDownloadingText ? (
							<i className="fas fa-fw fa-cog fa-spin" />
						) : (
							<i className="fas fa-fw fa-download" />
						)}
					</Button>
					{openedModals.length > 1 && (
						<Button
							disabled={isDownloadingPcap}
							variant="outline-primary"
							onClick={() => {
								setOpenedModals([]);
							}}
						>
							Close All Pop-ups <i className="fas fa-fw fa-close" />
						</Button>
					)}
				</ButtonGroup>
			</div>
			<SipTraceChart
				id={uniqueId}
				className="flex-grow-1"
				content={formatData(profile.value.messages, servers)}
				onClick={(messageId: string) => {
					if (profile.value.messages.some((m) => m.id === Number(messageId))) {
						setOpenedModals([...new Set([...openedModals, messageId])]);
					}
				}}
			/>
			{profile.value?.messages.map((m) => {
				return ReactDOM.createPortal(
					<MessageModal
						key={m.id}
						title={`${m.method} (${m.srcIp}:${m.srcPort} > ${m.dstIp}:${m.dstPort})`}
						show={openedModals.includes(String(m.id))}
						close={() => {
							setOpenedModals(
								openedModals.filter((opened) => opened !== String(m.id))
							);
						}}
						content={
							<Form className="h-100">
								<Form.Control
									style={{ resize: 'none' }}
									className="sip-trace-content h-100 form-control-plaintext"
									readOnly
									as="textarea"
									value={m.raw}
								/>
							</Form>
						}
					/>,
					document.getElementById('root')!
				);
			})}
		</div>
	);
};
