import { useCallback } from 'react';
import { CountryIndexResponse } from 'RtModels';
import { useGetCountries } from 'RtUi/app/user/service';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxCountrySelectProps<
	K extends keyof CountryIndexResponse,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		CountryIndexResponse,
		K,
		IsMulti,
		IsClearable
	> {
	valueKey?: K;
}

export const RtxCountrySelect = <
	K extends keyof CountryIndexResponse,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	label = 'Country',
	valueKey,
	...props
}: IRtxCountrySelectProps<K, IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetCountries();

	const formatOptionLabel = useCallback((country: CountryIndexResponse) => {
		return (
			<span>
				{country.label} ({country.iso3166Alpha3})
			</span>
		);
	}, []);

	return (
		<RtxSelectInput<CountryIndexResponse, K, IsMulti, IsClearable>
			label={label}
			valueKey={valueKey ?? ('iso3166Alpha3' as K)}
			labelKey="label"
			options={data}
			formatOptionLabel={formatOptionLabel}
			isLoading={isLoading}
			{...props}
		/>
	);
};
