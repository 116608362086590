import { AsrIndexResponseData } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { AverageCallDurationDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AverageCallDurationDataGridColumn';
import { CallDateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDateDataGridColumn';
import {
	DataGridColumnAlignment,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { FileStreamDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FileStreamDataColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { RateCenterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateCenterDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { CallDurationDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDurationDataGridColumn';
import { sumBy } from 'lodash-es';
import { AnswerRateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AnswerRateDataGridColumn';

export const getAsrColumns = (
	onDrilldown: (
		row: AsrIndexResponseData,
		key: keyof AsrIndexResponseData
	) => void,
	callDateFormat?: string
): Array<DataGridColumn<AsrIndexResponseData>> => [
	FileStreamDataGridColumn({
		accessorKey: 'fileStreamId',
		header: 'File Stream',
		hiddenIfEmpty: true
	}),
	CallDateDataGridColumn({
		accessorKey: 'callDate',
		header: 'Call Date',
		hiddenIfEmpty: true,
		format: callDateFormat
	}),
	AccountDataGridColumn({
		accessorKey: 'customerAccountId',
		header: 'Customer Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'customerSubscriptionId',
		header: 'Customer Subscription',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'customerExternalAccountId',
		header: 'Customer External Account Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'customerExternalSubAccountId',
		header: 'Customer External Sub Account Id',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'vendorAccountId',
		header: 'Vendor Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'vendorSubscriptionId',
		header: 'Vendor Subscription',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'egressConnectionId',
		header: 'Egress Connection Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressTrunkGroup',
		header: 'Egress Trunk Group',
		hiddenIfEmpty: true,
		onDrillDown: (e, row) => {
			e.preventDefault();
			onDrilldown(row, 'egressTrunkGroup');
		}
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressTrunkGroupLabel',
		header: 'Egress Trunk Group Label',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'ingressConnectionId',
		header: 'Ingress Connection Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressTrunkGroup',
		header: 'Ingress Trunk Group',
		hiddenIfEmpty: true,
		onDrillDown: (e, row) => {
			e.preventDefault();
			onDrilldown(row, 'ingressTrunkGroup');
		}
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressTrunkGroupLabel',
		header: 'Ingress Trunk Group Label',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'ingressReleaseCode',
		header: 'Ingress Release Code',
		hiddenIfEmpty: true
	}),
	RateCenterDataGridColumn({
		accessorKey: 'ingressRateCenterId',
		header: 'Ingress Rate Center',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressCountry',
		header: 'Ingress Country',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressCountry',
		header: 'Egress Country',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'egressReleaseCode',
		header: 'Egress Release Code',
		hiddenIfEmpty: true
	}),
	RateCenterDataGridColumn({
		accessorKey: 'egressRateCenterId',
		header: 'Egress Rate Center',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'dialCode',
		header: 'Dial Code',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ocn',
		header: 'OCN',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ocnType',
		header: 'OCN Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'company',
		header: 'Common Company',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'attestation',
		header: 'Attestation',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'attempts',
		header: 'Attempts',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'connects',
		header: 'Connects',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	CallDurationDataGridColumn({
		accessorKey: 'duration',
		header: 'Duration',
		showFooter: true
	}),
	AnswerRateDataGridColumn({
		accessorKey: 'asr',
		header: 'ASR',
		hiddenIfEmpty: true,
		showFooter: true,
		align: DataGridColumnAlignment.RIGHT,
		attempts: (rows) => sumBy(rows, 'attempts'),
		connections: (rows) => sumBy(rows, 'connects')
	}),
	AverageCallDurationDataGridColumn({
		accessorKey: 'aloc',
		header: 'ALOC',
		hiddenIfEmpty: true,
		showFooter: true,
		align: DataGridColumnAlignment.RIGHT,
		connections: (rows) => sumBy(rows, 'connects'),
		duration: (rows) => sumBy(rows, 'duration')
	}),
	IntegerDataGridColumn({
		accessorKey: 'sub6',
		header: 'Sub 6',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'sub18',
		header: 'Sub 18',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'sub30',
		header: 'Sub 30',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'jitter',
		header: 'Jitter',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'pdd',
		header: 'Pdd',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'mos',
		header: 'Mos',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'ingressPdd',
		header: 'Ingress Pdd',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'ingressJitter',
		header: 'Ingress Jitter',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'ingressMos',
		header: 'Ingress Mos',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'ingressPacketsSent',
		header: 'Ingress Packets Sent',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'ingressPacketsReceived',
		header: 'Ingress Packets Received',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'egressPdd',
		header: 'Egress Pdd',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'egressJitter',
		header: 'Egress Jitter',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'egressMos',
		header: 'Egress Mos',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'egressPacketsSent',
		header: 'Egress Packets Sent',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'egressPacketsReceived',
		header: 'Egress Packets Received',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'fas',
		header: 'Fas',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'isDestSame',
		header: 'Is Dest Same',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'isDestDetailSame',
		header: 'Is Dest Detail Same',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'toIsDnc',
		header: 'To Is Dnc',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'frIsValid',
		header: 'Fr Is Valid',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'frIsDno',
		header: 'Fr Is Dno',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'countUniqueAnis',
		header: 'Count Unique Anis',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'roboScore',
		header: 'Avg Robo Score',
		hiddenIfEmpty: true,
		onDrillDown: (e, row) => {
			e.preventDefault();
			onDrilldown(row, 'roboScore');
		}
	}),
	IntegerDataGridColumn({
		accessorKey: 'fraudScore',
		header: 'Fraud Score',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'countTokens',
		header: 'Count Tokens',
		hiddenIfEmpty: true
	})
];
