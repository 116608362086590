import {
	PickerValidDate,
	TimePicker,
	TimePickerProps
} from '@mui/x-date-pickers';

type RtxTimeInputProps = Omit<
	TimePickerProps<PickerValidDate, false>,
	'slotProps'
> & {
	required?: boolean;
};

export const RtxTimeInput = ({
	required = false,
	...props
}: RtxTimeInputProps) => {
	return (
		<TimePicker
			slotProps={{
				textField: {
					sx: {
						'width': '100%',
						'.MuiInputBase-root': {
							'marginTop': '0',
							'padding': '10px 14px 5px',
							'.MuiInputBase-input': {
								padding: 0
							}
						}
					},
					InputLabelProps: {
						required,
						shrink: true
					}
				}
			}}
			{...props}
		/>
	);
};
