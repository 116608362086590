/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SubscriptionProfileResponse = {
    properties: {
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        isLocked: {
            type: 'number',
        },
        isSystem: {
            type: 'number',
        },
        blockTypeId: {
            type: 'number',
            isNullable: true,
        },
        accountId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        serviceId: {
            type: 'number',
            isRequired: true,
        },
        routePlanId: {
            type: 'number',
            isNullable: true,
        },
        marginPlanId: {
            type: 'number',
            isNullable: true,
        },
        ratePlanId: {
            type: 'number',
            isNullable: true,
        },
        defaultSubscriptionId: {
            type: 'number',
            isNullable: true,
        },
        defaultScenarioId: {
            type: 'number',
            isNullable: true,
        },
        translatedNumberStd: {
            type: 'string',
            isNullable: true,
        },
        trackingGroupId: {
            type: 'number',
            isNullable: true,
        },
        defaultIsdnReleaseId: {
            type: 'number',
            isNullable: true,
        },
        defaultIsdnReleaseMs: {
            type: 'number',
            isNullable: true,
        },
        directSubscriptionId: {
            type: 'number',
            isNullable: true,
        },
        sourceSubscriptionId: {
            type: 'number',
            isNullable: true,
        },
        hasServiceNumbers: {
            type: 'number',
        },
        startDate: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endDate: {
            type: 'Date',
            format: 'date-time',
        },
        invoiceCycleGroupId: {
            type: 'number',
            isNullable: true,
        },
        timezoneId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        billDays: {
            type: 'number',
        },
        dueDays: {
            type: 'number',
        },
        disputeDays: {
            type: 'number',
        },
        disputeResponseDays: {
            type: 'number',
        },
        rateKeyDeleteDays: {
            type: 'number',
        },
        rateKeyMatchIncreaseDays: {
            type: 'number',
        },
        rateKeyExpandIncreaseDays: {
            type: 'number',
        },
        useNpdi: {
            type: 'number',
        },
        npdiFormatMode: {
            type: 'number',
        },
        defaultAni: {
            type: 'string',
            isNullable: true,
        },
        defaultAniUseMode: {
            type: 'number',
        },
        callingPartyCheckNanp: {
            type: 'number',
        },
        callingPartyPreferNanp: {
            type: 'number',
        },
        dialedNumberCheckNanp: {
            type: 'number',
        },
        dialedNumberPreferNanp: {
            type: 'number',
        },
        note: {
            type: 'string',
            isNullable: true,
        },
        lastRateLoadTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        defaultInterstateRate: {
            type: 'number',
            isRequired: true,
        },
        defaultIntrastateRate: {
            type: 'number',
            isRequired: true,
        },
        defaultIndeterminateRate: {
            type: 'number',
            isRequired: true,
        },
        defaultIncrementSeconds: {
            type: 'number',
            isRequired: true,
        },
        defaultMinimumSeconds: {
            type: 'number',
            isRequired: true,
        },
        ratingParametersPriority: {
            type: 'Array',
            isNullable: true,
        },
        externalAccountId: {
            type: 'string',
            isNullable: true,
        },
        externalSubAccountId: {
            type: 'string',
            isNullable: true,
        },
        defaultRoundingPrecision: {
            type: 'number',
        },
        attestation: {
            type: 'Attestation',
            isNullable: true,
        },
        sipConnectionsCount: {
            type: 'number',
            isRequired: true,
        },
        ratePlansCount: {
            type: 'number',
            isRequired: true,
        },
    },
};