import { SipAgentStatisticIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { FqdnDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FqdnDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PortDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PortDataGridColumn';

export const getSipAgentStatsColumns = (
	showServerInfo: boolean,
	showDailyTotals: boolean
): Array<DataGridColumn<SipAgentStatisticIndexResponse>> => [
	DateDataGridColumn({
		accessorKey: 'timeslot',
		header: 'Timeslot',
		format: !showDailyTotals ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD',
		exportExcelDateFormat: !showDailyTotals
			? 'yyyy-mm-dd hh:mm:ss AM/PM'
			: 'yyyy-mm-dd'
	}),
	FqdnDataGridColumn({
		accessorKey: 'agtFqdn',
		header: 'SIP Agent Fqdn',
		isInvisible: !showServerInfo
	}),
	DefaultDataGridColumn({
		accessorKey: 'agtLabel',
		header: 'SIP Agent Label',
		isInvisible: !showServerInfo
	}),
	PortDataGridColumn({
		accessorKey: 'agtPort',
		header: 'SIP Agent Port',
		isInvisible: !showServerInfo
	}),
	DefaultDataGridColumn({
		accessorKey: 'remoteLabel',
		header: 'Remote Label',
		isInvisible: !showServerInfo
	}),
	FqdnDataGridColumn({
		accessorKey: 'remoteFqdn',
		header: 'Remote Fqdn',
		isInvisible: !showServerInfo
	}),
	PortDataGridColumn({
		accessorKey: 'remotePort',
		header: 'Remote Port',
		isInvisible: !showServerInfo
	}),
	IntegerDataGridColumn({
		accessorKey: 'reqInvQty',
		header: 'Req Inv Quantity'
	}),
	IntegerDataGridColumn({
		accessorKey: 'reqOptQty',
		header: 'Req Opt Quantity'
	}),
	IntegerDataGridColumn({
		accessorKey: 'reqRegQty',
		header: 'Req Reg Quantity'
	}),
	IntegerDataGridColumn({
		accessorKey: 'res1xxQty',
		header: 'Req 1xx Quantity'
	}),
	IntegerDataGridColumn({
		accessorKey: 'res2xxQty',
		header: 'Req 2xx Quantity'
	}),
	IntegerDataGridColumn({
		accessorKey: 'res3xxQty',
		header: 'Req 3xx Quantity'
	}),
	IntegerDataGridColumn({
		accessorKey: 'res4xxQty',
		header: 'Req 4xx Quantity'
	}),
	IntegerDataGridColumn({
		accessorKey: 'res5xxQty',
		header: 'Req 5xx Quantity'
	}),
	IntegerDataGridColumn({
		accessorKey: 'res6xxQty',
		header: 'Req 6xxß Quantity'
	})
];
