/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LumenDidCreateRequest = {
    properties: {
        resourceId: {
            type: 'number',
            isRequired: true,
        },
        state: {
            type: 'string',
            isRequired: true,
        },
        rateCenter: {
            type: 'string',
            isRequired: true,
        },
        tnQty: {
            type: 'number',
            isRequired: true,
        },
        sequential: {
            type: 'boolean',
        },
        externalOrderId: {
            type: 'string',
        },
        subscriber: {
            type: 'LumenDidSubscriber',
            isRequired: true,
        },
        blockCustomerName: {
            type: 'boolean',
            isRequired: true,
        },
    },
};