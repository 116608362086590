import { useQuery } from 'react-query';
import { RtCommonApiRoutes } from 'RtExports/routes';
import { TimezoneIndexResponse } from 'RtModels';
import {
	RequestResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchTimezones = async <IncludeFullResponse extends boolean = false>(
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<TimezoneIndexResponse[], IncludeFullResponse>> => {
	return handleGetRequest<TimezoneIndexResponse[], IncludeFullResponse>(
		RtCommonApiRoutes.Timezone.Index,
		{
			includeFullResponse
		}
	);
};

export const useGetTimezones = <IncludeFullResponse extends boolean = false>(
	includeFullResponse?: IncludeFullResponse
) => {
	return useQuery<
		RequestResponse<TimezoneIndexResponse[], IncludeFullResponse>,
		Error
	>(['getTimezones', includeFullResponse], () =>
		fetchTimezones(includeFullResponse)
	);
};
