import { CallTypeIndexResponse } from 'RtModels';
import { useGetCallTypes } from 'RtUi/app/rtVue/Connections/lib/service';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxCallTypeSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		CallTypeIndexResponse,
		'callTypeId',
		IsMulti,
		IsClearable
	> {}

export const RtxCallTypeSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	label = 'Call Type',
	...props
}: IRtxCallTypeSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data, isLoading } = useGetCallTypes();

	return (
		<RtxSelectInput<CallTypeIndexResponse, 'callTypeId', IsMulti, IsClearable>
			label={label}
			valueKey="callTypeId"
			maxOptions={600}
			labelKey="callTypeId"
			options={data?.data ?? []}
			isLoading={isLoading}
			{...props}
		/>
	);
};
