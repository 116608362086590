import {
	DataGridColumn,
	FooterProps
} from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { isNil } from 'lodash-es';

type AnswerRateFooterConfiguration<T> =
	| {
			showFooter?: false;
			attempts?: (rows: T[]) => number;
			connections?: (rows: T[]) => number;
	  }
	| {
			showFooter: true;
			attempts: (rows: T[]) => number;
			connections: (rows: T[]) => number;
	  };

type AnswerRateDataGridColumnConfiguration<T> =
	AnswerRateFooterConfiguration<T> &
		DataGridColumnConfiguration<T> & {
			decimals?: number;
		};

export const AnswerRateDataGridColumn = <T = any,>({
	decimals = 2,
	attempts,
	connections,
	showFooter = false,
	...config
}: AnswerRateDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: any, isExporting?: boolean) => {
		if (isNil(value)) {
			return '';
		}

		if (isExporting) {
			return Number(value);
		}
		return `${Number(value).toFixed(decimals)} %`;
	};

	const getFooter = ({ table }: FooterProps<T>) => {
		if (!(attempts && connections)) {
			return '';
		}

		const rows = table
			.getPrePaginationRowModel()
			.rows.map((row) => row.original as T);

		const dividend = connections(rows);
		const divisor = attempts(rows);

		const total = (dividend / divisor) * 100;

		if (isNil(total)) {
			return '';
		}

		return <>{`${total.toFixed(decimals)} %`}</>;
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: any) => getValue(value, true),
		getValue: ({ cell }) => getValue(cell.getValue()),
		...(showFooter && {
			getFooter: (props) => getFooter(props)
		})
	});
};
