import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash-es';

import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { GuardianUserQueryIndexResponse } from 'RtModels';
import { CannedReportRouter } from 'RtUi/app/rtVue/CannedReports/CannedReports.router';
import CannedReportsAccordion from 'RtUi/app/rtVue/CannedReports/components/ReportsAccordions';
import { useGetCannedReports } from 'RtUi/app/rtVue/CannedReports/service';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import {
	ExistingReports,
	ReportBaseUrls
} from 'RtUi/app/rtVue/lib/constants/Constants';
import { Loading } from 'RtUi/components/ui/Loading';

export const CannedReportIndexContainer = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Standard: { header: 'My custom reports', isDefault: true },
		Global: { header: 'Standard reports', isDefault: true },
		Shared: { header: 'Shared with me', isDefault: true }
	});

	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);

	const handleRedirectToReportPage = (
		query: GuardianUserQueryIndexResponse
	) => {
		localStorage.setItem('applyUserQueries', JSON.stringify(query));
		const baseUrl =
			ReportBaseUrls[query.reportId as ExistingReports].getIndexRoute();

		navigate(baseUrl);
	};

	const [sharedReports, setSharedReports] = useState<
		GuardianUserQueryIndexResponse[]
	>([]);

	const [globalReports, setGlobalReports] = useState<
		GuardianUserQueryIndexResponse[]
	>([]);
	const [userReports, setUserReports] = useState<
		GuardianUserQueryIndexResponse[]
	>([]);
	const [reportsForDropdown, setReportsForDropdown] = useState<
		GuardianUserQueryIndexResponse[]
	>([]);

	const [favoritesReports, setFavoritesReports] = useState<
		GuardianUserQueryIndexResponse[]
	>([]);

	const {
		data: rawCannedReportsData,
		isLoading,
		refetch
	} = useGetCannedReports();

	const loadingState = isLoading || loading;

	useEffect(() => {
		if (!isEmpty(rawCannedReportsData)) {
			const shared = rawCannedReportsData?.filter(
				(data) => data.queryType === 'shared'
			);
			const global = rawCannedReportsData?.filter(
				(data) => data.queryType === 'global'
			);
			const user = rawCannedReportsData?.filter(
				(data) => data.queryType === 'user'
			);
			const favorite = rawCannedReportsData?.filter((data) => data.isFavorite);
			const selectFormat = rawCannedReportsData?.map((reports) => {
				return { ...reports, value: reports.reportId, label: reports.label };
			});
			setReportsForDropdown(selectFormat as any);
			setSharedReports(shared as any);
			setGlobalReports(global as any);
			setUserReports(user as any);
			setFavoritesReports(favorite as any);
		}
	}, [rawCannedReportsData]);

	const handleUpdateReportFavoriteStatus = async (
		report: GuardianUserQueryIndexResponse
	) => {
		setLoading(true);
		try {
			const rtVueHttp = new RtVueHttp();
			const payload = {
				...report,
				isFavorite: Number(!(report as any).isFavorite)
			};
			if (!(payload as any).queryDescription) {
				(payload as any).queryDescription = 'placeholder';
			}

			await rtVueHttp.updateGuardianUserQueryProfile(
				report.guardianUserQueryId,
				payload
			);
			refetch();
		} catch (e) {
		} finally {
			setLoading(false);
		}
	};

	const HeaderDropdown = () => {
		return (
			<div
				style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
			>
				<Select
					styles={{
						container: (baseStyles) => ({
							...baseStyles,
							width: '50%'
						})
					}}
					onChange={(query) =>
						handleRedirectToReportPage(query as GuardianUserQueryIndexResponse)
					}
					options={reportsForDropdown}
					isLoading={isLoading}
				/>
			</div>
		);
	};

	return (
		<TabbedLayout
			router={CannedReportRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
			appendToHeader={HeaderDropdown()}
		>
			{isLoading ? (
				<Loading />
			) : (
				<>
					<TabbedLayoutTab {...tabs.Standard}>
						<>
							<CannedReportsAccordion
								favoriteReports={favoritesReports}
								handleRedirectToReportPage={handleRedirectToReportPage}
								reports={userReports}
								handleUpdateReportFavoriteStatus={
									handleUpdateReportFavoriteStatus
								}
								loading={loadingState}
							/>
						</>
					</TabbedLayoutTab>
					<TabbedLayoutTab {...tabs.Global}>
						<>
							<CannedReportsAccordion
								handleRedirectToReportPage={handleRedirectToReportPage}
								reports={globalReports}
								handleUpdateReportFavoriteStatus={
									handleUpdateReportFavoriteStatus
								}
								loading={loadingState}
							/>
						</>
					</TabbedLayoutTab>
					<TabbedLayoutTab {...tabs.Shared}>
						<>
							<CannedReportsAccordion
								handleRedirectToReportPage={handleRedirectToReportPage}
								reports={sharedReports}
								handleUpdateReportFavoriteStatus={
									handleUpdateReportFavoriteStatus
								}
								loading={loadingState}
							/>
						</>
					</TabbedLayoutTab>
				</>
			)}
		</TabbedLayout>
	);
};

CannedReportIndexContainer.displayName = 'CannedReportIndexContainer';

CannedReportRouter.setIndexRtUiFunctionalComponent(CannedReportIndexContainer, {
	orderPriority: 1
});
