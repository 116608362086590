import { TimezoneIndexResponse } from 'RtModels';
import { useGetTimezones } from 'RtUi/app/rtCommon/Timezones/lib/service';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxTimezoneSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		TimezoneIndexResponse,
		'timezoneId',
		IsMulti,
		IsClearable
	> {}

export const RtxTimezoneSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	label = 'Time Zone',
	...props
}: IRtxTimezoneSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetTimezones();

	return (
		<RtxSelectInput<TimezoneIndexResponse, 'timezoneId', IsMulti, IsClearable>
			label={label}
			valueKey="timezoneId"
			maxOptions={600}
			labelKey="label"
			options={data}
			isLoading={isLoading}
			{...props}
		/>
	);
};
