import { noop } from 'lodash-es';
import { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import {
	CallflowService,
	ReleaseCodeIndexResponse,
	SubscriptionNanpProfileResponse,
	SubscriptionNanpUpdateRequest
} from 'RtModels';
import { SubscriptionResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionsResource';
import { useGetSubscriptionNanp } from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import { RtxForm } from 'RtUi/components/rtx/form';
import { RtxRadioInput } from 'RtUi/components/rtx/inputs/Radio/RtxRadioInput';
import { RtxReleaseCodeSelect } from 'RtUi/components/rtx/inputs/Select/instances/ReleaseCode/RtxReleaseCodeSelect';
import { RtxTextInput } from 'RtUi/components/rtx/inputs/Text/RtxTextInput';
import { Loading } from 'RtUi/components/ui/Loading';
import { WarningAlert } from 'RtUi/components/ui/WarningAlert';
import { RtError } from 'RtUi/utils/errors/RtError';

interface ISubscriptionNanpEditFormProps {
	subscriptionId: number;
	serviceId: number;
	onSuccess?: (profile: SubscriptionNanpProfileResponse) => void;
}

export const SubscriptionNanpEditForm = ({
	subscriptionId,
	serviceId,
	onSuccess = noop
}: ISubscriptionNanpEditFormProps) => {
	const [displayMode, setDisplayMode] = useState<boolean>(true);
	const [error, setError] = useState<RtError>();
	const [usaTfAniResultId, setUsaTfAniResultId] =
		useState<ReleaseCodeIndexResponse>();
	const [extendedNanpAniResultId, setExtendedNanpAniResultId] =
		useState<ReleaseCodeIndexResponse>();
	const [idddAniResultId, setIdddAniResultId] =
		useState<ReleaseCodeIndexResponse>();
	const [unknownAniResultId, setUnknownAniResultId] =
		useState<ReleaseCodeIndexResponse>();
	const { data, isFetching: isLoading } = useGetSubscriptionNanp({
		subscriptionId
	});

	if (isLoading) {
		return <Loading />;
	}

	if (!data) {
		return <p>Not found!</p>;
	}

	const onSubmit = async (data: SubscriptionNanpUpdateRequest) => {
		const rs = new SubscriptionResource();
		setError(undefined);

		try {
			const profile = await rs.updateSubscriptionNanp(subscriptionId, data);
			setDisplayMode(true);
			onSuccess(profile);
		} catch (e: any) {
			setError(new RtError(e));
		}
	};

	const shouldShowCallPartyMatching = () => {
		return [
			CallflowService.InboundVendorOrigination,
			CallflowService.InboundCustomerIdentification,
			CallflowService.OutboundCustomerIdentification,
			CallflowService.OutboundVendorTermination
		].includes(serviceId);
	};

	const shouldShowDialedNumberMatching = () => {
		return [
			CallflowService.InboundVendorOrigination,
			CallflowService.InboundCustomerIdentification,
			CallflowService.OutboundCustomerIdentification,
			CallflowService.OutboundVendorTermination
		].includes(serviceId);
	};

	return (
		<>
			<WarningAlert
				text="NANP Settings are for calculating jurisdiction only. They do NOT
						change the ANI written to the CDR and they do NOT change the ANI
						sent to the vendor. If the Default ANI is used, this will override
						the Jurisdiction settings for Unknown/Invalid ANI."
			/>
			<RtxForm<SubscriptionNanpUpdateRequest>
				displayMode={displayMode}
				defaultValues={{
					...data
				}}
				onSubmit={onSubmit}
				error={
					error && {
						name: 'root',
						error: {
							message: error.message
						}
					}
				}
				onEdit={() => setDisplayMode(false)}
				onCancel={() => setDisplayMode(true)}
			>
				{({ control }) => (
					<Row>
						<Col md={6}>
							<Card>
								<Card.Header>
									<h6>Jurisdiction Settings</h6>
								</Card.Header>
								<Card.Body>
									<Controller
										control={control}
										name="usaTfAniJurisdictionId"
										render={({ field: { value, onChange } }) => (
											<RtxRadioInput<number>
												label="USA Toll-Free ANI Rate"
												className="mb-3"
												displayMode={displayMode}
												onChange={(usaTfAniJurisdictionId) =>
													onChange(Number(usaTfAniJurisdictionId))
												}
												value={value}
												options={[
													{ value: 101, label: 'Interstate' },
													{ value: 102, label: 'Intrastate' }
												]}
											/>
										)}
									/>
									<Controller
										control={control}
										name="usaTfAniResultId"
										render={({ field: { value, onChange } }) => (
											<RtxReleaseCodeSelect
												label="USA Toll-Free ANI Reject"
												className="mb-3"
												displayMode={displayMode}
												value={usaTfAniResultId}
												allowedReleaseCodeIds={[1, 17, 18, 28, 34]}
												isClearable={true}
												initialOptionId={value ?? undefined}
												onChange={(usaTfAniResultId) => {
													setUsaTfAniResultId(usaTfAniResultId);
													onChange(usaTfAniResultId?.releaseCodeId);
												}}
											/>
										)}
									/>
									<Controller
										control={control}
										name="extendedNanpAniJurisdictionId"
										render={({ field: { value, onChange } }) => (
											<RtxRadioInput<number>
												label="Extended NANP ANI Rate"
												className="mb-3"
												displayMode={displayMode}
												onChange={onChange}
												value={value}
												options={[
													{ value: 101, label: 'Interstate' },
													{ value: 102, label: 'Intrastate' }
												]}
											/>
										)}
									/>
									<Controller
										control={control}
										name="extendedNanpAniResultId"
										render={({ field: { value, onChange } }) => (
											<RtxReleaseCodeSelect
												className="mb-3"
												label="Extended NANP ANI Reject"
												displayMode={displayMode}
												allowedReleaseCodeIds={[1, 17, 18, 28, 34]}
												isClearable={true}
												value={extendedNanpAniResultId}
												initialOptionId={value ?? undefined}
												onChange={(extendedNanpAniResultId) => {
													setExtendedNanpAniResultId(extendedNanpAniResultId);
													onChange(extendedNanpAniResultId?.releaseCodeId);
												}}
											/>
										)}
									/>
									<Controller
										control={control}
										name="idddAniJurisdictionId"
										render={({ field: { value, onChange } }) => (
											<RtxRadioInput<number>
												label="International ANI Rate"
												className="mb-3"
												displayMode={displayMode}
												onChange={onChange}
												value={value}
												options={[
													{ value: 101, label: 'Interstate' },
													{ value: 102, label: 'Intrastate' }
												]}
											/>
										)}
									/>
									<Controller
										control={control}
										name="idddAniResultId"
										render={({ field: { value, onChange } }) => (
											<RtxReleaseCodeSelect
												label="International ANI Reject"
												className="mb-3"
												displayMode={displayMode}
												value={idddAniResultId}
												allowedReleaseCodeIds={[1, 17, 18, 28, 34]}
												isClearable={true}
												initialOptionId={value ?? undefined}
												onChange={(idddAniResultId) => {
													setIdddAniResultId(idddAniResultId);
													onChange(idddAniResultId?.releaseCodeId);
												}}
											/>
										)}
									/>
									<Controller
										control={control}
										name="unknownAniJurisdictionId"
										render={({ field: { value, onChange } }) => (
											<RtxRadioInput<number>
												label="Unknown / Invalid ANI Rate"
												className="mb-3"
												displayMode={displayMode}
												onChange={onChange}
												value={value}
												options={[
													{ value: 101, label: 'Interstate' },
													{ value: 102, label: 'Intrastate' }
												]}
											/>
										)}
									/>
									<Controller
										control={control}
										name="unknownAniResultId"
										render={({ field: { value, onChange } }) => (
											<RtxReleaseCodeSelect
												label="Unknown / Invalid ANI Reject"
												displayMode={displayMode}
												value={unknownAniResultId}
												allowedReleaseCodeIds={[1, 17, 18, 28, 34]}
												isClearable={true}
												initialOptionId={value ?? undefined}
												onChange={(unknownAniResultId) => {
													setUnknownAniResultId(unknownAniResultId);
													onChange(unknownAniResultId?.releaseCodeId);
												}}
											/>
										)}
									/>
								</Card.Body>
							</Card>
						</Col>
						<Col md={6}>
							<Card>
								<Card.Header>
									<h6>Default ANI / Override Settings</h6>
								</Card.Header>
								<Card.Body>
									<Controller
										control={control}
										name="defaultAni"
										render={({ field: { value, onChange } }) => (
											<RtxTextInput
												label="Default ANI"
												className="mb-3"
												displayMode={displayMode}
												value={value}
												onChange={onChange}
											/>
										)}
									/>
									<Controller
										control={control}
										name="defaultAniUseMode"
										render={({ field: { value, onChange } }) => (
											<RtxRadioInput<number>
												label="Use Default ANI"
												displayMode={displayMode}
												onChange={onChange}
												value={value}
												options={[
													{ value: 1, label: 'Always' },
													{ value: 2, label: 'Never' },
													{ value: 3, label: 'When Ingress ANI is invalid' }
												]}
											/>
										)}
									/>
								</Card.Body>
							</Card>
							<Card hidden={!shouldShowDialedNumberMatching()} className="mt-3">
								<Card.Header>
									<h6>Dialed Number Matching</h6>
								</Card.Header>
								<Card.Body>
									<Controller
										control={control}
										name="dialedNumberCheckNanp"
										render={({ field: { value, onChange } }) => (
											<RtxRadioInput<number>
												label="Check for NANP Match"
												displayMode={displayMode}
												className="mb-3"
												onChange={onChange}
												value={value}
												options={[
													{ value: 0, label: 'No' },
													{ value: 1, label: 'Yes' }
												]}
											/>
										)}
									/>
									<Controller
										control={control}
										name="dialedNumberPreferNanp"
										render={({ field: { value, onChange } }) => (
											<RtxRadioInput<number>
												label="Prefer NANP Match"
												displayMode={displayMode}
												onChange={onChange}
												value={value}
												options={[
													{ value: 0, label: 'No' },
													{ value: 1, label: 'Yes' }
												]}
											/>
										)}
									/>
								</Card.Body>
							</Card>
							<Card hidden={!shouldShowCallPartyMatching()} className="mt-3">
								<Card.Header>
									<h6>Calling Party Matching</h6>
								</Card.Header>
								<Card.Body>
									<Controller
										control={control}
										name="callingPartyCheckNanp"
										render={({ field: { value, onChange } }) => (
											<RtxRadioInput<number>
												label="Check for NANP Match"
												displayMode={displayMode}
												className="mb-3"
												onChange={onChange}
												value={value}
												options={[
													{ value: 0, label: 'No' },
													{ value: 1, label: 'Yes' },
													{ value: 2, label: 'When Dialed Number is NANP' }
												]}
											/>
										)}
									/>
									<Controller
										control={control}
										name="callingPartyPreferNanp"
										render={({ field: { value, onChange } }) => (
											<RtxRadioInput<number>
												label="Prefer NANP Match"
												displayMode={displayMode}
												onChange={onChange}
												value={value}
												options={[
													{ value: 0, label: 'No' },
													{ value: 1, label: 'Yes' },
													{ value: 2, label: 'When Dialed Number is NANP' }
												]}
											/>
										)}
									/>
								</Card.Body>
							</Card>
							<Card className="mt-3">
								<Card.Header>
									<h6>NPDI Settings</h6>
								</Card.Header>
								<Card.Body>
									<Controller
										control={control}
										name="useNpdi"
										render={({ field: { value, onChange } }) => (
											<RtxRadioInput<number>
												label="Send NPDI"
												displayMode={displayMode}
												className="mb-3"
												onChange={onChange}
												value={value}
												options={[
													{ value: 0, label: 'No' },
													{ value: 1, label: 'Yes' }
												]}
											/>
										)}
									/>
									<Controller
										control={control}
										name="npdiFormatMode"
										render={({ field: { value, onChange } }) => (
											<RtxRadioInput<number>
												label="NPDI Format"
												displayMode={displayMode}
												onChange={onChange}
												value={value}
												options={[
													{
														value: 1,
														label: 'Standard: RFC4694 (SIP URI User Part)'
													},
													{ value: 2, label: 'Non-Standard (SIP Parameter)' }
												]}
											/>
										)}
									/>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				)}
			</RtxForm>
		</>
	);
};
