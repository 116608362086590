import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	ScenarioIndexRequest,
	ScenarioIndexResponse,
	ScenarioProfileRequest,
	ScenarioProfileResponse
} from 'RtModels';
import {
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchSipScenario = async (
	urlParams: ScenarioProfileRequest
): Promise<ScenarioProfileResponse | undefined> => {
	return handleGetRequest(RtxSipApiRoutes.Scenarios.Profile, {
		urlParams
	});
};

export const useGetSipScenario = (scenarioId: number) => {
	return useQuery<ScenarioProfileResponse | undefined, Error>(
		[`getSipScenario`, scenarioId],
		() => fetchSipScenario({ scenarioId }),
		{ enabled: Boolean(scenarioId) }
	);
};

const fetchSipScenarios = async <IncludeFullResponse extends boolean = false>(
	queryParams?: ScenarioIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<ScenarioIndexResponse[], IncludeFullResponse>> => {
	return handleGetRequest<ScenarioIndexResponse[], IncludeFullResponse>(
		RtxSipApiRoutes.Scenarios.Index,
		{
			queryParams,
			includeFullResponse
		}
	);
};

export const useGetSipScenarios = <IncludeFullResponse extends boolean = false>(
	params?: ScenarioIndexRequest,
	includeFullResponse?: IncludeFullResponse
) => {
	return useQuery<
		RequestResponse<ScenarioIndexResponse[], IncludeFullResponse>,
		Error
	>(['getSipScenarios', params, includeFullResponse], () =>
		fetchSipScenarios(params, includeFullResponse)
	);
};
