import { NumbersLiteIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { AverageCallDurationDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AverageCallDurationDataGridColumn';
import { CallDateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDateDataGridColumn';
import {
	DataGridColumnAlignment,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { FileStreamDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FileStreamDataColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PhoneNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PhoneNumberDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { RateCenterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateCenterDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { CallDurationDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDurationDataGridColumn';
import { sumBy } from 'lodash-es';
import { AnswerRateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AnswerRateDataGridColumn';

export const getNumbersLiteColumns = (
	callDateFormat?: string
): Array<DataGridColumn<NumbersLiteIndexResponse>> => [
	FileStreamDataGridColumn({
		accessorKey: 'fileStreamId',
		header: 'File stream',
		hiddenIfEmpty: true
	}),
	CallDateDataGridColumn({
		accessorKey: 'callDate',
		header: 'Call Date',
		format: callDateFormat
	}),
	PhoneNumberDataGridColumn({
		accessorKey: 'number',
		header: 'Number',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'customerAccountId',
		header: 'Customer Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'customerSubscriptionId',
		header: 'Customer Subscription',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'customerExternalAccountId',
		header: 'Customer External Account',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'customerExternalSubAccountId',
		header: 'Customer External Sub Account',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'vendorAccountId',
		header: 'Vendor Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'vendorSubscriptionId',
		header: 'Vendor Subscription',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'vendorExternalAccountId',
		header: 'Vendor External Account',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'vendorExternalSubAccountId',
		header: 'Vendor External Sub Account',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressTrunkGroupId',
		header: 'Ingress Trunk Group',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressTrunkGroupLabel',
		header: 'Ingress Trunk Group Label',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressTrunkGroupId',
		header: 'Egress Trunk Group',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressTrunkGroupLabel',
		header: 'Egress Trunk Group Label',
		hiddenIfEmpty: true
	}),
	RateCenterDataGridColumn({
		accessorKey: 'ingressRateCenterId',
		header: 'Ingress Rate Center',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressOcn',
		header: 'Ingress Ocn',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressOcnType',
		header: 'Ingress Ocn Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressState',
		header: 'Ingress State',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressCountry',
		header: 'Ingress Country',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'attestation',
		header: 'Attestation',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'cic',
		header: 'CIC',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'attempts',
		header: 'Attempts',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'connects',
		header: 'Connects',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	CallDurationDataGridColumn({
		accessorKey: 'duration',
		header: 'Duration',
		showFooter: true
	}),
	AnswerRateDataGridColumn({
		accessorKey: 'asr',
		header: 'ASR',
		hiddenIfEmpty: true,
		showFooter: true,
		align: DataGridColumnAlignment.RIGHT,
		attempts: (rows) => sumBy(rows, 'attempts'),
		connections: (rows) => sumBy(rows, 'connects')
	}),
	AverageCallDurationDataGridColumn({
		accessorKey: 'aloc',
		header: 'ALOC',
		hiddenIfEmpty: true,
		showFooter: true,
		align: DataGridColumnAlignment.RIGHT,
		connections: (rows) => sumBy(rows, 'connects'),
		duration: (rows) => sumBy(rows, 'duration')
	}),
	IntegerDataGridColumn({
		accessorKey: 'sub6',
		header: 'Sub 6',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'sub18',
		header: 'Sub 18',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'sub30',
		header: 'Sub 30',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'pdd',
		header: 'Pdd',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'revenue',
		header: 'Revenue',
		decimals: 4,
		hiddenIfEmpty: true,
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'cost',
		header: 'Cost',
		decimals: 4,
		hiddenIfEmpty: true,
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'margin',
		header: 'Margin',
		decimals: 4,
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'negCallCount',
		header: 'Neg Call Count',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'negDuration',
		header: 'Neg Duration',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'negRevenue',
		header: 'Neg Revenue',
		decimals: 4,
		hiddenIfEmpty: true,
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'negCost',
		header: 'Neg Cost',
		decimals: 4,
		hiddenIfEmpty: true,
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'negMargin',
		header: 'Neg Margin',
		decimals: 4,
		hiddenIfEmpty: true,
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'roboScore',
		header: 'Robo Score',
		hiddenIfEmpty: true
	})
];
