import { RocDocumentIndexResponse } from 'RtModels';
import { RocRequestResource } from 'RtUi/app/rt800/RespOrgChange/lib/resources/RocRequestResource';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRocRequestDocumentsColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/rocRequests';
import { FileInputButton } from 'RtUi/components/ui/FileInputButton';
import { toast } from 'react-toastify';
import { useCallback, useMemo, useState } from 'react';

interface IRocRequestDocumentsGridProps {
	rocTxnId: string;
	data: RocDocumentIndexResponse[];
	onUpload: () => void;
}

export const RocRequestDocumentsGrid = ({
	data,
	rocTxnId,
	onUpload
}: IRocRequestDocumentsGridProps) => {
	const [uploadingDocument, setUploadingDocument] = useState<boolean>(false);
	const rocRequestResource = useMemo(() => new RocRequestResource(), []);

	const uploadDocument = async (file: File | null) => {
		setUploadingDocument(true);

		if (file) {
			try {
				await rocRequestResource.createDocument(rocTxnId, file);
				onUpload();
			} catch (e: any) {
				toast.error(`Error on adding document: ${e.message}`);
			} finally {
				setUploadingDocument(false);
			}
		}
	};

	const downloadDocument = useCallback(
		async (doc: RocDocumentIndexResponse) => {
			await rocRequestResource.downloadDocument(doc);
		},
		[rocRequestResource]
	);

	const columns = useMemo(
		() => getRocRequestDocumentsColumns(downloadDocument),
		[downloadDocument]
	);

	return (
		<DataGrid<RocDocumentIndexResponse>
			data={data}
			pageName="rt800_RocRequestDocuments"
			columns={columns}
			disableExport
			headerAction={() => (
				<FileInputButton
					color="success"
					className="me-2"
					accept=".pdf,application/pdf"
					disabled={uploadingDocument}
					onChange={(file) => uploadDocument(file)}
				>
					{uploadingDocument ? (
						<>
							<i className="fas fa-fw fa-cog fa-spin" />
							<span>&nbsp;Adding Document...</span>
						</>
					) : (
						<>
							<i className="fas fa-fw fa-upload" />
							<span>&nbsp;Add Document</span>
						</>
					)}
				</FileInputButton>
			)}
		/>
	);
};
