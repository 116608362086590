import {
	DatePicker,
	DatePickerProps,
	PickerValidDate
} from '@mui/x-date-pickers';

export type RtxDateInputProps = Omit<
	DatePickerProps<PickerValidDate, false>,
	'views' | 'slotProps'
> & {
	displayMode?: boolean;
	required?: boolean;
};

export const RtxDateInput = ({
	required,
	disabled,
	displayMode,
	...props
}: RtxDateInputProps) => {
	return (
		<DatePicker
			views={['year', 'month', 'day']}
			disabled={disabled || displayMode}
			slotProps={{
				textField: {
					required,
					InputProps: {
						...((disabled || displayMode) && { endAdornment: null })
					},
					sx: {
						'width': '100%',
						'.MuiInputBase-root': {
							'marginTop': '0',
							'padding': '10px 14px 5px',
							'.MuiInputBase-input': {
								'padding': 0,
								'&.Mui-disabled': {
									'fontWeight': 550,
									'color': '#9c9c9c',
									'-webkit-text-fill-color': 'unset'
								}
							}
						}
					},
					InputLabelProps: {
						shrink: true
					}
				}
			}}
			{...props}
		/>
	);
};
