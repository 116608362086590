import {
	MgiCprIndexResponse,
	TaskErrorIndexResponse,
	TemplateCprTransferRequest,
	TemplateCprUpdateRequest
} from 'RtModels';
import { BulkCarrierUpdateButton } from 'RtUi/app/rt800/Cprs/lib/components/BulkCarrierUpdateButton';
import { CprEditorActions } from 'RtUi/app/rt800/Cprs/lib/components/CprEditorActions';
import { CprEditorCleanAside } from 'RtUi/app/rt800/Cprs/lib/components/CprEditorCleanAside';
import { ExportCprButton } from 'RtUi/app/rt800/Cprs/lib/components/ExportCprButton';
import { ImportCprBtn } from 'RtUi/app/rt800/Cprs/lib/components/ImportCprBtn';
import { CprProfileContext } from 'RtUi/app/rt800/Cprs/lib/context/CprContext';
import {
	copyCpr,
	deleteCpr,
	disconnectCpr,
	transferCpr,
	updateCpr
} from 'RtUi/app/rt800/Cprs/lib/services';
import * as CprErrorUtils from 'RtUi/app/rt800/Cprs/lib/util/CprErrorUtils';
import { TaskRouter } from 'RtUi/app/rt800/Tasks/Task.router';
import { ITaskProfile } from 'RtUi/app/rt800/Tasks/lib/resources/TaskResource';
import { TaskPollWithErrorsPromiseWrapper } from 'RtUi/app/rt800/Tasks/lib/utils/TaskPollWithErrorsPromiseWrapper';
import { Cpr, CprErrorType } from 'Somos/lib/SomosCpr/RtCprV2';
import { SomosCprFactory } from 'Somos/lib/SomosCpr/SomosCprFactory';
import { differenceBy } from 'lodash-es';
import moment from 'moment';
import { FC, useContext, useEffect, useState } from 'react';
import { Alert, Button, ProgressBar } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { NavLink, useNavigate } from 'react-router-dom';
import { useUpdate } from 'react-use';

interface ICprEditorActionFormProps {
	cpr: Cpr;
	goBackToParentsRouting: () => void;
}

export const CprEditorActionForm: FC<
	React.PropsWithChildren<ICprEditorActionFormProps>
> = ({ cpr, goBackToParentsRouting }) => {
	const {
		id,
		displayMode,
		setDisplayMode,
		isNumberAndHasTemplateAssigned,
		refetchCprs,
		router,
		resetProfile,
		isNumber,
		editMode,
		updateCpr: updateCurrentCpr,
		requestNewProfile,
		checkCadValidity
	} = useContext(CprProfileContext);
	const [isSubmitting, setIsSubmitting] = useState<boolean>();
	const [taskErrors, setTaskErrors] = useState<TaskErrorIndexResponse[]>([]);
	const [submittedTaskProfile, setSubmittedTaskProfile] =
		useState<ITaskProfile>();
	const navigate = useNavigate();
	const updateComponent = useUpdate();
	const { mutateAsync: copyCprReq } = useMutation(copyCpr);
	const { mutateAsync: updateCprReq } = useMutation(updateCpr);
	const { mutateAsync: transferCprReq } = useMutation(transferCpr);
	const { mutateAsync: disconnectCprReq } = useMutation(disconnectCpr);
	const { mutateAsync: deleteCprReq } = useMutation(deleteCpr);

	const criticalErrors = cpr.getErrors(CprErrorType.Critical);
	const isTransferMode = editMode === 'transfer';
	const isDelete = editMode === 'delete';
	const isDisconnect = editMode === 'disconnect';
	let showActionButtons = false;
	let showNormalSubmit = false; // create/copy/edit/transfer
	let showDeleteSubmit = false;
	let showDisconnectSubmit = false;

	if (!isSubmitting) {
		if (isDisconnect) {
			showDisconnectSubmit = true;
		} else if (isDelete) {
			showDeleteSubmit = true;
		} else if (isTransferMode) {
			showNormalSubmit = true;
		} else {
			showActionButtons = displayMode;
			showNormalSubmit = !displayMode;
		}
	}

	useEffect(() => {
		return cpr.onValidate(() => {
			updateComponent();
		});
	});

	const goToNewCpr = (id: string, effectiveTs: string) => {
		const newCprPath = router.getProfileRouteByIdAndEffectiveTs(
			id,
			effectiveTs
		);

		navigate(newCprPath);
	};

	const findNewRoute = (
		targetEffectiveTs: Date | null,
		newRoutes: MgiCprIndexResponse[]
	): Date | null => {
		if (targetEffectiveTs === null) {
			// Try to find next NOW date
			let nextPossibleWindow = moment().round(15, 'minutes', 'floor');
			let tries = 4;

			while (tries-- > 0) {
				for (const newRoute of newRoutes) {
					const newRouteEffectiveMoment = moment(newRoute.effectiveTs);
					const isMatch = newRouteEffectiveMoment.isSame(
						nextPossibleWindow,
						'minute'
					);

					if (isMatch) {
						return newRouteEffectiveMoment.toDate();
					}
				}

				nextPossibleWindow = nextPossibleWindow.clone().add(15, 'minutes');
			}
		} else {
			const targetEffectiveMoment = moment(targetEffectiveTs);

			for (const newRoute of newRoutes) {
				const newRouteEffectiveMoment = moment(newRoute.effectiveTs);
				const isMatch = newRouteEffectiveMoment.isSame(
					targetEffectiveMoment,
					'minute'
				);

				if (isMatch) {
					return newRouteEffectiveMoment.toDate();
				}
			}
		}

		return null;
	};

	const goToEffectiveTsView = (
		effectiveTs: moment.Moment | Date | string,
		carryOverTaskErrors?: TaskErrorIndexResponse[]
	) => {
		const effectiveTsISOStr =
			typeof effectiveTs === 'string' ? effectiveTs : effectiveTs.toISOString();

		if (carryOverTaskErrors) {
			//Save errors for resetComponent()
			/* 			AbstractCprProfileContainer.CarriedOverTaskErrors = [
				{ id, effectiveTsISOStr, carryOverTaskErrors }
			]; */
		}

		goToNewCpr(id, effectiveTsISOStr);
	};

	const submitCpr = async (e?: React.FormEvent<HTMLFormElement>) => {
		e?.preventDefault();

		if (!cpr || !editMode) {
			//TODO
			return;
		}
		const cadValidity = checkCadValidity();

		if (!cadValidity) {
			return;
		}

		setIsSubmitting(true);
		setDisplayMode(true);
		setTaskErrors([]);

		let taskPromise: Promise<ITaskProfile> | null = null;
		let goBackToRoutingAfterSubmit = false;
		let editModeOnCaughtError = false;

		const sourceEffectiveTs = cpr.getSourceEffectiveTs()!;
		const targetEffectiveTs = cpr.getTargetEffectiveTs();
		const interLataCarriers = cpr.getInterLataCarriers();
		const intraLataCarriers = cpr.getIntraLataCarriers();
		const oldCpr = SomosCprFactory.RtCprV2ToRtCprV1(cpr);

		if (editMode === 'copy') {
			if (targetEffectiveTs) {
				oldCpr.effectiveTs = targetEffectiveTs;
			} else {
				delete oldCpr.effectiveTs;
			}

			const req: TemplateCprUpdateRequest = {
				...oldCpr,
				interLataCarriers,
				intraLataCarriers,
				areasOfService: oldCpr.areasOfService ?? []
			};

			editModeOnCaughtError = true;

			taskPromise = copyCprReq({
				id,
				effectiveTs: sourceEffectiveTs,
				req,
				isNumber
			});
		} else if (editMode === 'edit') {
			const req: TemplateCprUpdateRequest = {
				...oldCpr,
				interLataCarriers,
				intraLataCarriers,
				areasOfService: oldCpr.areasOfService ?? []
			};

			editModeOnCaughtError = true;

			taskPromise = updateCprReq({
				id,
				effectiveTs: sourceEffectiveTs,
				req,
				isNumber
			});
		} else if (editMode === 'transfer') {
			const req: TemplateCprTransferRequest = {
				...oldCpr,
				interLataCarriers,
				intraLataCarriers,
				areasOfService: oldCpr.areasOfService ?? []
			};

			if (targetEffectiveTs === null) {
				delete req.effectiveTs;
			} else {
				req.effectiveTs = targetEffectiveTs;
			}

			//Remove CPR from PAD
			if (isNumberAndHasTemplateAssigned) {
				delete req.cpr;

				//@ts-ignore -- TODO
				delete req.interLataCarriers;
				//@ts-ignore -- TODO
				delete req.intraLataCarriers;
				//@ts-ignore -- TODO
				delete req.areasOfService;
			}

			taskPromise = transferCprReq({
				id,
				effectiveTs: sourceEffectiveTs,
				req,
				isNumber
			});
		} else if (editMode === 'disconnect') {
			const targetEffectiveDateModified =
				targetEffectiveTs === null ? undefined : targetEffectiveTs;
			goBackToRoutingAfterSubmit = true;

			taskPromise = disconnectCprReq({
				id,
				sourceEffectiveTs,
				targetEffectiveTs: targetEffectiveDateModified
			});
		} else if (editMode === 'delete') {
			goBackToRoutingAfterSubmit = true;
			taskPromise = deleteCprReq({
				id,
				effectiveTs: cpr.getSourceEffectiveTs()!,
				isNumber
			});
		}

		if (!taskPromise) {
			return;
		}

		try {
			const submittedTaskProfile = await taskPromise;
			setSubmittedTaskProfile(submittedTaskProfile);

			const previousResult = await refetchCprs();
			const previousRoutes = previousResult?.data?.data ?? [];

			const pollPromiseWrapper = new TaskPollWithErrorsPromiseWrapper(
				submittedTaskProfile.taskId,
				// Wait for 5 seconds before polling begins; it will take at least this much time
				5 * 1000
			);
			const taskErrors = await pollPromiseWrapper.getPromise();
			const hasTaskErrors = taskErrors.some(
				(taskError) => taskError.isWarning !== 1
			);
			const isCopyOrTransfer = ['transfer', 'copy'].includes(editMode);
			const currentResult = await refetchCprs();
			const currentRoutes = currentResult?.data?.data ?? [];

			const newRoutes = differenceBy(
				currentRoutes,
				previousRoutes,
				(route) => route.effectiveTs
			);

			if (isCopyOrTransfer) {
				const newRouteEffectiveDate = findNewRoute(
					targetEffectiveTs,
					newRoutes
				);

				if (newRouteEffectiveDate) {
					goToEffectiveTsView(newRouteEffectiveDate, taskErrors);

					return;
				}
			}

			if (hasTaskErrors) {
				if (editMode === 'edit') {
					const newProfile = await requestNewProfile();
					const newApprovalIndicator = newProfile.getApprovalIndicator();
					const newCprStatus = newProfile.getCprStatus();

					cpr.setApprovalIndicator(newApprovalIndicator);
					cpr.setCprStatus(newCprStatus);

					updateCurrentCpr(cpr);
				}

				setTaskErrors(taskErrors);
				setDisplayMode(false);
			}

			if (targetEffectiveTs && isCopyOrTransfer) {
				return goToEffectiveTsView(targetEffectiveTs);
			} else if (goBackToRoutingAfterSubmit) {
				return goBackToParentsRouting();
			}

			if (taskErrors.length > 0) {
				setTaskErrors(taskErrors);
			}
		} catch (error) {
			setDisplayMode(!editModeOnCaughtError);
		} finally {
			setIsSubmitting(false);
		}
	};

	let errorComponent: React.ReactNode = null;

	if (taskErrors.length > 0 || criticalErrors.length > 0) {
		let variant = 'warning-outline';
		const taskErrorsHaveCritical = taskErrors.some(
			(taskError) => taskError.isWarning !== 1
		);

		if (taskErrorsHaveCritical || criticalErrors.length > 0) {
			variant = 'danger-outline';
		}

		errorComponent = (
			<Alert variant={variant} className="mt-3">
				<h6>Some errors have occurred. Fix the following to submit again:</h6>
				<ul className="mb-0">
					{taskErrors.map(({ responseValue }, index) => (
						<li key={index}>{responseValue}</li>
					))}
					{criticalErrors.map((criticalError) => (
						<li
							key={
								criticalError.cprIndex +
								criticalError.message +
								criticalError.cprValue
							}
						>
							{CprErrorUtils.formatErrorMessage(criticalError)}
						</li>
					))}
				</ul>
			</Alert>
		);
	}

	if (showActionButtons) {
		return (
			<>
				<CprEditorActions cpr={cpr} />
				{errorComponent}
			</>
		);
	}

	return (
		<section className="mt-4 mb-2">
			<section>
				{isSubmitting && (
					<ProgressBar
						animated
						now={100}
						variant="info"
						style={{
							height: 39,
							borderRadius: 0,
							textTransform: 'capitalize'
						}}
						label={
							<h5 className="mb-0">
								{submittedTaskProfile ? (
									<span>
										<span>Processing&nbsp;</span>
										<NavLink
											className="text-white"
											style={{
												textDecoration: 'underline'
											}}
											target="_blank"
											rel="noreferrer"
											to={TaskRouter.getProfileRoute(
												submittedTaskProfile.taskId
											)}
										>
											<span>Task</span>
										</NavLink>
										<span>...</span>
									</span>
								) : (
									<span>Submitting {editMode}...</span>
								)}
							</h5>
						}
					/>
				)}

				{showNormalSubmit && (
					<section className="d-flex justify-content-between">
						<section className="d-none d-lg-block">
							<ImportCprBtn
								btnColor="white-alt"
								style={{ minWidth: 100 }}
								className="me-2"
							/>
							<ExportCprButton
								btnColor="white-alt"
								style={{ minWidth: 100 }}
								className="me-2"
							/>
							<BulkCarrierUpdateButton className="me-2" />
						</section>

						<section className="d-flex justify-content-end">
							<Button
								type="button"
								variant="white-alt"
								onClick={() => resetProfile()}
							>
								<span>Cancel</span>
							</Button>
							<CprEditorCleanAside className="ms-2" />
							<Button
								type="button"
								className="ms-2"
								variant="submit"
								disabled={criticalErrors.length > 0}
								onClick={() => submitCpr()}
							>
								<i className="fas fa-fw fa-check-circle" />
								<span style={{ textTransform: 'capitalize' }}>
									&nbsp;Submit {editMode}
								</span>
							</Button>
						</section>
					</section>
				)}

				{showDisconnectSubmit && (
					<section className="d-flex justify-content-end">
						<section className="align-self-center me-3 text-danger">
							By disconnecting {id} all routes and the{' '}
							{!isNumber ? 'template' : 'number'} will be made unusable.
						</section>
						<Button
							type="button"
							variant="white-alt"
							className="ms-2"
							onClick={() => resetProfile()}
						>
							<span>Cancel</span>
						</Button>
						<Button
							type="button"
							variant="danger"
							className="ms-2"
							disabled={criticalErrors.length > 0}
							onClick={() => submitCpr()}
						>
							<i className="fas fa-fw fa-times-circle" />
							<span>&nbsp;Yes, Permanently Disconnect</span>
						</Button>
					</section>
				)}

				{showDeleteSubmit && (
					<section className="d-flex justify-content-end">
						<section className="align-self-center me-3">
							Are you sure you would like to permanently delete this CPR?
						</section>
						<Button
							type="button"
							variant="white-alt"
							className="ms-2"
							onClick={() => resetProfile()}
						>
							<span>Cancel</span>
						</Button>
						<Button
							type="button"
							variant="danger"
							className="ms-2"
							onClick={() => submitCpr()}
							style={{
								textTransform: 'capitalize'
							}}
						>
							<i className="fas fa-fw fa-times-circle" />
							<span>&nbsp;Yes, Permanently Delete</span>
						</Button>
					</section>
				)}
			</section>
			{errorComponent}
		</section>
	);
};
