import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';

type RtxCheckboxInputProps = Omit<CheckboxProps, 'checked'> & {
	label: string;
	value: boolean;
	required?: boolean;
};

export const RtxCheckboxInput = ({
	label,
	value,
	required,
	...props
}: RtxCheckboxInputProps) => {
	return (
		<FormControlLabel
			control={<Checkbox checked={value} {...props} />}
			label={label}
			required={required}
		/>
	);
};
