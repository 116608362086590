import { CallTagMode } from 'RtModels';
import {
	IDefaultSelectOption,
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';
import { useMemo } from 'react';

export interface IRtxTagModeSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		IDefaultSelectOption<CallTagMode>,
		'value',
		IsMulti,
		IsClearable
	> {}

export const RtxTagModeSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	label = 'TagMode',
	...props
}: IRtxTagModeSelectProps<IsMulti, IsClearable>) => {
	const options: Array<IDefaultSelectOption<CallTagMode>> = useMemo(() => {
		return [
			{
				value: CallTagMode.MatchAny,
				label: 'Any may Match'
			},
			{
				value: CallTagMode.MatchAll,
				label: 'All Must Match'
			},
			{
				value: CallTagMode.AllowAny,
				label: 'Filter (Include) Any (Match still Required)'
			},
			{
				value: CallTagMode.BlockAny,
				label: 'Block (Exclude) Any'
			}
		];
	}, []);

	return (
		<RtxSelectInput<
			IDefaultSelectOption<CallTagMode>,
			'value',
			IsMulti,
			IsClearable
		>
			label={label}
			valueKey="value"
			labelKey="label"
			options={options}
			{...props}
		/>
	);
};
