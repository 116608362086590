import { useQuery } from 'react-query';
import { RtVueApiRoutes } from 'RtExports/routes';
import {
	RateCenterFilterIndexResponse,
	RateCenterIndexRequest,
	RateCenterIndexResponse
} from 'RtModels';
import {
	RequestResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchRateCenterFilter = async <
	IncludeFullResponse extends boolean = false
>(
	queryParams?: RateCenterIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<
	RequestResponse<RateCenterFilterIndexResponse, IncludeFullResponse>
> => {
	return handleGetRequest<RateCenterFilterIndexResponse, IncludeFullResponse>(
		RtVueApiRoutes.RateCenterFilter.Index,
		{
			queryParams,
			includeFullResponse
		}
	);
};

export const useGetRateCenters = (params?: RateCenterIndexRequest) => {
	return useQuery<RateCenterIndexResponse[], Error>(
		['getRateCenters', params],
		async () => {
			const response = await fetchRateCenterFilter(params, false);

			return response.data;
		}
	);
};
