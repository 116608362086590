import {
	IRtxSubscriptionSelectProps,
	RtxSubscriptionSelect
} from 'RtUi/components/rtx/inputs/Select/instances/Subscription/RtxSubscriptionSelect';

interface ICustomerOriginationSubscriptionSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends Omit<
		IRtxSubscriptionSelectProps<IsMulti, IsClearable>,
		'serviceIds'
	> {}

export const RtxCustomerOriginationSubscriptionSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>(
	props: ICustomerOriginationSubscriptionSelectProps<IsMulti, IsClearable>
): JSX.Element => {
	const serviceIds = [120];

	return RtxSubscriptionSelect({ ...props, serviceIds });
};
