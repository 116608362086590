/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum Integrations {
    Layered = 1,
    Telnyx = 2,
    Twilio = 3,
    VoipInnovations = 4,
    West = 5,
    ThreeEightTwo = 6,
    Plivo = 7,
    Endstream = 8,
    V1Voip = 9,
    Vitcom = 10,
    Flowroute = 11,
    Blitz = 12,
    TeleApi = 13,
    Vitelity = 14,
    Bandwidth = 15,
    Inteliquent = 16,
    Peerless = 17,
    Thinq = 18,
    Fractel = 19,
    ThreeThree = 20,
    SomosMgi = 21,
    SomosApi = 22,
    Skyetel = 23,
    ROC = 24,
    VoipMs = 25,
    VerazSwitch = 26,
    NetsapiensSwitch = 27,
    Lingo = 28,
    Manual = 29,
    BillingSystem = 30,
    Lumen = 31,
    Verizon = 32,
    LumenApi = 33,
    Call48 = 34,
    Nuso = 35,
    DidWW = 36,
    Voxbone = 37,
    Avoxi = 38,
    TenXPeople = 39,
    TurningPoint = 40,
    Trello = 41,
    Slack = 42,
    AniNetworks = 43,
    QuestBlue = 44,
    RevIo = 45,
    RouteTrustApi = 46,
    LingoOffnet = 47,
    RingSquared = 48,
    BlacklistAlliance = 49,
    MyHealthAngel = 50,
    RouteTrustTagging = 51,
    RouteTrustWorker = 5000,
}