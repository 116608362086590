import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	GatewayIndexRequest,
	GatewayIndexResponse,
	SipConnection,
	SipConnectionIndexRequest
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchSipConnection = async <
	IncludeFullResponse extends boolean = false
>(
	queryParams?: SipConnectionIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<SipConnection[], IncludeFullResponse>> => {
	return handleGetRequest(RtxSipApiRoutes.Connections.Index, {
		queryParams,
		includeFullResponse
	});
};

export const useGetSipConnection = (params?: SipConnectionIndexRequest) => {
	return useQuery<FullResponse<SipConnection[]>, Error>(
		['getSipConnection', params],
		() => fetchSipConnection(params, true)
	);
};

export const fetchGateways = async <
	IncludeFullResponse extends boolean = false
>(
	queryParams?: GatewayIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<GatewayIndexResponse[], IncludeFullResponse>> => {
	return handleGetRequest(RtxSipApiRoutes.Gateways.Index, {
		queryParams,
		includeFullResponse
	});
};

export const useGetGateways = <IncludeFullResponse extends boolean = false>(
	queryParams?: GatewayIndexRequest,
	includeFullResponse?: IncludeFullResponse
) => {
	return useQuery<
		RequestResponse<GatewayIndexResponse[], IncludeFullResponse>,
		Error
	>(
		[`useGetGateway`, queryParams, includeFullResponse],
		() => fetchGateways(queryParams, includeFullResponse),
		{
			enabled: true
		}
	);
};
