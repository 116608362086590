/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { CicIndexResponse } from 'RtModels';
import {
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchCicLookups = async <IncludeFullResponse extends boolean = false>(
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<CicIndexResponse[], IncludeFullResponse>> => {
	return handleGetRequest(Rt800ApiRoutes.Cics.Index, {
		includeFullResponse
	});
};

export const useGetCicLookups = <IncludeFullResponse extends boolean = false>(
	includeFullResponse?: IncludeFullResponse
) => {
	return useQuery<
		RequestResponse<CicIndexResponse[], IncludeFullResponse>,
		Error
	>('getCicLookups', () => {
		return fetchCicLookups(includeFullResponse);
	});
};
