/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtAdmApiRoutes } from 'RtExports/routes';
import { ServerProfileResponse } from 'RtModels';
import {
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchServers = <IncludeFullResponse extends boolean = false>(
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<ServerProfileResponse[], IncludeFullResponse>> => {
	return handleGetRequest(RtAdmApiRoutes.Servers.Index, {
		includeFullResponse
	});
};

export const useGetServers = <IncludeFullResponse extends boolean = false>(
	includeFullResponse?: IncludeFullResponse,
	enabled: boolean = true
) => {
	return useQuery<
		RequestResponse<ServerProfileResponse[], IncludeFullResponse>,
		Error
	>(
		['getServers', includeFullResponse],
		() => fetchServers(includeFullResponse),
		{ enabled }
	);
};
