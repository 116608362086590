import { ServerProfileResponse } from 'RtModels';
import { useGetServers } from 'RtUi/app/rtAdmin/Servers/lib/service';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxServerSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		ServerProfileResponse,
		'serverId',
		IsMulti,
		IsClearable
	> {}

export const RtxServerSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	displayMode,
	label = 'Server',
	...props
}: IRtxServerSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetServers();

	return (
		<RtxSelectInput<ServerProfileResponse, 'serverId', IsMulti, IsClearable>
			label={label}
			valueKey="serverId"
			labelKey="serverId"
			options={data}
			displayMode={displayMode}
			isLoading={isLoading}
			{...props}
		/>
	);
};
