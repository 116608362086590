import { IconButton } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { components, GroupBase, DropdownIndicatorProps } from 'react-select';
import { ReactSelectProps } from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

type IDropdownIndicatorProps<
	T,
	IsMulti extends boolean = false
> = DropdownIndicatorProps<T, IsMulti, GroupBase<T>> & {
	selectProps: ReactSelectProps<T, IsMulti>;
};

export const DropdownIndicator = <T, IsMulti extends boolean = false>({
	children,
	...props
}: IDropdownIndicatorProps<T, IsMulti>) => {
	const navigate = useNavigate();
	const [value] = props.getValue();

	const externalLink = useMemo(() => {
		const {
			isDisabled,
			isMulti,
			selectProps: { linkTo, router }
		} = props;

		if (!isDisabled) {
			return undefined;
		}

		if (isMulti) {
			return undefined;
		}

		if (linkTo) {
			return linkTo;
		}

		if (router && router.recordHasAccessToProfile(value)) {
			return router.getProfileRoute(value);
		}

		return undefined;
	}, [props, value]);

	const getChildren = () => {
		if (externalLink) {
			return (
				<IconButton
					style={{ padding: 0, pointerEvents: 'all' }}
					onClick={() => navigate(externalLink)}
				>
					<OpenInNewIcon />
				</IconButton>
			);
		}

		return children;
	};

	return (
		<components.DropdownIndicator<T, IsMulti, GroupBase<T>> {...props}>
			{getChildren()}
		</components.DropdownIndicator>
	);
};
