import {
	FormControlLabel,
	Checkbox,
	FormGroup,
	FormLabel,
	FormControl
} from '@mui/material';
import { isEqual } from 'lodash-es';

interface RtxMultipleCheckboxInputOption<T = string> {
	label: string;
	value: T;
}

type RtxMultipleCheckboxIInputProps<T = string> = {
	value?: T[];
	onChange: (value: T[]) => void;
	label: string;
	className?: string;
	options: Array<RtxMultipleCheckboxInputOption<T>>;
};

export const RtxMultipleCheckboxInput = <T = string,>({
	value = [],
	onChange,
	className,
	label,
	options
}: RtxMultipleCheckboxIInputProps<T>) => {
	const handleChange = (checked: boolean, currentValue: T) => {
		let newValue = [...value];

		if (checked) {
			newValue.push(currentValue);
		} else {
			newValue = newValue.filter((v) => !isEqual(v, currentValue));
		}

		onChange(newValue);
	};

	const isChecked = (checkboxValue: T) =>
		value.some((v) => isEqual(v, checkboxValue));

	return (
		<FormControl
			className={className}
			sx={{ width: '100%' }}
			variant="outlined"
		>
			<FormLabel>{label}</FormLabel>
			<FormGroup row>
				{options.map((opt, index) => (
					<FormControlLabel
						key={index}
						control={
							<Checkbox
								checked={isChecked(opt.value)}
								onChange={(evt, checked) => handleChange(checked, opt.value)}
							/>
						}
						label={opt.label}
					/>
				))}
			</FormGroup>
		</FormControl>
	);
};
