import { ScenarioIndexResponse } from 'RtModels';
import { useGetSipScenarios } from 'RtUi/app/rtSip/Scenarios/lib/service';
import { ScenarioRouter } from 'RtUi/app/rtSip/Scenarios/Scenario.router';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxScenarioSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		ScenarioIndexResponse,
		'scenarioId',
		IsMulti,
		IsClearable
	> {}

export const RtxScenarioSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	displayMode,
	label = 'Scenario',
	...props
}: IRtxScenarioSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetSipScenarios();

	return (
		<RtxSelectInput<ScenarioIndexResponse, 'scenarioId', IsMulti, IsClearable>
			label={label}
			valueKey="scenarioId"
			router={ScenarioRouter}
			labelKey="label"
			options={data}
			displayMode={displayMode}
			isLoading={isLoading}
			{...props}
		/>
	);
};
