/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtCommonApiRoutes, RtxSipApiRoutes } from 'RtExports/routes';
import {
	PeeringTypeIndexRequest,
	PeeringTypeIndexResponse,
	SubscriptionPeeringCreateRequest,
	SubscriptionPeeringIndexRequest,
	SubscriptionPeeringIndexResponse,
	SubscriptionPeeringProfileRequest,
	SubscriptionPeeringProfileResponse,
	SubscriptionPeeringUpdateRequest
} from 'RtModels';
import {
	FullResponse,
	handleDeleteRequest,
	handleGetRequest,
	handlePostRequest,
	handlePutRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchSubscriptionPeering = async (
	queryParams?: SubscriptionPeeringIndexRequest
): Promise<FullResponse<SubscriptionPeeringIndexResponse[]>> => {
	return handleGetRequest<SubscriptionPeeringIndexResponse[], true>(
		RtxSipApiRoutes.SubscriptionPeering.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetSubscriptionPeering = (
	queryParams?: SubscriptionPeeringIndexRequest
) => {
	return useQuery<FullResponse<SubscriptionPeeringIndexResponse[]>, Error>(
		['getSubscriptionPeering', queryParams],
		() => fetchSubscriptionPeering(queryParams)
	);
};

const fetchPeeringTypes = async <IncludeFullResponse extends boolean = false>(
	queryParams?: PeeringTypeIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<
	RequestResponse<PeeringTypeIndexResponse[], IncludeFullResponse>
> => {
	return handleGetRequest(RtCommonApiRoutes.PeeringTypes.Index, {
		queryParams,
		includeFullResponse
	});
};

export const useGetPeeringTypes = <IncludeFullResponse extends boolean = false>(
	queryParams?: PeeringTypeIndexRequest,
	includeFullResponse?: IncludeFullResponse,
	enabled: boolean = true
) => {
	return useQuery<
		RequestResponse<PeeringTypeIndexResponse[], IncludeFullResponse>,
		Error
	>(
		[`useGetPeeringTypes`, queryParams, includeFullResponse],
		() => fetchPeeringTypes(queryParams, includeFullResponse),
		{ enabled }
	);
};

export const deleteSubscriptionPeering = (subscriptionPeeringId: number) => {
	const urlParams: SubscriptionPeeringProfileRequest = {
		subscriptionPeeringId
	};

	return handleDeleteRequest(RtxSipApiRoutes.SubscriptionPeering.Delete, {
		urlParams
	});
};

export const createSubscriptionPeering = (
	createRequest: SubscriptionPeeringCreateRequest
) => {
	return handlePostRequest<SubscriptionPeeringProfileResponse>(
		RtxSipApiRoutes.SubscriptionPeering.Create,
		createRequest
	);
};

interface IUpdateSubscriptionPeeringParams {
	subscriptionPeeringId: number;
	updatedSubscriptionPeering: SubscriptionPeeringUpdateRequest;
}

export const updateSubscriptionPeering = ({
	subscriptionPeeringId,
	updatedSubscriptionPeering
}: IUpdateSubscriptionPeeringParams) => {
	const urlParams: SubscriptionPeeringProfileRequest = {
		subscriptionPeeringId
	};

	return handlePutRequest<SubscriptionPeeringUpdateRequest>(
		RtxSipApiRoutes.SubscriptionPeering.Update,
		updatedSubscriptionPeering,
		{ urlParams }
	);
};
