/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LumenDidRateCenterResponse = {
    properties: {
        rateCenterAbbrev: {
            type: 'string',
            isRequired: true,
        },
        stateAbbrev: {
            type: 'string',
            isRequired: true,
        },
        lata: {
            type: 'string',
            isRequired: true,
        },
        productID: {
            type: 'string',
            isRequired: true,
        },
        static911: {
            type: 'string',
            isRequired: true,
        },
        sellableStatus: {
            type: 'string',
            isRequired: true,
        },
    },
};