import { MarginPlanIndexResponse } from 'RtModels';
import { useGetMarginPlans } from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxMarginPlanSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		MarginPlanIndexResponse,
		'marginPlanId',
		IsMulti,
		IsClearable
	> {}

export const RtxMarginPlanSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	label = 'Margin Plan',
	...props
}: IRtxMarginPlanSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetMarginPlans();

	return (
		<RtxSelectInput<
			MarginPlanIndexResponse,
			'marginPlanId',
			IsMulti,
			IsClearable
		>
			label={label}
			valueKey="marginPlanId"
			labelKey="label"
			options={data}
			isLoading={isLoading}
			{...props}
		/>
	);
};
