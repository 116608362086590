import { GatewayIndexResponse } from 'RtModels';
import { useGetGateways } from 'RtUi/app/rtSip/Connections/lib/services';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxGatewaySelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		GatewayIndexResponse,
		'partitionSipGatewayId',
		IsMulti,
		IsClearable
	> {}

export const RtxGatewaySelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	label = 'Gateway',
	...props
}: IRtxGatewaySelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetGateways();

	return (
		<RtxSelectInput<
			GatewayIndexResponse,
			'partitionSipGatewayId',
			IsMulti,
			IsClearable
		>
			label={label}
			valueKey="partitionSipGatewayId"
			maxOptions={600}
			labelKey="listenFqdn"
			options={data}
			isLoading={isLoading}
			{...props}
		/>
	);
};
