import { Permissions } from 'RtExports/routes';
import { CdrSearchIndexResponse } from 'RtModels';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { FraudSimulatorRouter } from 'RtUi/app/rtGuardian/FraudSimulator/FraudSimulator.router';
import { CdrDetailsAside } from 'RtUi/app/rtVue/CdrSearchView/lib/components/CdrDetailsAside';
import { RatingSimulatorRouter } from 'RtUi/app/rtVue/RatingSimulator/RatingSimulator.router';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { PageSizeDropdown } from 'RtUi/components/data/DataGrid/components/PageSizeDropdown';
import { getCdrSearchColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/cdrSearch';
import { UserActions } from 'RtUi/state/actions/user';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { isNil } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Item } from 'react-contexify';
import { useQuery } from 'react-query';
import { useToggle } from 'react-use';
import { CdrSearchViewIndexContainer } from 'RtUi/app/rtVue/CdrSearchView/CdrSearchView.index';

interface ICdrSearchDataGridProps extends IRtVueReportDataGridProps {
	resource: HttpResource<CdrSearchIndexResponse> | null;
	isLoading?: boolean;
	recordsToLoad?: number;
	onChangeRecordsToLoad?: (numberOfRecords: number) => void;
	container: CdrSearchViewIndexContainer;
}

let hasClicked = false;

export const CdrSearchDataGrid = ({
	resource,
	isLoading,
	recordsToLoad,
	onChangeRecordsToLoad,
	container
}: ICdrSearchDataGridProps) => {
	const [selectedCdr, setSelectedCdr] = useState<CdrSearchIndexResponse>();
	const [showModal, toggleShowModal] = useToggle(false);

	const fullResultsReq = async () => {
		const res = await container.getResource(true);
		return res.getAll();
	};

	const { data = [] } = useQuery<CdrSearchIndexResponse[] | undefined, Error>(
		[`useGetCdrSearchReport`, resource],
		() => resource?.getAll()
	);
	const columns = useMemo(() => getCdrSearchColumns(), []);

	const removeSelection = () => {
		toggleShowModal();
		setSelectedCdr(undefined);
	};

	return (
		<>
			<DataGrid<CdrSearchIndexResponse>
				pageName="rtVue_cdrSearch"
				data={data}
				columns={columns}
				loading={isLoading}
				disableExternalLinks
				totalRows={resource?.getTotalDbCount()}
				enableExternalExport
				getExternalExportData={fullResultsReq}
				rowThemeColor={(record) =>
					record.vuid === selectedCdr?.vuid ? RowThemeColor.WARNING : undefined
				}
				menuLinks={(row) => (
					<>
						<Item
							onClick={() => {
								if (!hasClicked) {
									localStorage.setItem('cdrRecord', row.cdr_record);
									BrowserHistory.push(
										RatingSimulatorRouter.getIndexRouteWithParams({
											fileStreamId: row.file_stream_id
										})
									);
								}
								hasClicked = false;
							}}
						>
							<div
								className="d-flex flex-row"
								style={{ width: '100%', flexShrink: 0, flexGrow: 1 }}
							>
								<div className="flex-grow-1">
									<i className="fas fa-fw fa-search" />
									<span>Rating Simulator</span>
								</div>
								<div className="align-self-end">
									<Button
										variant="white"
										size="sm"
										className="py-0"
										title={`Open in a new tab.`}
										target="_blank"
										onClick={() => {
											localStorage.setItem('cdrRecord', row.cdr_record);
											hasClicked = true;
										}}
										href={`${RatingSimulatorRouter.getIndexRouteWithParams({
											fileStreamId: row.file_stream_id
										})}`}
										rel="noreferrer"
									>
										<i className="fas fa-external-link-alt px-2" />
									</Button>
								</div>
							</div>
						</Item>
						{!isNil(row.fraud_score) &&
							UserActions.has(Permissions.GuardianOperator) && (
								<Item
									onClick={() => {
										if (!hasClicked) {
											BrowserHistory.push(
												FraudSimulatorRouter.getIndexRouteWithParams({
													dialedNumber: row.to_raw || '',
													fileStreamId: row.file_stream_id,
													ani: row.fr_raw || '',
													startTime: row.ingress_invite_ts.toString(),
													originTrunkGroup: row.ingress_trunk_group_id,
													termTrunkGroup: row.egress_trunk_group_id
												})
											);
										}
										hasClicked = false;
									}}
								>
									<div
										className="d-flex flex-row"
										style={{ width: '100%', flexShrink: 0, flexGrow: 1 }}
									>
										<div className="flex-grow-1">
											<i className="fas fa-fw fa-search" />
											<span>Fraud Simulator</span>
										</div>
										<div className="align-self-end">
											<Button
												variant="white"
												size="sm"
												className="py-0"
												title={`Open in a new tab.`}
												target="_blank"
												onClick={() => {
													hasClicked = true;
												}}
												href={`${FraudSimulatorRouter.getIndexRouteWithParams({
													dialedNumber: row.to_raw || '',
													fileStreamId: row.file_stream_id,
													ani: row.fr_raw || '',
													startTime: row.ingress_invite_ts.toString(),
													originTrunkGroup: row.ingress_trunk_group_id,
													termTrunkGroup: row.egress_trunk_group_id
												})}`}
												rel="noreferrer"
											>
												<i className="fas fa-external-link-alt px-2" />
											</Button>
										</div>
									</div>
								</Item>
							)}
						<Item
							onClick={() => {
								setSelectedCdr(row);
								toggleShowModal();
							}}
						>
							<span className="d-flex justify-content-start align-items-center">
								<i className="fas fa-fw fa-link" />
								<span>Profile</span>
							</span>
						</Item>
					</>
				)}
				footerAction={() => (
					<PageSizeDropdown
						value={recordsToLoad}
						onChange={onChangeRecordsToLoad}
					/>
				)}
			/>
			{selectedCdr && (
				<CdrDetailsAside
					show={showModal}
					toggle={() => {
						removeSelection();
					}}
					cdrSearchItem={selectedCdr}
				/>
			)}
		</>
	);
};
