/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SubscriptionIndexResponse = {
    properties: {
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        isLocked: {
            type: 'number',
        },
        isSystem: {
            type: 'number',
        },
        blockTypeId: {
            type: 'number',
            isNullable: true,
        },
        accountId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        serviceId: {
            type: 'number',
            isRequired: true,
        },
        routePlanId: {
            type: 'number',
            isNullable: true,
        },
        ratePlanId: {
            type: 'number',
            isNullable: true,
        },
        defaultSubscriptionId: {
            type: 'number',
            isNullable: true,
        },
        defaultScenarioId: {
            type: 'number',
            isNullable: true,
        },
        translatedNumberStd: {
            type: 'string',
            isNullable: true,
        },
        trackingGroupId: {
            type: 'number',
            isNullable: true,
        },
        directSubscriptionId: {
            type: 'number',
            isNullable: true,
        },
        hasServiceNumbers: {
            type: 'number',
        },
        timezoneId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        note: {
            type: 'string',
            isNullable: true,
        },
        lastRateLoadTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        ratingParametersPriority: {
            type: 'Array',
            isNullable: true,
        },
        externalAccountId: {
            type: 'string',
            isNullable: true,
        },
        externalSubAccountId: {
            type: 'string',
            isNullable: true,
        },
        attestation: {
            type: 'Attestation',
            isNullable: true,
        },
        blockType: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        ratePlan: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        routePlan: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        isCustomer: {
            type: 'number',
            isRequired: true,
        },
    },
};