import { jwtDecode } from 'jwt-decode';
import type * as mixpanelType from 'mixpanel-browser';
import * as moment from 'moment-timezone';
import { Permissions, Rt800ApiRoutes, RtxApiRoutes } from 'RtExports/routes';
import {
	AuthorizationResponse,
	EntityIndexResponse,
	MyProfile2FACreateRequest,
	ProductId,
	RespOrgIndexResponse,
	UserProfileResponse
} from 'RtModels';
import { RtVueFilterSearch } from 'RtUi/app/rtVue/common/lib/http/RtVueFilterSearch';
import { UserHttp } from 'RtUi/app/user/lib/Http/UserHttp';
import { BaseActions } from 'RtUi/state/actions';
import { RtxPartitionId } from 'RtUi/state/actions/user/Constants';
import { FirebaseUser } from 'RtUi/state/actions/user/FirebaseUser';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { IUser, LoginAttemptsError, TFAChallengeError } from './interfaces';

declare const MIX_PANEL_ENABLED: boolean;
let mixpanel: typeof mixpanelType | undefined;

if (MIX_PANEL_ENABLED) {
	import('mixpanel-browser').then((mixpanelImports) => {
		mixpanel = mixpanelImports;

		mixpanel.init('6058242b294d1f9606addbce8ec73300');
	});
}

export interface ITokenDto {
	//extends RtxJwtPayload {
	exp: number;
	iat: number;
	//TODO: RTX-PUBLIC
	userId: number;
	email: string;
	tfaEnabled: boolean;
	tfaValidated: boolean;
}

class UserApplicationActions extends BaseActions {
	private static LocalStorageUserKey = '|_|S3R';
	private RespOrgIdsCanManage: string[] = [];
	private EntityIdsCanManage: string[] = [];
	private jwtExpiration: moment.Moment | null = null;
	private jwtCheckInterval = 10 * 1000; // 10 seconds;
	private userHttp = new UserHttp();
	private firebaseUser = FirebaseUser.getInstance();

	constructor() {
		super();

		setTimeout(() => {
			this.attemptToInitFromLocalStorage().then(() => {
				this.checkOnJwtExpiration();

				this.assureIsInitialized();
			});
		});
	}

	/**
	 * Attempt to login user
	 */
	public attemptToInitFromLocalStorage(): Promise<void> {
		let possibleUser: IUser | null = null;

		try {
			const possibleUserStr = localStorage.getItem(
				UserApplicationActions.LocalStorageUserKey
			);

			if (typeof possibleUserStr === 'string') {
				possibleUser = JSON.parse(possibleUserStr) as IUser;
			}
		} catch (cannotParseJsonError) {
			/** */
		}

		if (possibleUser && possibleUser.loginInfo !== null) {
			const isTokenValid = this.validateAndUpdateAuthToken(
				possibleUser.loginInfo.rtxJwt
			);

			if (isTokenValid && possibleUser.emailAddress) {
				this.updateUser(
					possibleUser.emailAddress,
					possibleUser.loginInfo,
					possibleUser.defaultRespOrgId,
					possibleUser.defaultEntityId
				);

				return this.updateCanManageIds();
			}
		}

		if (MIX_PANEL_ENABLED) {
			mixpanel?.reset();
		}

		return Promise.resolve();
	}

	// externalize and simplify this to remove necessity of using HTTPResource
	// this could be done at the same place as AxiosResource, we could use redux or a state management system to handle this
	// or just rely on react query to redirect after token is expired.
	// regardless, this is anti pattern. a method that takes a value from local storage and just pass forward to another method??
	/**
	 * @override
	 * @param token
	 * @returns if token is valid; this includes an empty string (removing the token)
	 */
	public validateAndUpdateAuthToken(token: string): boolean {
		let isValid = false;

		if (token) {
			const now = moment();
			const jwt: ITokenDto = jwtDecode(token);
			this.jwtExpiration = moment.unix(jwt.exp);
			isValid = now.isBefore(this.jwtExpiration);
		}

		if (!isValid) {
			this.jwtExpiration = null;
		}

		HttpRequest.updateAuthToken(token);

		return isValid;
	}

	/**
	 * Login user
	 * @param email
	 * @param password
	 */
	public login(email: string, password: string) {
		return this.userHttp
			.login(email, password)
			.then(async (loginInfo) => {
				this.validateAndUpdateAuthToken(loginInfo.rtxJwt);

				if (loginInfo.tfaEnabled && !loginInfo.tfaValidated) {
					throw new TFAChallengeError('TFA Challenge!');
				}

				this.updateUser(
					email,
					loginInfo,
					loginInfo.defaultRespOrgId,
					loginInfo.defaultEntityId
				);

				await this.updateCanManageIds();
				void RtVueFilterSearch.primeCache();

				return loginInfo;
			})
			.catch((err) => {
				if (err instanceof TFAChallengeError) {
					throw err;
				} else if (err instanceof LoginAttemptsError) {
					throw err;
				}

				throw new Error('E-Mail and/or password are incorrect');
			});
	}

	/**
	 * Only invoke if user has done a login() action before
	 * @param email
	 * @param totpCode
	 * @param rememberDevice
	 */
	public challengeTfa(
		email: string,
		totpCode: string,
		rememberDevice: boolean
	) {
		return this.userHttp
			.challengeTfa(email, totpCode, rememberDevice)
			.then(async (loginInfo) => {
				if (loginInfo.tfaEnabled && !loginInfo.tfaValidated) {
					throw new Error('TFA Challenge Failed!');
				}

				this.validateAndUpdateAuthToken(loginInfo.rtxJwt);
				this.updateUser(
					email,
					loginInfo,
					loginInfo.defaultRespOrgId,
					loginInfo.defaultEntityId
				);

				return this.updateCanManageIds().finally(() => loginInfo);
			})
			.catch(() => {
				throw new Error('6-Digit Code is Incorrect');
			});
	}

	/**
	 * Log User Out
	 */
	public logout() {
		const user = this.getStateAspect('user');

		this.validateAndUpdateAuthToken('');

		this.updateUser(user.emailAddress, null);

		this.updateCanManageIds(true);
	}

	/**
	 * Get userId for current user; undefined if user not logged in
	 */
	public getUserId() {
		const user = this.getStateAspect('user');

		if (user.loginInfo) {
			return user.loginInfo.userId;
		}

		return undefined;
	}

	/**
	 * Is the current user on a partition? Let's find out!
	 */
	public isOnPartitionId(partition: RtxPartitionId) {
		const user = this.getStateAspect('user');

		return user?.loginInfo?.partitionId === partition;
	}

	/**
	 * Get JWT for current user; undefined if user not logged in
	 */
	public getJwt() {
		const user = this.getStateAspect('user');

		if (user.loginInfo) {
			return user.loginInfo.rtxJwt;
		}

		return undefined;
	}

	/**
	 * Updates the RespOrgs and Entities user can manage
	 * @param clearIds
	 */
	public async updateCanManageIds(clearIds = false): Promise<void> {
		if (clearIds) {
			this.RespOrgIdsCanManage = [];
			this.EntityIdsCanManage = [];

			return Promise.resolve();
		}

		const hasEntityPermissions = this.has(
			...Rt800ApiRoutes.Entities.Index.permissions
		);
		const hasRespOrgPermissions = this.has(
			...Rt800ApiRoutes.RespOrgs.Index.permissions
		);
		const canManageParams = { isActive: 1, isManaged: 1 };

		if (hasEntityPermissions) {
			const entities = await HttpRequest.fetchWithRoute<EntityIndexResponse[]>(
				Rt800ApiRoutes.Entities.Index,
				{ params: canManageParams }
			);

			this.EntityIdsCanManage = entities.map((e) => e.entityId);
		}

		if (hasRespOrgPermissions) {
			const respOrgs = await HttpRequest.fetchWithRoute<RespOrgIndexResponse[]>(
				Rt800ApiRoutes.RespOrgs.Index,
				{ params: canManageParams }
			);

			this.RespOrgIdsCanManage = respOrgs.map((r) => r.respOrgId);
		}

		return;
	}

	/**
	 * Check if user has one of the permissions in args
	 * @param requiredPermissions
	 */
	public has(...requiredPermissions: Permissions[]) {
		const user = this.getStateAspect('user');

		if (requiredPermissions.length === 0) {
			return true;
		}

		if (user.loginInfo && user.loginInfo.permissions) {
			const { permissions: userPermissions } = user.loginInfo;

			return requiredPermissions.some((requiredPermission) =>
				userPermissions.includes(requiredPermission)
			);
		}

		return false;
	}

	/**
	 * Get User's current permissions
	 */
	public getPermissions(): Permissions[] {
		const user = this.getStateAspect('user');

		if (user.loginInfo && user.loginInfo.permissions) {
			const { permissions } = user.loginInfo;

			return permissions;
		}

		return [];
	}

	/**
	 * Get User's current products
	 */
	public getUsersProducts(addCommonProducts = false): ProductId[] {
		const user = this.getStateAspect('user');
		const productIds = user.loginInfo?.products ?? [];

		if (addCommonProducts) {
			productIds.push(ProductId.RT_COMMON, ProductId.RT_ADM);
		}

		return productIds;
	}

	/**
	 * Check if two factor authentication enabled for current user
	 */
	public isTFAEnabled(): boolean {
		const user = this.getStateAspect('user');

		return Boolean(
			user.loginInfo && user.loginInfo.tfaEnabled && user.loginInfo.tfaValidated
		);
	}

	public async enableTFA(tfaRequest: MyProfile2FACreateRequest) {
		const req: MyProfile2FACreateRequest = tfaRequest;
		const body = JSON.stringify(req);

		const response = await HttpRequest.fetchWithRoute<UserProfileResponse>(
			RtxApiRoutes.MyProfile2FA.Create,
			{ body }
		);

		const currentUser = this.getStateAspect('user');
		const currentLoginInfo = currentUser.loginInfo!;
		const updatedLoginInfo: AuthorizationResponse = {
			...currentLoginInfo,
			tfaEnabled: true,
			tfaValidated: true
		};

		this.updateUser(
			currentUser.emailAddress,
			updatedLoginInfo,
			currentUser.defaultRespOrgId,
			currentUser.defaultEntityId
		);

		return response;
	}

	public async disableTFA() {
		const response = await HttpRequest.fetchWithRoute<UserProfileResponse>(
			RtxApiRoutes.MyProfile2FA.Delete
		);

		const currentUser = this.getStateAspect('user');
		const currentLoginInfo = currentUser.loginInfo!;
		const updatedLoginInfo: AuthorizationResponse = {
			...currentLoginInfo,
			tfaEnabled: false,
			tfaValidated: false
		};

		this.updateUser(
			currentUser.emailAddress,
			updatedLoginInfo,
			currentUser.defaultRespOrgId,
			currentUser.defaultEntityId
		);

		return response;
	}

	/**
	 * Get user's default entityId
	 */
	public getDefaultEntityId() {
		const user = this.getStateAspect('user');

		if (user) {
			return user.defaultEntityId;
		}
	}

	public getDefaultRespOrgId() {
		const user = this.getStateAspect('user');

		if (user) {
			return user.defaultRespOrgId;
		}
	}

	/**
	 * Check to see if user has access to a given RespOrg
	 * @param record
	 */
	public hasAccessToRespOrg<T extends { respOrgId: string }>(
		record: T | string
	) {
		const respOrgId = typeof record === 'string' ? record : record.respOrgId;

		if (!respOrgId) {
			return false;
		}

		return this.hasAccessToRespOrgId(respOrgId);
	}

	/**
	 * Check to see if user has access to a given RespOrgId
	 * @param record
	 */
	public hasAccessToRespOrgId(respOrgId: string) {
		return (
			this.RespOrgIdsCanManage.findIndex(
				(respOrgCanMange) => respOrgCanMange === respOrgId
			) >= 0
		);
	}

	/**
	 * Check to see if user has access to a given Entity
	 * @param record
	 */
	public hasAccessToEntity<T extends { entityId: string }>(record: T | string) {
		const entityId = typeof record === 'string' ? record : record.entityId;

		if (!entityId) {
			return false;
		}

		return (
			this.EntityIdsCanManage.findIndex(
				(entityCanMange) => entityCanMange === entityId
			) >= 0
		);
	}

	/**
	 * track an event within Mixpanel
	 * @param eventName
	 * @param properties
	 * @param callback
	 */
	public trackEvent(
		eventName: string,
		properties?: mixpanelType.Dict,
		callback?: () => void
	) {
		if (MIX_PANEL_ENABLED) {
			mixpanel?.track(eventName, properties, undefined, callback);
		}
	}

	/**
	 * Make sure is initialized is set for app to start. If not, set it to initialized
	 */
	private assureIsInitialized() {
		const user = this.getStateAspect('user');

		if (user.isInitialized) {
			return;
		}

		user.isInitialized = true;

		this.updateState({ user });
	}

	/**
	 * Update User attribute in Application State
	 * @param emailAddress
	 * @param loginInfo
	 */
	private updateUser(
		emailAddress: string | null,
		loginInfo: AuthorizationResponse | null,
		defaultRespOrgId?: string,
		defaultEntityId?: string
	) {
		const isInitialized = true;
		const user: IUser = {
			emailAddress,
			loginInfo,
			defaultRespOrgId,
			defaultEntityId,
			isInitialized
		};

		localStorage.setItem(
			UserApplicationActions.LocalStorageUserKey,
			JSON.stringify(user)
		);

		if (loginInfo && emailAddress) {
			if (mixpanel) {
				mixpanel.identify(emailAddress);
				mixpanel.people.set_once({
					$email: emailAddress,
					host: location.host
				});

				mixpanel.people.set({
					// eslint-disable-next-line camelcase
					$last_login: new Date()
				});
			}

			try {
				this.firebaseUser.signIn(loginInfo);
			} catch (error) {
				console.error('Firebase sign in failed:', error);
			}
		} else {
			if (mixpanel) {
				mixpanel.reset();
			}

			this.firebaseUser.signOut();
		}

		this.updateState({ user });
	}

	/**
	 * Periodically checks if JWT is expiring/expired
	 */
	private checkOnJwtExpiration() {
		if (this.jwtExpiration) {
			const now = moment();
			const hasExpired = now.isAfter(this.jwtExpiration);

			if (hasExpired) {
				this.logout();
			}
		}

		setTimeout(() => this.checkOnJwtExpiration(), this.jwtCheckInterval);
	}
}

export const UserActions = new UserApplicationActions();
