import { ManagedEntityProfileResponse } from 'RtModels';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { ManagedUpdateSql } from '../lib/components/ManagedUpdateSql';
import { ManagedEntityEditor } from './lib/forms/ManagedEntityEditor';
import { ManagedEntityResource } from './lib/resources/ManagedEntityResource';
import { ManagedEntityRouter } from './ManagedEntity.router';

interface IManagedEntityContainerState {
	activeTab: string;
	displayMode: boolean;
	managedEntityProfile?: ManagedEntityProfileResponse;
}

@ManagedEntityRouter.getProfileRtUiController()
export class ManagedEntityProfileContainer extends ProfileApplicationContainer<
	{},
	IManagedEntityContainerState
> {
	public Tabs = ManagedEntityRouter.getProfileTabs();

	public managedEntityResource = new ManagedEntityResource();

	public state: IManagedEntityContainerState = {
		activeTab: this.Tabs.Profile.header,
		displayMode: true
	};

	public componentDidMount() {
		const respOrgId = this.getIdParam();

		this.managedEntityResource.get(respOrgId).then((managedEntityProfile) => {
			this.setState({ managedEntityProfile });
		});
	}

	public render() {
		const { managedEntityProfile } = this.state;

		if (!managedEntityProfile) {
			return <Loading />;
		}

		return (
			<TabbedLayout<ManagedEntityProfileResponse>
				router={ManagedEntityRouter}
				profile={managedEntityProfile}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<ManagedEntityEditor
						editMode={managedEntityProfile}
						onUpdate={(managedEntityProfile) =>
							this.setState({ managedEntityProfile })
						}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Credentials}>
					<article className="mb-5">
						<ManagedUpdateSql<ManagedEntityProfileResponse>
							id={managedEntityProfile.entityId}
							profile={managedEntityProfile}
							resourceClass={ManagedEntityResource}
							onUpdate={(managedEntityProfile) =>
								this.setState({ managedEntityProfile })
							}
						/>
					</article>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
