import { TollfreeEnhancedDetailIndexResponse } from 'RtModels';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { CallSimulatorRouter } from 'RtUi/app/rt800/CallSimulator/CallSimulator.router';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { CallDateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { FileStreamDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FileStreamDataColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { RateCenterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateCenterDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';

export const getTollFreeEnhancedDetailColumns = (): Array<
	DataGridColumn<TollfreeEnhancedDetailIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'vuid',
		header: 'vuid',
		hiddenIfEmpty: true
	}),
	FileStreamDataGridColumn({
		accessorKey: 'fileStreamId',
		header: 'File Stream'
	}),
	CallDateDataGridColumn({
		accessorKey: 'callDate',
		header: 'Call Date',
		format: 'YYYY-MM-DD HH:mm'
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressTrunkGroup',
		header: 'Ingress Trunk Group',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressTrunkGroup',
		header: 'Egress Trunk Group',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'connect',
		header: 'Connect',
		showFooter: true
	}),
	IntegerDataGridColumn({
		header: 'Raw MOU',
		accessorFn: (row) => {
			return (Number(row.rawDurationMs) || 0) / 60_000;
		}
	}),
	DefaultDataGridColumn({
		accessorKey: 'jurisdiction',
		header: 'Jurisdiction',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressToStd',
		header: 'Dialed Number',
		hiddenIfEmpty: true,
		onDrillDown: (e, row) => {
			e.preventDefault();

			BrowserHistory.push(
				CallSimulatorRouter.getIndexRouteWithParams({
					ingressToStd: row.ingressToStd,
					ani:
						row.ingressFrLrn && row.ingressFrLrn.length === 10
							? row.ingressFrLrn
							: row.ingressFrStd
				})
			);
		}
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressFrStd',
		header: 'From',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressFrLrn',
		header: 'From Lrn',
		hiddenIfEmpty: true
	}),
	RateCenterDataGridColumn({
		accessorKey: 'ingressFrRateCenterId',
		header: 'Rate Center',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressFrDialCode',
		header: 'Ingress Fr Dial Code',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'originalRevenue',
		header: 'Original Revenue',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'originalRevenueSubscriptionId',
		header: 'Original Subscription',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'originalRevenueRate',
		header: 'Original Revenue Rate',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		header: 'Original Revenue Duration',
		accessorFn: (row) => {
			return (
				row.originalRevenueDurationMs &&
				Number(row.originalRevenueDurationMs) / 60_000
			);
		},
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'originalCost',
		header: 'Original Cost',
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'originalCostRate',
		header: 'Original Cost Rate',
		decimals: 4
	}),
	IntegerDataGridColumn({
		header: 'Original Cost Duration MOU',
		accessorFn: (row) => {
			return (Number(row.originalRevenueDurationMs) || 0) / 60_000;
		}
	}),
	PrecisionDataGridColumn({
		accessorKey: 'originalMargin',
		header: 'Original Margin',
		showFooter: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'originalCic',
		header: 'Original Cic',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'calculatedCic',
		header: 'Calculated Cic'
	}),
	DefaultDataGridColumn({
		accessorKey: 'calculatedTemplate',
		header: 'Calculated Template'
	}),
	DefaultDataGridColumn({
		accessorKey: 'calculatedResporg',
		header: 'Calculated RespOrg'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'calculatedCost',
		header: 'Calculated Cost',
		showFooter: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'originalCostSubscriptionId',
		header: 'Original Cost Subscription'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'calculatedCostRate',
		header: 'Calculated Cost Rate',
		decimals: 4
	}),
	IntegerDataGridColumn({
		header: 'Calculated Cost Duration MOU',
		accessorFn: (row) => {
			return (Number(row.originalRevenueDurationMs) || 0) / 60_000;
		}
	}),
	PrecisionDataGridColumn({
		accessorKey: 'calculatedMargin',
		header: 'Calculated Margin',
		showFooter: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'marginSummary',
		header: 'Margin Summary',
		hiddenIfEmpty: true
	})
];
