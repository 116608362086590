import { FinancialIndexResponseData } from 'RtModels';
import { FinancialViewIndexContainer } from 'RtUi/app/rtVue/FinancialView/FinancialView.index';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getFinancialColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/financial';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { range } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useQuery } from 'react-query';

interface IFinancialDataGridProps extends IRtVueReportDataGridProps {
	resource: HttpResource<FinancialIndexResponseData> | null;
	isLoading?: boolean;
	onDrillDown: (row: FinancialIndexResponseData) => void;
	callDateFormat?: string;
	container: FinancialViewIndexContainer;
}

export const FinancialDataGrid = ({
	resource,
	isLoading,
	onDrillDown,
	callDateFormat,
	container
}: IFinancialDataGridProps) => {
	const [decimals, setDecimals] = useState<number>(2);

	const fullResultsReq = async () => {
		const res = await container.getResource(true);
		return res.getAll();
	};

	const { data = [] } = useQuery<
		FinancialIndexResponseData[] | undefined,
		Error
	>([`useGetFinancialReport`, resource], () => resource?.getAll());
	const columns = useMemo(
		() => getFinancialColumns(decimals, callDateFormat, onDrillDown),
		[decimals, callDateFormat, onDrillDown]
	);

	return (
		<DataGrid<FinancialIndexResponseData>
			pageName="rtVue_financial"
			data={data}
			columns={columns}
			loading={isLoading}
			totalRows={resource?.getTotalDbCount()}
			defaultSorting={[{ id: 'callDate', desc: true }]}
			enableExternalExport
			getExternalExportData={fullResultsReq}
			headerAction={() => (
				<Dropdown
					className="ms-1"
					onSelect={(eventKey) => setDecimals(Number(eventKey))}
				>
					<Dropdown.Toggle size="sm" variant="light" id="dropdown-basic">
						Precision
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{range(2, 9).map((r) => (
							<Dropdown.Item eventKey={r} key={`precision_${r}`}>
								{r}
							</Dropdown.Item>
						))}
					</Dropdown.Menu>
				</Dropdown>
			)}
		/>
	);
};
