import { addMinutes, endOfDay, format, subHours } from 'date-fns';
import moment from 'moment';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import {
	AccountIndexResponse,
	CdrSearchColumns,
	CdrSearchIndexRequest,
	CountryIndexResponse,
	RateCenterIndexResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { CdrSearchReportRouter } from 'RtUi/app/rtSip/CdrSearchReport/CdrSearchReport.router';
import { CdrSearchReportGrid } from 'RtUi/app/rtSip/CdrSearchReport/lib/grids/CdrSearchReportGrid';
import { RateCenterSelect } from 'RtUi/app/rtSip/MarginSummaryReport/lib/controls/RateCenterSelect';
import { CountrySelect } from 'RtUi/app/user/lib/controls/CountrySelect';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { MultipleCheckboxFormControl } from 'RtUi/components/form/MultipleCheckboxFormControl';
import { TimeFormControl } from 'RtUi/components/form/TimeFormControl';
import { useFormInitializer } from 'RtUi/components/rtx/form/hooks/useFormInitializer';
import { RtxSearchForm } from 'RtUi/components/rtx/form/RtxUiSearchForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { TwoFADisabledWarning } from 'RtUi/components/ui/WarningAlert/TwoFADisabledWarning';

export const CdrSearchReportIndexContainer = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search', isDefault: true },
		Results: { header: 'Results' }
	});
	const [callingPartyCountry, setCallingPartyCountry] =
		useState<CountryIndexResponse[]>();
	const [callingPartyRateCenter, setCallingPartyRateCenter] =
		useState<RateCenterIndexResponse[]>();
	const [revenueAccounts, setRevenueAccounts] =
		useState<AccountIndexResponse[]>();
	const [revenueSubscriptions, setRevenueSubscriptions] =
		useState<SubscriptionIndexResponse[]>();
	const [costAccounts, setCostAccounts] = useState<AccountIndexResponse[]>();
	const [costSubscriptions, setCostSubscriptions] =
		useState<SubscriptionIndexResponse[]>();
	const [destinationCountry, setDestinationCountry] =
		useState<CountryIndexResponse[]>();
	const [destinationRateCenter, setDestinationRateCenter] =
		useState<RateCenterIndexResponse[]>();
	const [resourceParams, setResourceParams] = useState<CdrSearchIndexRequest>({
		callDate: moment().utc().toDate(),
		startTime: format(
			subHours(addMinutes(new Date(), new Date().getTimezoneOffset()), 1),
			'HH:mm'
		),
		endTime: format(endOfDay(new Date()), 'HH:mm')
	});
	const initializer = useFormInitializer();

	const handleSubmitForm = (data: CdrSearchIndexRequest) => {
		setResourceParams(data);
		setActiveTab(tabs.Results.header);
	};

	return (
		<TabbedLayout
			router={CdrSearchReportRouter}
			activeTab={activeTab.header}
			onTabChange={(activeTab) => setActiveTab(activeTab)}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<TwoFADisabledWarning />
				<RtxSearchForm<CdrSearchIndexRequest>
					onSubmit={handleSubmitForm}
					defaultValues={resourceParams}
					initializer={initializer}
					onLoadUrlParams={handleSubmitForm}
				>
					{({ control }) => (
						<Row>
							<Col md={6}>
								<h5>Date and Time</h5>
								<hr />
								<Controller
									control={control}
									name="callDate"
									render={({ field: { onChange, value } }) => (
										<DatetimeFormControl
											className="inline-form"
											required
											showDateOnly
											label="Call Date"
											value={moment(value)}
											onChange={(callDate: moment.Moment) =>
												onChange(callDate.toDate())
											}
										/>
									)}
								/>
								<Controller
									control={control}
									name="startTime"
									render={({ field: { onChange, value } }) => (
										<TimeFormControl
											label="Start Time (UTC)"
											onChange={onChange}
											className="mb-3"
											value={value}
										/>
									)}
								/>
								<div style={{ marginBottom: '1rem' }}>
									<Controller
										control={control}
										name="endTime"
										render={({ field: { onChange, value } }) => (
											<TimeFormControl
												label="End Time (UTC)"
												onChange={onChange}
												className="mb-3"
												value={value}
											/>
										)}
									/>
								</div>
								<h5>Customer</h5>
								<hr />
								<Controller
									control={control}
									name="revenueAccountIds"
									render={({ field: { onChange, value } }) => (
										<AccountSelect<true>
											label="Accounts"
											controlGroupClassName="inline-form"
											value={revenueAccounts}
											onChange={(revenueAccounts: AccountIndexResponse[]) => {
												setRevenueAccounts(revenueAccounts);
												onChange(
													revenueAccounts.map((account) => account.accountId)
												);
											}}
											multi
											initialOptionId={value?.map((val) => val.toString())}
										/>
									)}
								/>
								<Controller
									control={control}
									name="revenueSubscriptionIds"
									render={({ field: { onChange, value } }) => (
										<SubscriptionSelect<true>
											label="Subscriptions"
											controlGroupClassName="inline-form"
											value={revenueSubscriptions}
											onChange={(
												revenueSubscriptions: SubscriptionIndexResponse[]
											) => {
												setRevenueSubscriptions(revenueSubscriptions);
												onChange(
													revenueSubscriptions.map(
														(subscription) => subscription.subscriptionId
													)
												);
											}}
											isMulti
											initialOptionId={value}
										/>
									)}
								/>
								<h5>Calling party</h5>
								<hr />
								<Controller
									control={control}
									name="callingPartyStd"
									render={({ field: { onChange, value } }) => (
										<InputFormControl
											className="inline-form no-arrows"
											label="Number (begins)"
											type="number"
											min={0}
											value={value}
											onChange={onChange}
										/>
									)}
								/>
								<Controller
									control={control}
									name="callingPartyDigits"
									render={({ field: { onChange, value } }) => (
										<InputFormControl
											className="inline-form no-arrows"
											label="Digits (contains)"
											type="number"
											min={0}
											value={value}
											onChange={onChange}
										/>
									)}
								/>
								<Controller
									control={control}
									name="callingPartyCountryIsoIds"
									render={({ field: { onChange, value } }) => (
										<CountrySelect<true>
											isMulti
											label="Countries"
											value={callingPartyCountry}
											onChange={(callingPartyCountry) => {
												setCallingPartyCountry(callingPartyCountry);
												onChange(
													callingPartyCountry.map(
														(country) => country.iso3166Alpha3
													)
												);
											}}
											initialOptionId={value}
										/>
									)}
								/>
								<Controller
									control={control}
									name="callingPartyRateCenterIds"
									render={({ field: { onChange, value } }) => (
										<RateCenterSelect<true>
											multi
											label="Rate Centers"
											value={callingPartyRateCenter}
											onChange={(callingPartyRateCenter) => {
												setCallingPartyRateCenter(callingPartyRateCenter);
												onChange(
													callingPartyRateCenter.map(
														(rateCenter) => rateCenter.rateCenterId
													)
												);
											}}
											initialOptionId={value?.map((val) => val.toString())}
										/>
									)}
								/>
								<Controller
									control={control}
									name="columns"
									render={({ field: { onChange, value } }) => (
										<MultipleCheckboxFormControl<CdrSearchColumns>
											label="Options"
											onChange={onChange}
											value={value}
											options={[
												{
													label: 'Show Rates',
													value: CdrSearchColumns.ShowRates
												},
												{
													label: 'Show Billing Information',
													value: CdrSearchColumns.ShowBilling
												},
												{
													label: 'Show LRN (for NANP)',
													value: CdrSearchColumns.ShowLrn
												}
											]}
										/>
									)}
								/>
							</Col>
							<Col md={6}>
								<h5>Miscellaneous</h5>
								<hr />
								<Controller
									control={control}
									name="inMin"
									render={({ field: { onChange, value } }) => (
										<InputFormControl
											type="number"
											value={value?.toString()}
											label="Min. Seconds"
											onChange={(inMin) => onChange(Number(inMin))}
										/>
									)}
								/>
								<Controller
									control={control}
									name="inMax"
									render={({ field: { onChange, value } }) => (
										<InputFormControl
											type="number"
											value={value?.toString()}
											label="Max. Seconds"
											onChange={(inMax) => onChange(Number(inMax))}
										/>
									)}
								/>
								<Controller
									control={control}
									name="cdrId"
									render={({ field: { onChange, value } }) => (
										<InputFormControl
											type="text"
											value={value?.toString()}
											label="CDR Id"
											onChange={(cdrId) => onChange(Number(cdrId))}
										/>
									)}
								/>
								<h5>Vendor</h5>
								<hr />
								<Controller
									control={control}
									name="costAccountIds"
									render={({ field: { onChange, value } }) => (
										<AccountSelect<true>
											label="Accounts"
											value={costAccounts}
											onChange={(costAccounts) => {
												setCostAccounts(costAccounts);
												onChange(
													costAccounts.map((account) => account.accountId)
												);
											}}
											initialOptionId={value?.map((value) => value.toString())}
											multi
										/>
									)}
								/>
								<Controller
									control={control}
									name="costSubscriptionIds"
									render={({ field: { onChange, value } }) => (
										<SubscriptionSelect<true>
											label="Subscriptions"
											value={costSubscriptions}
											onChange={(costSubscriptions) => {
												setCostSubscriptions(costSubscriptions);
												onChange(
													costSubscriptions.map(
														(subscription) => subscription.subscriptionId
													)
												);
											}}
											initialOptionId={value}
											isMulti
										/>
									)}
								/>
								<h5>Destination</h5>
								<hr />
								<Controller
									control={control}
									name="destinationStd"
									render={({ field: { onChange, value } }) => (
										<InputFormControl
											className="inline-form no-arrows"
											label="Number (begins)"
											type="number"
											min={0}
											value={value}
											onChange={onChange}
										/>
									)}
								/>
								<Controller
									control={control}
									name="destinationDigits"
									render={({ field: { onChange, value } }) => (
										<InputFormControl
											className="inline-form no-arrows"
											label="Digits (contains)"
											value={value}
											onChange={onChange}
											type="number"
											min={0}
										/>
									)}
								/>
								<Controller
									control={control}
									name="destinationCountryIsoIds"
									render={({ field: { onChange, value } }) => (
										<CountrySelect<true>
											isMulti
											label="Countries"
											value={destinationCountry}
											onChange={(destinationCountry) => {
												setDestinationCountry(destinationCountry);
												onChange(
													destinationCountry.map(
														(country) => country.iso3166Alpha3
													)
												);
											}}
											initialOptionId={value}
										/>
									)}
								/>
								<Controller
									control={control}
									name="destinationRateCenterIds"
									render={({ field: { onChange, value } }) => (
										<RateCenterSelect<true>
											multi
											label="Rate Centers"
											value={destinationRateCenter}
											onChange={(destinationRateCenter) => {
												setDestinationRateCenter(destinationRateCenter);
												onChange(
													destinationRateCenter.map(
														(rateCenter) => rateCenter.rateCenterId
													)
												);
											}}
											initialOptionId={value?.map((value) => value.toString())}
										/>
									)}
								/>
							</Col>
						</Row>
					)}
				</RtxSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<CdrSearchReportGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

CdrSearchReportIndexContainer.displayName = 'CdrSearchReportIndexContainer';
CdrSearchReportRouter.setIndexRtUiFunctionalComponent(
	CdrSearchReportIndexContainer,
	{
		groupName: 'Network Ops',
		orderPriority: 40
	}
);
