import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { FileShareCreateRequest, FileShareProfileResponse } from 'RtModels';
import { createFileShare } from 'RtUi/app/user/MyProfile/lib/services';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtxForm } from 'RtUi/components/rtx/form';
import { DragAndDropFileUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFileUploader';

interface IFileSharingUploadFormProps {
	onCreate: (profile: FileShareProfileResponse) => void;
	onCancel?: () => void;
}

export const FileSharingUploadForm = ({
	onCreate,
	onCancel
}: IFileSharingUploadFormProps) => {
	const [file, setFile] = useState<File>();
	const { mutateAsync } = useMutation(createFileShare);

	const handleSubmit = async (body: FileShareCreateRequest) => {
		if (!file) {
			return;
		}

		const data = await mutateAsync({ file, body });
		onCreate(data);
	};

	return (
		<RtxForm<FileShareCreateRequest>
			onSubmit={handleSubmit}
			onCancel={onCancel}
		>
			{({ control }) => (
				<>
					<Controller
						control={control}
						name="label"
						render={({ field: { onChange, value = '' } }) => (
							<InputFormControl
								label="Label"
								onChange={onChange}
								value={String(value)}
								required
							/>
						)}
					/>
					<DragAndDropFileUploader required value={file} onDrop={setFile} />
				</>
			)}
		</RtxForm>
	);
};
