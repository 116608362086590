import {
	CprRateSheetProfileRequest,
	CprRateSheetResultsProfileResponse
} from 'RtModels';
import {
	fetchCprRateResultsSheets,
	useGetCprRateResultsSheets
} from 'RtUi/app/rtLco/RateSheets/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCprRateSheetResultsColumns } from 'RtUi/components/data/DataGrid/configurations/rtLco/cprRateSheets';
import { useGetFullResults } from 'RtUi/components/hooks/useGetFullResults';
import { useMemo } from 'react';

interface IRateSheetResultsGridProps {
	resourceParams: CprRateSheetProfileRequest;
	rateSheetTypeId: number;
	autoFocusFilter?: boolean;
}

export const RateSheetResultsGrid = ({
	resourceParams,
	rateSheetTypeId
}: IRateSheetResultsGridProps) => {
	const { data, isFetching: isLoading } =
		useGetCprRateResultsSheets(resourceParams);

	const fullResultsReq = useGetFullResults(
		['exportCprRateResultsSheets', resourceParams],
		{},
		(params) => fetchCprRateResultsSheets(resourceParams, params)
	);

	const rateKeyLabels = useMemo(() => {
		if (rateSheetTypeId === 2) {
			return ['LATA', 'OCN', 'NPA', 'NXX'];
		}

		return ['NPA', 'NXX', 'LATA', 'OCN'];
	}, [rateSheetTypeId]);

	const columns = useMemo(
		() => getCprRateSheetResultsColumns(rateKeyLabels),
		[rateKeyLabels]
	);

	return (
		<DataGrid<CprRateSheetResultsProfileResponse>
			data={data?.data}
			columns={columns}
			enableExternalExport
			getExternalExportData={fullResultsReq}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rtLco_rateSheetResults'}
		/>
	);
};

RateSheetResultsGrid.displayName = 'RateSheetResultsGrid';
