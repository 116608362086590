import {
	JobIndexResponse,
	JobStatusId,
	JobTaskIndexResponse,
	JobTaskRecord
} from 'RtModels';
import { JobStatusHelper } from 'RtUi/app/rtCarrierConnect/Jobs/bin/JobStatusHelper';
import { IJobTasksHashMap } from 'RtUi/app/rtCarrierConnect/Jobs/lib/grids/JobTaskRecordGrid';
import {
	DataGridColumn,
	RowThemeColor
} from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnAlignment,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { JobTaskStatusDataGridColumn } from 'RtUi/components/data/DataGrid/columns/JobTaskStatusDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { mapPriorityToLabel } from 'RtUi/utils/maps';

export const getJobsColumns = (): Array<DataGridColumn<JobIndexResponse>> => [
	DefaultDataGridColumn({
		accessorKey: 'jobId',
		header: 'Job ID',
		minSize: 240
	}),
	DefaultDataGridColumn({
		accessorKey: 'jobSummary',
		header: 'Summary',
		minSize: 180
	}),
	DefaultDataGridColumn({
		accessorKey: 'jobLabel',
		header: 'Job name',
		minSize: 160
	}),
	DefaultDataGridColumn({
		accessorKey: 'ref1',
		header: 'Reference',
		minSize: 60
	}),
	IntegerDataGridColumn({
		accessorKey: 'totalQty',
		header: 'Qty'
	}),
	DefaultDataGridColumn({
		accessorKey: 'jobStatus',
		header: 'Status'
	}),
	IntegerDataGridColumn({
		accessorKey: 'tfnErrorQty',
		header: 'Error Qty',
		highlightCell: ({ cell }) =>
			cell.getValue<number>() > 0 ? RowThemeColor.DANGER : undefined
	}),
	DefaultDataGridColumn({
		header: 'Priority',
		accessorFn: (row) => mapPriorityToLabel(row.priority as number)
	}),
	IntegerDataGridColumn({
		accessorKey: 'resourceQty',
		header: 'Res Qty'
	}),
	IntegerDataGridColumn({
		accessorKey: 'resWithErrorQty',
		header: 'Res Error Qty',
		highlightCell: ({ cell }) =>
			cell.getValue<number>() > 0 ? RowThemeColor.DANGER : undefined
	}),
	DefaultDataGridColumn({
		accessorKey: 'resourcesUsed',
		header: 'Resources',
		isHidden: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Submitted'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'completedTs',
		header: 'Completed'
	}),
	DefaultDataGridColumn({
		accessorKey: 'userEmail',
		header: 'Submitted By'
	})
];

export const getTasksColumns = (): Array<
	DataGridColumn<JobTaskIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'referenceKey',
		header: 'Reference'
	}),
	JobTaskStatusDataGridColumn({
		accessorKey: 'taskStatusId',
		header: 'Status'
	}),
	DefaultDataGridColumn({
		accessorKey: 'resErrorText',
		header: 'Response'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updatedTs',
		header: 'Last Updated'
	})
];

export const getJobTaskRecordColumns = (
	jobTasksMap: IJobTasksHashMap
): Array<DataGridColumn<JobTaskRecord>> => [
	DefaultDataGridColumn({
		accessorKey: 'referenceKey',
		header: 'Reference'
	}),
	DefaultDataGridColumn({
		accessorKey: 'referenceKey',
		header: 'Previous Status',
		align: DataGridColumnAlignment.CENTER,
		getValue: ({ cell }) => {
			const referenceKey = cell.getValue<string>();
			const jobStatusHelper = new JobStatusHelper();
			if (!(referenceKey in jobTasksMap)) {
				return null;
			}
			const jobTask = jobTasksMap[referenceKey];

			return jobStatusHelper.renderJobStatus(jobTask.taskStatusId);
		}
	}),
	DefaultDataGridColumn({
		accessorKey: 'errorText',
		header: 'Status',
		align: DataGridColumnAlignment.CENTER,
		getValue: ({ cell }) => {
			const jobStatusHelper = new JobStatusHelper();
			const hasError = Boolean(cell.getValue());
			const jobStatus = hasError
				? JobStatusId.COMPLETED_WITH_ERROR
				: JobStatusId.COMPLETED_NO_ERROR;

			return jobStatusHelper.renderJobStatus(jobStatus);
		}
	}),
	DefaultDataGridColumn({
		accessorKey: 'errorText',
		header: 'Response'
	})
];
