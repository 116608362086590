import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { LcrScenarioRouter } from 'RtUi/app/rtLco/Scenarios/LcrScenario.router';
import { LcrScenariosGrid } from 'RtUi/app/rtLco/Scenarios/lib/grids/LcrScenariosGrid';
import { TemplateSelect } from 'RtUi/app/rt800/Templates/lib/controls/TemplateSelect';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TemplateIndexResponse } from 'RtModels';

interface IInitialResourceParams {
	search: string;
	isActive?: number | null;
	template: TemplateIndexResponse | undefined;
}

interface ILcrScenarioIndexContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {}

@LcrScenarioRouter.getIndexRtUiController()
export class LcrScenarioIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	ILcrScenarioIndexContainerState
> {
	public initParams: IInitialResourceParams = {
		search: '',
		isActive: null,
		template: undefined
	};

	public state: ILcrScenarioIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {}
	};

	constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { search, isActive, template } = this.state;

		const resourceParams: any = {};

		if (search) {
			resourceParams.search = search;
		}

		if (template) {
			resourceParams.templateName = template.templateName;
		}

		if (isActive !== null) {
			resourceParams.isActive = isActive;
		}

		return resourceParams;
	}

	public render() {
		return (
			<TabbedLayout
				router={LcrScenarioRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<TemplateSelect
							onChange={(template: TemplateIndexResponse) =>
								this.setState({ template })
							}
							value={this.state.template}
						/>
						<IsActiveRadioFormControl
							onChange={(isActive) => this.setState({ isActive })}
							value={this.state.isActive}
						/>
						<InputFormControl
							label="Search"
							onChange={(search) => this.setState({ search })}
							value={this.state.search}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<LcrScenariosGrid
						autoFocusFilter
						resourceParams={this.state.resourceParams}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
