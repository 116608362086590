import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	PortLimitIndexResponse,
	PortLimitUpdateRequest,
	PortLimitProfileRequest,
	PortLimitCreateRequest,
	PortLimitProfileResponse
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class PortLimitResource extends HttpResource<
	PortLimitIndexResponse,
	PortLimitProfileResponse
> {
	constructor() {
		super(RtxSipApiRoutes.PortLimits);
	}

	public create(createRequest: PortLimitCreateRequest) {
		const body = JSON.stringify(createRequest);

		return this.fetchWithRoute<PortLimitProfileResponse>(
			RtxSipApiRoutes.PortLimits.Create,
			{
				body
			}
		);
	}

	public update(
		portLimitId: number,
		ingressPortLimit: number | null,
		egressPortLimit: number | null
	) {
		const req: PortLimitUpdateRequest = { ingressPortLimit, egressPortLimit };
		const urlParams: PortLimitProfileRequest = { portLimitId };

		const body = JSON.stringify(req);

		return this.fetchWithRoute<PortLimitProfileResponse>(
			RtxSipApiRoutes.PortLimits.Update,
			{
				body,
				urlParams
			}
		);
	}

	public delete(portLimitId: number) {
		const urlParams: PortLimitProfileRequest = { portLimitId };
		return this.fetchWithRoute(RtxSipApiRoutes.PortLimits.Delete, {
			urlParams
		});
	}
}
