import { useQuery } from 'react-query';
import {
	CallTypeFilterIndexResponse,
	ConnectionIndexRequest,
	ConnectionIndexResponse,
	GuardianConnectionSubscriptionIndexRequest,
	GuardianConnectionSubscriptionIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import { RtVueApiRoutes } from 'RtExports/routes';

export const fetchConnections = async <
	IncludeFullResponse extends boolean = false
>(
	queryParams?: ConnectionIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<ConnectionIndexResponse[], IncludeFullResponse>> => {
	return handleGetRequest(RtVueApiRoutes.Connections.Index, {
		queryParams,
		includeFullResponse
	});
};

export const useGetConnections = <IncludeFullResponse extends boolean = false>(
	queryParams?: ConnectionIndexRequest,
	includeFullResponse?: IncludeFullResponse
) => {
	return useQuery<
		RequestResponse<ConnectionIndexResponse[], IncludeFullResponse>,
		Error
	>(
		[`getConnections`, queryParams],
		() => fetchConnections(queryParams, includeFullResponse),
		{ enabled: true }
	);
};

export const fetchCallTypes = async <
	IncludeFullResponse extends boolean = false
>(
	queryParams?: CallTypeFilterIndexResponse,
	includeFullResponse?: IncludeFullResponse
): Promise<
	RequestResponse<CallTypeFilterIndexResponse, IncludeFullResponse>
> => {
	return handleGetRequest(RtVueApiRoutes.CallTypeFilter.Index, {
		queryParams,
		includeFullResponse
	});
};

export const useGetCallTypes = <IncludeFullResponse extends boolean = false>(
	queryParams?: CallTypeFilterIndexResponse,
	includeFullResponse?: IncludeFullResponse
) => {
	return useQuery<
		RequestResponse<CallTypeFilterIndexResponse, IncludeFullResponse>,
		Error
	>(
		[`getCallTypes`, queryParams],
		() => fetchCallTypes(queryParams, includeFullResponse),
		{ enabled: true }
	);
};

const fetchConnectionSubscriptions = async (
	queryParams?: GuardianConnectionSubscriptionIndexRequest
) => {
	return handleGetRequest<GuardianConnectionSubscriptionIndexResponse[], true>(
		RtVueApiRoutes.GuardianConnectionSubscriptions.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetConnectionSubscriptions = (
	queryParams?: GuardianConnectionSubscriptionIndexRequest
) => {
	return useQuery<
		FullResponse<GuardianConnectionSubscriptionIndexResponse[]>,
		Error
	>(
		[`useGetConnectionSubscriptions`, queryParams],
		() => fetchConnectionSubscriptions(queryParams),
		{ enabled: true }
	);
};
