import { useCallback, useMemo } from 'react';
import { TrackingGroupIndexResponse } from 'RtModels';
import { useGetTrackingGroups } from 'RtUi/app/rtSip/TrackingGroups/lib/services';
import { TrackingGroupRouter } from 'RtUi/app/rtSip/TrackingGroups/TrackingGroup.router';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxTrackingGroupSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		TrackingGroupIndexResponse,
		'trackingGroupId',
		IsMulti,
		IsClearable
	> {
	showAllOption?: boolean;
}

export const RtxTrackingGroupSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	showAllOption = false,
	label = 'Tracking Group',
	...props
}: IRtxTrackingGroupSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetTrackingGroups();
	const options = useMemo(() => {
		if (!showAllOption) {
			return data;
		}

		return [
			{
				trackingGroupId: 0,
				label: 'All',
				hasIngressConnections: 0,
				hasEgressConnections: 0,
				isCustomerTrackingGroup: 0,
				isVendorTrackingGroup: 0,
				playLimit: 0,
				dialLimit: 0,
				expirationSeconds: 0
			},
			...data
		];
	}, [data, showAllOption]);

	const formatOptionLabel = useCallback(
		(trackingGroup: TrackingGroupIndexResponse) => {
			return (
				<span>
					{trackingGroup.trackingGroupId} ({trackingGroup.label})
				</span>
			);
		},
		[]
	);

	return (
		<RtxSelectInput<
			TrackingGroupIndexResponse,
			'trackingGroupId',
			IsMulti,
			IsClearable
		>
			label={label}
			valueKey="trackingGroupId"
			labelKey="label"
			router={TrackingGroupRouter}
			options={options}
			formatOptionLabel={formatOptionLabel}
			isLoading={isLoading}
			{...props}
		/>
	);
};
