import { AverageCallDurationDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AverageCallDurationDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { HeaderDataGridColumn } from 'RtUi/components/data/DataGrid/columns/HeaderDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { MinutesDataGridColumn } from 'RtUi/components/data/DataGrid/columns/MinutesDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { SipFullSummaryIndexResponse } from 'RtModels';
import { sumBy } from 'lodash-es';
import { DataGridColumnAlignment } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

export const getFixedRatingSummaryColumns = (): Array<
	DataGridColumn<SipFullSummaryIndexResponse>
> => [
	HeaderDataGridColumn({
		header: 'Ingress Totals',
		columns: [
			PrecisionDataGridColumn({
				accessorKey: 'inAtt',
				header: 'Atts',
				showFooter: true
			}),
			IntegerDataGridColumn({
				accessorKey: 'inCon',
				header: 'Cons',
				showFooter: true
			}),
			IntegerDataGridColumn({
				accessorKey: 'cpUnq',
				header: 'CP Unq'
			}),
			IntegerDataGridColumn({
				accessorKey: 'dnUnq',
				header: 'DN Unq'
			}),
			MinutesDataGridColumn({
				accessorKey: 'inRaw',
				header: 'Raw Min',
				showFooter: true
			}),
			AverageCallDurationDataGridColumn({
				accessorKey: 'inAcd',
				header: 'ACD',
				showFooter: true,
				align: DataGridColumnAlignment.RIGHT,
				connections: (rows) => sumBy(rows, 'inCon'),
				duration: (rows) => sumBy(rows, 'inRaw')
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Egress Totals',
		columns: [
			IntegerDataGridColumn({
				accessorKey: 'egAtt',
				header: 'Atts',
				showFooter: true
			}),
			IntegerDataGridColumn({
				accessorKey: 'egCon',
				header: 'Cons',
				showFooter: true
			}),
			MinutesDataGridColumn({
				accessorKey: 'egRaw',
				header: 'Raw Min',
				showFooter: true
			}),
			AverageCallDurationDataGridColumn({
				accessorKey: 'egAcd',
				header: 'ACD',
				showFooter: true,
				align: DataGridColumnAlignment.RIGHT,
				connections: (rows) => sumBy(rows, 'egCon'),
				duration: (rows) => sumBy(rows, 'egRaw')
			})
		]
	})
];
