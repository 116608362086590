import { AccountIndexRequest, AccountProfileResponse } from 'RtModels';
import { AccountEditForm } from 'RtUi/app/AccountManagement/Accounts/lib/forms/AccountEditForm';
import { AccountsDataGrid } from 'RtUi/app/AccountManagement/Accounts/lib/grids/AccountsDataGrid';
import { RtxSearchForm } from 'RtUi/components/rtx/form/RtxUiSearchForm';
import { useFormInitializer } from 'RtUi/components/rtx/form/hooks/useFormInitializer';
import { RtxIsActiveInput } from 'RtUi/components/rtx/inputs/IsActive/RtxIsActiveInput';
import { RtxTextInput } from 'RtUi/components/rtx/inputs/Text/RtxTextInput';
import {
	TabbedLayout,
	TabbedLayoutHelpContainer,
	TabbedLayoutTab
} from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { isEqual } from 'lodash-es';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AccountRouter } from './Account.router';

export const AccountIndexContainer = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDefault: true },
		Create: { header: 'Create Account', isAction: true }
	});
	const [resourceParams, setResourceParams] = useState<AccountIndexRequest>({
		isActive: 1
	});

	const formInitializer = useFormInitializer();
	const navigate = useNavigate();

	const goToProfile = (profile: AccountProfileResponse) => {
		const profilePath = AccountRouter.getProfileRoute(profile.accountId);

		navigate(profilePath);
	};

	const submitHandler = (data: AccountIndexRequest) => {
		setResourceParams(data);
		setActiveTab(tabs.Results.header);
	};

	return (
		<TabbedLayout
			router={AccountRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<Row>
					<Col md={4}>
						<RtxSearchForm<AccountIndexRequest>
							initializer={formInitializer}
							onSubmit={submitHandler}
							defaultValues={resourceParams}
							onLoadUrlParams={(urlParams) => {
								if (!isEqual(urlParams, resourceParams)) {
									submitHandler(urlParams);
								}
							}}
						>
							{({ control }) => (
								<>
									<Controller
										control={control}
										name="search"
										render={({ field: { onChange, value } }) => (
											<RtxTextInput
												label="Search"
												className="mb-3"
												onChange={onChange}
												value={value}
											/>
										)}
									/>
									<Controller
										control={control}
										name="isActive"
										render={({ field: { onChange, value } }) => (
											<RtxIsActiveInput
												label="Status"
												onChange={onChange}
												value={value}
											/>
										)}
									/>
								</>
							)}
						</RtxSearchForm>
					</Col>
				</Row>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<AccountsDataGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Create}>
				<AccountEditForm onSuccess={goToProfile} />
			</TabbedLayoutTab>

			<TabbedLayoutHelpContainer tab={tabs.Results.header}>
				<p>
					Access to Routing Groups disaster recovery tool are based on your
					RespOrg id permissions.
				</p>
			</TabbedLayoutHelpContainer>
		</TabbedLayout>
	);
};

AccountRouter.setIndexRtUiFunctionalComponent(AccountIndexContainer, {
	description: 'Retrieve a list of Accounts.',
	orderPriority: 0
});
