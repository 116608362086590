import { EntityIndexResponse } from 'RtModels';
import { useGetEntities } from 'RtUi/app/rt800/Entities/lib/services';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxEntitySelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		EntityIndexResponse,
		'entityId',
		IsMulti,
		IsClearable
	> {
	allowAll?: boolean;
	isActive?: boolean;
}

export const RtxEntitySelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	displayMode,
	label = 'Entity',
	isActive,
	allowAll = false,
	...props
}: IRtxEntitySelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetEntities({
		...(isActive !== undefined && { isActive: Number(isActive) }),
		...(!allowAll && { isManaged: 1 })
	});

	const optionRenderer = (entity: EntityIndexResponse) => (
		<span>
			{entity.entityId} ({entity.label})
		</span>
	);

	return (
		<RtxSelectInput<EntityIndexResponse, 'entityId', IsMulti, IsClearable>
			label={label}
			valueKey="entityId"
			labelKey="label"
			options={data}
			displayMode={displayMode}
			isLoading={isLoading}
			formatOptionLabel={optionRenderer}
			{...props}
		/>
	);
};
