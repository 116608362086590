import { PeeringTypeIndexResponse } from 'RtModels';
import { useGetPeeringTypes } from 'RtUi/app/rtLcr/SubscriptionPeering/services';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';

export interface IRtxPeeringTypeSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		PeeringTypeIndexResponse,
		'peeringTypeId',
		IsMulti,
		IsClearable
	> {}

export const RtxPeeringTypeSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	displayMode,
	label = 'peeringType',
	...props
}: IRtxPeeringTypeSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetPeeringTypes();

	return (
		<RtxSelectInput<
			PeeringTypeIndexResponse,
			'peeringTypeId',
			IsMulti,
			IsClearable
		>
			label={label}
			valueKey="peeringTypeId"
			labelKey="label"
			options={data}
			displayMode={displayMode}
			isLoading={isLoading}
			{...props}
		/>
	);
};
