import { useQuery } from 'react-query';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { RoutePlanIndexRequest, RoutePlanIndexResponse } from 'RtModels';
import {
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchRoutePlans = async <IncludeFullResponse extends boolean = false>(
	queryParams?: RoutePlanIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<RoutePlanIndexResponse[], IncludeFullResponse>> => {
	return handleGetRequest(RtxSipApiRoutes.RoutePlans.Index, {
		queryParams,
		includeFullResponse
	});
};

export const useGetRoutePlans = <IncludeFullResponse extends boolean = false>(
	params?: RoutePlanIndexRequest,
	includeFullResponse?: IncludeFullResponse
) => {
	return useQuery<
		RequestResponse<RoutePlanIndexResponse[], IncludeFullResponse>,
		Error
	>(['getRoutePlan', params, includeFullResponse], () =>
		fetchRoutePlans(params, includeFullResponse)
	);
};
