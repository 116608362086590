/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
	CountryIndexResponse,
	DialCodeIndexRequest,
	LataIndexResponse,
	Lerg6IndexRequest,
	LergIndexRequest,
	RateCenterIndexResponse
} from 'RtModels';
import { RateCenterSelect } from 'RtUi/app/rtSip/MarginSummaryReport/lib/controls/RateCenterSelect';
import { DialCodeRouter } from 'RtUi/app/rtVue/DialCode/DialCode.router';
import { LataSelect } from 'RtUi/app/rtVue/DialCode/lib/controls/LataSelect';
import { DialCodeGrid } from 'RtUi/app/rtVue/DialCode/lib/grids/DialCodeGrid';
import { Lerg6Grid } from 'RtUi/app/rtVue/DialCode/lib/grids/Lerg6Grid';
import { LrnGrid } from 'RtUi/app/rtVue/DialCode/lib/grids/LrnGrid';
import { SomosLergGrid } from 'RtUi/app/rtVue/DialCode/lib/grids/SomosLergGrid';
import { DoNotOriginateGrid } from 'RtUi/app/rtVue/DoNotOriginate/lib/grids/DoNotOriginateGrid';
import { CountrySelect } from 'RtUi/app/user/lib/controls/CountrySelect';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { isEmpty, isNil } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Button, Card, Nav } from 'react-bootstrap';

type DialCodeComplexParams = {
	dialCodeParams: DialCodeIndexRequest;
	lerg6Params: Lerg6IndexRequest;
	somosLerg: LergIndexRequest;
	nanpNumber?: string;
	ani?: string;
};

enum ContentTabs {
	DialCode = 'dialcode',
	Lerg = 'lerg',
	Somos = 'somos',
	DoNotOriginate = 'dno',
	Lrn = 'lrn'
}

const LRN_FORMAT_REGEX = /^1(\d{6})(\d{4})$/g;

// @DialCodeRouter.getIndexRtUiController({
// 	groupName: 'Helpful Tools',
// 	groupId: 'helpfulTools',
// 	orderPriority: 10,
// 	groupOrderPriority: 1090
// })

export const DialCodeIndexContainer = (): JSX.Element => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search', isDefault: true },
		Results: { header: 'Results' }
	});
	const [isActive, setIsActive] = useState<number | null>(1);
	const [rateKey, setRateKey] = useState<string>();
	const [countries, setCountries] = useState<CountryIndexResponse[]>();
	const [rateCenters, setRateCenters] = useState<RateCenterIndexResponse[]>();
	const [lata, setLata] = useState<LataIndexResponse>();
	const [nanp, setNanp] = useState<string>();
	const [nanpValidationError, setNanpValidationError] = useState<string>();
	const [selectedKey, setSelectedKey] = useState(ContentTabs.DialCode);

	const resourceParams = useMemo(() => {
		const params: DialCodeComplexParams = {
			dialCodeParams: {
				pageSize: 5000
			},
			lerg6Params: {},
			somosLerg: {}
		};

		if (isActive !== null) {
			params.dialCodeParams.isActive = isActive;
		}

		if (!isNil(nanp) && nanp.match(LRN_FORMAT_REGEX)) {
			const nanpNumber = nanp.replace(LRN_FORMAT_REGEX, '$1$2');
			params.nanpNumber = nanpNumber;
			params.dialCodeParams.dialCode = nanp.replace(LRN_FORMAT_REGEX, '1$1');

			const npanxx = nanp.replace(LRN_FORMAT_REGEX, '$1');
			params.lerg6Params.npanxx = npanxx;
			params.somosLerg.npa = npanxx.substring(0, 3);
			params.somosLerg.nxx = npanxx.substring(3, 6);

			params.ani = nanpNumber;
		}

		if (!isNil(rateKey)) {
			params.dialCodeParams.dialCode = rateKey;

			const npanxx = rateKey.startsWith('1') ? rateKey.substring(1) : '';

			if (npanxx) {
				params.ani = rateKey;
				params.lerg6Params.npanxx = npanxx;
			}

			if (npanxx.length >= 6) {
				params.somosLerg.npa = npanxx.substring(0, 3);
				params.somosLerg.nxx = npanxx.substring(3, 6);
			}
		}

		if (!isNil(rateCenters)) {
			params.dialCodeParams.rateCenterIds = rateCenters.map(
				(rateCenter) => rateCenter.rateCenterId
			);
		}

		if (!isNil(lata)) {
			params.dialCodeParams.countrySpecific = lata.lata;
			params.lerg6Params.lataName = lata.lata;
		}

		if (!isNil(countries)) {
			params.dialCodeParams.iso3166Alpha3s = countries.map(
				(country) => country.iso3166Alpha3
			);
		}

		return params;
	}, [countries, isActive, lata, nanp, rateCenters, rateKey]);

	const showLerg = !isEmpty(resourceParams.lerg6Params);
	const showSomosLerg = !isEmpty(resourceParams.somosLerg);
	const showLrn = !isNil(resourceParams.nanpNumber);
	const showDoNotOriginate = Boolean(resourceParams.ani);
	const additionalTabs =
		showLerg || showSomosLerg || showDoNotOriginate || showLrn;

	const handleSubmit = (e: any) => {
		e.preventDefault();
		setSelectedKey(ContentTabs.DialCode);
		setActiveTab(tabs.Results.header);
	};

	const onChangeNanpHandler = (value: string) => {
		setNanp(value);
		setNanpValidationError(undefined);

		if (value && !value.match(LRN_FORMAT_REGEX)) {
			setNanpValidationError('Invalid NANP number');
		}
	};

	return (
		<TabbedLayout
			router={DialCodeRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm onSubmit={(e) => handleSubmit(e)}>
					<CountrySelect<true>
						isMulti
						label="Countries"
						value={countries}
						onChange={setCountries}
					/>
					<RateCenterSelect<true>
						label="Rate Center"
						value={rateCenters}
						onChange={setRateCenters}
						multi
					/>
					<InputFormControl
						label="Rate Key"
						displayMode={false}
						onChange={setRateKey}
						value={rateKey}
					/>
					<InputFormControl
						label="NANP number"
						type="number"
						customValidityError={nanpValidationError}
						displayMode={false}
						onChange={onChangeNanpHandler}
						value={nanp}
					/>
					<LataSelect displayMode={false} onChange={setLata} value={lata} />
					<IsActiveRadioFormControl onChange={setIsActive} value={isActive} />
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab header={tabs.Results.header}>
				<Card>
					{additionalTabs && (
						<Nav
							variant="tabs"
							defaultActiveKey={selectedKey}
							onSelect={(selectedKey) =>
								setSelectedKey(
									(selectedKey as ContentTabs) ?? ContentTabs.DialCode
								)
							}
						>
							<Nav.Link as={Button} size="sm" eventKey={ContentTabs.DialCode}>
								Dial Codes
							</Nav.Link>
							{showLerg && (
								<Nav.Link as={Button} size="sm" eventKey={ContentTabs.Lerg}>
									LERG
								</Nav.Link>
							)}
							{showSomosLerg && (
								<Nav.Link as={Button} size="sm" eventKey={ContentTabs.Somos}>
									SOMOS LERG
								</Nav.Link>
							)}
							{showDoNotOriginate && (
								<Nav.Link
									as={Button}
									size="sm"
									className="m-0"
									eventKey={ContentTabs.DoNotOriginate}
								>
									Do Not Originate
								</Nav.Link>
							)}
							{showLrn && (
								<Nav.Link as={Button} size="sm" eventKey={ContentTabs.Lrn}>
									LRN
								</Nav.Link>
							)}
						</Nav>
					)}

					{selectedKey === ContentTabs.DialCode && (
						<DialCodeGrid resourceParams={resourceParams.dialCodeParams} />
					)}
					{selectedKey === ContentTabs.Lerg && (
						<Lerg6Grid resourceParams={resourceParams.lerg6Params} />
					)}
					{selectedKey === ContentTabs.Somos && (
						<SomosLergGrid resourceParams={resourceParams.somosLerg} />
					)}
					{selectedKey === ContentTabs.DoNotOriginate && (
						<DoNotOriginateGrid
							resourceParams={{
								ani: resourceParams.ani
							}}
						/>
					)}
					{selectedKey === ContentTabs.Lrn && resourceParams.nanpNumber && (
						<LrnGrid nanpNumber={resourceParams.nanpNumber} />
					)}
				</Card>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

DialCodeIndexContainer.displayName = 'DialCodeIndexContainer';

DialCodeRouter.setIndexRtUiFunctionalComponent(DialCodeIndexContainer, {
	groupName: 'Helpful Tools',
	groupId: 'helpfulTools',
	orderPriority: 10,
	groupOrderPriority: 1090
});
