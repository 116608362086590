/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LumenDidSubscriber = {
    properties: {
        businessName: {
            type: 'string',
        },
        firstName: {
            type: 'string',
        },
        lastName: {
            type: 'string',
        },
        externalSubscriberId: {
            type: 'string',
        },
        serviceAddress: {
            type: 'LumenDidServiceAddress',
            isRequired: true,
        },
    },
};