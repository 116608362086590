import { useCallback, useMemo, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { QueryClient } from 'react-query';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { useMount } from 'react-use';
import { SubscriptionIndexResponse } from 'RtModels';
import { fetchSubscriptions } from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import { SubscriptionRouter } from 'RtUi/app/AccountManagement/Subscriptions/Subscription.router';
import { AccountDataGridCache } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';
import { GenericTypeRender } from 'RtUi/components/ui/GenericTypeRenderer';

export interface IRtxSubscriptionSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		SubscriptionIndexResponse,
		'subscriptionId',
		IsMulti,
		IsClearable
	> {
	isActive?: number;
	showAccountInfo?: boolean;
	serviceIds?: number[];
	showAllOption?: boolean;
}

export const RtxSubscriptionSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	isActive = 1,
	showAllOption = false,
	serviceIds,
	showAccountInfo = true,
	displayMode,
	label = 'Subscription',
	...props
}: IRtxSubscriptionSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const queryClient = useMemo(() => new QueryClient(), []);
	const [options, setOptions] = useState<SubscriptionIndexResponse[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const promiseOptions = async (searchText: string) => {
		setIsLoading(true);
		const data = await queryClient.fetchQuery(
			['subscriptions'],
			() =>
				fetchSubscriptions({
					isActive,
					serviceIds,
					...(searchText && { search: searchText })
				}),
			{ staleTime: 30000 }
		);

		let options = [...data];

		if (showAllOption) {
			const allOption = {
				subscriptionId: 0,
				accountId: 0,
				isActive: 1,
				serviceId: 0,
				isLocked: 0,
				blockTypeId: 0,
				defaultSubscriptionId: 0,
				directSubscriptionId: 0,
				hasServiceNumbers: 0,
				note: '',
				lastRateLoadTs: new Date(),
				timezoneId: -1,
				label: 'All',
				blockType: null,
				routePlan: null,
				ratePlan: null,
				isCustomer: 0
			};

			options = [allOption, ...options];
		}

		setOptions(options);
		setIsLoading(false);
		return { options };
	};

	useMount(() => {
		AccountDataGridCache.warmup();
		promiseOptions('');
	});

	const formatOptionLabel = useCallback(
		(subscription: SubscriptionIndexResponse) => {
			if (subscription.subscriptionId === 0) {
				return <span>{subscription.label}</span>;
			}

			const label = `${subscription.label} (${subscription.subscriptionId})`;

			return (
				<section className="d-flex flex-wrap">
					<span>{label}</span>

					{typeof subscription.accountId === 'number' && showAccountInfo && (
						<Badge bg="light" text="dark" className="ms-1">
							Account&nbsp;·&nbsp;
							<GenericTypeRender
								id={subscription.accountId}
								resourceCacheMap={AccountDataGridCache}
							/>
						</Badge>
					)}
				</section>
			);
		},
		[showAccountInfo]
	);

	const filterOption = useCallback(
		(
			option: FilterOptionOption<SubscriptionIndexResponse>,
			rawInput: string
		) => {
			const { data: subscription } = option;
			if (subscription && rawInput.length > 0) {
				const accountLabel = AccountDataGridCache.getLabelFor(
					subscription.accountId!
				);

				if (accountLabel.toLowerCase().includes(rawInput)) {
					return true;
				}
			}

			return false;
		},
		[]
	);

	return (
		<RtxSelectInput<
			SubscriptionIndexResponse,
			'subscriptionId',
			IsMulti,
			IsClearable
		>
			label={label}
			valueKey="subscriptionId"
			formatOptionLabel={formatOptionLabel}
			router={SubscriptionRouter}
			labelKey="label"
			options={options}
			displayMode={displayMode}
			filterOption={filterOption}
			isAsync
			promiseOptions={promiseOptions}
			isLoading={isLoading}
			{...props}
		/>
	);
};
