import { noop } from 'lodash-es';
import { ReactNode } from 'react';
import { CallflowDirectionId, CallTypes } from 'RtModels';
import {
	RtxRadioInput,
	RtxRadioInputProps
} from 'RtUi/components/rtx/inputs/Radio/RtxRadioInput';

type RtxDirectionInputProps = Omit<
	RtxRadioInputProps<number | undefined>,
	'options' | 'label' | 'onChange'
> & {
	label?: string;
	callType?: CallTypes;
	onChange?: (value: number | undefined) => void;
};

export const RtxDirectionInput = ({
	label = 'Direction',
	onChange = noop,
	...props
}: RtxDirectionInputProps) => {
	const ingressCustomers = [
		CallTypes.Iddd,
		CallTypes.Oneplus,
		CallTypes.Tfterm,
		CallTypes.Local
	];

	let ingressLabel: ReactNode = 'Ingress';
	let egressLabel: ReactNode = 'Egress';

	if (props.callType) {
		const isIngressCustomer = ingressCustomers.includes(props.callType);
		const ingressAppend = isIngressCustomer ? 'customer' : 'vendor';
		const egressAppend = !isIngressCustomer ? 'customer' : 'vendor';

		ingressLabel = (
			<span>
				<span>Ingress</span>
				<span className="text-muted">&nbsp;({ingressAppend})</span>
			</span>
		);

		egressLabel = (
			<span>
				<span>Egress</span>
				<span className="text-muted">&nbsp;({egressAppend})</span>
			</span>
		);
	}

	return (
		<RtxRadioInput
			label={label}
			options={[
				{
					value: CallflowDirectionId.Inbound,
					label: ingressLabel
				},
				{
					value: CallflowDirectionId.Outbound,
					label: egressLabel
				}
			]}
			{...props}
			onChange={(value) => onChange(Number(value))}
		/>
	);
};
