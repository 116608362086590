import { RateCenterIndexResponse } from 'RtModels';
import {
	IRtxSelectInputInstanceProps,
	RtxSelectInput
} from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';
import { useGetRateCenters } from 'RtUi/components/rtx/inputs/Select/service';

export interface IRtxRateCenterSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends IRtxSelectInputInstanceProps<
		RateCenterIndexResponse,
		'rateCenterId',
		IsMulti,
		IsClearable
	> {}

export const RtxRateCenterSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	label = 'Rate Centers',
	...props
}: IRtxRateCenterSelectProps<IsMulti, IsClearable>): JSX.Element => {
	const { data = [], isLoading } = useGetRateCenters();

	return (
		<RtxSelectInput<
			RateCenterIndexResponse,
			'rateCenterId',
			IsMulti,
			IsClearable
		>
			label={label}
			valueKey="rateCenterId"
			labelKey="label"
			options={data}
			isLoading={isLoading}
			{...props}
		/>
	);
};
