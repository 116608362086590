/* eslint-disable @typescript-eslint/type-annotation-spacing */
import { noop } from 'lodash-es';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import {
	InvoiceCycleGroup,
	JurisPrecedenceOptions,
	SubscriptionBillingProfileResponse,
	TimezoneIndexResponse
} from 'RtModels';
import { SubscriptionResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionsResource';
import { useGetSubscriptionBilling } from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import { RtxForm } from 'RtUi/components/rtx/form';
import { RtxNumberInput } from 'RtUi/components/rtx/inputs/Number/RtxNumber';
import { RtxPrecisionInput } from 'RtUi/components/rtx/inputs/Precision/RtxPrecisionInput';
import { RtxInvoiceCycleGroupSelect } from 'RtUi/components/rtx/inputs/Select/instances/InvoiceCycleGroup/RtxInvoiceCycleGroupSelect';
import { RtxRatingPrecedenceSelect } from 'RtUi/components/rtx/inputs/Select/instances/RatingPrecedence/RtxRatingPrecedence';
import { RtxTimezoneSelect } from 'RtUi/components/rtx/inputs/Select/instances/Timezone/RtxTimezoneSelect';
import { IDefaultSelectOption } from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';
import { Loading } from 'RtUi/components/ui/Loading';
import { RtError } from 'RtUi/utils/errors/RtError';

interface ISubscriptionBillingFormProps {
	subscriptionId: number;
	onSuccess?: (profile: SubscriptionBillingProfileResponse) => void;
	onCancel?: () => void;
}

export const SubscriptionBillingForm = ({
	subscriptionId,
	onSuccess = noop
}: ISubscriptionBillingFormProps) => {
	const { data, isFetching: isLoading } = useGetSubscriptionBilling({
		subscriptionId
	});
	const [displayMode, setDisplayMode] = useState<boolean>(true);
	const [timezone, setTimezone] = useState<TimezoneIndexResponse>();
	const [invoiceCycleGroup, setInvoiceCycleGroup] =
		useState<InvoiceCycleGroup>();
	const [ratingParametersPriority, setRatingParametersPriority] =
		useState<Array<IDefaultSelectOption<JurisPrecedenceOptions>>>();
	const [error, setError] = useState<RtError>();

	if (isLoading) {
		return <Loading />;
	}

	if (!data) {
		return <p>Not found!</p>;
	}

	const onSubmit = async (data: SubscriptionBillingProfileResponse) => {
		const rs = new SubscriptionResource();
		setError(undefined);

		try {
			const profile = await rs.updateSubscriptionBilling(subscriptionId, data);
			setDisplayMode(true);
			onSuccess(profile);
		} catch (e: any) {
			setError(new RtError(e));
		}
	};

	return (
		<RtxForm<SubscriptionBillingProfileResponse>
			defaultValues={data}
			displayMode={displayMode}
			onSubmit={onSubmit}
			error={
				error && {
					name: 'root',
					error: {
						message: error.message
					}
				}
			}
			onEdit={() => setDisplayMode(false)}
			onCancel={() => setDisplayMode(true)}
		>
			{({ control }) => (
				<Row>
					<Col lg={6}>
						<Controller
							control={control}
							name="invoiceCycleGroupId"
							render={({ field: { onChange, value } }) => (
								<RtxInvoiceCycleGroupSelect
									label="Invoice Cycle Group"
									className="mb-3"
									displayMode={displayMode}
									onChange={(invoiceCycleGroup: InvoiceCycleGroup) => {
										setInvoiceCycleGroup(invoiceCycleGroup);
										onChange(invoiceCycleGroup.invoiceCycleGroupId);
									}}
									value={invoiceCycleGroup}
									initialOptionId={value ?? undefined}
								/>
							)}
						/>
						<Controller
							control={control}
							name="billDays"
							render={({ field: { onChange, value } }) => (
								<RtxNumberInput
									className="mb-3"
									required
									label="Bill Days"
									displayMode={displayMode}
									value={value}
									onChange={onChange}
								/>
							)}
						/>
						<Controller
							control={control}
							name="disputeDays"
							render={({ field: { onChange, value } }) => (
								<RtxNumberInput
									className="mb-3"
									required
									label="Dispute Days"
									displayMode={displayMode}
									value={value}
									onChange={onChange}
								/>
							)}
						/>
						<Controller
							control={control}
							name="rateKeyDeleteDays"
							render={({ field: { onChange, value } }) => (
								<RtxNumberInput
									className="mb-3"
									required
									label="Rate Key Delete Days"
									displayMode={displayMode}
									value={value}
									onChange={onChange}
								/>
							)}
						/>
						<Controller
							control={control}
							name="rateKeyExpandIncreaseDays"
							render={({ field: { onChange, value } }) => (
								<RtxNumberInput
									className="mb-3"
									required
									label="Rate Key Expand Increase Days"
									displayMode={displayMode}
									value={value}
									onChange={onChange}
								/>
							)}
						/>
						<Controller
							control={control}
							name="defaultMinimumSeconds"
							render={({ field: { onChange, value } }) => (
								<RtxNumberInput
									className="mb-3"
									label="Default Minimum Seconds"
									displayMode={displayMode}
									value={value}
									onChange={onChange}
								/>
							)}
						/>
						<Controller
							control={control}
							name="defaultIncrementSeconds"
							render={({ field: { onChange, value } }) => (
								<RtxNumberInput
									className="mb-3"
									label="Default Increment Seconds"
									displayMode={displayMode}
									value={value}
									onChange={onChange}
								/>
							)}
						/>
						<Controller
							control={control}
							name="defaultRoundingPrecision"
							render={({ field: { onChange, value = 0 } }) => (
								<RtxNumberInput
									displayMode={displayMode}
									className="mb-3"
									label="Default Rounding Precision"
									value={value}
									onChange={onChange}
								/>
							)}
						/>
					</Col>
					<Col lg={6}>
						<Controller
							control={control}
							name="timezoneId"
							render={({ field: { onChange, value } }) => (
								<RtxTimezoneSelect
									required
									displayMode={displayMode}
									onChange={(timezone: TimezoneIndexResponse) => {
										setTimezone(timezone);
										onChange(timezone.timezoneId);
									}}
									className="mb-3"
									value={timezone}
									initialOptionId={value ?? undefined}
								/>
							)}
						/>
						<Controller
							control={control}
							name="dueDays"
							render={({ field: { onChange, value } }) => (
								<RtxNumberInput
									required
									label="Due Days"
									className="mb-3"
									displayMode={displayMode}
									value={value}
									onChange={onChange}
								/>
							)}
						/>
						<Controller
							control={control}
							name="disputeResponseDays"
							render={({ field: { onChange, value } }) => (
								<RtxNumberInput
									required
									label="Dispute Response Days"
									className="mb-3"
									displayMode={displayMode}
									value={value}
									onChange={(value) => onChange(Number(value))}
								/>
							)}
						/>
						<Controller
							control={control}
							name="rateKeyMatchIncreaseDays"
							render={({ field: { onChange, value } }) => (
								<RtxNumberInput
									required
									label="Rate Key Match Increase Days"
									displayMode={displayMode}
									className="mb-3"
									value={value}
									onChange={(value) => onChange(Number(value))}
								/>
							)}
						/>
						<Controller
							control={control}
							name="defaultInterstateRate"
							render={({ field: { onChange, value } }) => (
								<RtxPrecisionInput
									precision={6}
									label="Default Interstate Rate"
									displayMode={displayMode}
									value={value}
									className="mb-3"
									onChange={onChange}
								/>
							)}
						/>
						<Controller
							control={control}
							name="defaultIntrastateRate"
							render={({ field: { onChange, value } }) => (
								<RtxPrecisionInput
									precision={6}
									label="Default Intrastate Rate"
									displayMode={displayMode}
									value={value}
									className="mb-3"
									onChange={(value) => onChange(Number(value))}
								/>
							)}
						/>
						<Controller
							control={control}
							name="defaultIndeterminateRate"
							render={({ field: { onChange, value } }) => (
								<RtxPrecisionInput
									precision={6}
									label="Default Indeterminate Rate"
									displayMode={displayMode}
									value={value}
									className="mb-3"
									onChange={(value) => onChange(Number(value))}
								/>
							)}
						/>
						<Controller
							control={control}
							name="ratingParametersPriority"
							render={({ field: { onChange, value } }) => (
								<RtxRatingPrecedenceSelect<true>
									isMulti
									label="Rating Precedence"
									displayMode={displayMode}
									initialOptionId={value ?? undefined}
									value={ratingParametersPriority}
									onChange={(ratingPrecedence) => {
										setRatingParametersPriority(ratingPrecedence);
										onChange(ratingPrecedence.map((v) => v.value));
									}}
								/>
							)}
						/>
					</Col>
				</Row>
			)}
		</RtxForm>
	);
};
