import {
	Autocomplete,
	Box,
	InputAdornment,
	Link,
	Tooltip
} from '@mui/material';
import { Clear, Help } from '@mui/icons-material';
import { has, noop, uniq } from 'lodash-es';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RtxFormContext } from 'RtUi/components/rtx/form/context/FormContext';
import {
	isValidPhoneNumber,
	processValue
} from 'RtUi/components/rtx/inputs/PhoneNumber/utils';
import { RtxTextInput } from 'RtUi/components/rtx/inputs/Text/RtxTextInput';
import { RtxChip } from 'RtUi/components/rtx/ui/Chip/RtxChip';

export enum NumberTypes {
	NANP,
	IDDD
}

type InputValue<IsMulti extends boolean = false> = IsMulti extends true
	? string[]
	: string;

interface IRtxPhoneNumberInputProps<IsMulti extends boolean = false> {
	value: InputValue<IsMulti> | undefined;
	isMulti?: IsMulti;
	allowedType?: NumberTypes;
	onChange: (value: InputValue<IsMulti>) => void;
	shownLimit?: number;
	className?: string;
	displayMode?: boolean;
	required?: boolean;
	disabled?: boolean;
	label?: string;
}

export const RtxPhoneNumberInput = <IsMulti extends boolean = false>({
	value,
	onChange = noop,
	isMulti,
	shownLimit = 20,
	displayMode = false,
	allowedType,
	className,
	required,
	disabled,
	label = 'Phone Number'
}: IRtxPhoneNumberInputProps<IsMulti>) => {
	const [showAll, setShowAll] = useState<boolean>(false);
	const { setError, clearFormErrors, errors } = useContext(RtxFormContext);
	const [customValidityError, setCustomValidityError] = useState<string>();
	const [inputListValue, setInputListValue] = useState<string[]>([]);
	const [inputValue, setInputValue] = useState<string>('');
	const isMultiValue = Boolean(isMulti);

	const labelComponent = useMemo(() => {
		if (allowedType === undefined || displayMode) {
			return label;
		}

		if (allowedType === NumberTypes.NANP) {
			return (
				<Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span>{label}</span>
					<Tooltip title='Numbers may be entered as 10 Digits, "1" and 10 Digits, or "+1" and 10 Digits'>
						<Help />
					</Tooltip>
				</Box>
			);
		}

		return (
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
				<span>{label}</span>
				<Tooltip title='Numbers must be entered as "+<CountryCode><Digits>"'>
					<Help />
				</Tooltip>
			</Box>
		);
	}, [label, allowedType, displayMode]);

	const validateInputValue = useCallback(
		(value: InputValue<IsMulti>) => {
			if (displayMode) {
				return;
			}

			let newCustomValidityError = '';

			if (has(errors, 'root.phoneInput')) {
				clearFormErrors('phoneInput');
			}

			const isInvalid = isMulti
				? (value as string[]).filter((v) => !isValidPhoneNumber(v, allowedType))
						.length
				: !isValidPhoneNumber(value as string, allowedType);

			if (value && isInvalid) {
				newCustomValidityError = 'Invalid phone value';
				setError('phoneInput', newCustomValidityError);
			}

			setCustomValidityError(newCustomValidityError);
		},
		[errors, isMulti, allowedType, clearFormErrors, setError, displayMode]
	);

	useEffect(() => {
		if (isMultiValue) {
			const initialValue = (value ?? []) as string[];
			setInputListValue(
				initialValue.filter((n) => n).map((n) => processValue(n, allowedType))
			);
			validateInputValue(initialValue.filter((n) => n) as InputValue<IsMulti>);
			return;
		}

		const initialValue = (value ?? '') as string;
		setInputValue(processValue(initialValue, allowedType));
		validateInputValue(initialValue as InputValue<IsMulti>);
	}, [isMultiValue, allowedType, validateInputValue, value]);

	const onChangeMultiValue = (value: string[]) => {
		const newValue = uniq(value);
		validateInputValue(newValue as InputValue<IsMulti>);
		setInputListValue(newValue);
		onChange(newValue.map((v) => v.replace(/\D/g, '')) as InputValue<IsMulti>);
	};

	const onPaste = (evt: React.ClipboardEvent<HTMLInputElement>) => {
		const text = evt.clipboardData.getData('text');
		const numbers = text.split(/[\s\n\r,]+/);
		onChangeMultiValue([
			...inputListValue,
			...numbers.map((n) => processValue(n, allowedType))
		]);
		evt.preventDefault();
	};

	if (isMultiValue) {
		return (
			<Autocomplete
				className="flex-grow-1"
				options={[]}
				disabled={disabled || displayMode}
				sx={{
					'.MuiInputBase-root': {
						'padding': '10px 14px 5px',
						'.MuiInputBase-input': {
							minSize: 10,
							marginTop: '0',
							padding: '0 !important'
						}
					}
				}}
				freeSolo
				value={inputListValue}
				multiple
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					const newValue = newInputValue.replace(/\D/g, '');
					setInputValue(processValue(newValue, allowedType));
				}}
				renderTags={(value, props) => {
					const useLimit = shownLimit ?? 0;
					const startDisplayIndex = showAll ? 0 : value.length - useLimit;

					return (
						<>
							{useLimit && value.length > useLimit && (
								<>
									{startDisplayIndex > 0 && (
										<Link
											component="button"
											variant="body2"
											className="Cpr-Value-Display-Link"
											onClick={() => {
												setShowAll(true);
											}}
										>
											<strong>
												({shownLimit} of {value.length}) Show All&nbsp;
											</strong>
										</Link>
									)}
									{startDisplayIndex === 0 && (
										<Link
											component="button"
											className="Cpr-Value-Display-Link"
											variant="body2"
											onClick={() => {
												setShowAll(false);
											}}
										>
											<strong>Show Less&nbsp;</strong>
										</Link>
									)}
								</>
							)}
							{value.map((option, index) =>
								index < startDisplayIndex ? undefined : (
									<RtxChip
										label={option}
										{...props({ index })}
										key={index}
										deleteIcon={<Clear />}
										className={
											!isValidPhoneNumber(option, allowedType) ? 'invalid' : ''
										}
									/>
								)
							)}
						</>
					);
				}}
				onChange={(event: any, newValue: string[]) => {
					onChangeMultiValue(newValue);
				}}
				renderInput={(params) => (
					<RtxTextInput
						{...params}
						label={labelComponent}
						className={className}
						displayMode={displayMode}
						disabled={disabled}
						error={displayMode ? undefined : Boolean(customValidityError)}
						helperText={displayMode ? undefined : customValidityError}
						onPaste={onPaste}
					/>
				)}
			/>
		);
	}

	return (
		<RtxTextInput
			label={labelComponent}
			value={displayMode ? inputValue : inputValue.replace(/\D/g, '')}
			className={className}
			required={required}
			onChange={(value) => {
				const newValue = value.replace(/\D/g, '');
				validateInputValue(newValue as InputValue<IsMulti>);
				setInputValue(processValue(newValue, allowedType));
				onChange(newValue as InputValue<IsMulti>);
			}}
			InputProps={{
				startAdornment: displayMode && isMulti && inputValue && (
					<InputAdornment position="start">
						<RtxChip label={inputValue} sx={{ color: '#00000061' }} />
					</InputAdornment>
				)
			}}
			displayMode={displayMode}
			disabled={disabled}
			error={displayMode ? undefined : Boolean(customValidityError)}
			helperText={displayMode ? undefined : customValidityError}
		/>
	);
};

RtxPhoneNumberInput.displayName = 'RtxPhoneNumberInput';
