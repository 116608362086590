import { noop } from 'lodash-es';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import {
	CicIndexResponse,
	EntityIndexResponse,
	PeeringTypeIndexResponse,
	RespOrgIndexResponse,
	SubscriptionIndexResponse,
	SubscriptionPeeringCreateRequest,
	SubscriptionPeeringIndexResponse,
	SubscriptionPeeringUpdateRequest
} from 'RtModels';
import {
	createSubscriptionPeering,
	updateSubscriptionPeering
} from 'RtUi/app/rtLcr/SubscriptionPeering/services';
import { RtxForm } from 'RtUi/components/rtx/form';
import { RtxCarrierSelect } from 'RtUi/components/rtx/inputs/Select/instances/Carrier/RtxCarrierSelect';
import { RtxEntitySelect } from 'RtUi/components/rtx/inputs/Select/instances/Entity/RtxEntitySelect';
import { RtxPeeringTypeSelect } from 'RtUi/components/rtx/inputs/Select/instances/PeeringType/RtxPeeringTypeSelect';
import { RtxRespOrgSelect } from 'RtUi/components/rtx/inputs/Select/instances/RespOrg/RtxRespOrgSelect';
import { RtxSubscriptionSelect } from 'RtUi/components/rtx/inputs/Select/instances/Subscription/RtxSubscriptionSelect';
import { RtError } from 'RtUi/utils/errors/RtError';

type SubscriptionPeeringFormValue =
	| SubscriptionPeeringCreateRequest
	| SubscriptionPeeringUpdateRequest;

interface ISubscriptionPeeringEditorProps {
	onSuccess?: () => void;
	onCancel?: () => void;
	editMode?: SubscriptionPeeringIndexResponse;
	subscriptionId?: number;
}

export const SubscriptionPeeringEditor = ({
	onSuccess = noop,
	onCancel = noop,
	subscriptionId,
	editMode
}: ISubscriptionPeeringEditorProps) => {
	const [error, setError] = useState<RtError>();
	const [peeredValue, setPeeredValue] = useState<
		RespOrgIndexResponse | EntityIndexResponse | CicIndexResponse | undefined
	>();
	const [peeringType, setPeeringType] = useState<PeeringTypeIndexResponse>();
	const [subscription, setSubscription] = useState<SubscriptionIndexResponse>();
	const { mutateAsync: updateSubscription } = useMutation(
		updateSubscriptionPeering
	);
	const { mutateAsync: createSubscription } = useMutation(
		createSubscriptionPeering
	);

	const onSubmit = async (data: SubscriptionPeeringFormValue) => {
		setError(undefined);
		try {
			if (editMode) {
				await updateSubscription({
					subscriptionPeeringId: editMode.subscriptionPeeringId,
					updatedSubscriptionPeering: {
						peeredValue: data.peeredValue
					}
				});
			} else {
				await createSubscription(data as SubscriptionPeeringCreateRequest);
			}
			onSuccess();
		} catch (err: any) {
			setError(new RtError(err));
		}
	};

	return (
		<RtxForm<SubscriptionPeeringFormValue>
			defaultValues={{ subscriptionId, ...editMode }}
			onSubmit={onSubmit}
			error={
				error && {
					name: 'root',
					error: {
						message: error.message
					}
				}
			}
			onCancel={onCancel}
		>
			{({ control }) => (
				<>
					<Controller
						control={control}
						name="subscriptionId"
						render={({ field: { onChange, value } }) => (
							<RtxSubscriptionSelect
								required
								className="mb-3"
								displayMode={Boolean(subscriptionId)}
								onChange={(subscription) => {
									setSubscription(subscription);
									onChange(subscription.subscriptionId);
								}}
								value={subscription}
								initialOptionId={value}
								appendDropdownToBody={false}
							/>
						)}
					/>
					<Controller
						control={control}
						name="peeringTypeId"
						render={({ field: { onChange, value } }) => (
							<RtxPeeringTypeSelect
								value={peeringType}
								className="mb-3"
								initialOptionId={value}
								isDisabled={Boolean(editMode)}
								onChange={(type) => {
									setPeeringType(type);
									onChange(type.peeringTypeId);
									setPeeredValue(undefined);
								}}
								appendDropdownToBody={false}
							/>
						)}
					/>
					{peeringType !== undefined && (
						<Controller
							control={control}
							name="peeredValue"
							render={({ field: { onChange, value } }) => {
								switch (peeringType?.peeringTypeId) {
									case 1:
										return (
											<RtxEntitySelect
												allowAll
												onChange={(entity) => {
													setPeeredValue(entity);
													onChange(entity.entityId);
												}}
												value={peeredValue as EntityIndexResponse}
												initialOptionId={value}
												appendDropdownToBody={false}
											/>
										);
									case 2:
										return (
											<RtxRespOrgSelect
												allowAll
												onChange={(respOrg) => {
													setPeeredValue(respOrg);
													onChange(respOrg.respOrgId);
												}}
												value={peeredValue as RespOrgIndexResponse}
												initialOptionId={value}
												appendDropdownToBody={false}
											/>
										);
									case 3:
										return (
											<RtxCarrierSelect
												label="CIC"
												onChange={(selectedCarrier) => {
													setPeeredValue(selectedCarrier);
													onChange(selectedCarrier.cic);
												}}
												value={peeredValue as CicIndexResponse}
												initialOptionId={value}
												appendDropdownToBody={false}
											/>
										);
									default:
										return <></>;
								}
							}}
						/>
					)}
				</>
			)}
		</RtxForm>
	);
};
