import { FC, useState } from 'react';
import { FileProcessorType } from 'RtModels';
import { LrnLookupResource } from 'RtUi/app/rtVue/LrnLookup/lib/resources/LrnLookupResource';
import { DragAndDropFileUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFileUploader';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { FileUtils } from 'RtUi/utils/file/FileUtils';

interface ILrnLookupUploadProps {
	onCancel: () => void;
	onSuccess: () => void;
}

export const LrnLookupUpload: FC<ILrnLookupUploadProps> = ({
	onCancel,
	onSuccess
}) => {
	const [file, setFile] = useState<File | undefined>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [error, setError] = useState<any>(undefined);

	const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		if (!file) {
			return;
		}

		setError(undefined);
		setIsSubmitting(true);

		try {
			const resource = new LrnLookupResource();
			await resource.create(
				{
					fileProcessTypeId: FileProcessorType.LrnLookup,
					summary: ''
				},
				file
			);

			onSuccess();
		} catch (e) {
			setError(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<RtUiNarrowForm
			onCancel={onCancel}
			displayMode={false}
			onSubmit={onSubmit}
			submitButtonText="Upload"
			error={error}
			isSubmitting={isSubmitting}
		>
			<DragAndDropFileUploader
				required
				accept={FileUtils.AcceptTypes.Csv}
				value={file}
				onDrop={setFile}
			/>
		</RtUiNarrowForm>
	);
};

LrnLookupUpload.displayName = 'LrnLookupUpload';
