import { noop } from 'lodash-es';
import { useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { BlockedNumber, BlockedNumberCreateRequest } from 'RtModels';
import { BlockedNumberSpreadsheetParser } from 'RtUi/app/rtSip/BlockNumbers/lib/parsers/BlockedNumberSpreadsheetParser';
import { postBlockedNumbers } from 'RtUi/app/rtSip/BlockNumbers/lib/services';
import { RtxForm } from 'RtUi/components/rtx/form';
import { RtxIsActiveInput } from 'RtUi/components/rtx/inputs/IsActive/RtxIsActiveInput';
import {
	NumberTypes,
	RtxPhoneNumberInput
} from 'RtUi/components/rtx/inputs/PhoneNumber/RtxPhoneNumberInput';
import { RtxRadioInput } from 'RtUi/components/rtx/inputs/Radio/RtxRadioInput';
import { RtxTextInput } from 'RtUi/components/rtx/inputs/Text/RtxTextInput';
import { CollapsibleCard } from 'RtUi/components/ui/CollapsibleCard';
import { DragAndDropFileUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFileUploader';
import { RtError } from 'RtUi/utils/errors/RtError';
import { FileUtils } from 'RtUi/utils/file/FileUtils';

enum CreateType {
	NANP,
	IDDD,
	UPLOAD
}

const numberTypes = {
	[CreateType.NANP]: NumberTypes.NANP,
	[CreateType.IDDD]: NumberTypes.IDDD,
	[CreateType.UPLOAD]: NumberTypes.IDDD
};

interface IBlockNumberCreateFormProps {
	onUpdate?: (profile: BlockedNumber) => void;
}

export const BlockNumberCreateForm = ({
	onUpdate = noop
}: IBlockNumberCreateFormProps) => {
	const [createType, setCreateType] = useState<CreateType>(CreateType.NANP);
	const [file, setFile] = useState<File>();
	const [processingFile, setProcessingFile] = useState<boolean>(false);
	const [error, setError] = useState<RtError>();
	const { mutateAsync } = useMutation(postBlockedNumbers);

	const onSubmit = async (data: BlockedNumberCreateRequest) => {
		const newNumber = await mutateAsync(data);
		onUpdate(newNumber);
	};

	const processFile = async (file: File) => {
		const fileUtils = new FileUtils();

		try {
			setProcessingFile(true);
			setError(undefined);
			const spreadsheet = await fileUtils.fileToSpreadsheet(file);
			const parser = new BlockedNumberSpreadsheetParser(spreadsheet);
			parser.on('error', (eventMessage) => {
				setError(new RtError({ message: eventMessage }));
			});
			const parsedBlockedNumbers = parser.parseServiceNumberRecords();
			setFile(file);
			setProcessingFile(false);

			return parsedBlockedNumbers;
		} catch (error: any) {
			setProcessingFile(false);
			setError(error);
		}

		return undefined;
	};

	return (
		<>
			<RtxRadioInput<CreateType>
				className="mb-3"
				label="Block Numbers create type"
				value={createType}
				onChange={(value) => setCreateType(Number(value))}
				options={[
					{
						label: 'NANP',
						value: CreateType.NANP
					},
					{
						label: 'IDDD',
						value: CreateType.IDDD
					},
					{
						label: 'Upload a list of NANP/IDDD',
						value: CreateType.UPLOAD
					}
				]}
			/>
			{[CreateType.NANP, CreateType.IDDD].includes(createType) && (
				<Row>
					<Col md={8}>
						<RtxForm<BlockedNumberCreateRequest>
							createMode
							onSubmit={onSubmit}
							defaultValues={{
								blockedNumbers: [
									{
										isActive: 1
									}
								]
							}}
						>
							{({ control }) => (
								<>
									<Controller
										control={control}
										name="blockedNumbers"
										render={({ field: { onChange, value } }) => (
											<RtxPhoneNumberInput<true>
												required
												isMulti
												label="Phone Numbers"
												className="mb-3"
												value={value.map((n) => n.phoneNumber)}
												onChange={(numbers) => {
													const newValue = numbers.length
														? numbers.map((n) => ({
																...value[0],
																phoneNumber: n
															}))
														: [
																{
																	isActive: 1
																}
															];

													onChange(newValue);
												}}
												allowedType={numberTypes[createType]}
											/>
										)}
									/>
									<Controller
										control={control}
										name="blockedNumbers"
										render={({ field: { onChange, value } }) => (
											<RtxTextInput
												label="Summary"
												className="mb-3"
												required
												onChange={(summary) => {
													onChange(
														value.map((n) => ({
															...n,
															summary
														}))
													);
												}}
												value={value[0]?.summary}
											/>
										)}
									/>
									<Controller
										control={control}
										name="blockedNumbers"
										render={({ field: { onChange, value } }) => (
											<RtxIsActiveInput
												required
												hideBothOption
												className="mb-3"
												label="Block ANI"
												onChange={(blockAni) => {
													onChange(
														value.map((n) => ({
															...n,
															blockAni
														}))
													);
												}}
												value={value[0]?.blockAni}
											/>
										)}
									/>
									<Controller
										control={control}
										name="blockedNumbers"
										render={({ field: { onChange, value } }) => (
											<RtxIsActiveInput
												required
												className="mb-3"
												hideBothOption
												label="Block Destination"
												onChange={(blockDestination) => {
													onChange(
														value.map((n) => ({
															...n,
															blockDestination
														}))
													);
												}}
												value={value[0]?.blockDestination}
											/>
										)}
									/>
								</>
							)}
						</RtxForm>
					</Col>
				</Row>
			)}
			{createType === CreateType.UPLOAD && (
				<Row>
					<Col md={8}>
						<RtxForm<BlockedNumberCreateRequest>
							createMode
							onSubmit={onSubmit}
							defaultValues={{
								blockedNumbers: []
							}}
							error={
								error && {
									name: 'blockedNumbers',
									error: {
										message: error.message
									}
								}
							}
						>
							{({ control }) => (
								<>
									<CollapsibleCard
										header="Example of Blocked Numbers Spreadsheet"
										className="ps-0 mt-2"
									>
										<Table
											size="sm"
											bordered
											className="bg-white mb-0"
											responsive
										>
											<thead>
												<tr>
													<th>tn</th>
													<th>summary</th>
													<th>active</th>
													<th>block ani</th>
													<th>block destination</th>
												</tr>
											</thead>
											<tbody>
												{[
													['+1512-123-1234', 'test1', 1, 1, 1],
													['+152112345678', 'test2', 1, 0, 1],
													['+44123455656', 'test3', 0, 0, 1]
												].map((values, index) => (
													<tr key={index}>
														{values.map((value, innerIndex) => (
															<td className="text-monospace" key={innerIndex}>
																{value}
															</td>
														))}
													</tr>
												))}
											</tbody>
										</Table>
									</CollapsibleCard>
									<Controller
										control={control}
										name="blockedNumbers"
										render={({ field: { onChange } }) => (
											<DragAndDropFileUploader
												accept={FileUtils.AcceptTypes.Spreadsheet}
												required
												processing={processingFile}
												value={file}
												onDrop={async (file) => {
													const numbers = await processFile(file);
													onChange(numbers);
												}}
											/>
										)}
									/>
								</>
							)}
						</RtxForm>
					</Col>
				</Row>
			)}
		</>
	);
};
