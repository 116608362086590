import {
	RtxTextInput,
	RtxTextInputProps
} from 'RtUi/components/rtx/inputs/Text/RtxTextInput';

type RtxTextAreaInputProps = Omit<RtxTextInputProps, 'rows' | 'multiline'>;

export const RtxTextAreaInput = ({ ...props }: RtxTextAreaInputProps) => {
	return <RtxTextInput multiline rows={4} {...props} />;
};
