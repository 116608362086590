import { FinancialIndexResponseData } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { CallDateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDateDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { FileStreamDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FileStreamDataColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PercentDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PercentDataGridColumn';
import { RateCenterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateCenterDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { noop } from 'lodash-es';
import { CallDurationDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDurationDataGridColumn';

export const getFinancialColumns = (
	decimals: number,
	callDateFormat?: string,
	onDrillDown: (row: FinancialIndexResponseData) => void = noop
): Array<DataGridColumn<FinancialIndexResponseData>> => [
	FileStreamDataGridColumn({
		accessorKey: 'fileStreamId',
		header: 'File Stream',
		hiddenIfEmpty: true
	}),
	CallDateDataGridColumn({
		accessorKey: 'callDate',
		header: 'Call Date',
		format: callDateFormat
	}),
	AccountDataGridColumn({
		accessorKey: 'customerAccountId',
		header: 'Customer Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'customerSubscriptionId',
		header: 'Customer Subscription',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressCountry',
		header: 'Ingress Country',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressConnectionId',
		header: 'Ingress Connection Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressTrunkGroup',
		header: 'Ingress Trunk Group',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressTrunkGroupLabel',
		header: 'Ingress Trunk Group Label',
		hiddenIfEmpty: true
	}),
	RateCenterDataGridColumn({
		accessorKey: 'ingressRateCenterId',
		header: 'Ingress Rate Center',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'vendorAccountId',
		header: 'Vendor Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'vendorSubscriptionId',
		header: 'Vendor Subscription',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressCountry',
		header: 'Egress Country',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressConnectionId',
		header: 'Egress Connection Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressTrunkGroup',
		header: 'Egress Trunk Group',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressTrunkGroupLabel',
		header: 'Egress Trunk Group Label',
		hiddenIfEmpty: true
	}),
	RateCenterDataGridColumn({
		accessorKey: 'egressRateCenterId',
		header: 'Egress Rate Center',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'dialCode',
		header: 'Dial Code',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'jurisdiction',
		header: 'Jurisdiction',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ocn',
		header: 'OCN',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ocnType',
		header: 'OCN Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'company',
		header: 'Common Company',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'commonName',
		header: 'Common Name',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'customerExternalAccountId',
		header: 'Customer Account Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'customerExternalSubAccountId',
		header: 'Customer Sub Account Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'vendorExternalAccountId',
		header: 'Vendor Account Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'vendorExternalSubAccountId',
		header: 'Vendor Sub Account Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'attestation',
		header: 'Attestation',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'callCount',
		header: 'Call Count',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	CallDurationDataGridColumn({
		accessorKey: 'duration',
		header: 'Duration',
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'revenue',
		header: 'Revenue',
		hiddenIfEmpty: true,
		showFooter: true,
		decimals
	}),
	PrecisionDataGridColumn({
		accessorFn: (record: any) =>
			Number(Number(record.revenue) / Number(record.duration)),
		header: 'RPM',
		hiddenIfEmpty: true,
		isHidden: true,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'cost',
		header: 'Cost',
		hiddenIfEmpty: true,
		showFooter: true,
		decimals
	}),
	PrecisionDataGridColumn({
		accessorFn: (record: any) =>
			Number(Number(record.cost) / Number(record.duration)),
		header: 'CPM',
		hiddenIfEmpty: true,
		isHidden: true,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'margin',
		header: 'Margin',
		hiddenIfEmpty: true,
		showFooter: true,
		decimals
	}),
	PrecisionDataGridColumn({
		accessorFn: (record: any) =>
			Number(Number(record.margin) / Number(record.duration)),
		header: 'MPM',
		hiddenIfEmpty: true,
		isHidden: true,
		decimals: 6
	}),
	IntegerDataGridColumn({
		accessorKey: 'negCallCount',
		header: 'Neg Call Count',
		hiddenIfEmpty: true,
		showFooter: true
	}),
	CallDurationDataGridColumn({
		accessorKey: 'negDuration',
		header: 'Neg Duration'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'negRevenue',
		header: 'Neg Revenue',
		hiddenIfEmpty: true,
		decimals
	}),
	PrecisionDataGridColumn({
		accessorKey: 'negCost',
		header: 'Net Cost',
		hiddenIfEmpty: true,
		showFooter: true,
		decimals
	}),
	PrecisionDataGridColumn({
		accessorKey: 'negMargin',
		header: 'Neg Margin',
		hiddenIfEmpty: true,
		decimals,
		onDrillDown: (e, row) => {
			e.preventDefault();
			onDrillDown(row);
		}
	}),
	PercentDataGridColumn({
		accessorKey: 'marginPercent',
		header: 'Margin Percent',
		decimals: 2
	}),
	IntegerDataGridColumn({
		accessorKey: 'countTokens',
		header: 'Count Tokens',
		hiddenIfEmpty: true
	})
];
